import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { API_URLS } from '../constants/url';
import axiosInstance from '../utils/apis';
import { startLoader, stopLoader } from '../utils/utils';

interface FetchAllSegmentsAPIPayload {
  partnerId?: string;
  status: string;
  no_users: boolean;
}

interface FetchAllSegmentCategoriesAPIPayload {
  partnerId: string | undefined;
}

interface FetchSegmentAPIPayload {
  id: number | undefined;
}

interface DeleteSegmentAPIPayload {
  id: number | undefined;
}

export interface FetchAllSegmentsData {
  segment_id: number;
  internal_name: string;
  internal_description: string;
}

export interface tagType {
  label: string;
  tagId: number;
  tagName: string;
  description: string;
}

export interface UpsertSegmentsAPIPayload {
  segment_id: number | undefined | boolean;
  internal_name: string | undefined;
  internal_description: string | undefined;
  segment_source_name: string | undefined;
  expires_globally_at: number | Date | string | undefined;
  is_system_managed?: boolean | undefined;
  created?: string | undefined;
  updated?: string | undefined;
  lastUpdatedBy?: string;
}

export const getSegmentList = async (payload: FetchAllSegmentsAPIPayload) => {
  try {
    startLoader();
    const { partnerId, status, no_users } = payload;
    const response = await axiosInstance.get(
      API_URLS.GET_SEGMENT_LIST + (partnerId ? `?partnerId=${partnerId}` : '') + `&status=${status}` + `&no_users=${no_users}`,
    );
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const getSegmentCategories = async (payload: FetchAllSegmentCategoriesAPIPayload) => {
  try {
    startLoader();
    const { partnerId } = payload;
    const response = await axiosInstance.get(API_URLS.GET_CATEGORY_LIST + (partnerId ? `?partnerId=${partnerId}` : ''));
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const getSegmentById = async (payload: FetchSegmentAPIPayload) => {
  try {
    startLoader();
    const { id } = payload;
    const response = await axiosInstance.get(API_URLS.GET_SEGMENT + '?id=' + id);
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const deleteSegmentData = async (payload: DeleteSegmentAPIPayload) => {
  try {
    startLoader();
    const { id } = payload;
    const response = await axiosInstance.delete(API_URLS.DELETE_SEGMENT + '?id=' + id);
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.errType || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.errType);
    }
  }

  return null;
};

export const upsertSegmentData = async (payload: UpsertSegmentsAPIPayload, isEdit: boolean) => {
  try {
    startLoader();
    if (!isEdit) {
      const response = await axiosInstance.post(API_URLS.CREATE_SEGMENT, payload);
      if (response.success) {
        stopLoader();
        return response;
      }
    } else {
      const response = await axiosInstance.put(API_URLS.UPDATE_SEGMENT, payload);
      if (response.success) {
        stopLoader();
        return response;
      }
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};
