import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable, { IDataGridRef } from '../../component/DataTable/DataTable';
import { showCreateSegmentModalAction } from '../../redux/actions/modalActions/modalActions';
import { RootState } from '../../redux/reducers/rootReducer';
import { TAG_SEGMENT_LIST_COLUMN } from '../../constants/columns/tagSegment.constants';
import { useCurrentPartner } from '../../hooks/useCurrentPartner';
import { getSegmentList } from '../../services/segment.services';
import { GridColDef } from '@mui/x-data-grid-pro';
import { DataResponseType } from '../PromoCodeChainRule/PromoCodeChainRule';

interface TagResponse {
  segment_id: number;
  internal_name: string;
  internal_description: string;
  segment_source_name: string;
  expires_globally_at: string;
  is_system_managed: boolean;
  created_at: string;
  updated_at: string;
  last_updated_by: string;
}

const TagList = () => {
  const dispatch = useDispatch();
  const { partnerShortName } = useCurrentPartner();
  const upsertSegmentData = useSelector((state: RootState) => state.modalReducer.showCreateSegment);
  const allocateSegmentData = useSelector((state: RootState) => state.modalReducer.showCreateSegmentFulfillmentJob);
  const globalDeleteModalData = useSelector((state: RootState) => state.modalReducer.showGlobalDelete);
  const dataGridRef = useRef<IDataGridRef>(null);
  const TAG_SEGMENT_COLUMN_DATA = TAG_SEGMENT_LIST_COLUMN();
  const columns = useMemo<GridColDef[]>(() => {
    return [...TAG_SEGMENT_COLUMN_DATA];
  }, [TAG_SEGMENT_COLUMN_DATA]);

  // Reload Table after updating or creating Tag
  useEffect(() => {
    if ((upsertSegmentData.show && upsertSegmentData?.data?.success) || !allocateSegmentData.show) {
      dataGridRef.current?.fetchData();
    }
  }, [upsertSegmentData, allocateSegmentData]);

  // Reload Table after deleting a Tag
  useEffect(() => {
    if (!globalDeleteModalData.show) {
      dataGridRef.current?.fetchData();
    }
  }, [globalDeleteModalData.show]);

  // Redirect Admin to Fulfillment Dashboard after creating a Job
  useEffect(() => {
    if (!allocateSegmentData.show && allocateSegmentData?.data?.success) {
      console.log('Redirect Admin to Fulfillment Dashboard');
    }
  }, [allocateSegmentData.show]);

  const handleDoubleClick = (e: any) => {
    if (e.row.systemManaged) {
      dispatch(showCreateSegmentModalAction(true, e.row, true));
    } else {
      dispatch(showCreateSegmentModalAction(true, e.row, false));
    }
  };

  const fetchData = async () => {
    try {
      const res: DataResponseType<TagResponse> = await getSegmentList({
        partnerId: partnerShortName,
        status: 'inactive',
        no_users: false,
      });
      const data = res?.data?.map((item: TagResponse) => {
        return {
          id: item.segment_id,
          name: item.internal_name,
          description: item.internal_description,
          category: item.segment_source_name,
          expires: item.expires_globally_at,
          systemManaged: item.is_system_managed,
          created: item.created_at,
          updated: item.updated_at,
          lastUpdatedBy: item.last_updated_by,
          action: item.segment_id,
        };
      });
      return {
        data: data || [],
        totalRows: res?.totalRows,
      };
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  return (
    <>
      <DataTable
        columns={columns}
        fetchData={fetchData}
        ref={dataGridRef}
        hideFooter
        isInfiniteScroll
        enableSearch
        handleDoubleClick={handleDoubleClick}
      />
    </>
  );
};

export default TagList;
