import React, { ReactElement, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import Grid from '@mui/material/Grid';
import { useHistory } from 'react-router-dom';
import { protectedRoutes } from '../../constants/routeEnum';
import Divider from '@mui/material/Divider';
import { useStyles } from './PartnerLandingStyle';
import ThemeContext from '../../context';
import { RenderByPermission } from '../../component/shared/RenderByPermission';
import { PartnerPermissions } from '../../constants/permission';
import { checkPermission } from '../../utils/permission';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';

/**
 * Withdraw list
 * @returns {JSX.Element}
 * @constructor
 */

interface PartnerType {
  name: string;
  theme: string;
  logo: string;
  permissions: string[];
  width?: string;
  aliasName: string;
}
export const partners = [
  {
    name: 'Game Taco',
    theme: 'gametaco',
    permissions: [PartnerPermissions.GameTaco],
    logo: '',
    width: '100%',
    aliasName: 'gt',
  },
  {
    name: 'Skillify',
    theme: 'skillify',
    permissions: [PartnerPermissions.Skillify],
    logo: '',
    width: '100%',
    aliasName: 'sk',
  },
  {
    name: 'World Winner',
    theme: 'worldwinner',
    permissions: [PartnerPermissions.WorldWinner],
    logo: '',
    aliasName: 'ww',
  },
  {
    name: 'Fanduel',
    theme: 'fanduel',
    permissions: [PartnerPermissions.FanDuel],
    logo: 'https://s3.amazonaws.com/cdn.fanduel.com/images/2019/Homepage/Home/fd-shield-logo.svg',
    width: '100%',
    aliasName: 'fd',
  },
];

const PartnerLandingPage = (): ReactElement => {
  const [, setThemeInfo] = React.useContext(ThemeContext);
  const [partnerData, setPartnerData] = React.useState<PartnerType>();
  const [totalPartner, setTotalPartner] = React.useState(0);
  const history = useHistory();

  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const handleClose = () => {
    setOpenDialog(false);
  };
  const confirmDialog = (data: PartnerType) => {
    setOpenDialog(true);
    setPartnerData(data);
  };

  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    width: '100% !important', // Overrides inline-style
    [theme.breakpoints.down('xl')]: {
      height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      '& .MuiImageBackdrop-root': {
        opacity: 0.15,
      },
      '& .MuiImageMarked-root': {
        opacity: 0,
      },
      '& .MuiTypography-root': {
        border: '4px solid currentColor',
      },
    },
  }));

  const ImageSrc = styled('img')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  });

  const Image = styled('span')(() => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
  }));

  const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.primary.light,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  }));

  const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.primary.light,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  }));

  function handleClick(data: PartnerType) {
    setOpenDialog(false);
    setThemeInfo(data.theme);
    history.push(data.aliasName + protectedRoutes.home);
  }
  useEffect(() => {
    const partnerCount: PartnerType[] = [];

    partners.forEach((item) => {
      if (checkPermission(item.permissions)) {
        partnerCount.push(item);
      }
    });
    setTotalPartner(partnerCount.length);
    if (partnerCount.length === 1) {
      handleClick(partnerCount[0]);
    }
  }, []);

  const classes = useStyles();

  return (
    <>
      {totalPartner !== 0 && (
        <>
          <Typography variant="h2" component="div" align="center" marginTop={2}>
            Select a Partner
          </Typography>
          <Divider
            style={{
              backgroundColor: 'black',
              borderBottomWidth: 3,
              marginTop: 25,
            }}
          />
        </>
      )}
      {/* <Grid container> */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%' }}>
        {partners.map((data) => {
          return (
            <RenderByPermission
              key={data.name}
              permissions={data.permissions}
              renderIf={() => (
                <ImageButton
                  focusRipple
                  key={data.name}
                  style={{
                    width: data.width,
                  }}
                  onClick={() => (localStorage.getItem('theme') !== null ? confirmDialog(data) : handleClick(data))}
                >
                  <ImageSrc style={{ backgroundImage: `src(${data.logo})` }} />
                  <ImageBackdrop className="MuiImageBackdrop-root" />
                  <Image>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="inherit"
                      sx={{
                        position: 'relative',
                        fontSize: '2rem',
                        p: 4,
                        pt: 2,
                        pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                      }}
                    >
                      {data.name}
                      <ImageMarked className="MuiImageMarked-root" />
                    </Typography>
                  </Image>
                </ImageButton>
              )}
            />
          );
        })}
      </Box>
      {/* </Grid> */}
      {totalPartner === 0 && (
        <Card className={classes.noPartner} sx={{ px: '15px' }}>
          <CardContent>
            <Typography variant="h6" align="center">
              {'Uh oh!, It looks like no roles have been assigned to you yet.'}
            </Typography>
          </CardContent>
        </Card>
      )}

      {localStorage.getItem('theme') !== null && (
        <Dialog open={openDialog} onClose={handleClose}>
          <DialogTitle>
            Are you sure you want to switch to <b>{partnerData?.name}</b> ?
          </DialogTitle>

          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={() => handleClick(partnerData as PartnerType)}>Yes</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default PartnerLandingPage;
