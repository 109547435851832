import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Checkbox, Chip, Tooltip } from '@mui/material';
import { COMMON_FILTER_COMPONENT } from './commonFilterComponent';
import { Dispatch } from 'redux';
import { payloadInterface, showCreatePromoCodeModalAction } from '../../redux/actions/modalActions/modalActions';

interface skuClassesInterface {
  entitlements: string;
  entitlementsChip: string;
}
export const SKU_LIST_COLUMN = (skuClasses: skuClassesInterface, dispatch: Dispatch<{ type: string; payload: payloadInterface }>): GridColDef[] => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      description: 'Record ID on server',
      hide: true,
    },
    {
      field: 'name',
      headerName: 'SKU Name',
      width: 160,
      description: 'The user-friendly name of the SKU. Not shown to users.',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params?.row?.description} placement="right-start">
          <span style={{ textOverflow: 'ellipsis', width: '100px' }}>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'promoList',
      headerName: 'Promo Code',
      width: 200,
      description: 'PromoCode for SKU',
      renderCell: (params: GridRenderCellParams) => (
        <div className={skuClasses.entitlements}>
          {params.value?.map((promoList: any, index: number) => {
            return (
              <Tooltip
                sx={{
                  position: 'relative',
                  bottom: '10px',
                  right: '5px',
                }}
                title={promoList.description}
                key={promoList.id}
                placement="top-start"
              >
                <Chip
                  key={index}
                  label={promoList.name}
                  className={skuClasses.entitlementsChip}
                  onClick={() => dispatch(showCreatePromoCodeModalAction(true, promoList, false))}
                ></Chip>
              </Tooltip>
            );
          })}
        </div>
      ),
      filterOperators: COMMON_FILTER_COMPONENT,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 150,
      hide: true,
      description: 'SKU Description',
    },
    { field: 'price', headerName: 'Price', width: 78, description: 'Price' },
    {
      field: 'entitlements',
      headerName: 'Entitlements',
      width: 350,
      hide: true,
      description: 'The currencies and amounts given to users who purchase the SKU.',
      renderCell: (params: GridRenderCellParams) => (
        <div className={skuClasses.entitlements}>
          {Object.keys(params.value).map((entitlements: string, index: number) => {
            return <Chip key={index} label={`${entitlements}: ${params.value[entitlements]}`} className={skuClasses.entitlementsChip}></Chip>;
          })}
        </div>
      ),
      filterOperators: COMMON_FILTER_COMPONENT,
    },
    {
      field: 'platforms',
      headerName: 'Platform Ids',
      width: 200,
      hide: true,
      description: 'PlatForm Ids for SKU',
      renderCell: (params: GridRenderCellParams) => (
        <div className={skuClasses.entitlements}>
          {params.value?.map((platForm: string, index: number) => {
            return (
              <Chip
                key={index}
                label={platForm}
                // className={skuClasses.entitlementsChip}
              ></Chip>
            );
          })}
        </div>
      ),
      filterOperators: COMMON_FILTER_COMPONENT,
    },
    {
      field: 'platformNames',
      headerName: 'Platform Names',
      width: 200,
      // hide: true,
      description: 'PlatForm Names for SKU',
      renderCell: (params: GridRenderCellParams) => (
        <div className={skuClasses.entitlements}>
          {params.value?.map((platForm: string, index: number) => {
            return (
              <Chip
                key={index}
                label={platForm}
                // className={skuClasses.entitlementsChip}
              ></Chip>
            );
          })}
        </div>
      ),
      filterOperators: COMMON_FILTER_COMPONENT,
    },
    {
      field: 'isEnabled',
      headerName: 'Enabled',
      width: 130,
      description: 'Is the SKU available to users who match the whitelists/blacklists?',
      renderCell: (params: GridRenderCellParams) => <Checkbox checked={params.value} disabled name="isEnabled" />,
    },
    {
      field: 'isBaseSku',
      headerName: 'Base SKU',
      width: 125,
      description: 'Is this SKU a default SKU?',
      renderCell: (params: GridRenderCellParams) => <Checkbox checked={params.value} disabled name="isBaseSku" />,
    },
  ];
};
