export const gametaco = {
  palette: {
    primary: {
      light: '#ffac33',
      main: '#ff9800',
      dark: '#b26a00',
      contrastText: '#000000',
    },
    secondary: {
      light: '#e666fb',
      main: '#e040fb',
      dark: '#9c2caf',
      contrastText: '#fcfcfc',
    },
    info: {
      light: '#83ffe1',
      main: '#64ffda',
      dark: '#46b298',
    },
    warning: {
      light: '#ffff33',
      main: '#ffff00',
      dark: '#b2b200',
    },
    error: {
      light: '#ff7474',
      main: '#ff5252',
      dark: '#b23939',
    },
    success: {
      light: '#6fbf73',
      main: '#4caf50',
      dark: '#357a3',
    },
    background: {
      default: '#b26a00',
    },
    logo: {
      emblem: 'https://taco-webapp.s3.us-west-2.amazonaws.com/game_Taco_short_logo.png',
      CompanyLogo: '',
    },
  },
  typography: {},
};

export const common_theme = {
  palette: {
    primary: {
      light: '#ffac33',
      main: '#ff9800',
      dark: '#b26a00',
      contrastText: '#000000',
    },
    secondary: {
      light: '#e666fb',
      main: '#e040fb',
      dark: '#9c2caf',
      contrastText: '#fcfcfc',
    },
    info: {
      light: '#83ffe1',
      main: '#64ffda',
      dark: '#46b298',
    },
    warning: {
      light: '#ffff33',
      main: '#ffff00',
      dark: '#b2b200',
    },
    error: {
      light: '#ff7474',
      main: '#ff5252',
      dark: '#b23939',
    },
    success: {
      light: '#6fbf73',
      main: '#4caf50',
      dark: '#357a3',
    },
    background: {
      default: '#b26a00',
    },
    logo: {
      emblem: 'https://taco-webapp.s3.us-west-2.amazonaws.com/game_Taco_short_logo.png',
      CompanyLogo: '',
    },
  },
  typography: {},
};

export const skillify = {
  palette: {
    primary: {
      main: '#A020F0',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#A020F0',
      contrastText: '#fcfcfc',
    },
    info: {
      main: '#008080',
    },
    warning: {
      main: '#FFFF00',
    },
    error: {
      main: '#FF0000',
    },
    success: {
      main: '#00FF00',
    },
    logo: {
      emblem: 'http://localhost:3000/images/SkillifyBullLogo.png',
      CompanyLogo: '',
    },
  },
  typography: {},
};

export const worldwinner = {
  palette: {
    primary: {
      light: '#6573c3',
      main: '#3f51b5',
      dark: '#2c387e',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#66cfff',
      main: '#40c4ff',
      dark: '#2c89b2',
      contrastText: '#fcfcfc',
    },
    info: {
      light: '#ffac33',
      main: '#ff9800',
      dark: '#b26a00',
    },
    warning: {
      light: '#ffff33',
      main: '#ffff00',
      dark: '#b2b200',
    },
    error: {
      light: '#ff7474',
      main: '#ff5252',
      dark: '#b23939',
    },
    success: {
      light: '#6fbf73',
      main: '#4caf50',
      dark: '#357a3',
    },
    logo: {
      emblem: 'https://taco-webapp.s3.us-west-2.amazonaws.com/game_Taco_short_logo.png',
      CompanyLogo: '',
    },
  },
  typography: {},
};

export const fanduel = {
  palette: {
    primary: {
      light: '#4383cc',
      dark: '#0e4686',
      main: '#0e4686',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#99dfff',
      dark: '#80d8ff',
      main: '#80d8ff',
      contrastText: '#fcfcfc',
    },
    info: {
      light: '#e7f7fe',
      dark: '#e1f5fe',
      main: '#e1f5fe',
    },
    warning: {
      light: '#ffff33',
      main: '#ffff00',
      dark: '#b2b200',
    },
    error: {
      light: '#ff7474',
      main: '#ff5252',
      dark: '#b23939',
    },
    success: {
      light: '#6fbf73',
      main: '#4caf50',
      dark: '#357a3',
    },
    background: {
      default: '#b26a00',
    },
    logo: {
      emblem: 'http://localhost:3000/images/FanDuelEmblem.png',
      CompanyLogo: '',
    },
  },
  typography: {},
};
