import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    paddingTop: '40px',
    marginTop: '20px',
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '20px',
    borderRadius: '20px',
    backgroundColor: '#d3d3d3',
    cursor: 'pointer',
  },
  addPartner: {
    padding: '40px 100px 20px 100px',
    marginTop: '30px',
    marginBottom: '20px',
    marginLeft: '30px',
    borderRadius: '20px',
    backgroundColor: '#d3d3d3',
    cursor: 'pointer',
  },
  noPartner: {
    padding: '40px 100px 20px 100px',
    borderRadius: '20px',
    backgroundColor: '#d3d3d3',
    cursor: 'pointer',
    position: 'absolute',
    width: '500px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});
