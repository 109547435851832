import React, { ReactElement } from 'react';

const PrizeRedeemHome = (): ReactElement => {
  return (
    <div>
      <h2>PrizeRedeem Home</h2>
    </div>
  );
};

export default PrizeRedeemHome;
