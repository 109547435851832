import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import SidebarItem from './SideBarItem';
import { clearFavoritesData, addFavoritesData } from '../../redux/actions/CommonActions/commonActions';
import { API_URLS } from '../../constants/url';

import { useAuth0 } from '@auth0/auth0-react';
import axiosInstance from '../../utils/apis';
import { openRoute } from '../../constants/routeEnum';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { appMenuItems } from '../../constants/sidebar.constant';
import { startLoader, stopLoader } from '../../utils/utils';

const PREFIX = 'SideBar';

const classes = {
  appMenu: `${PREFIX}-appMenu`,
  navList: `${PREFIX}-navList`,
  menuItem: `${PREFIX}-menuItem`,
  menuItemIcon: `${PREFIX}-menuItemIcon`,
};

const StyledList = styled(List)(() => ({
  width: '100%',

  [`& .${classes.navList}`]: {
    width: drawerWidth,
  },

  [`& .${classes.menuItem}`]: {
    width: drawerWidth,
  },

  [`& .${classes.menuItemIcon}`]: {
    color: '#97c05c ',
  },
}));
interface FavoriteItem {
  name?: string | undefined | null;
  title?: string | undefined | null;
  link?: string | undefined | null;
}

const SideBar: React.FC<{ open: boolean }> = (props: { open: boolean }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently({
      audience: `https://${process.env.REACT_APP_AUTH0_AUDIENCE}`,
    }).then((token) => {
      // eslint-disable-next-line no-console

      if (isAuthenticated) {
        const adminUserID = localStorage.getItem('adminUserID');
        startLoader();
        axiosInstance
          .get(`${API_URLS.FAVORITES_API}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              adminUserID: adminUserID,
            },
          })
          .then((res) => {
            const response = res.data;
            dispatch(clearFavoritesData());
            response.forEach((favorite: FavoriteItem) => {
              dispatch(
                addFavoritesData({
                  name: favorite.name,
                  title: favorite.title,
                }),
              );
            });
            stopLoader();
          })
          .catch((err) => {
            stopLoader();
            console.log(err); // eslint-disable-line
          });
      } else {
        history.push(openRoute.login);
      }
    });
  }, [isAuthenticated]);
  const { open } = props;
  return (
    <StyledList disablePadding>
      {appMenuItems.map((item, index) => {
        return <SidebarItem itemData={item} {...item} key={index} sidebarOpen={open} />;
      })}
    </StyledList>
  );
};

const drawerWidth = 240;

export default SideBar;
