import React, { Fragment, useEffect, useState } from 'react'; // useRef // useEffect, // Fragment,
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/rootReducer';
import {
  showCreateDailyReturnSetModalAction,
  showCreateDailyReturnSlotModalAction,
  showGlobalDeleteModalAction,
} from '../../../redux/actions/modalActions/modalActions';
// import { UpsertDailyReturnSlot } from '../DailyReturn/UpsertDailyReturnSlot';
import { Close, DeleteOutline, DragIndicator, Edit, Help } from '@mui/icons-material';
import { Box } from '@mui/system';
import { Field, Form } from 'react-final-form';
import InputWrapper from '../../UI/Input';
import InputFieldsTooltip from '../../InputFieldsTooltip';
import SelectWrapper from '../../UI/SelectWrapper';
import { composeValidators, maxLength, required } from '../../../utils/validations';
import useStyles from '../Sku/skuStyles';
import { Container, Draggable } from 'react-smooth-dnd';
import { API_URLS } from '../../../constants/url';
import { AxiosError } from 'axios';
import { startLoader, stopLoader } from '../../../utils/utils';
import axiosInstance from '../../../utils/apis';
import { toast } from 'react-toastify';
import { useCurrentPartner } from '../../../hooks/useCurrentPartner';
import '../../UI/Ui.css';
import { upsertDailyRewardSetData, upsertDailyRewardSlotData } from '../../../services/dailyReturn.service';
import AddIcon from '@mui/icons-material/Add';
import { IDeleteData } from '../../../container/CurrencyType/CurrencyType';

interface rewardInterface {
  currencyTypeId: string;
  amount: string;
}

interface setTypeInterface {
  value: number;
  label: string;
}

interface setInterface {
  name: string | undefined;
  description: string | undefined;
  type: setTypeInterface;
  sequenceNum: number | undefined;
  params: Record<string, unknown> | undefined;
  metadata: Record<string, unknown> | undefined;
}

interface slotInterface {
  id?: number;
  setId: number;
  slotId: number;
  name: string;
  weight: number;
  sequenceNum: number;
  rewards: rewardInterface[] | undefined;
}

interface DailyReturnTypeData {
  value: number;
  label: string;
  is_selected?: number;
}

export const reorder = (list: slotInterface[], startIndex: number | null, endIndex: number | null): slotInterface[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex || 0, 1);
  result.splice(endIndex || 0, 0, removed);

  return result;
};

export const UpsertDailyReturnSet = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { partnerShortName } = useCurrentPartner();

  const dailyReturnSetModalData = useSelector((state: RootState) => state.modalReducer.showDailyReturnSet);

  const slotModalState = useSelector((state: RootState) => state.modalReducer.showDailyReturnSlot);

  const setModalData = dailyReturnSetModalData.data;
  const [isEditForm, setIsEditForm] = useState<boolean>(false);

  const isReadable = dailyReturnSetModalData?.isReadable;

  // create state for set data
  const [setData, setFormData] = useState<setInterface>();

  const [slotData, setSlotData] = useState<slotInterface[]>([]);

  const [dailyReturnTypes, setDailyReturnTypes] = useState<DailyReturnTypeData[]>([]);

  const [id, setId] = useState<number | undefined>();

  const globalDeleteModalData = useSelector((state: RootState) => state.modalReducer.showGlobalDelete);

  const handleClose = () => {
    dispatch(showCreateDailyReturnSetModalAction(false, { success: true }));
    clearData();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const clearData = () => {
    setFormData({} as setInterface);
    setSlotData([]);
    setIsEditForm(false);
  };

  const onDrop = ({ removedIndex, addedIndex }: { removedIndex: number | null; addedIndex: number | null }) => {
    const newList = (items: slotInterface[]) => reorder(items, removedIndex, addedIndex);
    setSlotData(newList(slotData));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getDailyRewardSetData = async (setId: number | undefined) => {
    try {
      startLoader();
      setIsEditForm(true);
      const set = await axiosInstance.get(
        API_URLS.GET_DAILY_REWARD_SET + (partnerShortName ? `?partnerId=${partnerShortName}&id=${setId}` : `?id=${setId}`),
      );

      const slots = await axiosInstance.get(
        API_URLS.GET_DAILY_REWARD_SET_SLOTS + (partnerShortName ? `?partnerId=${partnerShortName}&id=${setId}` : `?id=${setId}`),
      );

      const reOrderedSlots = slots.data.sort((a: slotInterface, b: slotInterface) => a.sequenceNum - b.sequenceNum);

      const findValue = dailyReturnTypes.find((data: DailyReturnTypeData) => data.label === set.type)?.value;

      setFormData({
        name: set?.data.name,
        description: set?.data.description,
        type: {
          value: Number(findValue),
          label: set?.data.type,
        },
        sequenceNum: set?.data.sequenceNum,
        metadata: set?.data.metadata || {},
        params: set?.data.params || {},
      });
      setSlotData(reOrderedSlots);
      stopLoader();
    } catch (e: unknown | AxiosError) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        toast.error((e as AxiosError)?.response?.data?.message || e.message);
      } else {
        toast.error((e as AxiosError).response?.data?.message);
      }
    }

    return null;
  };

  const handleDailyReturnSetSubmit = async (value: setInterface) => {
    let data;
    let type;
    if (isEditForm) {
      type = 'updated';
      data = {
        setId: Number(setModalData?.id),
        name: value?.name,
        description: value?.description,
        type: value?.type?.label,
        sequenceNum: value?.sequenceNum,
        metadata: setData?.metadata || {},
        params: setData?.params || {},
      };
    } else {
      type = 'created';
      setIsEditForm(false);
      data = {
        setId: undefined,
        name: value?.name,
        description: value?.description,
        type: value?.type?.label,
        sequenceNum: 1,
        metadata: {},
        params: {},
      };
    }

    try {
      startLoader();
      const response = await upsertDailyRewardSetData({ isEdit: isEditForm, data });
      if (response) {
        let sequence = 0;
        for (const slot of slotData) {
          sequence += 1;
          slot.sequenceNum = sequence;
          data = {
            slotId: slot.slotId,
            setId: slot.setId || response.data,
            name: slot.name,
            weight: slot.weight,
            rewards: slot.rewards,
            sequenceNum: sequence,
            metadata: {},
            params: {},
          };
          await upsertDailyRewardSlotData({ isEdit: true, data });
        }
        stopLoader();
        toast.success(`Successfully ${type} Set!`);
      }
      if (isEditForm) {
        handleClose();
      } else {
        setIsEditForm(true);
        setId(response.data);
        getDailyRewardSetData(response.data);
      }
    } catch (e) {
      toast.error('There was a problem creating or updating the set!');
    }
  };

  useEffect(() => {
    if (!globalDeleteModalData.show && dailyReturnSetModalData.show && setModalData?.id) {
      getDailyRewardSetData(setModalData?.id);
    }
  }, [globalDeleteModalData.show, dailyReturnSetModalData.show]);

  useEffect(() => {
    const dailyReturnTypes: DailyReturnTypeData[] = [{ value: 1, label: 'WHEEL' }];

    if (setModalData?.id && dailyReturnSetModalData.show) {
      getDailyRewardSetData(setModalData.id);
    } else {
      setSlotData([]);
    }
    setDailyReturnTypes([...dailyReturnTypes]);
    setId(setModalData?.id || undefined);
  }, [setModalData?.id, setModalData, dailyReturnSetModalData.show]);

  useEffect(() => {
    if (!slotModalState.show && dailyReturnSetModalData.show && setModalData?.id) {
      getDailyRewardSetData(setModalData?.id);
    }
  }, [slotModalState.show, dailyReturnSetModalData.show]);

  return (
    <div>
      <Dialog sx={{ zIndex: 400 }} open={dailyReturnSetModalData.show} onClose={handleClose} disableRestoreFocus fullWidth maxWidth="md">
        <div>
          <DialogContent>
            <Typography align="center" variant="h5" style={{ marginBottom: '10px' }}>
              {id === undefined ? 'Create Set' : 'Edit Set'}
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right', marginTop: '-40px' }}>
              <Close />
            </IconButton>
            <Divider
              sx={{
                backgroundColor: 'black',
                borderBottomWidth: 2,
                marginTop: '25px',
                marginBottom: '25px',
              }}
            />
            <Box sx={{ mt: '20px' }}>
              <Grid container>
                <Grid xs={12}>
                  <Form onSubmit={handleDailyReturnSetSubmit}>
                    {({ handleSubmit }) => (
                      <form method="post" onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Fragment>
                                <Grid item xs={8} className={classes.gridStyle}>
                                  <Field
                                    component={InputWrapper}
                                    id="standard-basic"
                                    variant="outlined"
                                    defaultValue={setModalData?.id ? setData?.name : ''}
                                    name={'name'}
                                    type="text"
                                    readOnly={isReadable}
                                    placeholder="Please enter a set name."
                                    label={
                                      <InputFieldsTooltip
                                        title="Set Name"
                                        description="The Set Name is a user-friendly name to easily identify the Wheel, this is not seen by players."
                                      />
                                    }
                                  />
                                </Grid>

                                <Grid item xs={4}>
                                  <Field
                                    name="type"
                                    option={dailyReturnTypes}
                                    component={SelectWrapper}
                                    validate={required}
                                    readOnly={isReadable}
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    initialValue={setModalData?.id ? setData?.type : dailyReturnTypes[1]}
                                    focused
                                    label={
                                      <Fragment>
                                        Set Type
                                        <Tooltip
                                          sx={{
                                            position: 'relative',
                                            bottom: '10px',
                                            right: '5px',
                                          }}
                                          title="Select which type of set is being defined."
                                          placement="right-start"
                                        >
                                          <IconButton size="medium">
                                            <Help sx={{ fontSize: '20px' }} />
                                          </IconButton>
                                        </Tooltip>
                                      </Fragment>
                                    }
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 200,
                                        },
                                      },
                                    }}
                                  >
                                    {}
                                  </Field>
                                </Grid>
                              </Fragment>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Field
                              name="description"
                              component={InputWrapper}
                              type="text"
                              rows={3}
                              readOnly={isReadable}
                              multiline
                              defaultValue={setModalData?.id ? setData?.description : ''}
                              validate={composeValidators(required, maxLength(128))}
                              placeholder="Description"
                              label={
                                <InputFieldsTooltip
                                  title="Description"
                                  description="Please enter a description for this set. This is not seen by players."
                                />
                              }
                            />
                          </Grid>
                          {isEditForm && (
                            <Grid item xs={12} className={classes.gridStyle}>
                              <fieldset>
                                <legend>
                                  <Typography variant="body1">
                                    Slots
                                    <Tooltip title="Add a Slot to the Set." placement="right-start">
                                      <IconButton
                                        onClick={() => {
                                          dispatch(showCreateDailyReturnSlotModalAction(true, { setId: setModalData?.id }));
                                        }}
                                        size="medium"
                                      >
                                        <AddIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                </legend>

                                <List
                                  className={'dailyRewardOverFlow'}
                                  sx={{
                                    maxHeight: '350px',
                                    overflow: 'auto',
                                  }}
                                >
                                  <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
                                    {slotData?.map((slot, index) => (
                                      <Draggable key={index}>
                                        <ListItem
                                          sx={{
                                            border: 'solid',
                                            borderWidth: '1px',
                                            marginBottom: '5px',
                                            borderRadius: '25px',
                                            background: '#f5f5f5',
                                          }}
                                        >
                                          <Grid xs={12}>
                                            <Grid container spacing={1}>
                                              <Grid item xs={2}>
                                                <ListItemText
                                                  sx={{
                                                    marginTop: '15px',
                                                  }}
                                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                  // @ts-ignore
                                                  primary={`${slot?.name}`}
                                                />
                                              </Grid>

                                              <Grid item xs={2}>
                                                <ListItemText
                                                  sx={{
                                                    marginTop: '15px',
                                                  }}
                                                  primary={`Weight: ${
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    // @ts-ignore
                                                    Number(slot?.weight) * 100
                                                  } %`}
                                                />
                                              </Grid>
                                              <Grid xs={6}>
                                                <Grid xs={12}>
                                                  <Grid
                                                    container
                                                    spacing={1}
                                                    sx={{
                                                      marginTop: '10px',
                                                    }}
                                                  >
                                                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                                    {/*//@ts-ignore*/}
                                                    {slot?.rewards?.realmoney ? (
                                                      <Grid item xs={4}>
                                                        <Chip
                                                          sx={{
                                                            background: '#65D6AD',
                                                          }}
                                                          key={'realmoney'}
                                                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                          // @ts-ignore
                                                          label={`Real Money: ${slot?.rewards?.realmoney}`}
                                                        ></Chip>
                                                      </Grid>
                                                    ) : null}

                                                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                                    {/*//@ts-ignore*/}
                                                    {slot?.rewards?.bonusbuck ? (
                                                      <Grid item xs={4}>
                                                        <Chip
                                                          sx={{
                                                            background: '#65D6AD',
                                                          }}
                                                          key={'bonusbucks'}
                                                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                          // @ts-ignore
                                                          label={`Bonus Bucks: ${slot?.rewards?.bonusbuck}`}
                                                        ></Chip>
                                                      </Grid>
                                                    ) : null}

                                                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                                    {/*//@ts-ignore*/}
                                                    {slot?.rewards?.rewardpoint ? (
                                                      <Grid item xs={4}>
                                                        <Chip
                                                          sx={{
                                                            background: '#65D6AD',
                                                          }}
                                                          key={'reawrdpoints'}
                                                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                          // @ts-ignore
                                                          label={`Reward Points: ${slot?.rewards?.rewardpoint}`}
                                                        ></Chip>
                                                      </Grid>
                                                    ) : null}
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                              <Grid xs={2}>
                                                <Grid container>
                                                  <Grid item xs={12}>
                                                    <Tooltip title="Edit Slot">
                                                      <Button
                                                        size="small"
                                                        style={{ marginLeft: 16 }}
                                                        onClick={() => {
                                                          dispatch(
                                                            showCreateDailyReturnSlotModalAction(true, {
                                                              id: slot?.slotId,
                                                            }),
                                                          );
                                                        }}
                                                      >
                                                        <Edit color="secondary" />
                                                      </Button>
                                                    </Tooltip>
                                                  </Grid>
                                                  <Grid item xs={12}>
                                                    <Tooltip title="Delete Slot">
                                                      <Button
                                                        size="small"
                                                        style={{ marginLeft: 16 }}
                                                        onClick={() => {
                                                          const deleteData: IDeleteData = {};
                                                          deleteData.deleteModule = 'Slot';
                                                          deleteData.deleteId = slot?.slotId;
                                                          deleteData.item = {};
                                                          deleteData.item.value = slot?.slotId;
                                                          deleteData.item.label = slot?.name;
                                                          deleteData.item.item = {};
                                                          deleteData.item.item.description = '';
                                                          dispatch(showGlobalDeleteModalAction(true, deleteData));
                                                        }}
                                                      >
                                                        <DeleteOutline color="secondary" />
                                                      </Button>
                                                    </Tooltip>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>

                                          <ListItemSecondaryAction>
                                            <ListItemIcon className="drag-handle">
                                              <DragIndicator sx={{ cursor: 'pointer' }} />
                                            </ListItemIcon>
                                          </ListItemSecondaryAction>
                                        </ListItem>
                                      </Draggable>
                                    ))}
                                  </Container>
                                </List>
                              </fieldset>
                            </Grid>
                          )}
                          <Fragment>
                            <Grid item xs={12}>
                              <Button variant="contained" type="submit" fullWidth disabled={isReadable}>
                                {id === undefined ? 'Create Set' : 'Update Set'}
                              </Button>
                            </Grid>
                          </Fragment>
                        </Grid>
                      </form>
                    )}
                  </Form>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};
