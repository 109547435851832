import React, { ReactElement } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/rootReducer';
import { showUpsertCurrencyTypeModalAction, showCreateSuccessModalAction } from '../../../redux/actions/modalActions/modalActions';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
// import TextField from '@mui/material/TextField';
import { Form, Field } from 'react-final-form';
import InputWrapper from '../../../component/UI/Input';
import { toast } from 'react-toastify';
import { addCurrencyType, updateCurrencyType } from '../../../services/currencyType.service';
import { composeValidators, required, maxLength, isLowerCase } from '../../../utils/validations';
import InputFieldsTooltip from '../../InputFieldsTooltip';

const UpsertCurrencyType = (): ReactElement => {
  const upsertCurrencyTypeData = useSelector((state: RootState) => state.modalReducer.showUpsertCurrencyType);
  const currencyTypeData = upsertCurrencyTypeData.data || {};
  const currencyTypeId = currencyTypeData?.id || currencyTypeData?.currencyTypeId;
  const isReadable = upsertCurrencyTypeData?.isReadable;

  const dispatch = useDispatch();

  const useStyles = makeStyles({
    divider: {
      backgroundColor: 'black',
      borderBottomWidth: 2,
      marginTop: '25px',
      marginBottom: '25px',
    },
    leftGrid: {
      marginRight: '90px',
      marginLeft: '20px',
      flexDirection: 'column',
    },
    rightGrid: {
      marginRight: '20px',
      marginLeft: '90px',
      flexDirection: 'column',
    },
    updateStatusButton: {
      backgroundColor: 'rgb(50, 205, 50)',
      color: 'black',
      width: '250px',
      height: '50px',
      textTransform: 'none',
    },
    gridStyle: {
      marginBottom: '25px',
    },
  });

  const classes = useStyles();
  const handleClose = () => {
    dispatch(showUpsertCurrencyTypeModalAction(false, null));
  };

  const handleCurrencyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    currencyTypeData['name'] = e.target.value;
  };

  const handleCurrencyDescChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    currencyTypeData['description'] = e.target.value;
  };

  const handleUpdateSubmit = async () => {
    try {
      const upsertCurrencyTypeData = {
        currencyType: {
          currencyTypeId: currencyTypeId,
          name: currencyTypeData.name,
          description: currencyTypeData.description,
        },
      };
      const res = await updateCurrencyType(upsertCurrencyTypeData);
      if (res) {
        dispatch(showCreateSuccessModalAction(false, { update: true }));
        dispatch(showUpsertCurrencyTypeModalAction(false, null));
        toast.success('Currency Type successfully updated!');
      }
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };
  const handleSubmit = async () => {
    try {
      const createCurrencyTypeData = {
        currencyType: {
          name: currencyTypeData.name,
          description: currencyTypeData.description,
        },
      };
      const res = await addCurrencyType(createCurrencyTypeData);
      if (res) {
        dispatch(showCreateSuccessModalAction(false, { create: true }));
        dispatch(showUpsertCurrencyTypeModalAction(false, null));
        toast.success('Created Currency Type Successfully');
      }
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };
  return (
    <div>
      <Dialog open={upsertCurrencyTypeData.show} onClose={handleClose} disableRestoreFocus>
        <DialogContent className="modal-body">
          <div className="modal-ct">
            <Grid container>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <Typography variant="h5" align="center">
                    {currencyTypeData && currencyTypeId ? 'Edit Currency Type' : 'Create Currency Type'}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right' }}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />

            <Grid container style={{ marginTop: '20px' }}>
              <Grid item xs={12}>
                <Form onSubmit={currencyTypeData && currencyTypeId ? handleUpdateSubmit : handleSubmit}>
                  {({ handleSubmit }) => (
                    <form method="post" onSubmit={handleSubmit}>
                      <Grid>
                        {currencyTypeData && currencyTypeId && (
                          <Grid item xs={12} className={classes.gridStyle}>
                            <Field
                              component={InputWrapper}
                              id="standard-basic"
                              variant="outlined"
                              defaultValue={currencyTypeData && currencyTypeId}
                              name="currencyTypeID"
                              type="number"
                              readOnly={isReadable}
                              disabled
                              label={<InputFieldsTooltip title="Currency Type ID" description="Record ID on the server." />}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} className={classes.gridStyle}>
                          <Field
                            component={InputWrapper}
                            id="standard-basic"
                            variant="outlined"
                            defaultValue={currencyTypeData && currencyTypeData.name ? currencyTypeData.name : ''}
                            name="name"
                            onChange={handleCurrencyNameChange}
                            type="text"
                            readOnly={isReadable}
                            validate={composeValidators(required, maxLength(128), isLowerCase)}
                            placeholder="Enter Currency Name"
                            label={
                              <InputFieldsTooltip
                                title="Currency Name"
                                description="Computer-friendly name for currency; please use all lowercase letters."
                              />
                            }
                          />
                        </Grid>
                        <Grid item xs={12} className={classes.gridStyle}>
                          <Field
                            component={InputWrapper}
                            validate={composeValidators(required)}
                            id="standard-basic"
                            variant="outlined"
                            name="description"
                            readOnly={isReadable}
                            defaultValue={currencyTypeData && currencyTypeData.description ? currencyTypeData.description : ''}
                            onChange={handleCurrencyDescChange}
                            type="text"
                            placeholder="Enter Description"
                            label={<InputFieldsTooltip title="Description" description="Human-friendly description of the currency type." />}
                          />
                        </Grid>
                      </Grid>
                      <Box
                        textAlign="center"
                        sx={{
                          marginBottom: '10 px',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Grid item lg={12}>
                          <Button variant="contained" color="primary" type="submit" fullWidth disabled={isReadable}>
                            <b>{currencyTypeData && currencyTypeId ? 'Edit Currency Type' : 'Create Currency Type'}</b>
                          </Button>
                        </Grid>
                      </Box>
                    </form>
                  )}
                </Form>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UpsertCurrencyType;
