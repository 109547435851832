export const prizeReedemTypeState = {
  request: 'PRIZE-REEDEM-API-REQUEST',
  success: 'PRIZE-REEDEM-API-SUCCESS',
  error: 'PRIZE-REEDEM-API-ERROR',
};

export const prizeRedeemCancelState = {
  request: 'PRIZE-REEDEM-CANCEL-API-REQUEST',
  success: 'PRIZE-REEDEM-CANCEL-API-SUCCESS',
  error: 'PRIZE-REEDEM-CANCEL-API-ERROR',
};

export const prizeRedeemConfirmState = {
  request: 'PRIZE-REEDEM-CONFIRM-API-REQUEST',
  success: 'PRIZE-REEDEM-CONFIRM-API-SUCCESS',
  error: 'PRIZE-REEDEM-CONFIRM-API-ERROR',
};
