import { GridColDef } from '@mui/x-data-grid-pro';

export const GOAL_GOALS_COLUMNS: GridColDef[] = [
  {
    headerName: 'ID',
    field: 'id',
    width: 100,
    description: 'Goal ID',
  },
  {
    headerName: 'Name',
    field: 'name',
    width: 250,
    description: 'Name',
  },
  {
    headerName: 'Description',
    field: 'description',
    width: 200,
    description: 'Description',
  },
];

export const GOAL_VARIABLES_COLUMNS: GridColDef[] = [
  {
    headerName: 'ID',
    field: 'id',
    width: 100,
    description: 'Goal ID',
  },
  {
    headerName: 'Name',
    field: 'name',
    width: 250,
    description: 'Name',
  },
  {
    headerName: 'Description',
    field: 'description',
    width: 200,
    description: 'Description',
  },
];
