import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/rootReducer';
import { showCreateSegmentModalAction, showCreateSuccessModalAction } from '../../../redux/actions/modalActions/modalActions';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';

import { Form, Field } from 'react-final-form';
import InputWrapper from '../../../component/UI/Input';
import { toast } from 'react-toastify';
import { getSegmentById, upsertSegmentData, getSegmentCategories, UpsertSegmentsAPIPayload } from '../../../services/segment.services';
import { composeValidators, required, maxLength } from '../../../utils/validations';
import InputFieldsTooltip from '../../InputFieldsTooltip';
import { stopLoader } from '../../../utils/utils';
import { AxiosError } from 'axios';
import SelectWrapper from '../../UI/SelectWrapper';
import { Help } from '@mui/icons-material';
import { useCurrentPartner } from '../../../hooks/useCurrentPartner';
import DateWrapper from '../../UI/DateWrapper';

interface SegmentCategory {
  value: number;
  label: string;
}

interface CategoryResponse {
  segment_source_id: number;
  segment_source_name: string;
}

const UpsertSegment = (): ReactElement => {
  const { partnerShortName } = useCurrentPartner();
  const upsertSegmentModalData = useSelector((state: RootState) => state.modalReducer.showCreateSegment);
  const segmentData = upsertSegmentModalData.data || {};
  const segmentId = segmentData?.id ? segmentData.id : false;
  const isReadable = upsertSegmentModalData?.isReadable;

  const [formData, setFormData] = useState<UpsertSegmentsAPIPayload>();

  const [categories, setCategories] = useState<SegmentCategory[]>([]);
  console.log({ categories });

  const dispatch = useDispatch();

  const useStyles = makeStyles({
    divider: {
      backgroundColor: 'black',
      borderBottomWidth: 2,
      marginTop: '25px',
      marginBottom: '25px',
    },
    leftGrid: {
      marginRight: '90px',
      marginLeft: '20px',
      flexDirection: 'column',
    },
    rightGrid: {
      marginRight: '20px',
      marginLeft: '90px',
      flexDirection: 'column',
    },
    updateStatusButton: {
      backgroundColor: 'rgb(50, 205, 50)',
      color: 'black',
      width: '250px',
      height: '50px',
      textTransform: 'none',
    },
    gridStyle: {
      marginBottom: '25px',
    },
  });

  const classes = useStyles();
  const handleClose = () => {
    dispatch(showCreateSegmentModalAction(false, null));
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log({ e });
    const updatedData = formData || ({} as UpsertSegmentsAPIPayload);

    updatedData.internal_name = e.target.value;

    setFormData({ ...updatedData });
  };

  const handleDescChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log({ e });
    const updatedData = formData || ({} as UpsertSegmentsAPIPayload);

    updatedData.internal_description = e.target.value;

    setFormData({ ...updatedData });
  };

  const fetchSegmentCategories = async () => {
    try {
      const SegmentCategoryRes = await getSegmentCategories({ partnerId: partnerShortName });

      console.log({ SegmentCategoryRes });

      const categories: SegmentCategory[] = SegmentCategoryRes?.data?.map((item: CategoryResponse) => {
        return {
          value: item.segment_source_id,
          label: item.segment_source_name,
        };
      });
      setCategories(categories);

      stopLoader();
    } catch (e: unknown | AxiosError) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        toast.error((e as AxiosError)?.response?.data?.message || e.message);
      } else {
        toast.error((e as AxiosError).response?.data?.message);
      }
    }

    return null;
  };

  const fetchSegmentData = async (id: number | undefined) => {
    try {
      const SegmentRes = await getSegmentById({ id: id });
      await setFormData({
        segment_id: SegmentRes?.data.segment_id,
        internal_name: SegmentRes?.data.internal_name,
        internal_description: SegmentRes?.data.internal_description,
        segment_source_name: SegmentRes?.data.segment_source_name,
        expires_globally_at: SegmentRes?.data.expires_globally_at,
        is_system_managed: SegmentRes?.data.is_system_managed,
        created: SegmentRes?.data.created_at,
        updated: SegmentRes?.data.updated_at,
        lastUpdatedBy: SegmentRes?.data.last_updated_by,
      });
      stopLoader();
    } catch (e: unknown | AxiosError) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        toast.error((e as AxiosError)?.response?.data?.message || e.message);
      } else {
        toast.error((e as AxiosError).response?.data?.message);
      }
    }

    return null;
  };

  const handleSubmit = async (formValues: {
    internal_name: string;
    internal_description: string;
    segment_source_name: { value: number; label: string };
    expires_globally_at?: number | Date | string | undefined;
  }) => {
    console.log({ formValues });
    try {
      const upsertData: UpsertSegmentsAPIPayload = {
        segment_id: undefined,
        internal_name: formValues?.internal_name,
        internal_description: formValues?.internal_description,
        segment_source_name: formValues?.segment_source_name.label,
        expires_globally_at: formValues?.expires_globally_at,
      };
      const res = await upsertSegmentData(upsertData, !!segmentId);
      if (res) {
        dispatch(showCreateSuccessModalAction(false, { create: true }));
        dispatch(showCreateSegmentModalAction(false, null));
        toast.success('Created Tag Successfully');
      }
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  const handleUpdateSubmit = async (formValues: {
    internal_name: string;
    internal_description: string;
    segment_source_name: { value: number; label: string };
    expires_globally_at?: number | Date | string | undefined;
  }) => {
    console.log({ formValues });
    try {
      const upsertData: UpsertSegmentsAPIPayload = {
        segment_id: segmentId,
        internal_name: formValues?.internal_name,
        internal_description: formValues?.internal_description,
        segment_source_name: formValues?.segment_source_name.label,
        expires_globally_at: formValues?.expires_globally_at,
      };
      const res = await upsertSegmentData(upsertData, !!segmentId);
      if (res) {
        dispatch(showCreateSuccessModalAction(false, { update: true }));
        dispatch(showCreateSegmentModalAction(false, null));
        toast.success('Tag successfully updated!');
      }
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  useEffect(() => {
    if (upsertSegmentModalData.show) {
      fetchSegmentCategories();
      if (segmentId) {
        fetchSegmentData(segmentId);
      }
    }
  }, [upsertSegmentModalData.show]);

  return (
    <div>
      <Dialog open={upsertSegmentModalData.show} fullWidth onClose={handleClose} disableRestoreFocus>
        <DialogContent className="modal-body">
          <div className="modal-ct">
            <Grid container>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <Typography variant="h5" align="center">
                    {segmentId ? 'Edit Tag' : 'Create Tag'}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right' }}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />

            <Grid container style={{ marginTop: '20px' }}>
              <Grid item xs={12}>
                <Form onSubmit={segmentId ? handleUpdateSubmit : handleSubmit}>
                  {({ handleSubmit }) => (
                    <form method="post" onSubmit={handleSubmit}>
                      <Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={6} className={classes.gridStyle}>
                            <Field
                              component={InputWrapper}
                              id="standard-basic"
                              variant="outlined"
                              defaultValue={segmentId ? formData?.internal_name : ''}
                              name="internal_name"
                              onChange={handleNameChange}
                              type="text"
                              readOnly={segmentId ? true : false}
                              disabled={segmentId ? true : false}
                              validate={composeValidators(required, maxLength(128))}
                              placeholder="Enter Segment Name"
                              label={
                                <InputFieldsTooltip
                                  title="Segment Name"
                                  description={segmentId ? 'Cannot edit the Name of an existing Segment.' : 'Segment Name'}
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={6} className={classes.gridStyle}>
                            <Field
                              name="segment_source_name"
                              option={categories}
                              component={SelectWrapper}
                              validate={required}
                              readOnly={isReadable}
                              disabled={segmentId ? true : false}
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              initialValue={
                                segmentId
                                  ? categories.find((o) => o.label === formData?.segment_source_name)
                                  : categories.find((o) => o.label === 'marketing')
                              }
                              focused
                              label={
                                <Fragment>
                                  Category
                                  <Tooltip
                                    sx={{
                                      position: 'relative',
                                      bottom: '10px',
                                      right: '5px',
                                    }}
                                    title={
                                      segmentId
                                        ? 'Cannot edit the Category of an existing Segment.'
                                        : 'Select which Category this Segment belongs too'
                                    }
                                    placement="right-start"
                                  >
                                    <IconButton size="medium">
                                      <Help sx={{ fontSize: '20px' }} />
                                    </IconButton>
                                  </Tooltip>
                                </Fragment>
                              }
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                  },
                                },
                              }}
                            >
                              {}
                            </Field>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.gridStyle}>
                          <Field
                            component={InputWrapper}
                            validate={composeValidators(required)}
                            multiline
                            id="standard-basic"
                            variant="outlined"
                            name="internal_description"
                            readOnly={isReadable}
                            disabled={isReadable}
                            defaultValue={segmentId ? formData?.internal_description : ''}
                            onChange={handleDescChange}
                            type="text"
                            placeholder="Enter Description"
                            label={<InputFieldsTooltip title="Description" description="Human-friendly description of the Segment." />}
                          />
                        </Grid>

                        <Grid item xs={12} className={classes.gridStyle}>
                          {segmentId ? (
                            <Field
                              name="expires_globally_at"
                              component={DateWrapper}
                              inputFormat="MM/dd/yyyy HH:mm:ss"
                              defaultValue={segmentId ? formData?.expires_globally_at : ''}
                              readOnly={isReadable}
                              disabled={isReadable}
                              type="datetime-local"
                              label={<InputFieldsTooltip title="Expires at" description="When will the Segment expire." />}
                            ></Field>
                          ) : (
                            <Field
                              name="expires_globally_at"
                              component={DateWrapper}
                              validate={required}
                              inputFormat="MM/dd/yyyy HH:mm:ss"
                              readOnly={isReadable}
                              disabled={isReadable}
                              type="datetime-local"
                              label={<InputFieldsTooltip title="Expires at" description="When will the Segment expire." />}
                            ></Field>
                          )}
                        </Grid>
                      </Grid>
                      <Box
                        textAlign="center"
                        sx={{
                          marginBottom: '10 px',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Grid item lg={12}>
                          <Button variant="contained" color="primary" type="submit" fullWidth disabled={isReadable}>
                            <b>{segmentId ? 'Edit Tag' : 'Create Tag'}</b>
                          </Button>
                        </Grid>
                      </Box>
                      <Grid container spacing={1}>
                        <Grid item justifyContent={'left'} display={'flex'}>
                          {segmentId && formData?.lastUpdatedBy ? `Last Updated By: ${formData.lastUpdatedBy}` : ''}
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Form>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UpsertSegment;
