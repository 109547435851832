export const messages = {
  userManagement: {
    addFunds: {
      modalName: 'Add Funds',
      header: 'Please select the type and amount of funds you would like to give SnowEmber',
      from: 'addfunds',
    },
    sendNotification: {
      modalName: 'Send Notification',
      header: 'Please select the type and amount of funds you would like to give SnowEmber',
      from: 'send-notification',
    },
    updateStatus: {
      modalName: 'User Account action',
      from: 'update-status',
      body: 'Player`s account will restrict their access to cash games on the platform.',
    },
  },
};

export const dropdowns = {
  userStatus: [
    { value: 'suspend', label: 'Suspend' },
    { value: 'ban', label: 'BAN' },
    { value: 'kill', label: 'KILL' },
  ],
  selectAction: [
    { value: 'fraud', label: 'Fraud' },
    { value: 'multiple-accounts', label: 'Multiple Accounts' },
  ],
  fundType: [
    { value: 'Cash', label: 'Cash' },
    { value: 'bonusMoney', label: 'Bonus Money' },
    { value: 'tacoTokens', label: 'Taco Tokens' },
    { value: 'rewardPoints', label: 'Reward Points' },
  ],

  fundReason: [
    { value: 'gift', label: 'Gift' },
    { value: 'compensation', label: 'Compensation' },
    { value: 'customerService', label: 'Customer Service' },
  ],
};
