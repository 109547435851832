export const URLS = {
  BASE_URL: `${process.env.REACT_APP_BASE_URL}/v2/`,
  WW_ADMIN_TOOL_URL: 'http://localhost:3002/api/v2/',
  DEVELOPMENT_BASE_URL: 'http://localhost:3000/api/development/',
};

export const API_URLS = {
  LOGIN_URL: process.env.REACT_APP_AUTH_BASE_URL + 'auth/local/',
  SEARCH_USER: URLS.BASE_URL + 'gt/playerSupport/searchUser?limit=10&offset=0&isIdsOnly=true&keyWord=',
  WITHDRAWALS_REQUEST: URLS.BASE_URL + 'gt/withdrawal/requests',
  WITHDRAWALS_HISTORY: URLS.BASE_URL + 'gt/withdrawal/history',
  WITHDRAWALS_PENDING: URLS.BASE_URL + 'gt/withdrawal/pending',
  TOURNAMENTS_LIST: URLS.BASE_URL + 'gt/tournament/all',
  GET_USER: URLS.BASE_URL + 'gt/playerSupport/userList',
  UPDATE_USER_STATUS: URLS.BASE_URL + 'gt/playerSupport/updatePlayerStatus',
  ADD_FUND_BY_TYPE: URLS.BASE_URL + 'gt/PlayerSupport/updateFunds',
  NOTIFICATION: URLS.BASE_URL + 'gt/PlayerSupport/notification',
  PRIZE_REDEEM_REQUEST: URLS.BASE_URL + 'gt/playerSupport/rp/redeem/requests',
  PRIZE_REDEEM_CONFIRM: URLS.BASE_URL + 'gt/playerSupport/rp/redeem/confirm',
  PRIZE_REDEEM_CANCEL: URLS.BASE_URL + 'gt/playerSupport/rp/redeem/cancel',
  PRIZE_REDEEM_HISTORY: URLS.BASE_URL + 'gt/playerSupport/rp/redeem/history',
  GET_PERMISSIONS: URLS.BASE_URL + 'common/permissions',
  GET_PROMO_CODE: URLS.BASE_URL + 'ww/marketing/promocode/get',
  GET_PROMO_CODE_BY_SKUID: URLS.BASE_URL + 'ww/marketing/promocode/getbyskuid',
  DELETE_PROMO_CODE: URLS.BASE_URL + 'ww/marketing/promocode/delete',
  ADD_PROMO_CODE: URLS.BASE_URL + 'ww/marketing/promocode/create',
  UPDATE_PROMO_CODE: URLS.BASE_URL + 'ww/marketing/promocode/update',
  GET_SKU: URLS.BASE_URL + 'ww/marketing/sku/get',
  DELETE_SKU: URLS.BASE_URL + 'ww/marketing/sku/delete',
  ADD_SKU: URLS.BASE_URL + 'ww/marketing/sku/create',
  UPDATE_SKU: URLS.BASE_URL + 'ww/marketing/sku/update',
  EDIT_SKU_TAG_BY_ID: URLS.BASE_URL + 'ww/marketing/sku/skuTag/editBySkuId',
  SKU_TAG_BY_SKU_ID: URLS.BASE_URL + 'ww/marketing/sku/skuTag/skuTagBySkuId',
  GET_ALL_TAG: URLS.BASE_URL + 'ww/marketing/sku/skuTag/all',
  GET_PROMO_TYPE: URLS.BASE_URL + 'ww/marketing/promocode/type/get',
  DELETE_PROMO_TYPE: URLS.BASE_URL + 'ww/marketing/promocode/type/delete',
  ADD_PROMO_TYPE: URLS.BASE_URL + 'ww/marketing/promocode/type/create',
  UPDATE_PROMO_TYPE: URLS.BASE_URL + 'ww/marketing/promocode/type/update',
  GET_CURRENCY_TYPE: URLS.BASE_URL + 'ww/platform/currency/type/get',
  DELETE_CURRENCY_TYPE: URLS.BASE_URL + 'ww/platform/currency/type/delete',
  ADD_CURRENCY_TYPE: URLS.BASE_URL + 'ww/platform/currency/type/create',
  UPDATE_CURRENCY_TYPE: URLS.BASE_URL + 'ww/platform/currency/type/update',
  TRANSACTIONS_LIST: URLS.BASE_URL + 'fd/discovery/transaction/all',
  FAVORITES_API: URLS.BASE_URL + 'common/favourites',
  DELETE_TRANSACTION: URLS.BASE_URL + 'fd/discovery/transaction/delete',
  RMV_DELETED_TRANSACTIONS: URLS.BASE_URL + 'fd/discovery/transaction/removeDeleted',
  UPDATE_TRANSACTION_STATUS: URLS.BASE_URL + 'fd/discovery/transaction/update',
  UNDO_DELETED_TRANSACTIONS: URLS.BASE_URL + 'fd/discovery/transaction/updateDeleted',
  GET_PROMO_CODE_EXECUTION_STRATEGY_LIST: URLS.BASE_URL + 'ww/marketing/promocode/executionStrategy/get',
  GET_PROMO_CODE_CHAIN_RULE: URLS.BASE_URL + 'ww/marketing/promocode/chainRule/get',
  DELETE_PROMO_CODE_CHAIN_RULE: URLS.BASE_URL + 'ww/marketing/promocode/chainRule/delete',
  ADD_PROMO_CODE_CHAIN_RULE: URLS.BASE_URL + 'ww/marketing/promocode/chainRule/create',
  UPDATE_PROMO_CODE_CHAIN_RULE: URLS.BASE_URL + 'ww/marketing/promocode/chainRule/update',
  GET_PROMO_CODE_EXPIRATION_STRATEGY: URLS.BASE_URL + 'ww/marketing/promocode/executionStrategy/get',
  GET_PLATFORM_LIST: URLS.BASE_URL + 'ww/marketing/promocode/chainRule/getPlatforms',
  GET_GOALS: URLS.BASE_URL + 'ww/goals/get',
  GET_DEPOSIT_TRANSACTIONS: URLS.BASE_URL + 'ww/deposit/get',
  SKIP_DEPOSIT_TRANSACTIONS: URLS.BASE_URL + 'ww/deposit/skip',
  GET_DAILY_REWARD_SET: URLS.BASE_URL + 'ww/marketing/daily-reward/set',
  GET_DAILY_REWARD_SETS: URLS.BASE_URL + 'ww/marketing/daily-reward/set/all',
  CREATE_DAILY_REWARD_SET: URLS.BASE_URL + 'ww/marketing/daily-reward/set',
  UPDATE_DAILY_REWARD_SET: URLS.BASE_URL + 'ww/marketing/daily-reward/set',
  DELETE_DAILY_REWARD_SET: URLS.BASE_URL + 'ww/marketing/daily-reward/set',
  GET_DAILY_RETURN_SLOT: URLS.BASE_URL + 'ww/marketing/daily-reward/slot',
  GET_DAILY_REWARD_SLOTS: URLS.BASE_URL + 'ww/marketing/daily-reward/slots/',
  GET_DAILY_REWARD_SET_SLOTS: URLS.BASE_URL + 'ww/marketing/daily-reward/slot/set',
  CREATE_DAILY_REWARD_SLOT: URLS.BASE_URL + 'ww/marketing/daily-reward/slot',
  UPDATE_DAILY_REWARD_SLOT: URLS.BASE_URL + 'ww/marketing/daily-reward/slot',
  DELETE_DAILY_REWARD_SLOT: URLS.BASE_URL + 'ww/marketing/daily-reward/slot',
  GET_DAILY_REWARD_SET_GROUP: URLS.BASE_URL + 'ww/marketing/daily-reward/set-group',
  GET_DAILY_REWARD_SET_GROUP_SETS: URLS.BASE_URL + 'ww/marketing/daily-reward/set-group/set',
  GET_DAILY_REWARD_SET_GROUPS: URLS.BASE_URL + 'ww/marketing/daily-reward/set-group/all',
  CREATE_DAILY_REWARD_SET_GROUP: URLS.BASE_URL + 'ww/marketing/daily-reward/set-group',
  UPDATE_DAILY_REWARD_SET_GROUP: URLS.BASE_URL + 'ww/marketing/daily-reward/set-group',
  DELETE_DAILY_REWARD_SET_GROUP: URLS.BASE_URL + 'ww/marketing/daily-reward/set-group',
  CREATE_DAILY_REWARD_SET_GROUP_MEMBER: URLS.BASE_URL + 'ww/marketing/daily-reward/set-group/member',
  UPDATE_DAILY_REWARD_SET_GROUP_MEMBER: URLS.BASE_URL + 'ww/marketing/daily-reward/set-group/member',
  DELETE_DAILY_REWARD_SET_GROUP_MEMBER: URLS.BASE_URL + 'ww/marketing/daily-reward/set-group/member',
  GET_SEGMENT_LIST: URLS.BASE_URL + 'ww/marketing/segment/all',
  GET_SEGMENT: URLS.BASE_URL + 'ww/marketing/segment',
  CREATE_SEGMENT: URLS.BASE_URL + 'ww/marketing/segment',
  UPDATE_SEGMENT: URLS.BASE_URL + 'ww/marketing/segment',
  DELETE_SEGMENT: URLS.BASE_URL + 'ww/marketing/segment',
  IS_SEGMENT_EMPTY: URLS.BASE_URL + 'ww/marketing/segment/empty',
  IS_SEGMENT_ARCHIVED: URLS.BASE_URL + 'ww/marketing/segment/archived',
  GET_CATEGORY: URLS.BASE_URL + 'ww/marketing/segment/category',
  GET_CATEGORY_LIST: URLS.BASE_URL + 'ww/marketing/segment/category/all',
  CREATE_FULFILLMENT_JOB: URLS.BASE_URL + 'ww/fulfillment',
  GET_FULFILLMENT_JOB_LIST: URLS.BASE_URL + 'ww/fulfillment/all',
  GET_FULFILLMENT_JOB: URLS.BASE_URL + 'ww/fulfillment',
};
