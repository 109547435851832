import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Chip, Tooltip } from '@mui/material';
import { COMMON_FILTER_COMPONENT } from './commonFilterComponent';
import { Dispatch } from 'redux';
import { payloadInterface } from '../../redux/actions/modalActions/modalActions';

interface SetClassesInterface {
  entitlements: string;
  entitlementsChip: string;
}
export const SET_LIST_COLUMN = (): GridColDef[] => {
  return [
    {
      field: 'id',
      headerName: 'Set ID',
      width: 80,
      description: 'Record ID on server',
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Set Name',
      width: 160,
      description: 'The user-friendly name for the Daily Return Set.',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params?.row?.description} placement="right-start">
          <span style={{ textOverflow: 'ellipsis', width: '100px' }}>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'type',
      headerName: 'Set Type',
      width: 150,
      description: 'The Type of Set Assigned to the Set.',
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 200,
      description: 'Which platforms are these sets available on ?',
    },
  ];
};

export const BEHAVIOR_LIST_COLUMN = (
  setClasses: SetClassesInterface,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dispatch: Dispatch<{ type: string; payload: payloadInterface }>,
): GridColDef[] => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      description: 'Record ID on server',
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 160,
      description: 'The user-friendly name for the Daily Return Set.',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params?.row?.description} placement="right-start">
          <span style={{ textOverflow: 'ellipsis', width: '100px' }}>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 150,
      hide: true,
      description: 'Set Description',
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 150,
      description: 'The Type of Set Wheel, Lottery, etc.',
    },
    {
      field: 'span',
      headerName: 'Span',
      width: 150,
      description: 'How often does the wheel occur',
    },
    {
      field: 'platformNames',
      headerName: 'Platforms',
      width: 200,
      // hide: true,
      description: 'Which platforms are these sets available on ?',
      renderCell: (params: GridRenderCellParams) => (
        <div className={setClasses.entitlements}>
          {params.value?.map((platForm: string, index: number) => {
            return <Chip key={index} label={platForm}></Chip>;
          })}
        </div>
      ),
      filterOperators: COMMON_FILTER_COMPONENT,
    },
    {
      field: 'blackList',
      headerName: 'Black List',
      width: 200,
      hide: true,
      description: 'Which Universes are these sets available on ?',
      renderCell: (params: GridRenderCellParams) => (
        <div className={setClasses.entitlements}>
          {params.value?.map((platForm: string, index: number) => {
            return <Chip key={index} label={platForm}></Chip>;
          })}
        </div>
      ),
      filterOperators: COMMON_FILTER_COMPONENT,
    },
    {
      field: 'whiteList',
      headerName: 'White List',
      width: 200,
      hide: true,
      description: 'Which Universes are not allowed to receive these sets ?',
      renderCell: (params: GridRenderCellParams) => (
        <div className={setClasses.entitlements}>
          {params.value?.map((platForm: string, index: number) => {
            return <Chip key={index} label={platForm}></Chip>;
          })}
        </div>
      ),
      filterOperators: COMMON_FILTER_COMPONENT,
    },
  ];
};
