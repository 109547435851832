import React, { ReactElement } from 'react';
import { AvatarsModal } from './AvatarsModal';
import { CloseAccountModal } from './CloseAccountModal';
import { ContactModal } from './ContactModal';
import { MergeAccountsModal } from './MergeAccountsModal';
import { ResetPasswordModal } from './ResetPasswordModal';
import { UpdateStatusModal } from './UpdateStatusModal';

export const CreditAccounts = (): ReactElement => {
  return (
    <>
      <UpdateStatusModal />
      <ContactModal />
      <ResetPasswordModal />
      <AvatarsModal />
      <CloseAccountModal />
      <MergeAccountsModal />
    </>
  );
};
