import React, {
  forwardRef,
  ForwardRefRenderFunction,
  MutableRefObject,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import {
  DataGridPro,
  GridCallbackDetails,
  GridColDef,
  GridColumnVisibilityModel,
  GridEventListener,
  GridEvents,
  GridRowId,
  GridRowModel,
} from '@mui/x-data-grid-pro';
import _ from 'lodash';
import { CustomLoadingOverlay, QuickSearchToolbar, NoRowsOverlay } from './DataTableComponents';
import moment from 'moment';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { darken, lighten } from '@mui/material/styles';
import Box from '@mui/material/Box';

export interface fetchData<T> {
  ({ page, pageSize }: FetchDataParams): Promise<T>;
}
interface DataTableProps {
  columns: GridColDef[];
  checkbox?: boolean;
  defaultPageSize?: number;
  onItemsSelected?: (selectedRows: any) => void | undefined;
  batchMenus?: ReactElement | null;
  fetchData: fetchData<
    | {
        data: any[];
        totalRows: number;
      }
    | undefined
  >;
  isInfiniteScroll?: boolean;
  enableSearch?: boolean;
  disableExport?: boolean;
  backendSearch?: ReactElement | null;
  hideFooter?: boolean;
  handleDoubleClick?: GridEventListener<GridEvents.cellDoubleClick> | undefined;
  handleColumnVisibilityChange?: (model: GridColumnVisibilityModel, details: GridCallbackDetails) => void;
  apiReference?: MutableRefObject<GridApiPro>;
  isGetRowClassName?: boolean;
  minimumHeight?: string;
  maximumHeight?: string;
}
export interface FetchDataParams {
  page: number;
  pageSize: number;
  startDate?: string;
  endDate?: string;
}

export interface IDataGridRef {
  fetchData: (page?: number) => void;
}
export interface FilterByMenu {
  item: string;
  columnName?: string;
}
function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const DataTable: ForwardRefRenderFunction<IDataGridRef, DataTableProps> = (
  {
    isInfiniteScroll,
    defaultPageSize,
    checkbox,
    columns,
    batchMenus,
    onItemsSelected,
    fetchData,
    enableSearch = false,
    disableExport = false,
    hideFooter = false,
    handleDoubleClick,
    handleColumnVisibilityChange,
    apiReference,
    isGetRowClassName,
    minimumHeight = '68vh',
    maximumHeight = '80vh',
  },
  ref,
): ReactElement => {
  const rows = useRef<GridRowModel[]>([]);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize || 10);
  const [page, setPage] = useState<number>(0);
  const [filteredRows, setFilteredRows] = useState<GridRowModel[]>([]);
  const totalRowsOld = useRef<number>(0);
  const totalRows = useRef<number>(0);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const getBackgroundColor = (color: string, mode: string) => (mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6));

  const getHoverBackgroundColor = (color: string, mode: string) => (mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.1));

  const getData = async (page: number, pageSize: number) => {
    try {
      setLoading(true);
      let newRows = [];
      const data = await fetchData({ page, pageSize });

      if (!data) {
        return;
      }

      if (isInfiniteScroll && page !== 0) {
        newRows = [...rows.current, ...data.data];
      } else {
        newRows = data?.data || [];
      }
      rows.current = [...newRows];
      setFilteredRows([...newRows]);
      totalRows.current = Number(data?.totalRows || 0);
      totalRowsOld.current = Number(data?.totalRows || 0);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleScrollEnd = () => {
    if (page * pageSize < totalRows.current && page * pageSize + pageSize < totalRows.current) {
      setPage(page + 1);
    }
  };

  const filterLocalRows = _.debounce((value) => {
    const searchRegex = new RegExp(escapeRegExp(value), 'i');
    const filterRows = rows.current.filter((row) =>
      Object.keys(row).some((item) => {
        if (typeof row[item] === 'object') {
          return searchRegex.test(JSON.stringify(row[item])?.toString());
        }
        if (item === 'startTsz' || item === 'endTsz') {
          return searchRegex.test(
            moment((row[item] as number) * 1000)
              .utc()
              .format('YYYY-MM-DD HH:mm:ss')
              .toString(),
          );
        }
        return searchRegex.test(row[item]?.toString());
      }),
    );
    setFilteredRows([...filterRows]);
  }, 500);
  const debounceRequest = useCallback((value) => filterLocalRows(value), []);

  const handleClientSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
    debounceRequest(value);
  };

  useImperativeHandle(ref, () => ({
    fetchData(newPage = page) {
      getData(newPage, pageSize);

      if (newPage === 0) setPage(newPage);
    },
  }));

  useEffect(() => {
    getData(page, pageSize);
  }, [page, pageSize]);

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        minHeight: minimumHeight,
        maxHeight: maximumHeight,
      }}
    >
      <Box
        style={{ flexGrow: 1 }}
        sx={{
          '& .fulfillment-theme--Processing': {
            bgcolor: (theme: { palette: { info: { main: string }; mode: string } }) =>
              getBackgroundColor(theme.palette.info.main, theme.palette.mode),
            '&:hover': {
              bgcolor: (theme: { palette: { info: { main: string }; mode: string } }) =>
                getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
            },
          },
          '& .fulfillment-theme--Complete': {
            bgcolor: (theme: { palette: { success: { main: string }; mode: string } }) =>
              getBackgroundColor(theme.palette.success.main, theme.palette.mode),
            '&:hover': {
              bgcolor: (theme: { palette: { success: { main: string }; mode: string } }) =>
                getHoverBackgroundColor(theme.palette.success.main, theme.palette.mode),
            },
          },
          '& .fulfillment-theme--Errors': {
            bgcolor: (theme: { palette: { error: { main: string }; mode: string } }) =>
              getBackgroundColor(theme.palette.error.main, theme.palette.mode),
            '&:hover': {
              bgcolor: (theme: { palette: { error: { main: string }; mode: string } }) =>
                getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
            },
          },
          '& .fulfillment-theme--Failed': {
            bgcolor: (theme: { palette: { error: { main: string }; mode: string } }) =>
              getBackgroundColor(theme.palette.error.main, theme.palette.mode),
            '&:hover': {
              bgcolor: (theme: { palette: { error: { main: string }; mode: string } }) =>
                getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
            },
          },
        }}
      >
        <DataGridPro
          columns={columns}
          rows={filteredRows}
          loading={isInfiniteScroll && loading}
          autoHeight={isInfiniteScroll ? false : true}
          checkboxSelection={checkbox}
          pageSize={pageSize}
          pagination
          rowCount={totalRows.current}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
            onItemsSelected && onItemsSelected(newSelectionModel);
          }}
          onCellDoubleClick={handleDoubleClick}
          onColumnVisibilityModelChange={handleColumnVisibilityChange}
          selectionModel={selectionModel}
          paginationMode="server"
          rowsPerPageOptions={[10, 20, 50, 100]}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          disableColumnMenu
          hideFooterPagination={hideFooter}
          getRowClassName={isGetRowClassName ? (params) => `fulfillment-theme--${params.row.status}` : () => ''}
          onRowsScrollEnd={handleScrollEnd}
          hideFooter={isInfiniteScroll}
          apiRef={apiReference}
          components={{
            Toolbar: QuickSearchToolbar,
            NoRowsOverlay,
            LoadingOverlay: isInfiniteScroll ? CustomLoadingOverlay : undefined,
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'id', sort: 'asc' }],
            },
          }}
          componentsProps={{
            toolbar: {
              enableSearch,
              disableExport,
              value: searchValue,
              onChange: handleClientSearch,
              clearSearch: () => {
                setSearchValue('');
                debounceRequest('');
              },
            },
          }}
        />

        {totalRows ? (
          <Grid container spacing={2} sx={{ my: '20px' }}>
            {batchMenus}
          </Grid>
        ) : (
          ''
        )}
        {!isInfiniteScroll && (
          <Backdrop open={loading} sx={{ position: 'absolute' }}>
            <CircularProgress />
          </Backdrop>
        )}
      </Box>
    </div>
  );
};

export default forwardRef(DataTable);
