import { FilterByMenu } from '../../../component/DataTable/DataTable';
import { IDeleteData } from '../../../container/CurrencyType/CurrencyType';
import { ItemType, updateTransactionInterface } from '../../../container/Transactions/TransactionDiscovery';
import { MODAL_ACTION } from './modelType';

export interface payloadInterface {
  isVisible: boolean;
  isReadable?: boolean;
  userData?: any;
  module?: NodeModule | string;
}

export interface showUserActionModalInterface {
  id: number;
  userName: string;
  email: string;
  funds: number;
  bonus_cash: number;
  ticket: string;
  profile: { name: string };
  banned: string;
  status: string;
  createdAt: string;
  paypal_email: string;
}

export interface showNotificationModalInterface {
  value?: any;
}

export interface showModalByModuleNameInterface {
  actionData?: {
    Action: {
      moduleName: string;
    };
  };
  deleteModule?: string;
  deleteId?: string;
  item?: {
    value: string;
  };
  description?: string;
}

export interface showAllocateSkuInterface {
  skuId?: number;
  skuData?: {
    isEnabled: boolean;
    isBaseSku: boolean;
  };
}

export interface showCreateSkuModalInterface {
  success?: boolean;
  id?: number;
}

export interface showCreateSegmentInterface {
  id?: number;
  name?: string;
  category?: string;
  description?: string;
  expires?: string;
}

export interface showCreateFulfillmentJobInterface {
  actionDefault: string;
  scheduleDefault: boolean;
}

interface fulfillmentLogInterface {
  userId: number | string;
  message: string;
}
export interface showFullfillmentJobInterface {
  action?: string;
  users?: number;
  tags?: number[];
  logs?: fulfillmentLogInterface;
}

export interface showCreateDailyReturnSetModalInterface {
  id?: number;
  setId?: number;
  setGroupId?: number;
  name?: string;
  description?: string;
  span?: string;
  resetStrategyId?: number;
  sequenceNum?: string;
  isEnabled?: boolean | false;
  meta?: string;
  success?: boolean | false;
}

export interface showCreateDailyReturnSlotModalInterface {
  id?: number;
  slotId?: number;
  setId?: number;
  displayName?: string;
  rewards?: string;
}

export interface showCreatePromoTypeInterface {
  editData: {
    promoCodeTypeId: number;
    promoCodeTypeName: string;
  };
}

export interface showUpsertCurrencyTypeInterface {
  id?: number;
  name: string;
  description: string;
}

export interface showUpsertSkipDepositInterface {
  id?: string;
  skuId: number;
  bt_txn_id: string;
  remaining_balance: number | 0;
  isSkippedReason: string | '';
  isSkippedTransaction: boolean | false;
}

export interface showUpdateSuccessInterface {
  transactionIds?: number;
  toStatus?: string | undefined | FilterByMenu;
}

export interface showChainRuleModalInterface {
  success?: boolean;
  parent?: string | undefined;
  parentPromoCode?: number | undefined;
  id?: number | undefined;
  ruleId?: number | undefined;
  strategy?: any | undefined;
}

interface showCreateSuccessModalInterface {
  delete?: boolean;
  rmvDeleted?: boolean;
  update?: boolean;
  create?: boolean;
}

export const showCreateSkuModalAction = (
  isVisible: boolean,
  userData: showCreateSkuModalInterface | null = null,
  isReadable?: boolean,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_CREATE_SKU_MODEL,
    payload: { isVisible, userData, isReadable },
  };
};

export const showUserActionModalAction = (
  isVisible: boolean,
  userData: showUserActionModalInterface | null = null,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_USER_ACTION_MODEL,
    payload: { isVisible, userData },
  };
};
export const showNotificationModalAction = (
  isVisible: boolean,
  userData: ItemType | object | null = null,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_NOTIFICATION_MODEL,
    payload: { isVisible, userData },
  };
};
export const showAddCreditModalAction = (isVisible: boolean): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_ADD_CREDITS_MODEL,
    payload: { isVisible },
  };
};

export const showUserUpdateModalAction = (isVisible: boolean): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_USER_UPDATE_MODEL,
    payload: { isVisible },
  };
};

export const showContactModalAction = (isVisible: boolean): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_CONTACT_MODEL,
    payload: { isVisible },
  };
};

export const showResetPasswordModalAction = (isVisible: boolean): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_RESET_PASSWORD_MODEL,
    payload: { isVisible },
  };
};
export const showAvatarsModalAction = (isVisible: boolean): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_AVATARS_MODEL,
    payload: { isVisible },
  };
};
export const showCloseAccountModalAction = (isVisible: boolean): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_CLOSE_ACCOUNT_MODEL,
    payload: { isVisible },
  };
};
export const showMergeAccountModalAction = (isVisible: boolean): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_MERGE_ACCOUNT_MODEL,
    payload: { isVisible },
  };
};

export const showUpdateTransactionModalAction = (isVisible: boolean, userData: null): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_UPDATE_TRANSACTION_MODEL,
    payload: { isVisible, userData },
  };
};

export const showUpsertCurrencyTypeModalAction = (
  isVisible: boolean,
  userData: showUpsertCurrencyTypeInterface | null = null,
  isReadable?: boolean,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_UPSERT_CURRENCY_TYPE_MODEL,
    payload: { isVisible, userData, isReadable },
  };
};

export const showUpsertSkipDepositModalAction = (
  isVisible: boolean,
  userData: showUpsertSkipDepositInterface[] | null = null,
  isReadable?: boolean,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_UPSERT_SKIP_DEPOSIT_MODEL,
    payload: { isVisible, userData, isReadable },
  };
};

export const showUpdateSuccessModalAction = (
  isVisible: boolean,
  userData: showUpdateSuccessInterface | null = null,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_UPDATE_SUCCESS_MODEL,
    payload: { isVisible, userData },
  };
};
export const showUpdateFailedModalAction = (
  isVisible: boolean,
  userData: showUpdateSuccessInterface | null = null,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_UPDATE_FAILED_MODEL,
    payload: { isVisible, userData },
  };
};

export const showDeleteTransactionModalAction = (
  isVisible: boolean,
  userData: ItemType[] | null = null,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_DELETE_TRANSACTION_MODEL,
    payload: { isVisible, userData },
  };
};

export const showRemoveTransactionModalAction = (
  isVisible: boolean,
  userData: ItemType[] | [any] | null = null,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_REMOVE_TRANSACTION_MODEL,
    payload: { isVisible, userData },
  };
};

export const showUndoDeleteTransactionModalAction = (
  isVisible: boolean,
  userData: ItemType[] | null = null,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_UNDO_DELETE_TRANSACTION_MODEL,
    payload: { isVisible, userData },
  };
};

export const showCreateSuccessModalAction = (
  isVisible: boolean,
  userData: showCreateSuccessModalInterface | null = null,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_CREATE_SUCCESS_MODEL,
    payload: { isVisible, userData },
  };
};

export const showCreateTransactionModalAction = (
  isVisible: boolean,
  userData: updateTransactionInterface | null = null,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_CREATE_TRANSACTION_MODEL,
    payload: { isVisible, userData },
  };
};

export const showCreatePromoCodeModalAction = (
  isVisible: boolean,
  userData: {
    id?: number;
  } | null = null,
  isReadable?: boolean,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_CREATE_PROMO_CODE_MODEL,
    payload: { isVisible, userData, isReadable },
  };
};

export const showCreatePromoTypeModalAction = (
  isVisible: boolean,
  userData: showCreatePromoTypeInterface | null = null,
  isReadable?: boolean,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_CREATE_PROMOTYPE_MODEL,
    payload: { isVisible, userData, isReadable },
  };
};

export const showCreateSegmentModalAction = (
  isVisible: boolean,
  userData: showCreateSegmentInterface | null = null,
  isReadable?: boolean,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_CREATE_SEGMENT_MODAL,
    payload: { isVisible, userData, isReadable },
  };
};

export const showCreateSegmentFulfillmentModalAction = (
  isVisible: boolean,
  userData: showCreateFulfillmentJobInterface | null = null,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_CREATE_SEGMENT_FULLFILLMENT_JOB,
    payload: { isVisible, userData },
  };
};

export const showSegmentFulfillmentJobModalAction = (
  isVisible: boolean,
  userData: showFullfillmentJobInterface | null = null,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_SEGMENT_FULLFILLMENT_JOB,
    payload: { isVisible, userData },
  };
};

export const showCreateDailyReturnSetGroupModalAction = (
  isVisible: boolean,
  userData: showCreateDailyReturnSetModalInterface | null = null,
  isReadable?: boolean,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_CREATE_DAILY_RETURN_SET_GROUP_MODAL,
    payload: { isVisible, userData, isReadable },
  };
};

export const showCreateDailyReturnSetModalAction = (
  isVisible: boolean,
  userData: showCreateDailyReturnSetModalInterface | null = null,
  isReadable?: boolean,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_CREATE_DAILY_RETURN_SET_MODAL,
    payload: { isVisible, userData, isReadable },
  };
};

export const showCreateDailyReturnSlotModalAction = (
  isVisible: boolean,
  userData: showCreateDailyReturnSlotModalInterface | null = null,
  isReadable?: boolean,
): { type: string; payload: payloadInterface } => {
  console.log('Running Slot Modal Action');
  return {
    type: MODAL_ACTION.SHOW_CREATE_DAILY_RETURN_SLOT_MODAL,
    payload: { isVisible, userData, isReadable },
  };
};

export const showAllocateSkuAction = (
  isVisible: boolean,
  userData: showAllocateSkuInterface | null = null,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_ALLOCATE_SKU,
    payload: { isVisible, userData },
  };
};

export const showActionStepperModalAction = (isVisible: boolean): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_ACTION_STEPPER_MODEL,
    payload: { isVisible },
  };
};

export const showChainRuleModalAction = (
  isVisible: boolean,
  userData: showChainRuleModalInterface | null = null,
  isReadable?: boolean,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_CHAIN_RULE_MODEL,
    payload: { isVisible, userData, isReadable },
  };
};

export const showModalByModuleName = (
  module: string,
  isVisible: boolean,
  userData: showModalByModuleNameInterface | null = null,
): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_MODEL_BY_MODULE,
    payload: { module, isVisible, userData },
  };
};

export const showGlobalDeleteModalAction = (isVisible: boolean, userData: IDeleteData | null = null): { type: string; payload: payloadInterface } => {
  return {
    type: MODAL_ACTION.SHOW_GLOBAL_DELETE_MODEL,
    payload: { module, isVisible, userData },
  };
};

export const showDeployGoalModalAction = (isVisible: boolean, userData: any = null): { type: string; payload: any } => {
  return {
    type: MODAL_ACTION.SHOW_DEPLOY_GOAL_MODEL,
    payload: { isVisible, userData },
  };
};

export const showGoalListModalAction = (isVisible: boolean, userData: any = null, isReadable?: boolean): { type: string; payload: any } => {
  return {
    type: MODAL_ACTION.SHOW_GOAL_LIST_MODEL,
    payload: { isVisible, userData, isReadable },
  };
};

export const showSuccessModalAction = (isVisible: boolean, userData: any = null): { type: string; payload: any } => {
  return {
    type: MODAL_ACTION.SHOW_SUCCESS_MODEL,
    payload: { isVisible, userData },
  };
};
