import makeStyles from '@mui/styles/makeStyles';

const useStylesChainRuleList = makeStyles(() => ({
  chain: {
    width: '100%',
    overflow: 'auto',
    overflowY: 'hidden',
    height: '37px',
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '&::-webkit-scrollbar': {
      height: '2px',
    },
  },
  chainChip: {
    margin: '1px 2px',
    borderRadius: '2px',
    '& .MuiChip-label': {
      padding: '0px 5px',
    },
  },
}));

export default useStylesChainRuleList;
