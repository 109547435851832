import React from 'react';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { Chip } from '@mui/material';
import { COMMON_FILTER_COMPONENT } from './commonFilterComponent';

interface chainRuleInterface {
  chain: string;
  chainChip: string;
}

export const PROMO_CODE_CHAIN_RULE_COLUMN = (chainRuleClasses: chainRuleInterface): GridColDef[] => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      description: 'Record ID on server',
      width: 100,
      hide: true,
    },
    {
      field: 'parentPromoCode',
      headerName: 'Parent Promo',
      description:
        'Parent promo code of the chain, a chain can have only one parent promo code and a child promo code in one chain can not be a parent in the other chain',
      width: 200,
    },
    {
      field: 'chain',
      headerName: 'Child Promo',
      description: 'Children promo codes of the chain',
      width: 350,
      renderCell: (params: GridRenderCellParams) => (
        <div className={chainRuleClasses.chain}>
          {params.value?.map((promoCode: string, index: number) => {
            return <Chip key={index} label={promoCode} className={chainRuleClasses.chainChip}></Chip>;
          })}
        </div>
      ),
      filterOperators: COMMON_FILTER_COMPONENT,
    },
    {
      field: 'trigger',
      headerName: 'Trigger',
      description: 'Trigger',
      width: 200,
    },
    {
      field: 'expirationStrategy',
      headerName: 'Execution Strategy',
      description: 'Execution Strategy',
      width: 200,
    },
  ];
};
