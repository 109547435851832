import { Button, Grid, Tooltip } from '@mui/material';
import { DeleteOutline, Edit } from '@mui/icons-material';
import React, { Fragment, useRef, useMemo, useEffect } from 'react';
import { toast } from 'react-toastify';
import DataTable, { IDataGridRef } from '../../component/DataTable/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { showCreateDailyReturnSetGroupModalAction, showGlobalDeleteModalAction } from '../../redux/actions/modalActions/modalActions';
import { BEHAVIOR_LIST_COLUMN } from '../../constants/columns/dailyReturn.constants';
import useStylesSkuList from '../SKU/skuListStyle';
import { useCurrentPartner } from '../../hooks/useCurrentPartner';
import { RootState } from '../../redux/reducers/rootReducer';
import { IDeleteData } from '../CurrencyType/CurrencyType';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { getBehaviourList } from '../../services/dailyReturn.service';
import { API_URLS } from '../../constants/url';

interface IDailyRewardSetGroup {
  setGroupId: number;
  name: string;
  description: string;
  span: string;
  type: string;
  public: boolean;
  platforms: string[];
  platformNames: string[];
  blacklist: string[];
  whitelist: string[];
}

const SetGroupList = () => {
  const DataGridRef = useRef<IDataGridRef>(null);
  const dispatch = useDispatch();
  const columnClass = useStylesSkuList();
  const { partnerShortName } = useCurrentPartner();

  const globalDeleteModalData = useSelector((state: RootState) => state.modalReducer.showGlobalDelete);

  const BehaviorListColumns = useMemo<GridColDef[]>(() => {
    const Final_Column = BEHAVIOR_LIST_COLUMN(columnClass, dispatch);
    const All_Column: GridColDef[] = [
      {
        field: 'action',
        headerName: 'Action',
        description: 'Action',
        width: 250,
        renderCell: (params: GridRenderCellParams) => (
          <Fragment>
            {
              <Tooltip title="Edit">
                <Button size="small" style={{ marginLeft: 16 }} onClick={() => dispatch(showCreateDailyReturnSetGroupModalAction(true, params.row))}>
                  <Edit color="secondary" />
                </Button>
              </Tooltip>
            }
            <Tooltip title="Delete">
              <Button
                size="small"
                style={{ marginLeft: 16 }}
                onClick={() => {
                  const deleteData: IDeleteData = {};
                  deleteData.deleteModule = 'Set Group';
                  deleteData.deleteId = params.row.id;
                  deleteData.item = {};
                  deleteData.item.value = params.row.id;
                  deleteData.item.label = params.row.name;
                  deleteData.item.item = {};
                  deleteData.item.item.description = params.row.description;
                  dispatch(showGlobalDeleteModalAction(true, deleteData));
                }}
              >
                <DeleteOutline color="error" />
              </Button>
            </Tooltip>
          </Fragment>
        ),
      },
    ];
    return [...Final_Column, ...All_Column];
  }, [BEHAVIOR_LIST_COLUMN]);

  const handleDoubleClick = (e: any) => {
    dispatch(showCreateDailyReturnSetGroupModalAction(true, e.row, false));
  };

  const fetchData = async () => {
    try {
      const res = await getBehaviourList({
        url: API_URLS.GET_DAILY_REWARD_SET_GROUPS,
        partnerId: partnerShortName,
      });

      console.log({ res });
      const data = res?.data?.map((item: IDailyRewardSetGroup) => {
        return {
          id: item.setGroupId,
          name: item.name,
          description: item.description,
          type: item.type,
          span: item.span,
          platformNames: item.platformNames,
          blackList: item.blacklist,
          whiteList: item.whitelist,
          action: item.setGroupId,
        };
      });

      console.log({ data });

      console.log('Request Data');
      return {
        data: data || [],
        totalRows: res?.totalRows,
      };
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  useEffect(() => {
    if (!globalDeleteModalData.show) {
      DataGridRef.current?.fetchData();
    }
  }, [globalDeleteModalData.show]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DataTable
            columns={BehaviorListColumns}
            fetchData={fetchData}
            ref={DataGridRef}
            handleDoubleClick={handleDoubleClick}
            isInfiniteScroll
            enableSearch
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SetGroupList;
