import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { API_URLS } from '../constants/url';
import axiosInstance from '../utils/apis';
import { startLoader, stopLoader } from '../utils/utils';

interface FetchAllFullfillmentAPIPayload {
  partnerId?: string;
}

interface FetchFullfillmentAPIPayload {
  id: number | false | undefined;
}

export interface CreateSegmentFulfillmentJobAPIPayload {
  data: {
    segmentJob: {
      isScheduled: boolean;
      startAt?: number | string | Date | undefined;
      userCount: number;
      userIdList: number[];
      tags: number[];
      tagLabels: string[];
      action: string;
      createdBy: {
        id: string;
        email: string;
        useName: string;
      };
    };
  };
}

export interface FetchFulfillmentData {
  action: string;
  user_count: number;
  tags: string[];
  logs: {
    userId: number;
    message: string;
  };
  sf: string;
  success_count: number | string;
  failed_count: number | string;
  status: string;
  created_by: string;
  start: number;
  end: number;
}

export const createSegmentFulfillmentJob = async (payload: CreateSegmentFulfillmentJobAPIPayload) => {
  try {
    startLoader();

    const response = await axiosInstance.post(API_URLS.CREATE_FULFILLMENT_JOB, payload.data);
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const getFullfillmentJobList = async (payload: FetchAllFullfillmentAPIPayload) => {
  try {
    startLoader();
    const { partnerId } = payload;
    const response = await axiosInstance.get(API_URLS.GET_FULFILLMENT_JOB_LIST + (partnerId ? `?partnerId=${partnerId}` : ''));
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const getFullfillmentJobById = async (payload: FetchFullfillmentAPIPayload) => {
  try {
    startLoader();
    const { id } = payload;
    const response = await axiosInstance.get(API_URLS.GET_FULFILLMENT_JOB + '?id=' + id);
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};
