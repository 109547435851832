import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import axiosMiddleware from 'redux-axios-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers/rootReducer';
import { LOGIN_DEFAULT } from './actions/type';
import axiosInstance from '../utils/apis';

const middlewares = [thunk, axiosMiddleware(axiosInstance)];
const user = localStorage.getItem('currentUser' || '{}');
const loginReducer = LOGIN_DEFAULT;
if (user) {
  loginReducer.success = true;
  loginReducer.loginSuccess = true;
  loginReducer.data = { ...LOGIN_DEFAULT, ...JSON.parse(user) };
  loginReducer.loading = false;
}

const initialState = { loginReducer };

const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middlewares)));

export default store;
