import React, { ReactElement } from 'react';

import WithdrawList from './WithdrawalsList';

/**
 * All withdraws
 * @returns {JSX.Element}
 * @constructor
 */
const Withdraws = (): ReactElement => {
  return <WithdrawList type="all" />;
};

export default Withdraws;
