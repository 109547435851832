import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Button, Grid, Tooltip } from '@mui/material';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { toast } from 'react-toastify';
import { API_URLS } from '../../constants/url';
import DataTable, { IDataGridRef } from '../../component/DataTable/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { showCreatePromoTypeModalAction, showGlobalDeleteModalAction } from '../../redux/actions/modalActions/modalActions';

import { getPromoType, getPromoTypeList } from '../../services/promoCodeType.service';
// import CommonBreadcrumbs from '../../component/BreadCrumb/BreadCrumb';
import { RootState } from '../../redux/reducers/rootReducer';
import { DataResponseType } from '../PromoCodeChainRule/PromoCodeChainRule';
import { IDeleteData } from '../CurrencyType/CurrencyType';

interface PromoTypeT {
  PromoCodeTypeName: string;
}

interface PromoResponse {
  promoCodeTypeId: number;
  promoCodeTypeName: string;
}
const PromoType = () => {
  const DataGridRef = useRef<IDataGridRef>(null);
  const dispatch = useDispatch();
  const [PromoCodeType, setPromoCodeType] = useState<PromoTypeT>({
    PromoCodeTypeName: '',
  });

  const globalDeleteModalData = useSelector((state: RootState) => state.modalReducer.showGlobalDelete);

  const createSuccessData = useSelector((state: RootState) => state.modalReducer.showCreateSuccess);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const PromoTypeColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      description: 'PromoCode Type ID',
    },
    {
      field: 'promoCodeTypeName',
      headerName: 'Promo Code Type Name',
      flex: 2,
      description: 'Promo Code Type Name',
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      flex: 1,
      description: 'Action',
      renderCell: (params: GridRenderCellParams) => (
        <Fragment>
          <Tooltip title="Edit">
            <Button
              size="small"
              style={{ marginLeft: 16 }}
              onClick={() => {
                handleEditPromoType(params.value);
              }}
            >
              <Edit color="secondary" />
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              size="small"
              style={{ marginLeft: 16 }}
              onClick={() => {
                handleDeletePromoType(params.row);
              }}
            >
              <DeleteOutline color="error" />
            </Button>
          </Tooltip>
        </Fragment>
      ),
    },
  ];
  const handleDoubleClick = (e: any) => {
    dispatch(showCreatePromoTypeModalAction(true, e.row, false));
  };
  const fetchDataPromoType = async () => {
    try {
      const res: DataResponseType<PromoResponse> = await getPromoTypeList({
        url: API_URLS.GET_PROMO_TYPE,
      });
      const data = res?.data?.map((item: PromoResponse) => {
        return {
          id: item.promoCodeTypeId,
          promoCodeTypeName: item.promoCodeTypeName,
          action: item.promoCodeTypeId,
        };
      });
      return {
        data: data || [],
        totalRows: res?.totalRows,
      };
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  const handleEditPromoType = async (id: number) => {
    try {
      const res = await getPromoType({
        id: id,
        url: API_URLS.GET_PROMO_TYPE,
      });
      if (res) {
        const editData = res.data;
        setPromoCodeType({
          ...PromoCodeType,
          PromoCodeTypeName: editData.promoCodeTypeName,
        });
        dispatch(showCreatePromoTypeModalAction(true, editData));
      }
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };
  const handleDeletePromoType = async (row: { id: number; promoCodeTypeName: string }) => {
    try {
      const deleteData: IDeleteData = {};
      deleteData.deleteModule = 'Promo Code Type';
      deleteData.deleteId = row.id;
      deleteData.item = {};
      deleteData.item.value = row.id;
      deleteData.item.label = row.promoCodeTypeName;
      dispatch(showGlobalDeleteModalAction(true, deleteData));
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  useEffect(() => {
    if (!globalDeleteModalData.show) {
      DataGridRef.current?.fetchData();
    }
  }, [globalDeleteModalData.show]);

  useEffect(() => {
    DataGridRef.current?.fetchData();
  }, [createSuccessData.data]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DataTable
            columns={PromoTypeColumns}
            fetchData={fetchDataPromoType}
            ref={DataGridRef}
            handleDoubleClick={handleDoubleClick}
            isInfiniteScroll
            enableSearch
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PromoType;
