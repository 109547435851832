import React from 'react';
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { Checkbox, Tooltip } from '@mui/material';
import moment from 'moment';
export const TAG_SEGMENT_LIST_COLUMN = (): GridColDef[] => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      description: 'Record ID on server for the Tag.',
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 180,
      description: 'The user-friendly name of the Tag. The Name should be Short and Concise, elaborate in the Description section.',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params?.row?.description} placement="right-start">
          <span style={{ textOverflow: 'ellipsis', width: '100px' }}>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 250,
      description: 'A description of the purpose of the Tag for Admins.',
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 100,
      description: 'The Category for the Tag, Categories allow Admins to Segment Users depending on the Business Purpose.',
      hide: true,
    },
    {
      field: 'systemManaged',
      headerName: 'System Managed',
      width: 150,
      description: 'A System Managed Tag cannot be updated, these are Tags essential to how the System Operates.',
      renderCell: (params: GridRenderCellParams) => <Checkbox checked={params.value} disabled name="isEnabled" />,
    },
    {
      field: 'expires',
      headerName: 'Expires',
      width: 130,
      description: 'The Date and Time when the Expires and will no longer be active within the System.',
      valueGetter: (params: any) => {
        const valueFormatted = moment(params.value as number)
          .utc()
          .toDate();
        return valueFormatted;
      },
      valueFormatter: (params: GridValueFormatterParams) => {
        const valueFormatted = moment(params.value as number).format('YYYY-MM-DD HH:mm:ss');
        if (valueFormatted == 'Invalid date') {
          return 'Does not expire';
        }
        return valueFormatted.toLocaleString();
      },
      type: 'date',
    },
    {
      field: 'created',
      headerName: 'Created',
      width: 130,
      description: 'The Date and Time the Tag/Segment was Originally Created.',
      valueGetter: (params: any) => {
        const valueFormatted = moment(params.value as number)
          .utc()
          .toDate();
        return valueFormatted;
      },
      valueFormatter: (params: GridValueFormatterParams) => {
        const valueFormatted = moment(params.value as number).format('YYYY-MM-DD HH:mm:ss');
        return valueFormatted.toLocaleString();
      },
      type: 'date',
    },
    {
      field: 'updated',
      headerName: 'Updated',
      width: 125,
      description: 'The Last Date and Time the Tag/Segment Record has been Updated.',
      hide: true,
      valueGetter: (params: any) => {
        const valueFormatted = moment(params.value as number)
          .utc()
          .toDate();
        return valueFormatted;
      },
      valueFormatter: (params: GridValueFormatterParams) => {
        const valueFormatted = moment(params.value as number).format('YYYY-MM-DD HH:mm:ss');
        return valueFormatted.toLocaleString();
      },
      type: 'date',
    },
  ];
};
