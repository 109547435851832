import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
// import { SvgIconProps } from '@mui/material/SvgIcon'

import List from '@mui/material/List';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';

import IconExpandLess from '@mui/icons-material/ExpandLess';
import IconExpandMore from '@mui/icons-material/ExpandMore';

import SidebarItemComponent from './SidebarItemComponent';
import { RenderByPermission } from '../shared/RenderByPermission';

const PREFIX = 'SidebarItem';

export const classes = {
  menuItem: `${PREFIX}-menuItem`,
  menuItemIcon: `${PREFIX}-menuItemIcon`,
  menuItemHover: `${PREFIX}-menuItemHover`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  [`& .${classes.menuItem}`]: {
    '&.active': {
      background: theme.palette.secondary.dark,
      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.main,
      },
    },
  },
  [`& .${classes.menuItemHover}`]: {
    '&:hover': {
      background: theme.palette.primary.main,
    },
    '& .MuiListItemIcon-root': {
      margin: '4px 0px',
      minWidth: '42px',
    },
    '& .MuiListItemIcon-root ~ .MuiListItemText-root': {
      visibility: 'hidden',
      height: '24px',
      minWidth: '170px',
      '& .MuiTypography-root': {
        height: '0px',
        transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
    '& .MuiFormControlLabel-root': {
      transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    '&:hover  .MuiListItemText-root': {
      visibility: 'visible',
      '& .MuiTypography-root': {
        height: '48px',
        background: theme.palette.primary.main,
        padding: '12px 12px',
        marginTop: '-12px',
        borderRadius: '5px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    '&:hover  .MuiFormControlLabel-root': {
      visibility: 'visible',
      height: '48px',
      marginLeft: '161px',
      background: theme.palette.primary.main,
      borderRadius: '0px 5px 5px 0px',
    },
  },
  [`& .${classes.menuItemIcon}`]: {
    color: theme.palette.secondary.dark,
  },
}));

// React runtime PropTypes
export const AppMenuItemPropTypesC = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  accessKey: PropTypes.string,
  Icon: PropTypes.elementType,
  items: PropTypes.array,
};

// TypeScript compile-time props type, infered from propTypes
// https://dev.to/busypeoples/notes-on-typescript-inferring-react-proptypes-1g88
type AppMenuItemPropTypes = PropTypes.InferProps<typeof AppMenuItemPropTypesC>;
type AppMenuItemPropsWithoutItems = Omit<AppMenuItemPropTypes, 'items'>;

// Improve child items declaration
export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
  items?: AppMenuItemProps[];
  handleItemClick?: (arg1: boolean) => void;
  Permissions: string[];
  PartnerPermissions?: string[];
  sidebarOpen?: boolean;
  title?: string;
  topLevel?: boolean;
  itemData?: AppMenuItemProps;
  hasClick?: boolean;
};

const SidebarItem: React.FC<AppMenuItemProps> = (props: AppMenuItemProps) => {
  const { name, accessKey, link, Icon, items = [], handleItemClick, Permissions, PartnerPermissions, sidebarOpen, title, topLevel, itemData } = props;

  const isExpandable = items && items.length > 0;
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(!open);
  }
  interface Event {
    type?: string;
  }

  function handleHover(e: Event) {
    if (e.type === 'mouseenter') {
      setOpen(true);
    } else if (e.type === 'mouseleave') {
      setOpen(false);
    }
  }

  useEffect(() => {
    setOpen(false);
  }, [sidebarOpen]);

  const MenuItemRoot = (
    <SidebarItemComponent
      className={classes.menuItem}
      link={link}
      onClick={handleClick}
      itemKey={accessKey ? accessKey : ''}
      title={title}
      isExpandable={isExpandable}
      handleItemClick={handleItemClick}
      sidebarOpen={sidebarOpen}
      topLevel={topLevel}
      itemData={itemData}
    >
      {/* Display an icon if any */}
      {!!Icon && (
        <ListItemIcon className={`${classes.menuItemIcon} `}>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText primary={name} inset={(!Icon && sidebarOpen) || (!Icon && !sidebarOpen && topLevel)} sx={{ whiteSpace: 'nowrap' }} />
      {/* Display the expand menu if the item has children */}
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </SidebarItemComponent>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      {sidebarOpen && <Divider />}
      <List component="div" disablePadding>
        {items.map((item, index) => (
          <SidebarItem {...item} itemData={item} key={index} sidebarOpen={sidebarOpen} />
        ))}
      </List>
    </Collapse>
  ) : null;
  return (
    <Root onMouseEnter={!sidebarOpen ? handleHover : undefined} onMouseLeave={!sidebarOpen ? handleHover : undefined}>
      {Permissions ? (
        <RenderByPermission permissions={Permissions} partnerPermissions={PartnerPermissions} renderIf={() => MenuItemRoot} renderElse={() => null} />
      ) : (
        MenuItemRoot
      )}
      {MenuItemChildren}
    </Root>
  );
};

export default SidebarItem;
