import React, { useState, ReactElement, useRef, useEffect, Fragment } from 'react';
import DataTable, { FetchDataParams, FilterByMenu, IDataGridRef } from '../../component/DataTable/DataTable';
import { API_URLS } from '../../constants/url';
import { Grid } from '@mui/material';
import SelectDropdown from '../../component/SelectDropdown/SelectDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/rootReducer';
import { TRANSACTION_COLUMNS } from '../../constants/columns/transactions.constant';
import {
  showCreateTransactionModalAction,
  showDeleteTransactionModalAction,
  showRemoveTransactionModalAction,
  showUndoDeleteTransactionModalAction,
  // showCreateSuccessModalAction,
} from '../../redux/actions/modalActions/modalActions';
import CreateTransaction from '../../component/Modals/TransactionDiscovery/CreateTransaction';
import CreateTransactionSuccess from '../../component/Modals/TransactionDiscovery/CreateTransactionSuccess';
import UpdateTransactionSuccess from '../../component/Modals/TransactionDiscovery/UpdateTransactionSuccess';
import UpdateTransactionFailed from '../../component/Modals/TransactionDiscovery/UpdateTransactionFailed';
import DeleteTransaction from '../../component/Modals/TransactionDiscovery/deleteTransaction';
import RemoveTransaction from '../../component/Modals/TransactionDiscovery/removeTransaction';
import FloatingButton from '../../component/FloatingButton/FloatingButton';

import DeleteIcon from '@mui/icons-material/Delete';
import BackendSearchBar from '../../component/BackendSearchBar/BackendSearchBar';
import { DiscoveryPermissions } from '../../constants/permission';
import { deleteOneOrManyTransactions, getTransactionsData } from '../../services/transactions.service';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Tooltip } from '@mui/material';

import CommonBreadcrumbs from '../../component/BreadCrumb/BreadCrumb';
import { DeleteOutline, Edit, RestoreFromTrash } from '@mui/icons-material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import UndoDeleteTransaction from '../../component/Modals/TransactionDiscovery/UndoDeleteTransaction';
import { breadCrumbsKey } from '../../constants/breadCrumbConfig';
import { useMemo } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
/**
 * Withdraw list
 * @returns {JSX.Element}
 * @constructor
 */
export interface ItemType {
  id: number;
  userId: number;
  playedGameId: number;
  tournamentId: number;
  winnings: number;
  status: string;
  created: string;
  lastUpdated: string;
  isDeleted: boolean;
  walletReferenceId: number;
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface updateTransactionInterface {
  status?: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const TransactionDiscovery = (): ReactElement => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>('');
  const DataGridRef = useRef<IDataGridRef>(null);
  const [searchBy, setSearchBy] = React.useState<FilterByMenu>({
    item: '',
  });
  const [transactionState, setTransactionState] = useState('all');
  const [tabValue, setTabValue] = useState(0);
  const [transType, setTransType] = useState<string>('All Transactions');
  const [selectedItems, setSelectedItems] = useState<ItemType[]>([]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 0:
        setTransactionState('all');
        setTransType('All Transactions');
        break;
      case 1:
        setTransactionState('failed');
        setTransType('All Failed Transactions');
        break;
      case 2:
        setTransactionState('deleted');
        setTransType('All Deleted Transactions');
        break;
      default:
    }
    setTabValue(newValue);
  };
  const handleChange = (option: FilterByMenu) => {
    setSearchBy(option);
  };

  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleYes = () => {
    // setOpenDialog(true);
    handleDeleteAllFailed();
  };

  const updateTransaction = (value: updateTransactionInterface) => {
    if (transactionState === 'failed' && value.status === 'COMPLETED') {
      setOpenDialog(true);
    }
    if (transactionState === 'all' || value.status !== 'COMPLETED') {
      dispatch(showCreateTransactionModalAction(true, value));
    }
  };

  const undoDeleteTransaction = (value: ItemType[]) => {
    dispatch(showUndoDeleteTransactionModalAction(true, [...value]));
  };
  // const createTransaction = (value: any) => {
  //   dispatch(showCreateTransactionModalAction(true, value));
  // };

  const deleteTransaction = (value: ItemType[]) => {
    dispatch(showDeleteTransactionModalAction(true, [...value]));
  };

  const rmvDeletedTransaction = (value: ItemType[]) => {
    dispatch(showRemoveTransactionModalAction(true, [...value]));
  };

  const handleDeleteAllFailed = async () => {
    try {
      await deleteOneOrManyTransactions({
        allFailed: true,
      });
      toast.success('Transaction successfully deleted!');
      setOpenDialog(false);
      DataGridRef.current?.fetchData();
    } catch (err) {
      toast.error('Something Is Wrong');
    }
  };
  const menuItems: FilterByMenu[] = [
    { item: 'Tournament ID', columnName: 'tournamentId' },
    { item: 'Game ID', columnName: 'playedGameId' },
    { item: 'User ID', columnName: 'userId' },
    { item: 'Status', columnName: 'status' },
  ];

  const columns = useMemo<GridColDef[]>(() => {
    const new_column: GridColDef = {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      width: 250,
      description: 'Action',
      renderCell: (params: GridRenderCellParams) => (
        <Fragment>
          {(transactionState === 'all' || transactionState === 'failed') && (
            <Tooltip title="Edit">
              <Button
                size="small"
                style={{ marginLeft: 16 }}
                onClick={() => {
                  updateTransaction(params.row);
                }}
              >
                <Edit color="secondary" />
              </Button>
            </Tooltip>
          )}
          {transactionState === 'deleted' && (
            <Tooltip title="Restore">
              <Button
                size="small"
                style={{ marginLeft: 16 }}
                onClick={() => {
                  undoDeleteTransaction(selectedItems.length ? selectedItems : [params.row.id]);
                }}
              >
                <RestoreFromTrash color="secondary" />
              </Button>
            </Tooltip>
          )}
          <Tooltip title={transactionState === 'deleted' ? 'Remove' : 'Delete'}>
            <Button
              size="small"
              style={{ marginLeft: 16 }}
              onClick={() => {
                transactionState === 'deleted'
                  ? rmvDeletedTransaction(selectedItems.length ? selectedItems : [params.row.id])
                  : deleteTransaction(selectedItems.length ? selectedItems : [params.row.id]);
              }}
            >
              <DeleteOutline color="error" />
            </Button>
          </Tooltip>
        </Fragment>
      ),
    };
    return [...TRANSACTION_COLUMNS, new_column];
  }, [transactionState, selectedItems]);

  const startDate = useSelector((state: RootState) => state.commonReducers.startDateSelected);
  const endDate = useSelector((state: RootState) => state.commonReducers.endDateSelected);
  // useEffect(() => {
  //   DataGridRef.current?.fetchData();
  // }, [startDate, endDate]);

  // const processBulkActions = () => {
  //   console.log('processBulkActions');
  //   dispatch(showUpdateFailedModalAction(true, null));
  //   dispatch(showDeleteTransactionModalAction(true, null));
  // };

  const floatingButtonClick = () => {
    dispatch(showCreateTransactionModalAction(true, null));
  };

  const floatingButtonDeleteClick = () => {
    setOpenDialog(true);
  };

  const bulkActions = [
    {
      action: 'Delete All Failed Transactions',
      onClick: () => {
        floatingButtonDeleteClick();
      },
      icon: <DeleteIcon />,
      Permissions: [DiscoveryPermissions.Delete],
    },
  ];

  async function fetchEvent({ page, pageSize }: FetchDataParams) {
    const helper = API_URLS.TRANSACTIONS_LIST;
    let allFailed = false;
    let showDeleted = false;
    if (transactionState === 'failed') {
      allFailed = true;
    }
    if (transactionState === 'deleted') {
      showDeleted = true;
    }

    const response = await getTransactionsData({
      offset: page * pageSize,
      limit: pageSize,
      url: helper,
      search,
      searchBy: searchBy.columnName,
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
      allFailed: allFailed,
      showDeleted: showDeleted,
    });

    const rows = response?.data?.map((item: ItemType) => {
      return {
        playedGameId: item.playedGameId,
        id: item.id,
        userId: item.userId,
        tournamentId: item.tournamentId,
        winnings: item.winnings,
        status: item.status,
        walletReferenceId: item.walletReferenceId,
        created_date: item.created,
        lastUpdated: item.lastUpdated,
        isDeleted: item.isDeleted,
      };
    });

    return {
      data: rows || [],
      totalRows: response?.totalRows,
    };
  }
  const createSuccessData = useSelector((state: RootState) => state.modalReducer.showCreateSuccess);
  useEffect(() => {
    DataGridRef.current?.fetchData();
  }, [transactionState, createSuccessData.data]);

  // Items selected
  const onItemsSelected = (items: ItemType[]) => {
    setSelectedItems(items);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <Tabs
            // orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={handleTabChange}
            aria-label=" Tabs"
          >
            <Tab label="All Transactions" {...a11yProps(0)} />
            <Tab label="All Failed Transactions" {...a11yProps(1)} />
            <Tab label="All Deleted Transactions" {...a11yProps(2)} />
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          <CommonBreadcrumbs title="Transaction" breadCrumbKey={breadCrumbsKey.transactionDiscovery} />
          <Grid container>
            <Grid item xs={12} md={8} lg={9} sx={{ px: '8px' }}>
              <BackendSearchBar search={search} setSearch={setSearch} DataGridRef={DataGridRef} />
            </Grid>

            <Grid item xs={12} md={4} lg={3}>
              <SelectDropdown handleChange={handleChange} searchBy={searchBy} menuItems={menuItems} label="Search By" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={tabValue} index={0}>
          {transType === 'All Transactions' && (
            <DataTable columns={columns} checkbox fetchData={fetchEvent} ref={DataGridRef} enableSearch onItemsSelected={onItemsSelected} />
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {transType === 'All Failed Transactions' && (
            <DataTable columns={columns} checkbox fetchData={fetchEvent} ref={DataGridRef} enableSearch onItemsSelected={onItemsSelected} />
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          {transType === 'All Deleted Transactions' && (
            <DataTable columns={columns} checkbox fetchData={fetchEvent} ref={DataGridRef} enableSearch onItemsSelected={onItemsSelected} />
          )}
        </TabPanel>
      </Grid>
      <Grid
        item
        sx={{
          display: 'flex',
          marginTop: '94px',
        }}
        xs={12}
      ></Grid>

      {transType === 'All Failed Transactions' && (
        <FloatingButton bulkActions={bulkActions} floatingButtonClick={floatingButtonClick} fabPermissions={[DiscoveryPermissions.Create]} />
      )}
      <RemoveTransaction />
      <UndoDeleteTransaction />

      <DeleteTransaction />

      <CreateTransactionSuccess />
      <CreateTransaction />
      <UpdateTransactionSuccess />
      <UpdateTransactionFailed />

      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Are you sure you want to update a deleted transaction ?</DialogTitle>

        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TransactionDiscovery;
