import React, { useState, ReactElement, useRef, useEffect } from 'react';
// import Moment from 'moment';
import DataTable, {
  //   FetchDataParams,
  FilterByMenu,
  IDataGridRef,
} from '../../component/DataTable/DataTable';
// import { API_URLS } from '../../constants/url';
import { Grid } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
// import { getTournamentsData } from '../../services/tournaments.service';
import FloatingButton from '../../component/FloatingButton/FloatingButton';
import { useDispatch, useSelector } from 'react-redux';
import { showDeployGoalModalAction } from '../../redux/actions/modalActions/modalActions';
import { GOAL_GOALS_COLUMNS, GOAL_VARIABLES_COLUMNS } from '../../constants/columns/goalsManagement.constant';
import SelectDropdown from '../../component/SelectDropdown/SelectDropdown';
import { RootState } from '../../redux/reducers/rootReducer';
import BackendSearchBar from '../../component/BackendSearchBar/BackendSearchBar';
import CommonBreadcrumbs from '../../component/BreadCrumb/BreadCrumb';
import { GoalPermissions } from '../../constants/permission';
import { breadCrumbsKey } from '../../constants/breadCrumbConfig';
/**
 * Withdraw list
 * @returns {JSX.Element}
 * @constructor
 */
// interface ItemType {
//   user: { userName: string };
//   token: string;
//   createdAt: string;
//   entryFee: string;
//   gameName: string;
//   id: number;
//   prize: number;
//   size: number;
//   timeRemaining: string;
//   type: string;
//   typeCurrency: string;
// }

const menuItems: FilterByMenu[] = [
  { item: 'ID', columnName: 'tournaments.id' },
  { item: 'Name', columnName: 'tournaments.name' },
  { item: 'Description', columnName: 'tournaments.description' },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const GoalsManagement = (): ReactElement => {
  const [search, setSearch] = useState('');
  const DataGridRef = useRef<IDataGridRef>(null);
  const [tabValue, setTabValue] = useState(0);
  const [tournamentsType, setTournamentsType] = useState<string>('Goals');
  const [searchBy, setSearchBy] = React.useState<string>('');
  const dispatch = useDispatch();
  const handleChange = (event: any) => {
    setSearchBy(event.target.value as string);
  };

  const updateReloadTable = useSelector((state: RootState) => state.modalReducer.showDeployGoal);

  useEffect(() => {
    if (!updateReloadTable.show) {
      DataGridRef.current?.fetchData();
    }
  }, [updateReloadTable.show]);

  // useEffect(() => {
  //   DataGridRef.current?.fetchData();
  // }, [startDate, endDate]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 0:
        setSearchBy('Goals');
        setTournamentsType('Goals');
        break;
      case 1:
        setSearchBy('Variables');
        setTournamentsType('Variables');
        break;
      default:
    }
    setTabValue(newValue);
  };

  const floatingButtonClick = () => {
    console.log('FAB Clicked');
  };

  const fetchEvent = async () => {
    // const helper = API_URLS.TOURNAMENTS_LIST;
    // const response = await getTournamentsData({
    //   offset: page * pageSize,
    //   limit: pageSize,
    //   url: helper,
    //   search,
    //   searchBy,
    //   tournamentStatus: tournamentsType,
    //   startDate: new Date(startDate),
    //   endDate: new Date(endDate),
    // });

    // const rows = response?.results?.map((item: ItemType) => {
    //   return {
    //     createdAt: Moment(item.createdAt, 'DD/MM/YYYY, HH:mm:ss a').format(
    //       'YYYY/MM/DD/ HH:mm:ss',
    //     ),
    //     entryFee: item && item.entryFee,
    //     gameName: item.gameName,
    //     id: item.id,
    //     prize: item.prize,
    //     size: item.size,
    //     timeRemaining: item.timeRemaining,
    //     type: item.type,
    //     typeCurrency: item.typeCurrency,
    //   };
    // });

    // return {
    //   data: rows || [],
    //   totalRows: response?.totalRows,
    // };
    console.log(searchBy);
    return {
      data: [],
      totalRows: 0,
    };
  };

  const bulkActions = [
    {
      action: 'Deploy Goal',
      onClick: () => {
        dispatch(showDeployGoalModalAction(true, null));
      },
      icon: <AddIcon />,
      Permissions: [GoalPermissions.Create],
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Tabs
            // orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Tournament Tabs"
          >
            <Tab label="Goals" {...a11yProps(0)} />
            <Tab label="Variables" {...a11yProps(1)} />
          </Tabs>
        </Grid>

        <Grid item xs={4} justifyContent="flex-end" sx={{ display: 'flex' }}>
          <CommonBreadcrumbs title="Management" breadCrumbKey={breadCrumbsKey.goal} />
        </Grid>

        {/* <Grid item xs={4} justifyContent="flex-end" sx={{ display: 'flex' }}>
          <CommonBreadcrumbs title="Tournaments" breadCrumbKey={'management'} />
        </Grid> */}

        <Grid item xs={12}>
          <TabPanel value={tabValue} index={0}>
            {tournamentsType === 'Goals' && (
              <>
                <Grid
                  container
                  sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Grid item xs={10}>
                    <BackendSearchBar search={search} setSearch={setSearch} DataGridRef={DataGridRef} />
                  </Grid>

                  <Grid>
                    <SelectDropdown
                      handleChange={handleChange}
                      //   searchBy={searchBy}
                      menuItems={menuItems}
                      label="Search By"
                      style={{ width: 150 }}
                    />
                  </Grid>
                </Grid>
                <DataTable columns={GOAL_GOALS_COLUMNS} checkbox fetchData={fetchEvent} ref={DataGridRef} isInfiniteScroll enableSearch />
              </>
            )}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {tournamentsType === 'Variables' && (
              <>
                <Grid
                  container
                  sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Grid item xs={10}>
                    <BackendSearchBar search={search} setSearch={setSearch} DataGridRef={DataGridRef} />
                  </Grid>

                  <Grid>
                    <SelectDropdown
                      handleChange={handleChange}
                      //   searchBy={searchBy}
                      menuItems={menuItems}
                      label="Search By"
                      style={{ width: 150 }}
                    />
                  </Grid>
                </Grid>
                <DataTable columns={GOAL_VARIABLES_COLUMNS} checkbox fetchData={fetchEvent} ref={DataGridRef} isInfiniteScroll enableSearch />
              </>
            )}
          </TabPanel>
        </Grid>
      </Grid>

      <FloatingButton bulkActions={bulkActions} floatingButtonClick={floatingButtonClick} fabPermissions={[GoalPermissions.Create]} />
    </>
  );
};

export default GoalsManagement;
