import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  // useState
} from 'react';
import {
  // Autocomplete,
  // Button,
  // Checkbox,
  Divider,
  Modal,
  // FormControl,
  Grid,
  IconButton,
  // Tooltip,
  // InputLabel,
  // List,
  // ListItem,
  // ListItemIcon,
  // ListItemSecondaryAction,
  // ListItemText,
  // MenuItem,
  // Select,
  // SelectChangeEvent,
  // TextField,
  // Tooltip,
  Typography,
  Tooltip,
  FormControl,
  TextField,
  Chip,
  FormHelperText,
  Button,
} from '@mui/material';
import {
  Field,
  Form,
  // Field
} from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
// import { RootState } from '../../../redux/reducers/rootReducer';
import { showCreateDailyReturnSlotModalAction } from '../../../redux/actions/modalActions/modalActions';
import {
  Add,
  Close,
  Help,
  // Help,
  // DragIndicator,
  // Help
} from '@mui/icons-material';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import InputWrapper from '../../UI/Input';
import InputFieldsTooltip from '../../InputFieldsTooltip';
// import SelectWrapper from '../../UI/SelectWrapper';
import {
  objectKeyLength,
  // required
} from '../../../utils/validations';
import useStyles from '../Sku/skuStyles';
import { FormApi } from 'final-form';
import { getCurrencyTypeList } from '../../../services/currencyType.service';
import { API_URLS } from '../../../constants/url';
import SelectWrapper from '../../UI/SelectWrapper';
import { RootState } from '../../../redux/reducers/rootReducer';
import { startLoader, stopLoader } from '../../../utils/utils';
import axiosInstance from '../../../utils/apis';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useCurrentPartner } from '../../../hooks/useCurrentPartner';
import { upsertDailyRewardSlotData } from '../../../services/dailyReturn.service';
// import { getPlatformList } from '../../../services/promoCode.service';
// import { toast } from 'react-toastify';
// import InputWrapper from '../../UI/Input';
// import InputFieldsTooltip from '../../InputFieldsTooltip';
// import SelectWrapper from '../../UI/SelectWrapper';
// import { required } from '../../../utils/validations';
// import { getPromoCodeData } from '../../../services/promoCode.service';
// import {
//   getExpirationStrategyList,
//   upsertChainRuleData,
// } from '../../../services/promoCodeChainRule.service';
// import { Container, Draggable } from 'react-smooth-dnd';
// import { toast } from 'react-toastify';
// import InputFieldsTooltip from '../../InputFieldsTooltip';
// import promoCodeFilter, { PromoType } from '../../../utils/promoCodeFilter';

interface rewardInterface {
  currencyTypeId: string;
  amount: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface slotInterface {
  slotId: number;
  setId: number;
  name: string;
  weight: number;
  sequenceNum: number | undefined;
  description: string;
  add_key?: string;
  add_value?: string;
  rewards: rewardInterface[];
}

interface TypesType {
  value: number;
  label: string;
  is_selected?: number;
  name?: string;
  currencyTypeId: number;
}

interface CurrencyTypeData {
  value: number;
  label: string;
  is_selected?: number;
}

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const UpsertDailyReturnSlot = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openChildModal, setOpenChildModal] = useState<boolean>(false);
  const [isEditForm, setIsEditForm] = useState<boolean>(false);
  // create state for slotData
  const [slotData, setSlotData] = useState<slotInterface>();

  const { partnerShortName } = useCurrentPartner();

  const upsertSlotModalData = useSelector((state: RootState) => state.modalReducer.showDailyReturnSlot);

  const handleClose = () => {
    dispatch(showCreateDailyReturnSlotModalAction(false));
    setOpenChildModal(false);
    clearData();
  };

  const clearData = () => {
    console.log('Slot Data Cleared from Modal!e');
    setSlotData({} as slotInterface);
    // setIsEditForm(false);
  };

  // const handleChange = (
  //   event: React.SyntheticEvent,
  //   value: itemInterface[],
  // ) => {
  //   setChainRule({
  //     ...chainRule,
  //     childPromoCodeIds: value,
  //   });
  // };

  // const handleSelectChange = (event: SelectChangeEvent<string>) => {
  //   const { name, value } = event.target;
  //   setChainRule({
  //     ...chainRule,
  //     [name]: value,
  //   });
  // };

  const handleDailyReturnSlotSubmit = async (value: slotInterface) => {
    console.log('Slot Data Submitted!' + isEditForm);
    console.log('Creating or updating Daily Return Slot');
    console.log('Creating or updating Daily Rewards Slot');
    console.log(value);

    let data;
    let type;
    if (isEditForm) {
      type = 'updated';
      data = {
        slotId: value.slotId,
        setId: value.setId,
        name: value.name,
        weight: value.weight,
        rewards: value.rewards,
        sequenceNum: slotData?.sequenceNum,
        metadata: {},
        params: {},
      };
    } else {
      type = 'created';
      setIsEditForm(false);
      data = {
        slotId: undefined,
        setId: upsertSlotModalData?.data?.setId || 0,
        name: value.name,
        weight: value.weight,
        rewards: value.rewards,
        sequenceNum: undefined,
        metadata: {},
        params: {},
      };
    }

    try {
      const res = await upsertDailyRewardSlotData({ isEdit: isEditForm, data });
      if (res) {
        toast.success(`Successfully ${type} Set!`);
        handleClose();
      }
    } catch (e) {
      toast.error('There was a problem creating or updating the set!');
    }
  };

  const [currencyTypes, setCurrencyTypes] = useState<CurrencyTypeData[]>([]);
  const [currencyTypeList, setCurrencyTypeList] = useState<TypesType[]>([]);
  const [entitlementCurrency, setEntitlementCurrency] = useState<CurrencyTypeData[]>([]);

  const formRef: React.MutableRefObject<FormApi> = useRef({} as FormApi);

  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const characterCode = e.key;
    if (characterCode === 'Backspace') return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  };

  //set Type is_select Or Not
  const SetTypeSelect = (key: string[], value: number) => {
    let newCurrencyType = [...currencyTypes];
    if (value === 1) {
      newCurrencyType = entitlementCurrency.filter((item: CurrencyTypeData) => !key.includes(item.label));
    } else {
      const newRec = currencyTypes.find((item: CurrencyTypeData) => item.label === key[0]);
      newCurrencyType = [...entitlementCurrency, newRec as CurrencyTypeData];
    }
    setEntitlementCurrency([...newCurrencyType]);
  };

  //handle add entitlements data
  const handleAddData = () => {
    let newData = formRef.current.getFieldState('rewards')?.value;
    const add_key_id = formRef.current.getFieldState('add_key')?.value?.value;
    const add_value = formRef.current.getFieldState('add_value')?.value;
    const add_key = currencyTypes.find((item) => item.value === add_key_id)?.label as string;
    SetTypeSelect([add_key], 1);
    newData = { ...newData, [add_key]: add_value };
    formRef.current.change('rewards', newData);
    formRef.current.change('add_key', '');
    formRef.current.change('add_value', '');
  };

  //handle entitlements Delete
  const handleEntitlementsDelete = (data: string) => {
    const entitlements = formRef.current.getFieldState('rewards')?.value;
    if (currencyTypes.filter((type) => type.label === data).length !== 0) {
      SetTypeSelect([data], 0);
    }
    delete entitlements[data];
    formRef.current.change('rewards', { ...entitlements });
    formRef.current.resetFieldState('rewards');
  };

  const getDailyRewardSlotData = async (slotId: number | undefined) => {
    try {
      startLoader();
      const res = await axiosInstance.get(
        API_URLS.GET_DAILY_RETURN_SLOT + (partnerShortName ? `?partnerId=${partnerShortName}&id=${slotId}` : `?id=${slotId}`),
      );

      const slot = res.data;

      setSlotData(slot);

      const keys = Object.keys(slot.rewards);
      SetTypeSelect(keys, 1);
      stopLoader();
    } catch (e: unknown | AxiosError) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        toast.error((e as AxiosError)?.response?.data?.message || e.message);
      } else {
        toast.error((e as AxiosError).response?.data?.message);
      }
    }

    return null;
  };

  useEffect(() => {
    const data = currencyTypeList.map((item: TypesType) => {
      return {
        value: item.currencyTypeId,
        label: item.name || '',
      };
    });
    setCurrencyTypes([...data]);
    setEntitlementCurrency([...data]);
  }, [currencyTypeList]);

  useEffect(() => {
    const getCurrencyData = async () => {
      const res = await getCurrencyTypeList({
        url: API_URLS.GET_CURRENCY_TYPE,
      });
      setCurrencyTypeList([...res.data]);
    };

    if (upsertSlotModalData?.data?.id) {
      getDailyRewardSlotData(upsertSlotModalData?.data?.id);
    }
    if (upsertSlotModalData.show) {
      getCurrencyData();
    }
  }, [openChildModal, upsertSlotModalData.show]);

  return (
    <Fragment>
      <Tooltip title="Add a Slot to the Set." placement="right-start">
        <IconButton
          onClick={() => {
            console.log('Slot from Sets is clicked');
            setOpenChildModal(true);
          }}
          size="medium"
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Modal open={openChildModal || upsertSlotModalData.show} onClose={handleClose}>
        <Box sx={{ ...modalStyle, width: 500 }}>
          <Typography align="center" variant="h5" style={{ marginBottom: '10px' }}>
            {upsertSlotModalData?.data?.id === undefined ? 'Create Slot' : 'Update Slot'}
          </Typography>
          <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right', marginTop: '-40px' }}>
            <Close />
          </IconButton>
          <Divider
            sx={{
              backgroundColor: 'black',
              borderBottomWidth: 2,
              marginTop: '25px',
              marginBottom: '25px',
            }}
          />

          <Grid container>
            <Grid xs={12}>
              <Form onSubmit={handleDailyReturnSlotSubmit} initialValues={slotData}>
                {({ handleSubmit, form, values }) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  formRef.current = form;
                  return (
                    <form method="post" onSubmit={handleSubmit}>
                      <Grid item lg={12} className={classes.gridStyle}>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Field
                              component={InputWrapper}
                              id="standard-basic"
                              variant="outlined"
                              defaultValue={upsertSlotModalData?.data?.id ? slotData?.name : ''}
                              name="name"
                              type="text"
                              // readOnly={isReadable}
                              placeholder="Please enter a Slot name."
                              label={
                                <InputFieldsTooltip
                                  title="Slot Name"
                                  description="The Slot Name is a user-friendly name to easily identify it, this is not seen by players."
                                />
                              }
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <Field
                              component={InputWrapper}
                              id="standard-basic"
                              variant="outlined"
                              defaultValue={upsertSlotModalData?.data?.id ? slotData?.weight : ''}
                              name="weight"
                              type="number"
                              // readOnly={isReadable}
                              placeholder="Please enter a weight."
                              label={<InputFieldsTooltip title="Slot Weight" description="What is the chance of the Player landing on this slot?" />}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={12} className={classes.gridStyle}>
                        <Field name="rewards" autoFocus validate={objectKeyLength}>
                          {({ input, meta }) => (
                            <>
                              <FormControl fullWidth variant="outlined">
                                <TextField
                                  label={
                                    <Fragment>
                                      Rewards
                                      <Tooltip
                                        sx={{
                                          position: 'relative',
                                          bottom: '10px',
                                          right: '5px',
                                        }}
                                        title="The currencies and amounts given to players as a reward for the Daily Return Slot"
                                        placement="right-start"
                                      >
                                        <IconButton size="medium">
                                          <Help sx={{ fontSize: '20px' }} />
                                        </IconButton>
                                      </Tooltip>
                                    </Fragment>
                                  }
                                  onChange={input.onChange}
                                  error={meta.error && (meta.dirty || meta.submitFailed)}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <div className={classes.root}>
                                        {Object.keys(input.value).map((data: any, index: number) => {
                                          return (
                                            <Chip
                                              label={data + ': ' + input.value[data]}
                                              className={classes.chip}
                                              onDelete={() => {
                                                handleEntitlementsDelete(data);
                                              }}
                                              key={index}
                                            />
                                          );
                                        })}
                                      </div>
                                    ),
                                  }}
                                ></TextField>
                                {meta.error && (meta.dirty || meta.submitFailed) && (
                                  <FormHelperText error={meta.error !== ''}>{meta.error || meta.submitError}</FormHelperText>
                                )}
                              </FormControl>
                            </>
                          )}
                        </Field>
                      </Grid>

                      <Grid item lg={12} className={classes.gridStyle}>
                        <Grid container spacing={1}>
                          <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Field
                              name="add_key"
                              option={entitlementCurrency}
                              // readOnly={isReadable}
                              component={SelectWrapper}
                              displayEmpty
                              label={
                                <Fragment>
                                  Entitlement Type
                                  <Tooltip
                                    sx={{
                                      position: 'relative',
                                      bottom: '10px',
                                      right: '5px',
                                    }}
                                    title="The currency type given to users who purchase the SKU."
                                    placement="right-start"
                                  >
                                    <IconButton size="medium">
                                      <Help sx={{ fontSize: '20px' }} />
                                    </IconButton>
                                  </Tooltip>
                                </Fragment>
                              }
                            ></Field>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4}>
                            <Field
                              name="add_value"
                              component={InputWrapper}
                              onKeyDown={handleKeypress}
                              // readOnly={isReadable}
                              placeholder="Enter Amount"
                              label={<InputFieldsTooltip title="Amount" description="The value of the entitled currency in the SKU." />}
                            />
                          </Grid>
                          <Grid item xs={2} sm={2} md={2} lg={2}>
                            <Button
                              variant="contained"
                              onClick={handleAddData}
                              disabled={!values.add_key || values.add_key === '' || !values.add_value || values.add_value === ''}
                              sx={{ py: '10px' }}
                            >
                              <Add style={{ fontSize: '34px' }}></Add>
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          type="submit"
                          fullWidth
                          // disabled={isReadable}
                        >
                          {upsertSlotModalData?.data?.id === undefined ? 'Create Slot' : 'Update Slot'}
                        </Button>
                      </Grid>
                    </form>
                  );
                }}
              </Form>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Fragment>
  );
};
