import React, { useState, useMemo, useRef, ReactNode } from 'react';
import Moment from 'moment';
import DataTable, { FetchDataParams, FilterByMenu, IDataGridRef } from '../../component/DataTable/DataTable';
import { API_URLS } from '../../constants/url';
import DropDown from '../../component/DropDown/DropDown';
import { getPrizeRedeemData, prizeRedeemCancel, prizeRedeemConfirm } from '../../services/prizeRedeem.service';
import { PRIZE_REDEEM_COLUMNS } from '../../constants/columns/prizeRedeem.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/rootReducer';
import { Grid } from '@mui/material';
import SelectDropdown from '../../component/SelectDropdown/SelectDropdown';
import BackendSearchBar from '../../component/BackendSearchBar/BackendSearchBar';
import { PlayerSupportPermissions } from '../../constants/permission';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
interface PrizeRedeemListType {
  type: string;
}
export interface ItemType {
  id: number;
  email: string;
  playerName: string;
  amount: number;
  date: string;
  prizeName: string;
  token: string;
}
interface PrizeRedeemListData {
  TransactionLog: {
    id: number;
    submittedData: {
      email: string;
      playerName: string;
      amount: number;
      date: string;
      prizename: string;
    };
    createdAt: number;
    status: string;
  };
  user: { userName: string };
  profile: { name: string };
  TacoToken: { tokens: string };
  bonus_cash: number;
  token: string;
}

const menuItems: FilterByMenu[] = [
  { item: 'ID', columnName: 'TransactionLog.id' },
  { item: 'Username', columnName: 'user.user_name' },
];
const PrizeRedeemList = (props: PrizeRedeemListType): JSX.Element => {
  const DataGridRef = useRef<IDataGridRef>(null);
  const [search, setSearch] = useState<string>('');
  const [searchBy, setSearchBy] = React.useState<FilterByMenu>({
    item: '',
  });
  const startDate = useSelector((state: RootState) => state.commonReducers.startDateSelected);
  const endDate = useSelector((state: RootState) => state.commonReducers.endDateSelected);
  // Hide actions on history type
  const columns = useMemo(() => {
    let new_column: typeof PRIZE_REDEEM_COLUMNS = [];
    if (props.type === 'pending') {
      new_column = [
        {
          headerName: 'Action',
          field: 'action',
          width: 200,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <>
                <DropDown
                  title="Dropdown"
                  items={[
                    {
                      name: 'Confirm',
                      onClick: () => {
                        processRedeemConfirm(params.row);
                      },
                      Permissions: [PlayerSupportPermissions.Cash],
                    },
                    {
                      name: 'Cancel',
                      onClick: () => {
                        processRedeemCancel(params.row);
                      },
                      Permissions: [PlayerSupportPermissions.Cash],
                    },
                  ]}
                />
              </>
            ) as ReactNode;
          },
        },
      ];
    } else if (props.type === 'history') {
      new_column = [
        {
          headerName: 'Status',
          field: 'status',
          width: 130,
        },
      ];
    }

    return [...PRIZE_REDEEM_COLUMNS, ...new_column];
  }, [props.type]);

  const processRedeemConfirm = async (item: ItemType) => {
    const token = item?.token;
    await prizeRedeemConfirm({ payload: token });
    DataGridRef?.current?.fetchData();
  };

  const processRedeemCancel = async (item: ItemType) => {
    const token = item?.token;
    await prizeRedeemCancel({ payload: token });
    DataGridRef?.current?.fetchData();
  };

  const handleChange = (option: FilterByMenu) => {
    setSearchBy(option);
  };

  // useEffect(() => {
  //   DataGridRef.current?.fetchData();
  // }, [startDate, endDate]);

  async function fetchEvent({ page, pageSize }: FetchDataParams) {
    let helper;
    switch (props.type) {
      case 'pending':
        helper = API_URLS.PRIZE_REDEEM_REQUEST;
        break;
      case 'history':
        helper = API_URLS.PRIZE_REDEEM_HISTORY;
        break;
    }

    const response = await getPrizeRedeemData({
      offset: page * pageSize,
      limit: pageSize,
      url: helper,
      search,
      searchBy: searchBy.columnName,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      filter: '',
    });
    const rows = response?.results?.map((value: PrizeRedeemListData) => {
      return {
        id: value.TransactionLog.id ? value.TransactionLog.id : 0,
        playerName: value.user.userName || (value.profile ? value.profile.name : ''),
        email: value.TransactionLog.submittedData.email,
        cash: value.TransactionLog.submittedData.amount,
        rewardPoints: value.TacoToken ? value.TacoToken.tokens : 0,
        bonusCash: value.bonus_cash ? value.bonus_cash : 0,
        amount: value.TransactionLog.submittedData.amount ? value.TransactionLog.submittedData.amount : 'No funds given',
        date: Moment(value.TransactionLog.createdAt).format('YYYY/MM/DD/ HH:mm:ss'),
        status: value.TransactionLog.status,
        prizeName: value.TransactionLog.submittedData.prizename,
        token: value.token,
      };
    });

    return {
      data: rows || [],
      totalRows: response?.totalRows,
    };
  }
  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: '10px',
        }}
      >
        <Grid item xs={10}>
          <BackendSearchBar search={search} setSearch={setSearch} DataGridRef={DataGridRef} />
        </Grid>

        <Grid>
          <SelectDropdown handleChange={handleChange} searchBy={searchBy} menuItems={menuItems} label="Search By" style={{ width: 150 }} />
        </Grid>
      </Grid>
      <DataTable columns={columns} checkbox={props.type !== 'history'} fetchData={fetchEvent} ref={DataGridRef} enableSearch />
    </>
  );
};

export default PrizeRedeemList;
