import React, { ReactElement } from 'react';
import RedeemList from './PrizeRedeemList';

/**
 * Pending Redeems
 * @returns {JSX.Element}
 * @constructor
 */
const PrizeRedeemHistory = (): ReactElement => {
  return <RedeemList type="history" />;
};

export default PrizeRedeemHistory;
