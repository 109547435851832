import { matchPath, useLocation } from 'react-router-dom';
import { partners } from '../container/PartnerLandingPage/PartnerLandingPage';

export const useCurrentPartner = () => {
  const { pathname } = useLocation();
  const path = matchPath<{
    partner: string;
  }>(pathname, { path: '/:partner' });
  const partner = path?.params?.partner;
  const currentPartner = partners.find((item) => item.aliasName === partner)?.theme || 'common_theme';
  return { currentPartner, partnerShortName: partner };
};
