import { ItemType } from '../container/Withdrawals/WithdrawalsList';
import Request from './requests';
interface OptType {
  limit: number;
  offset: number;
}

interface processWithdrawInBatchesInterface {
  withdraws: ItemType[];
}
/**
 * Get withdraw requests list
 * @param {object} opts {limit, offset}
 */
const getWithdrawRequests = (opts: OptType): Promise<OptType> => {
  return Request({
    url: process.env.REACT_APP_WITHDRAW_ADMIN_API_URL + '/withdraw-requests',
    method: 'get',
    params: opts,
  });
};

/**
 * Get withdraw requests pending list
 * @param {object} opts {limit, offset}
 */
const getWithdrawRequestsPending = (opts: OptType): Promise<OptType> => {
  return Request({
    url: process.env.REACT_APP_WITHDRAW_ADMIN_API_URL + '/withdraw-requests-pending',
    method: 'get',
    params: opts,
  });
};

/**
 * Get withdraw history list
 * @param {object} opts {limit, offset}
 */
const getWithdrawHistory = (opts: OptType): Promise<OptType> => {
  return Request({
    url: process.env.REACT_APP_WITHDRAW_ADMIN_API_URL + '/withdraw-history',
    method: 'get',
    params: opts,
  });
};

/**
 * Process withdraw in batches
 * @param opts
 * @returns {*}
 */
const processWithdrawInBatches = (opts: processWithdrawInBatchesInterface) => {
  return Request({
    url: process.env.REACT_APP_WITHDRAW_ADMIN_API_URL + '/process-withdraw-batches',
    method: 'post',
    data: opts,
  });
};

export default {
  getWithdrawRequests,
  getWithdrawHistory,
  getWithdrawRequestsPending,
  processWithdrawInBatches,
};
