import { Reducer } from 'redux';
import { FilterByMenu } from '../../../component/DataTable/DataTable';
import { showChainRuleModalInterface, showNotificationModalInterface, showUserActionModalInterface } from '../../actions/modalActions/modalActions';
import { MODAL_ACTION } from '../../actions/modalActions/modelType';
import { MODAL_STATE } from '../../actions/type';

export interface ModelState {
  showUserAction: {
    show: boolean;
    data: showUserActionModalInterface | null;
  };
  showNotification: {
    show: boolean;
    data: showNotificationModalInterface | null;
  };
  showAddCredit: {
    show: boolean;
  };
  showUserUpdate: {
    show: boolean;
  };
  showContact: {
    show: boolean;
  };
  showResetPassword: {
    show: boolean;
  };
  showAvatars: {
    show: boolean;
  };
  showCloseAccount: {
    show: boolean;
  };
  showMergeAccount: {
    show: boolean;
  };
  showUpdateTransaction: {
    show: boolean;
    data: {
      id?: number;
      created_date?: Date;
      lastUpdated?: Date;
      userId?: number;
      walletReferenceId?: number;
      playedGameId?: number;
      status?: string;
    } | null;
  };
  showUpsertCurrencyType: {
    show: boolean;
    data: {
      id?: number | undefined;
      name?: string | undefined;
      description?: string | undefined;
      action?: number | undefined;
      currencyTypeId?: number | undefined;
    } | null;
    isReadable?: boolean;
  };
  showUpsertSkipDeposit: {
    show: boolean;
    data: {
      id?: string | undefined;
      bt_txn_id?: string | undefined;
      skuId?: number | undefined;
      isSkippedReason?: string | undefined;
      remaining_balance?: number | undefined;
      isSkippedTransaction?: boolean | false;
    } | null;
    isReadable?: boolean;
    areMultipleDepositsSelected?: boolean;
  };
  showUpdateSuccess: {
    show: boolean;
    data: {
      transactionIds?: number | undefined;
      toStatus?: string | undefined;
    } | null;
  };
  showUpdateFailed: {
    show: boolean;
    data: {
      transactionIds?: number | undefined;
      toStatus?: string | undefined;
      trnIDs?: number[] | undefined;
    } | null;
  };
  showDeleteTransaction: {
    show: boolean;
    data:
      | {
          deleteId: number;
        }
      | number
      | null;
  };
  showRemoveTransaction: {
    show: boolean;
    data:
      | {
          deleteId: number;
        }
      | number
      | null;
  };
  showUndoDeleteTransaction: {
    show: boolean;
    data?:
      | {
          deleteId: number;
        }
      | number
      | null;
  };
  showCreateTransaction: {
    show: boolean;
    data: {
      id?: number | undefined;
      userId?: string | undefined;
      walletReferenceId?: string | undefined;
      playedGameId?: string | undefined;
      tournamentId?: string | undefined;
      winnings?: string | undefined;
      status?: FilterByMenu | undefined;
      created_date?: Date | undefined;
      lastUpdated?: Date | undefined;
    } | null;
  };
  showCreateSuccess: {
    show: boolean;
    data: boolean | null;
  };
  showCreatePromoCode: {
    show: boolean;
    data: {
      id?: number | undefined;
      promoCodeId?: number | undefined;
      chainRuleData?: { isChained: boolean; parentPromoCodeId?: number } | undefined;
    } | null;
    isReadable?: boolean;
  };
  showCreatePromoType: {
    show: boolean;
    data: {
      id?: number | undefined;
      promoCodeTypeId?: number | undefined;
      promoCodeTypeName?: string | undefined;
    } | null;
    isReadable?: boolean;
  };
  showCreateSku: {
    show: boolean;
    data: {
      id?: number | undefined;
      skuId?: number | undefined;
      success?: boolean | undefined;
    } | null;
    isReadable?: boolean;
  };
  showAllocateSku: {
    show: boolean;
    data: {
      id?: number | undefined;
      skuId?: number | undefined;
    } | null;
  };
  showActionStepper: {
    show: boolean;
  };
  showGlobalDelete: {
    show: boolean;
    data: {
      deleteModule?: string;
      deleteId?: number;
      item?: {
        value: string;
        label: string;
        item?: {
          description: string;
        };
      };
    } | null;
  };
  showChainRule: {
    show: boolean;
    data: showChainRuleModalInterface | null;
    isReadable?: boolean;
  };
  showDeployGoal: {
    show: boolean;
    data: any;
  };
  showSuccess: {
    show: boolean;
    data: any;
  };
  showGoalList: {
    show: boolean;
    data: any;
    isReadable?: boolean;
  };
  showDailyReturnSetGroup: {
    show: boolean;
    data: {
      id: number;
      setId?: number | undefined;
      name: string | undefined;
      type: string | undefined;
      span: string | undefined;
      description: string | undefined;
      resetStrategy: number | undefined;
      success?: boolean | undefined;
    } | null;
    isReadable?: boolean;
  };
  showDailyReturnSet: {
    show: boolean;
    data: {
      id?: number | undefined;
      setId?: number | undefined;
      name: string | undefined;
      type: string | undefined;
      description: string | undefined;
      span: string | undefined;
      groupName: string | undefined;
      groupDescription: string | undefined;
      groupType: string | undefined;
      resetStrategy: number | undefined;
      success?: boolean | undefined;
    } | null;
    isReadable?: boolean;
  };
  showDailyReturnSlot: {
    show: boolean;
    data: {
      id?: number | undefined;
      setId?: number | undefined;
      slotId?: number | undefined;
      success?: boolean | undefined;
    } | null;
    isReadable?: boolean;
  };
  showCreateSegment: {
    show: boolean;
    data: {
      id?: number | undefined;
      success?: boolean | undefined;
    } | null;
    isReadable?: boolean;
  };
  showCreateSegmentFulfillmentJob: {
    show: boolean;
    data: {
      id?: number | undefined;
      success?: boolean | undefined;
    } | null;
    isReadable?: boolean;
  };
  showSegmentFulfillmentJob: {
    show: boolean;
    data: {
      id?: number | undefined;
      success?: boolean | undefined;
    } | null;
    isReadable?: boolean;
  };
}

export const modalReducer: Reducer<ModelState> = (state = MODAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case MODAL_ACTION.SHOW_USER_ACTION_MODEL:
      return {
        ...state,
        showUserAction: {
          ...state.showUserAction,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_NOTIFICATION_MODEL:
      return {
        ...state,
        showNotification: {
          ...state.showNotification,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_ADD_CREDITS_MODEL:
      return {
        ...state,
        showAddCredit: {
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_USER_UPDATE_MODEL:
      return {
        ...state,
        showUserUpdate: {
          ...state.showUserUpdate,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_CONTACT_MODEL:
      return {
        ...state,
        showContact: {
          ...state.showContact,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_RESET_PASSWORD_MODEL:
      return {
        ...state,
        showResetPassword: {
          ...state.showResetPassword,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_AVATARS_MODEL:
      return {
        ...state,
        showAvatars: {
          ...state.showAvatars,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_CLOSE_ACCOUNT_MODEL:
      return {
        ...state,
        showCloseAccount: {
          ...state.showCloseAccount,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_MERGE_ACCOUNT_MODEL:
      return {
        ...state,
        showMergeAccount: {
          ...state.showMergeAccount,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_UPDATE_TRANSACTION_MODEL:
      return {
        ...state,
        showUpdateTransaction: {
          ...state.showUpdateTransaction,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_UPSERT_CURRENCY_TYPE_MODEL:
      return {
        ...state,
        showUpsertCurrencyType: {
          ...state.showUpsertCurrencyType,
          show: payload.isVisible,
          data: payload.userData,
          isReadable: payload.isReadable,
        },
      };
    case MODAL_ACTION.SHOW_UPSERT_SKIP_DEPOSIT_MODEL:
      return {
        ...state,
        showUpsertSkipDeposit: {
          ...state.showUpsertSkipDeposit,
          show: payload.isVisible,
          data: payload.userData,
          isReadable: payload.isReadable,
        },
      };
    case MODAL_ACTION.SHOW_UPDATE_SUCCESS_MODEL:
      return {
        ...state,
        showUpdateSuccess: {
          ...state.showUpdateSuccess,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_UPDATE_FAILED_MODEL:
      return {
        ...state,
        showUpdateFailed: {
          ...state.showUpdateFailed,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_CREATE_TRANSACTION_MODEL:
      return {
        ...state,
        showCreateTransaction: {
          ...state.showCreateTransaction,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_CREATE_SUCCESS_MODEL:
      return {
        ...state,
        showCreateSuccess: {
          ...state.showCreateSuccess,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_DELETE_TRANSACTION_MODEL:
      return {
        ...state,
        showDeleteTransaction: {
          ...state.showDeleteTransaction,
          show: payload.isVisible,
          data: payload.userData,
        },
      };

    case MODAL_ACTION.SHOW_REMOVE_TRANSACTION_MODEL:
      return {
        ...state,
        showRemoveTransaction: {
          ...state.showDeleteTransaction,
          show: payload.isVisible,
          data: payload.userData,
        },
      };

    case MODAL_ACTION.SHOW_UNDO_DELETE_TRANSACTION_MODEL:
      return {
        ...state,
        showUndoDeleteTransaction: {
          ...state.showDeleteTransaction,
          show: payload.isVisible,
          data: payload.userData,
        },
      };

    case MODAL_ACTION.SHOW_CREATE_PROMO_CODE_MODEL:
      return {
        ...state,
        showCreatePromoCode: {
          ...state.showCreatePromoCode,
          show: payload.isVisible,
          data: payload.userData,
          isReadable: payload.isReadable,
        },
      };

    case MODAL_ACTION.SHOW_CREATE_SKU_MODEL:
      return {
        ...state,
        showCreateSku: {
          ...state.showCreateSku,
          show: payload.isVisible,
          data: payload.userData,
          isReadable: payload.isReadable,
        },
      };

    case MODAL_ACTION.SHOW_CREATE_PROMOTYPE_MODEL:
      return {
        ...state,
        showCreatePromoType: {
          ...state.showCreatePromoType,
          show: payload.isVisible,
          data: payload.userData,
          isReadable: payload.isReadable,
        },
      };

    case MODAL_ACTION.SHOW_ALLOCATE_SKU:
      return {
        ...state,
        showAllocateSku: {
          ...state.showAllocateSku,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_ACTION_STEPPER_MODEL:
      return {
        ...state,
        showActionStepper: {
          ...state.showActionStepper,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_MODEL_BY_MODULE:
      return {
        ...state,
        [payload.module]: {
          ...state[`${payload.module}` as keyof typeof MODAL_STATE],
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_GLOBAL_DELETE_MODEL:
      return {
        ...state,
        showGlobalDelete: {
          ...state.showGlobalDelete,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_CHAIN_RULE_MODEL:
      return {
        ...state,
        showChainRule: {
          ...state.showChainRule,
          show: payload.isVisible,
          data: payload.userData,
          isReadable: payload.isReadable,
        },
      };
    case MODAL_ACTION.SHOW_DEPLOY_GOAL_MODEL:
      return {
        ...state,
        showDeployGoal: {
          ...state.showDeployGoal,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_SUCCESS_MODEL:
      return {
        ...state,
        showSuccess: {
          ...state.showDeployGoal,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_GOAL_LIST_MODEL:
      return {
        ...state,
        showGoalList: {
          ...state.showGoalList,
          show: payload.isVisible,
          data: payload.userData,
          isReadable: payload.isReadable,
        },
      };
    case MODAL_ACTION.SHOW_CREATE_DAILY_RETURN_SET_GROUP_MODAL:
      return {
        ...state,
        showDailyReturnSetGroup: {
          ...state.showDailyReturnSetGroup,
          show: payload.isVisible,
          data: payload.userData,
          isReadable: payload.isReadable,
        },
      };
    case MODAL_ACTION.SHOW_CREATE_DAILY_RETURN_SET_MODAL:
      return {
        ...state,
        showDailyReturnSet: {
          ...state.showDailyReturnSet,
          show: payload.isVisible,
          data: payload.userData,
          isReadable: payload.isReadable,
        },
      };
    case MODAL_ACTION.SHOW_CREATE_DAILY_RETURN_SLOT_MODAL:
      return {
        ...state,
        showDailyReturnSlot: {
          ...state.showDailyReturnSlot,
          show: payload.isVisible,
          data: payload.userData,
          isReadable: payload.isReadable,
        },
      };
    case MODAL_ACTION.SHOW_CREATE_SEGMENT_MODAL:
      return {
        ...state,
        showCreateSegment: {
          ...state.showCreateSegment,
          show: payload.isVisible,
          data: payload.userData,
          isReadable: payload.isReadable,
        },
      };
    case MODAL_ACTION.SHOW_CREATE_SEGMENT_FULLFILLMENT_JOB:
      return {
        ...state,
        showCreateSegmentFulfillmentJob: {
          ...state.showCreateSegmentFulfillmentJob,
          show: payload.isVisible,
          data: payload.userData,
          isReadable: payload.isReadable,
        },
      };
    case MODAL_ACTION.SHOW_SEGMENT_FULLFILLMENT_JOB:
      return {
        ...state,
        showSegmentFulfillmentJob: {
          ...state.showSegmentFulfillmentJob,
          show: payload.isVisible,
          data: payload.userData,
          isReadable: payload.isReadable,
        },
      };
    default:
      return state;
  }
};
