import React, { Fragment, useEffect, useRef } from 'react';
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/rootReducer';
import { showChainRuleModalAction } from '../../../redux/actions/modalActions/modalActions';
import { Close, DragIndicator, Help } from '@mui/icons-material';
import { Box } from '@mui/system';
import { getPromoCodeData } from '../../../services/promoCode.service';
import { getExpirationStrategyList, upsertChainRuleData } from '../../../services/promoCodeChainRule.service';
import { Container, Draggable } from 'react-smooth-dnd';
import { toast } from 'react-toastify';
import InputFieldsTooltip from '../../InputFieldsTooltip';
import promoCodeFilter, { PromoType } from '../../../utils/promoCodeFilter';

interface chainRuleI {
  parentPromoCodeId: undefined | promoCodeInterface;
  childPromoCodeIds: itemInterface[];
  parentPromoStartTime: number;
  parentPromoEndTime: number;
  trigger: string;
  expirationStrategy: string;
  parentPromoPlatforms: number[];
}

// interface IParentPromoCode {
//   label: string;
//   value: number;
//   startTime?: Date;
//   endTime?: Date;
//   usageCount?: number;
//   platformNames?: string[];
//   metadata?: {
//     ruleId: number;
//     isChained: boolean;
//     isChainStart: boolean;
//     hide_code: boolean;
//   };
// }

interface itemInterface {
  label: string;
  name: string;
  strategyId: number;
  description: string;
  code: number;
  value: string;
  promoCodeId: number;
  startTsz: number;
  endTsz: number;
  usageCount: number;
  platformNames: [];
}

interface promoCodeInterface {
  label: string;
  value: number;
  startTime: number;
  endTime: number;
  usageCount: number;
  platformNames: [];
}

export const reorder = (list: itemInterface[], startIndex: number | null, endIndex: number | null): itemInterface[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex || 0, 1);
  result.splice(endIndex || 0, 0, removed);

  return result;
};
export const CreateChainRule = () => {
  const chainRuleModalData = useSelector((state: RootState) => state.modalReducer.showChainRule);
  const unassignedParentPromoCode = chainRuleModalData?.data?.parentPromoCode || chainRuleModalData?.data?.strategy?.parent;
  const id = chainRuleModalData?.data?.id || chainRuleModalData?.data?.ruleId;
  const modalData = chainRuleModalData?.data?.strategy || chainRuleModalData?.data;
  const isReadable = chainRuleModalData?.isReadable;
  const dispatch = useDispatch();
  const [chainRule, setChainRule] = React.useState<chainRuleI>({
    parentPromoCodeId: {
      label: '',
      value: 0,
      usageCount: 0,
      startTime: 0,
      endTime: 0,
      platformNames: [],
    },
    parentPromoStartTime: 0,
    parentPromoEndTime: 0,
    childPromoCodeIds: [],
    trigger: '',
    expirationStrategy: '',
    parentPromoPlatforms: [],
  });
  const [promoCodeList, setPromoCodeList] = React.useState<promoCodeInterface[]>([]);
  const [childPromoCodeList, setChildPromoCodeList] = React.useState<itemInterface[]>([]);
  const [expirationStrategyList, setExpirationStrategyList] = React.useState<itemInterface[]>([]);
  const [triggerList, setTriggerList] = React.useState<itemInterface[]>([]);
  const expirationStrategy = useRef<itemInterface[] | null>(null);
  const handleClose = () => {
    dispatch(showChainRuleModalAction(false));
    clearData();
  };
  const clearData = () => {
    setChainRule({
      parentPromoCodeId: undefined,
      parentPromoStartTime: 0,
      parentPromoEndTime: 0,
      childPromoCodeIds: [],
      trigger: '',
      expirationStrategy: '',
      parentPromoPlatforms: [],
    });
    expirationStrategy.current = null;
  };
  const handleAutoCompleteChange = (event: React.SyntheticEvent, value: promoCodeInterface | null) => {
    if (value) {
      setChainRule({
        ...chainRule,
        parentPromoCodeId: value,
        parentPromoStartTime: value.startTime,
        parentPromoEndTime: value.endTime,
        parentPromoPlatforms: value.platformNames,
      });
      const newChildPromoCodeList: PromoType[] = promoCodeList.filter((item: promoCodeInterface) => item.value !== value.value);
      const filteredList = promoCodeFilter(newChildPromoCodeList, {
        promoStartTime: value.startTime,
        promoEndTime: value.endTime,
        promoPlatformNames: value.platformNames,
      });
      setChildPromoCodeList(filteredList);
    }
  };

  const handleChange = (event: React.SyntheticEvent, value: itemInterface[]) => {
    setChainRule({
      ...chainRule,
      childPromoCodeIds: value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setChainRule({
      ...chainRule,
      [name]: value,
    });
  };

  const onDrop = ({ removedIndex, addedIndex }: { removedIndex: number | null; addedIndex: number | null }) => {
    const newList = (items: itemInterface[]) => reorder(items, removedIndex, addedIndex);
    setChainRule({
      ...chainRule,
      childPromoCodeIds: newList(chainRule.childPromoCodeIds),
    });
  };

  const handleSubmitChainRule = async () => {
    // TODO: Logic for Asigning Chain Rule to Promo Codes still need to be finished
    const currentChildPromoCodeIds = chainRule.childPromoCodeIds.map((item: itemInterface) => item.value);
    const assignPromoCodeIds = currentChildPromoCodeIds;
    const bodyData = {
      strategy: {
        parent: chainRule.parentPromoCodeId?.label,
        chain: chainRule.childPromoCodeIds.map((item: itemInterface) => item.label),
        trigger: chainRule.trigger,
        expirationStrategy: chainRule.expirationStrategy,
      },
      assignPromoCodeIds,
      parentPromoCodeID: chainRule.parentPromoCodeId?.value,
    };
    const res = await upsertChainRuleData({
      isEdit: false,
      data: bodyData,
    });
    if (res.success) {
      toast.success('Chain Rule Created Successfully');
      dispatch(showChainRuleModalAction(false, { success: true }));
      clearData();
    } else {
      toast.error('Something went wrong');
    }
  };
  const handleUpdateChainRule = async () => {
    const currentChildPromoCodeIds = chainRule.childPromoCodeIds.map((item: itemInterface) => item.value);
    const OriginalChildPromoCodeIds = modalData?.chain
      ?.map((item: string) => {
        return promoCodeList.find((promo: promoCodeInterface) => promo.label === item);
      })
      .filter((notUndefined: string) => notUndefined !== undefined)
      .map((item: itemInterface) => item.value);
    const unassignChildPromoCodeIds = OriginalChildPromoCodeIds.filter(
      (promo1: string) => !currentChildPromoCodeIds.find((promo2) => promo1 === promo2),
    );
    const assignChildPromoCodeIds: string[] = [];
    let alreadyExist = false;
    currentChildPromoCodeIds.map((curChiPromo: string) => {
      OriginalChildPromoCodeIds.map((orgChiPromo: string) => {
        if (orgChiPromo === curChiPromo) {
          alreadyExist = true;
        }
      });
      if (!alreadyExist) {
        assignChildPromoCodeIds.push(curChiPromo);
      }
      alreadyExist = false;
    });
    const newParentPromo = chainRule?.parentPromoCodeId?.label;
    const newParentPromoId = chainRule?.parentPromoCodeId?.value;
    const bodyData = {
      ruleId: id,
      strategy: {
        parent: chainRule.parentPromoCodeId?.label,
        chain: chainRule.childPromoCodeIds.map((item: itemInterface) => item.label),
        trigger: chainRule.trigger,
        expirationStrategy: chainRule.expirationStrategy,
      },
      parentPromoCodeID: chainRule.parentPromoCodeId?.value,
      unassignChildPromoCodeIds,
      assignChildPromoCodeIds,
      unassignParentPromoCodeId: newParentPromo === unassignedParentPromoCode ? null : unassignedParentPromoCode,
      assignParentPromoCodeId: newParentPromo === unassignedParentPromoCode ? null : newParentPromoId,
    };
    const res = await upsertChainRuleData({
      isEdit: true,
      data: bodyData,
    });

    if (res.success) {
      toast.success('Chain Rule Updated Successfully');
      dispatch(showChainRuleModalAction(false, { success: true }));
      clearData();
    } else {
      toast.error('Something went wrong');
    }
  };

  useEffect(() => {
    const setChainRuleData = async () => {
      const promocodeKey = modalData?.parent || modalData?.parentPromoCode;
      const parentPromoCodeData = promoCodeList.find((item: promoCodeInterface) => item.label === promocodeKey);
      const childPromoCodeIds = modalData?.chain
        ?.map((item: string) => {
          return promoCodeList.find((promo: promoCodeInterface) => promo.label === item);
        })
        .filter((notUndefined: undefined) => notUndefined !== undefined);
      setChainRule({
        parentPromoCodeId: parentPromoCodeData,
        childPromoCodeIds,
        trigger: modalData?.trigger,
        expirationStrategy: modalData?.expirationStrategy,
        parentPromoStartTime: parentPromoCodeData?.startTime || 0,
        parentPromoEndTime: parentPromoCodeData?.endTime || 0,
        parentPromoPlatforms: parentPromoCodeData?.platformNames || [],
      });
      const newChildPromoCodeList = promoCodeList.filter(
        (item: promoCodeInterface) => item.value !== (parentPromoCodeData && parentPromoCodeData.value),
      );
      const filteredList = promoCodeFilter(newChildPromoCodeList, {
        promoStartTime: parentPromoCodeData?.startTime || 0,
        promoEndTime: parentPromoCodeData?.endTime || 0,
        promoPlatformNames: parentPromoCodeData?.platformNames || [],
      });
      setChildPromoCodeList(filteredList);
    };
    const setParentData = async () => {
      const promocodeKey = modalData?.parent || modalData?.parentPromoCode;
      const parentPromoCode = promoCodeList.find((item: promoCodeInterface) => item.label === promocodeKey);
      setChainRule({
        ...chainRule,
        parentPromoCodeId: parentPromoCode,
      });
      const newChildPromoCodeList = promoCodeList.filter((item: promoCodeInterface) => item.value !== (parentPromoCode && parentPromoCode.value));
      const filteredList = promoCodeFilter(newChildPromoCodeList, {
        promoStartTime: parentPromoCode?.startTime || 0,
        promoEndTime: parentPromoCode?.endTime || 0,
        promoPlatformNames: parentPromoCode?.platformNames || [],
      });
      setChildPromoCodeList(filteredList);
    };
    if (id !== undefined && promoCodeList.length !== 0) {
      setChainRuleData();
    } else if (modalData?.parent && promoCodeList.length !== 0) {
      setParentData();
    }
  }, [chainRuleModalData.show, promoCodeList]);

  useEffect(() => {
    console.log('################################################');
    const fetchPromoCodeList = async () => {
      const response = await getPromoCodeData({});
      let filteredPromoList = [];
      if (id) {
        filteredPromoList = response?.data;
      } else {
        filteredPromoList = promoCodeFilter(response?.data, {
          notChained: true,
        });
      }
      const promoCodeListRes = filteredPromoList?.map((item: itemInterface) => ({
        label: item.code,
        value: item.promoCodeId,
        startTime: item.startTsz,
        endTime: item.endTsz,
        usageCount: item.usageCount,
        platformNames: item.platformNames,
      }));
      setPromoCodeList([...promoCodeListRes]);
      setChildPromoCodeList([...promoCodeListRes]);
    };
    const fetchExpirationStrategyList = async () => {
      const [responseTrigger, responseES] = await Promise.all([
        getExpirationStrategyList({ type: 'chain' }),
        getExpirationStrategyList({ type: 'promo' }),
      ]);
      const expirationStrategyListRes = responseES?.data?.map((item: itemInterface) => ({
        label: item.name,
        value: item.strategyId,
        description: item?.description,
      }));
      const TriggerListRes = responseTrigger?.data?.map((item: itemInterface) => ({
        label: item.name,
        value: item.strategyId,
        description: item.description,
      }));
      setExpirationStrategyList([...expirationStrategyListRes]);
      expirationStrategy.current = expirationStrategyListRes;
      setTriggerList([...TriggerListRes]);
    };
    if (chainRuleModalData?.show) {
      fetchPromoCodeList();
      fetchExpirationStrategyList();
    }
  }, [chainRuleModalData.show]);

  useEffect(() => {
    const filterExpirationStrategy = () => {
      const filteredExpirationStrategy =
        expirationStrategy?.current?.filter((item: itemInterface) => {
          if (chainRule.trigger === 'applyNextOnExpire') {
            return item.label !== 'usageCount';
          } else if (chainRule.trigger === 'applyNextOnUsage') {
            return item.label !== 'expireAllOnFirstUse';
          }
          return false;
        }) || [];
      setExpirationStrategyList([...filteredExpirationStrategy]);
    };
    if (chainRule.trigger !== '' && expirationStrategy?.current) {
      filterExpirationStrategy();
    }
  }, [chainRule.trigger, expirationStrategy?.current]);

  return (
    <>
      <Dialog open={chainRuleModalData.show} onClose={handleClose} disableRestoreFocus fullWidth>
        <div>
          <DialogContent>
            <Typography align="center" variant="h5" style={{ marginBottom: '10px' }}>
              {id === undefined ? 'Create Chain Rule' : 'Update Chain Rule'}
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right', marginTop: '-40px' }}>
              <Close />
            </IconButton>
            <Divider
              sx={{
                backgroundColor: 'black',
                borderBottomWidth: 2,
                marginTop: '25px',
                marginBottom: '25px',
              }}
            />
            <Box sx={{ mt: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <Autocomplete
                      id="parentPromoCodeId"
                      value={chainRule.parentPromoCodeId}
                      onChange={handleAutoCompleteChange}
                      options={promoCodeList}
                      getOptionLabel={(option) => option.label}
                      disableClearable={isReadable}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={<InputFieldsTooltip title="Select Parent Promo Code" description="Select Parent Promo Code Description" />}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: isReadable,
                          }}
                        />
                      )}
                      renderOption={(props, option) => {
                        return (
                          <Box component="li" {...props} key={option.value}>
                            <span>{option.label}</span>
                          </Box>
                        );
                      }}
                    />
                  </FormControl>
                </Grid>
                {/*{chainRule?.childPromoCodeIds && chainRule?.childPromoCodeIds?.length !== 0 && (*/}
                <Grid item xs={12}>
                  <fieldset>
                    <legend>
                      <Typography variant="body1">Child Promo Code</Typography>
                    </legend>
                    <List
                      sx={{
                        maxHeight: '200px',
                        overflow: 'auto',
                      }}
                    >
                      <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
                        {chainRule?.childPromoCodeIds?.map((value, index) => (
                          <Draggable key={index}>
                            <ListItem>
                              <ListItemText primary={value.label} />
                              <Typography sx={{ marginRight: '30%' }}>Usage Count : {value.usageCount}</Typography>
                              <ListItemSecondaryAction>
                                <ListItemIcon className="drag-handle">
                                  <DragIndicator sx={{ cursor: 'pointer' }} />
                                </ListItemIcon>
                              </ListItemSecondaryAction>
                            </ListItem>
                          </Draggable>
                        ))}
                      </Container>
                    </List>
                  </fieldset>
                </Grid>

                <Grid item xs={12}>
                  {chainRule.parentPromoCodeId && (
                    <FormControl fullWidth variant="outlined">
                      <Autocomplete
                        multiple
                        options={childPromoCodeList}
                        disableCloseOnSelect
                        value={chainRule.childPromoCodeIds}
                        getOptionLabel={(option) => option.label}
                        renderTags={() => <div style={{ marginLeft: '10px' }}>{chainRule.childPromoCodeIds.length + ' selected'}</div>}
                        disableClearable={isReadable}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox style={{ marginRight: 8 }} checked={selected} />
                            {option.label}
                          </li>
                        )}
                        onChange={handleChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<InputFieldsTooltip title="Select Child Promo Codes" description="Select Child Promo Codes Description" />}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: isReadable,
                            }}
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {/*{chainRule?.childPromoCodeIds?.length !== 0 && (*/}
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>
                      <Fragment>
                        Select Trigger
                        <Tooltip
                          sx={{
                            position: 'relative',
                            bottom: '10px',
                            right: '5px',
                          }}
                          title="Select Trigger description"
                          placement="right-start"
                        >
                          <IconButton size="medium">
                            <Help sx={{ fontSize: '20px' }} />
                          </IconButton>
                        </Tooltip>
                      </Fragment>
                    </InputLabel>
                    <Select
                      fullWidth
                      value={chainRule.trigger}
                      onChange={handleSelectChange}
                      name="trigger"
                      label={'Select Trigger'}
                      readOnly={isReadable}
                    >
                      {triggerList.map((item, index) => (
                        <MenuItem value={item.label} key={index}>
                          <Tooltip title={item?.description}>
                            <Box sx={{ width: '100%' }}>{item.label}</Box>
                          </Tooltip>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {chainRule.trigger && (
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>
                        <Fragment>
                          Select Expiration Strategy
                          <Tooltip
                            sx={{
                              position: 'relative',
                              bottom: '10px',
                              right: '5px',
                            }}
                            title="Select Trigger description"
                            placement="right-start"
                          >
                            <IconButton size="medium">
                              <Help sx={{ fontSize: '20px' }} />
                            </IconButton>
                          </Tooltip>
                        </Fragment>
                      </InputLabel>
                      <Select
                        fullWidth
                        value={chainRule.expirationStrategy}
                        onChange={handleSelectChange}
                        name="expirationStrategy"
                        label={' Select Expiration Strategy'}
                        readOnly={isReadable}
                      >
                        {expirationStrategyList.map((item) => (
                          <MenuItem value={item.label} key={item.value}>
                            <Tooltip title={item?.description}>
                              <Box sx={{ width: '100%' }}>{item.label}</Box>
                            </Tooltip>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    // disabled={chainRule.childPromoCodeIds.length > 0 ? (isReadable ? true : false) : true}
                    disabled={isReadable ? true : false}
                    variant="contained"
                    onClick={id === undefined ? handleSubmitChainRule : handleUpdateChainRule}
                  >
                    {id === undefined ? 'Create Chain Rule' : 'Edit Chain Rule'}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};
