import { Backdrop } from '@mui/material';
import React from 'react';
import './style.css';

interface SnakeLoaderInterface {
  loadingText: string;
}

export function SnakePageLoader(props: SnakeLoaderInterface) {
  const { loadingText } = props;
  return (
    <div className="mainDiv">
      <Backdrop sx={{ color: '#fff', zIndex: '9999' }} open={true}>
        <div className="square">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <b className="loadingText">{loadingText}</b>
      </Backdrop>
    </div>
  );
}
