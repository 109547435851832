/* eslint-disable indent */
export const composeValidators = (...validators: any[]) => {
  return (value: string): string => {
    return validators.reduce((error, validator) => error || validator(value), undefined);
  };
};

export const required = (value: string | number): string | undefined => (value && String(value).trim() !== '' ? undefined : 'Required');

export const isValidEmail = (value: string): string | false =>
  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) && 'Please Provide valid email address';

export const objectKeyLength = (value: string): string | undefined => (value && Object.keys(value).length !== 0 ? undefined : 'Required');

export const isUpperCase = (value: string): string | undefined => (value && value.toUpperCase() === value ? undefined : 'Must be uppercase');

export const isLowerCase = (value: string): string | undefined => (value && value.toLowerCase() === value ? undefined : 'Must be lowercase');

export const maxLength =
  (length: number) =>
  (value: string): string | undefined =>
    value && value.trim().length > length ? `Must be ${length} characters or less` : undefined;

export const DateRequired =
  (endDate: Date | string) =>
  (value: string): string | undefined =>
    value && value >= endDate ? 'Must be less than end date' : undefined;

export const isValidAdvertiserID = (value: string): string | false =>
  !/21[a-zA-Z0-9]{3}/i.test(value) && value && String(value).trim() !== '' && 'Must be five alphanumeric character starts with "21"';
