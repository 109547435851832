import { Button, Tooltip } from '@mui/material';
import { DeleteOutline, Edit } from '@mui/icons-material';
import React, { useRef, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { API_URLS } from '../../constants/url';
import DataTable, { IDataGridRef } from '../../component/DataTable/DataTable';
import { useDispatch, useSelector } from 'react-redux';

import { showChainRuleModalAction, showGlobalDeleteModalAction } from '../../redux/actions/modalActions/modalActions';
import { RootState } from '../../redux/reducers/rootReducer';
import useStylesChainRuleList from './ChainRuleStyle';
import { PROMO_CODE_CHAIN_RULE_COLUMN } from '../../constants/columns/promoCodeChainRule.constant';
import { getChainRuleList } from '../../services/promoCodeChainRule.service';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { IDeleteData } from '../CurrencyType/CurrencyType';

interface ChainRule {
  ruleId: number;
  strategy: {
    parent: string;
    chain: string[];
    expirationStrategy: string;
    trigger: string;
  };
}

export interface DataResponseType<T> {
  data: T[];
  totalRows: number;
}
const PromoCodeChainRule = () => {
  const DataGridRef = useRef<IDataGridRef>(null);
  const dispatch = useDispatch();
  const chainRuleClasses = useStylesChainRuleList();
  const PROMO_CODE_CHAIN_LIST = PROMO_CODE_CHAIN_RULE_COLUMN(chainRuleClasses);

  const columns = useMemo<GridColDef[]>(() => {
    const All_Column: GridColDef[] = [
      {
        field: 'action',
        headerName: 'Action',
        headerAlign: 'center',
        description: 'Action',
        width: 220,
        renderCell: (params: GridRenderCellParams) => (
          <>
            <Tooltip title="Edit">
              <Button
                size="small"
                sx={{ ml: '16px' }}
                onClick={() => {
                  dispatch(showChainRuleModalAction(true, params.row));
                }}
              >
                <Edit color="secondary" />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                size="small"
                style={{ marginLeft: 16 }}
                onClick={() => {
                  const deleteData: IDeleteData = {};
                  deleteData.deleteModule = 'Promo Code Chain Rule';
                  deleteData.deleteId = params.row.id;
                  deleteData.item = {};
                  deleteData.item.value = params.row.id;
                  deleteData.item.label = params.row.parentPromoCode;
                  dispatch(showGlobalDeleteModalAction(true, deleteData));
                }}
              >
                <DeleteOutline color="error" />
              </Button>
            </Tooltip>
          </>
        ),
      },
    ];
    return [...PROMO_CODE_CHAIN_LIST, ...All_Column];
  }, [PROMO_CODE_CHAIN_LIST]);
  const handleDoubleClick = (e: any) => {
    dispatch(showChainRuleModalAction(true, e.row, false));
  };
  const fetchData = async () => {
    try {
      const res: DataResponseType<ChainRule> = await getChainRuleList({
        url: API_URLS.GET_PROMO_CODE_CHAIN_RULE,
      });
      const data = res?.data?.map((item: ChainRule) => {
        return {
          id: item.ruleId,
          parentPromoCode: item.strategy.parent,
          chain: item.strategy.chain,
          expirationStrategy: item.strategy.expirationStrategy,
          trigger: item.strategy.trigger,
        };
      });
      return {
        data: data || [],
        totalRows: res?.totalRows,
      };
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  const chainRuleModalData = useSelector((state: RootState) => state.modalReducer.showChainRule);

  const globalDeleteModalData = useSelector((state: RootState) => state.modalReducer.showGlobalDelete);

  useEffect(() => {
    if (chainRuleModalData?.data?.success) {
      DataGridRef.current?.fetchData();
    }
  }, [chainRuleModalData.data]);

  useEffect(() => {
    if (!globalDeleteModalData.show) {
      DataGridRef.current?.fetchData();
    }
  }, [globalDeleteModalData.show]);

  return (
    <>
      <DataTable columns={columns} fetchData={fetchData} ref={DataGridRef} handleDoubleClick={handleDoubleClick} isInfiniteScroll enableSearch />
    </>
  );
};

export default PromoCodeChainRule;
