export function handleColumnVisibility(columnName: string, defaultVisibility: boolean, localStorageVar: string) {
  const localData: string | null = localStorage.getItem(localStorageVar);
  const currentUser: string | null = localStorage.getItem('currentUser');
  const userMail: string | null = currentUser ? JSON.parse(currentUser).mail : null;
  const columnVisibility: { [key: string]: any } = localData ? JSON.parse(localData) : null;
  if (columnVisibility && columnVisibility.userMail == userMail) {
    if (columnVisibility[columnName]) {
      return false;
    } else {
      return true;
    }
  } else {
    return !defaultVisibility;
  }
}
