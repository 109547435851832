import React from 'react';
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import CircularProgress from '@mui/material/CircularProgress';
import WarningAmberTwoToneIcon from '@mui/icons-material/WarningAmberTwoTone';
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone';
import PriorityHighTwoToneIcon from '@mui/icons-material/PriorityHighTwoTone';
import { Chip } from '@mui/material';
import { COMMON_FILTER_COMPONENT } from './commonFilterComponent';
import moment from 'moment';

interface TagSegmentsClassesInterface {
  tags: string;
}

const handleStatusIcon = (status: string) => {
  switch (status) {
    case 'Processing':
      return <CircularProgress size={20} />;
      break;
    case 'Complete':
      return <TaskAltTwoToneIcon />;
      break;
    case 'Errors':
      return <WarningAmberTwoToneIcon />;
      break;
    case 'Failed':
      return <PriorityHighTwoToneIcon />;
      break;
    default:
      return;
  }
  console.log('FAB Clicked');
};

export const FULFILLMENT_CENTER_LIST_COLUMN = (skuClasses: TagSegmentsClassesInterface): GridColDef[] => {
  return [
    {
      field: 'id',
      headerName: 'Job ID',
      width: 90,
      description: 'Record ID on server for the Tag.',
    },
    {
      field: 'start',
      headerName: 'Start',
      width: 175,
      description: 'The user-friendly name of the Tag. The Name should be Short and Concise, elaborate in the Description section.',
      valueGetter: (params: any) => {
        const valueFormatted = moment(params.value as number)
          .utc()
          .toDate();
        return valueFormatted;
      },
      valueFormatter: (params: GridValueFormatterParams) => {
        const valueFormatted = moment(params.value as number).format('YYYY-MM-DD HH:mm:ss');
        return valueFormatted.toLocaleString();
      },
      type: 'date',
    },
    {
      field: 'end',
      headerName: 'End',
      width: 175,
      description: 'A description of the purpose of the Tag for Admins.',
      valueGetter: (params: any) => {
        const valueFormatted = moment(params.value as number)
          .utc()
          .toDate();
        return valueFormatted;
      },
      valueFormatter: (params: GridValueFormatterParams) => {
        const valueFormatted = moment(params.value as number).format('YYYY-MM-DD HH:mm:ss');
        return valueFormatted.toLocaleString();
      },
      type: 'date',
    },
    {
      field: 'user_count',
      headerName: 'User Count',
      width: 100,
      description: 'The Category for the Tag, Categories allow Admins to Segment Users depending on the Business Purpose.',
    },
    {
      field: 'tags',
      headerName: 'Tags',
      width: 200,
      description: 'The Tags to be added or removed for the Users',
      renderCell: (params: GridRenderCellParams) => (
        <div className={skuClasses.tags}>
          {params.value?.map((platForm: string, index: number) => {
            return <Chip key={index} label={platForm}></Chip>;
          })}
        </div>
      ),
      filterOperators: COMMON_FILTER_COMPONENT,
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      description: 'A System Managed Tag cannot be updated, these are Tags essential to how the System Operates.',
    },
    {
      field: 'success_fails',
      headerName: 'S/F',
      width: 100,
      description: 'The Date and Time the Tag/Segment was Originally Created.',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
      description: 'The Last Date and Time the Tag/Segment Record has been Updated.',
      renderCell: (params: GridRenderCellParams) => (
        <div>
          {params.value} {handleStatusIcon(params.value)}
        </div>
      ),
    },
  ];
};
