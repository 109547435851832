import React, { useState, useMemo, useRef, Fragment, useEffect } from 'react';
import DataTable, { FetchDataParams, FilterByMenu, IDataGridRef } from '../../component/DataTable/DataTable';
import BackendSearchBar from '../../component/BackendSearchBar/BackendSearchBar';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
// import useStylesSkuList from '../SKU/skuListStyle';
import { Grid } from '@mui/material';
import SelectDropdown from '../../component/SelectDropdown/SelectDropdown';
import { GOALS_LIST_COLUMNS } from '../../constants/columns/goalsList.constants';
import { API_URLS } from '../../constants/url';
import { startLoader, stopLoader } from '../../utils/utils';
import { getGoalsData } from '../../services/goals.service';
import { RootState } from '../../redux/reducers/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { handleColumnVisibility } from '../../utils/columnVisiPref';
import { showGoalListModalAction } from '../../redux/actions/modalActions/modalActions';
import GoalsListModal from '../../component/Modals/Goal/GoalListModal';
import moment from 'moment';

const GoalsList = (): JSX.Element => {
  const DataGridRef = useRef<IDataGridRef>(null);
  const [search, setSearch] = useState<string>('');

  const [goalStatus, setGoalStatus] = React.useState<FilterByMenu>({
    item: 'All',
  });

  const [searchBy, setSearchBy] = React.useState<FilterByMenu>({
    item: 'ID',
  });

  const startDate = useSelector((state: RootState) => state.commonReducers.startDateSelected);
  const endDate = useSelector((state: RootState) => state.commonReducers.endDateSelected);

  const dispatch = useDispatch();
  const currentUser: string | null = localStorage.getItem('currentUser');

  const userMail: string | null = currentUser ? JSON.parse(currentUser).mail : null;

  const handleColumnVisibilityChange = (newModel: GridColumnVisibilityModel) => {
    localStorage.setItem('columnVisibilityGoals', JSON.stringify({ ...newModel, userMail }));
  };

  // Hide actions on history type
  const columns = useMemo<GridColDef[]>(() => {
    const Final_Column = GOALS_LIST_COLUMNS(handleColumnVisibility);
    return Final_Column;
  }, [GOALS_LIST_COLUMNS]);

  const menuItems: FilterByMenu[] = [
    { item: 'ID', columnName: 'user.id' },
    { item: 'Username', columnName: 'user.user_name' },
  ];

  const goalStatusDropdown: FilterByMenu[] = [{ item: 'Active' }, { item: 'Expired' }, { item: 'Completed' }, { item: 'All' }];

  const handleChange = (option: FilterByMenu) => {
    setSearchBy(option);
  };

  const [openModal, setOpenModal] = useState(false);
  const handleDoubleClick = (e: any) => {
    setOpenModal(true);
    dispatch(showGoalListModalAction(true, e.row, true));
  };

  const handleGoalStatusChange = (option: FilterByMenu) => {
    setGoalStatus(option);
  };
  // useEffect(() => {
  //   DataGridRef.current?.fetchData();
  // }, [startDate, endDate]);

  async function fetchEvent({ page, pageSize }: FetchDataParams) {
    const helper = API_URLS.GET_GOALS;
    startLoader();
    if (pageLoad) {
      const response = await getGoalsData({
        offset: page * pageSize,
        limit: pageSize,
        url: helper,
        goalStatus: goalStatus.item,
        search,
        searchBy: searchBy.item,
        startDate: startDate ? new Date(startDate) : undefined,
        endDate: endDate ? new Date(endDate) : undefined,
      });
      stopLoader();
      const rowsData = response?.data?.map((value: any) => {
        const start = moment(value.startDate);
        const end = moment(value.endDate);
        return {
          id: value.id ? value.id : 0,
          parentGoalID: value.parent_goal_id,
          playerId: value.playerId,
          goalName: value.goalName,
          startDate: start.tz('America/New_York').format('LLL'),
          endDate: value.endDate != null ? end.tz('America/New_York').format('LLL') : value.endDate,
          displayName: value.displayName,
          status: value.status,
          description: value.description,
          subtitle: value.subtitle,
          goalType: value.goalType,
          goalCategory: value.goalCategory,
          segmentUniverse: value.segmentUniverse,
          progressRequired: value.progressRequired,
          goalProgress: value.goalProgress,
          maxClaimableRounds: value.maxClaimableRounds,
          roundsCompleted: value.roundsCompleted,
          rewardType: value.rewardType,
          rewardsRound: value.rewardsRound,
          totalPayout: value.totalPayout,
          rewardsClaimsPending: value.rewardsClaimsPending,
          lastRewardClaimedDate: value.lastRewardClaimedDate,
        };
      });
      console.log(rowsData);
      return {
        data: rowsData || [],
        totalRows: response?.totalRows,
      };
    } else {
      return {
        data: [],
        totalRows: 0,
      };
    }
  }

  const [pageLoad, setPageLoad] = useState<boolean>(false);
  useEffect(() => {
    setPageLoad(true);
    stopLoader();
  }, []);
  return (
    <>
      <Grid
        container
        columnGap={2}
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '2%',
        }}
      >
        <Grid item xs={8} sx={{ display: 'flex' }}>
          <BackendSearchBar search={search} setSearch={setSearch} DataGridRef={DataGridRef} label={`Search by ${searchBy.item}`} />

          <SelectDropdown handleChange={handleChange} searchBy={searchBy} menuItems={menuItems} label="Search By" style={{ width: 250 }} />
        </Grid>

        <Grid item xs={4}>
          <SelectDropdown handleChange={handleGoalStatusChange} searchBy={goalStatus} menuItems={goalStatusDropdown} label="Goal Status" />
        </Grid>
      </Grid>
      <DataTable
        columns={columns}
        fetchData={fetchEvent}
        ref={DataGridRef}
        handleDoubleClick={handleDoubleClick}
        enableSearch
        disableExport
        handleColumnVisibilityChange={handleColumnVisibilityChange}
      />

      {openModal && <GoalsListModal />}
    </>
  );
};

export default GoalsList;
