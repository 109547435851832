export const HEADERS = {
  CONTENT_TYPE: {
    'Content-Type': 'application/json',
  },
  X_ACCESS_TOKEN: {
    'x-access-token': '',
  },
  CONTENT_TYPE_FORM_DATA: 'ContentTypeFormData',
  AUTHORIZATION: 'Authorization',
  X_VERSION: {
    'x-version': '2.9.0',
  },
  X_GAME_NAME: {
    'x-game-name': 'jewel',
  },
  X_GAME_PLATFORM: {
    'x-game-platform': 'MOBILE',
  },
};
