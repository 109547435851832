import { Button, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Form } from 'react-final-form';
import { useAuth0 } from '@auth0/auth0-react';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { LoginSubmit } from './Login';
const PREFIX = 'FormLogin';

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.root}`]: {
    padding: '40px 30px 30px 30px',
    margin: 'auto',
    width: '50%',
    marginTop: '30%',
    backgroundColor: '#172b4dd9',
  },

  [`& .${classes.button}`]: {
    marginTop: '10px',
    display: 'inline-block',
  },
}));

interface FormLogin {
  loginSubmit: (values: LoginSubmit) => void;
}

const FormLogin: React.FC<FormLogin> = ({ loginSubmit }) => {
  const { loginWithRedirect } = useAuth0();
  return (
    <StyledGrid container justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Paper className={classes.root} elevation={4}>
          <Form onSubmit={loginSubmit}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Typography variant="h5" component="h3" align="center" sx={{ color: 'white' }}>
                  Welcome to the Tool Belt
                </Typography>

                <Grid container alignItems="center" justifyContent="center" flexDirection={'column'}>
                  <Button type="button" className={classes.button} onClick={() => loginWithRedirect()} variant="contained">
                    Single Sign-on <VpnKeyIcon style={{ marginBottom: '-5%' }} />
                  </Button>
                  <div style={{ marginTop: '20px' }}>
                    <img src="https://taco-webapp.s3.us-west-2.amazonaws.com/powered_by_gametaco.png" width="200px" />
                  </div>
                </Grid>
              </form>
            )}
          </Form>
        </Paper>
      </Grid>
    </StyledGrid>
  );
};

export default FormLogin;
