import React, { ReactElement } from 'react';

const PlayerList = (): ReactElement => {
  return (
    <div>
      <h2> Player List </h2>
    </div>
  );
};

export default PlayerList;
