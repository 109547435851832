import React from 'react';
import { GridRenderCellParams, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { Chip, Checkbox, Tooltip } from '@mui/material';
import moment from 'moment';
import { COMMON_FILTER_COMPONENT } from './commonFilterComponent';
import { payloadInterface, showCreateSkuModalAction } from '../../redux/actions/modalActions/modalActions';
import { Dispatch } from 'redux';

interface columnClassInterface {
  entitlements: string;
  entitlementsChip: string;
}

export const PROMO_LIST_COLUMN = (
  columnClass: columnClassInterface,
  dispatch: Dispatch<{ type: string; payload: payloadInterface }>,
): GridColDef[] => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      description: 'Record ID on server',
      hide: true,
    },
    {
      field: 'code',
      headerName: 'Promo Code',
      description: 'Promo code shown to or entered by user',
      width: 200,
      renderCell: (params: GridRenderCellParams<number>) => (
        <Tooltip title={params?.row?.description} placement="bottom-start">
          <span
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              width: '100%',
            }}
          >
            {params.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: 'skuList',
      headerName: 'SKU',
      width: 200,
      description: 'SKU for promo',
      renderCell: (params: GridRenderCellParams) => (
        <div className={columnClass.entitlements}>
          {params.value?.map((skuList: any, index: number) => {
            return (
              <Tooltip
                sx={{
                  position: 'relative',
                  bottom: '10px',
                  right: '5px',
                }}
                title={skuList.description}
                key={skuList.id}
                placement="top-start"
              >
                <Chip
                  key={index}
                  label={skuList.name}
                  className={columnClass.entitlementsChip}
                  color={skuList?.metadata?.bestValue ? 'primary' : 'default'}
                  onClick={() => dispatch(showCreateSkuModalAction(true, skuList, false))}
                ></Chip>
              </Tooltip>
            );
          })}
        </div>
      ),
      filterOperators: COMMON_FILTER_COMPONENT,
    },
    {
      field: 'chainRuleData',
      headerName: 'Chain Rule',
      description: 'Chain RUle Detail For Promo Code',
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div>
            {params?.value?.isChained ? (
              params?.value?.isChainStart ? (
                <Tooltip
                  sx={{
                    position: 'relative',
                  }}
                  title="Parent"
                  placement="right-start"
                >
                  <Chip label="P" variant="filled" color="primary" />
                </Tooltip>
              ) : (
                <Tooltip
                  sx={{
                    position: 'relative',
                  }}
                  title="Child"
                  placement="right-start"
                >
                  <Chip label="C" variant="outlined" color="primary" />
                </Tooltip>
              )
            ) : null}
            &nbsp;
            {params?.value?.isChained ? (
              <Tooltip
                sx={{
                  position: 'relative',
                }}
                title="Chained"
                placement="right-start"
              >
                <Chip label="C" variant="outlined" />
              </Tooltip>
            ) : (
              <Chip label="Not Chained" />
            )}
          </div>
        );
      },
      filterOperators: COMMON_FILTER_COMPONENT,
    },
    {
      field: 'PromoCodeTypeId',
      headerName: 'Promo Code Type',
      description: 'Promo Code Type',
      width: 180,
      hide: true,
    },
    {
      field: 'shortDescription',
      headerName: 'Short Description',
      description: 'The “title” of the promo code shown to the user during deposit, not shown in app.',
      width: 250,
    },
    {
      field: 'platforms',
      headerName: 'Platform Ids',
      width: 200,
      hide: true,
      description: 'PlatForm Ids for SKU',
      renderCell: (params: GridRenderCellParams) => (
        <div className={columnClass.entitlements}>
          {params.value?.map((platForm: string, index: number) => {
            return <Chip key={index} label={platForm} className={columnClass.entitlementsChip}></Chip>;
          })}
        </div>
      ),
      filterOperators: COMMON_FILTER_COMPONENT,
    },
    {
      field: 'platformNames',
      headerName: 'Platform Names',
      width: 200,
      description: 'PlatForm Names for Promo',
      renderCell: (params: GridRenderCellParams) => (
        <div className={columnClass.entitlements}>
          {params.value?.map((platForm: string, index: number) => {
            return <Chip key={index} label={platForm} className={columnClass.entitlementsChip}></Chip>;
          })}
        </div>
      ),
      filterOperators: COMMON_FILTER_COMPONENT,
    },
    {
      field: 'startTsz',
      headerName: 'Start Date',
      description: 'The earliest date and time in (UTC) that a user can apply this code if marked as Public.',
      width: 215,
      hide: true,
      valueGetter: (params: any) => {
        const valueFormatted = moment((params.value as number) * 1000)
          .utc()
          .toDate();
        return valueFormatted;
      },
      valueFormatter: (params: GridValueFormatterParams) => {
        const valueFormatted = moment(params.value as number).format('YYYY-MM-DD HH:mm:ss');
        return valueFormatted.toLocaleString();
      },
      type: 'date',
    },
    {
      field: 'endTsz',
      headerName: 'End Date',
      description: 'The latest date and time in (UTC) that a user can apply this code if marked as Public',
      width: 215,
      hide: true,
      valueGetter: (params: any) => {
        const valueFormatted = moment((params.value as number) * 1000)
          .utc()
          .toDate();
        return valueFormatted;
      },
      valueFormatter: (params: GridValueFormatterParams) => {
        const valueFormatted = moment(params.value as number).format('YYYY-MM-DD HH:mm:ss');
        return valueFormatted.toLocaleString();
      },
      type: 'date',
    },
    {
      field: 'instanceExpiryMins',
      headerName: 'Instance Expiry',
      description: 'The amount of time after applying the promocode that the user can deposit to take advantage of the code. ',
      width: 200,
      hide: true,
    },

    {
      field: 'isPublic',
      headerName: 'Enabled',
      description: 'is the Promo Code available for Public use?',
      width: 120,
      renderCell: (params: GridValueFormatterParams) => <Checkbox checked={params.value as boolean} disabled name="is_public_demo" />,
    },
  ] as GridColDef[];
};
