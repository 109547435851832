import { matchPath } from 'react-router-dom';
import { RouteConfig } from '../constants/routeConfig.constant';
import { useCurrentPartner } from '../hooks/useCurrentPartner';

const globalAny: any = global;

interface historyInterface {
  location: {
    pathname?: string;
  };
}

export function findCommonElement(array1: string[], array2: string[]): boolean {
  // Loop for array1
  for (let i = 0; i < array1.length; i++) {
    // Loop for array2
    for (let j = 0; j < array2.length; j++) {
      // Compare the element of each and
      // every element from both of the
      // arrays
      if (array1[i] === array2[j]) {
        // Return if common element found
        return true;
      }
    }
  }
  // Return if no common element exist
  return false;
}

export function startLoader(): void {
  // Show loader
  globalAny.props.setLoading(true);
}

export function stopLoader(): void {
  // hide loader
  globalAny.props.setLoading(false);
}

export function showDateFilter(history: historyInterface | null) {
  const path = history?.location?.pathname;
  const { partnerShortName } = useCurrentPartner();
  const getFromRouteConfig = RouteConfig.filter((item) => {
    return matchPath(path || '', {
      path: '/' + partnerShortName + item.path,
      exact: true,
      strict: false,
    });
  });
  if (getFromRouteConfig?.[0]?.disableDateFilter) {
    return false;
  }
  return true;
}
