//enums
import { protectedRoutes, userAccess } from './routeEnum';

// components
import PromoList from '../container/PromoCode/PromoList';
import PromoCodeType from '../container/PromoType/PromoType';
import CurrencyType from '../container/CurrencyType/CurrencyType';
import AllocateSku from '../container/AllocateSku/AllocateSku';
import SkuList from '../container/SKU/SkuList';
import PrizeRedeemHistory from '../container/PrizeRedeem/PrizeRedeemHistory';
import PrizeRedeemDiscovery from '../container/PrizeRedeem/PrizeRedeemDiscovery';
import Home from '../container/Favorites/Home';
import TransactionDiscovery from '../container/Transactions/TransactionDiscovery';
import PrizeRedeemHome from '../container/PrizeRedeem/PrizeRedeem';
import Tournaments from '../container/Tournaments/Tournaments';
import TournamentsCreate from '../container/Tournaments/TournamentsCreate';
import TournamentsCancelled from '../container/Tournaments/TournamentsCancelled';
import TournamentsCompleted from '../container/Tournaments/TournamentsCompleted';
import TournamentsPatterns from '../container/Tournaments/TournamentsPatterns';
import TournamentsSpecialEvents from '../container/Tournaments/TournamentsSpecialEvents';
import TournamentsActive from '../container/Tournaments/TournamentsActive';
import PrizeRedeemPending from '../container/PrizeRedeem/PrizeRedeemPending';
import WithdrawsHistory from '../container/Withdrawals/WithdrawalsHistory';
import GoalsManagement from '../container/Goals/GoalsManagement';
import Goals from '../container/Goals/Goals';
import PlayerList from '../container/PlayerManagement/PlayerList';
import PlayerPatterns from '../container/PlayerManagement/PlayerPatterns';
import PlayerSupport from '../container/PlayerManagement/PlayerSupport';
import TournamentsConfig from '../container/Tournaments/TournamentsConfig';
import PendingWithdrawals from '../container/Withdrawals/WithdrawalsPending';
import Withdraws from '../container/Withdrawals/Withdrawals';
import Discovery from '../container/Dashboard/Discovery';
import DailyReturnManagement from '../container/DailyDoor/SetList';
import SegmentManagement from '../container/TagSegments/TagDashboard';
import FulfillmentCenter from '../container/FulfillmentCenter/FullfillmentList';

//permissionds
import {
  CommunicationPublicRelationPermissions,
  GoalPermissions,
  PartnerPermissions,
  PlayerSupportPermissions,
  TournamentPermissions,
  WithdrawalPermissions,
  MarketingPermissions,
} from '../constants/permission';
import PromoCodeChainRule from '../container/PromoCodeChainRule/PromoCodeChainRule';
import Deposits from '../container/PlayerServices/Deposits';
import GoalsList from '../container/PlayerServices/GoalsList';

// Routes config
export const RouteConfig = [
  {
    path: protectedRoutes.dashboard,
    component: Discovery,
    requiredRoles: [userAccess.admin],
    permissions: [CommunicationPublicRelationPermissions.Read],
  },
  {
    path: protectedRoutes.deposits,
    component: Deposits,
    requiredRoles: [userAccess.admin],
    permissions: [CommunicationPublicRelationPermissions.Read],
  },
  {
    path: protectedRoutes.goalsList,
    component: GoalsList,
    requiredRoles: [userAccess.admin],
    permissions: [GoalPermissions.Read],
  },
  {
    path: protectedRoutes.transactionDiscovery,
    component: TransactionDiscovery,
    requiredRoles: [userAccess.admin],
    permissions: [CommunicationPublicRelationPermissions.Read],
  },
  {
    path: protectedRoutes.home,
    component: Home,
    requiredRoles: [userAccess.admin],
    permissions: [],
    disableDateFilter: true,
  },
  {
    path: protectedRoutes.withdrawals,
    component: Withdraws,
    requiredRoles: [userAccess.admin],
    permissions: [WithdrawalPermissions.Read],
  },
  {
    path: protectedRoutes.pendingWithdrawals,
    component: PendingWithdrawals,
    requiredRoles: [userAccess.admin],
    permissions: [WithdrawalPermissions.Read],
  },
  {
    path: protectedRoutes.historyWithdrawals,
    component: WithdrawsHistory,
    requiredRoles: [userAccess.admin],
    permissions: [WithdrawalPermissions.Read],
  },
  {
    path: protectedRoutes.prize,
    component: PrizeRedeemHome,
    requiredRoles: [userAccess.admin],
    permissions: [CommunicationPublicRelationPermissions.Read],
  },
  {
    path: protectedRoutes.prizeDiscovery,
    component: PrizeRedeemDiscovery,
    requiredRoles: [userAccess.admin],
    permissions: [CommunicationPublicRelationPermissions.Read],
  },
  {
    path: protectedRoutes.prizePending,
    component: PrizeRedeemPending,
    requiredRoles: [userAccess.admin],
    permissions: [CommunicationPublicRelationPermissions.Read],
  },
  {
    path: protectedRoutes.prizeHistory,
    component: PrizeRedeemHistory,
    requiredRoles: [userAccess.admin],
    permissions: [CommunicationPublicRelationPermissions.Read],
  },
  {
    path: protectedRoutes.tournaments,
    component: Tournaments,
    requiredRoles: [userAccess.admin],
    permissions: [TournamentPermissions.Read],
  },
  {
    path: protectedRoutes.tournamentsConfig,
    component: TournamentsConfig,
    requiredRoles: [userAccess.admin],
    permissions: [TournamentPermissions.Read],
  },
  {
    path: protectedRoutes.tournamentsActive,
    component: TournamentsActive,
    requiredRoles: [userAccess.admin],
    permissions: [TournamentPermissions.Read],
  },
  {
    path: protectedRoutes.tournamentCreate,
    component: TournamentsCreate,
    requiredRoles: [userAccess.admin],
    permissions: [TournamentPermissions.Read],
  },
  {
    path: protectedRoutes.tournamentsCancelled,
    component: TournamentsCancelled,
    requiredRoles: [userAccess.admin],
    permissions: [TournamentPermissions.Read],
  },
  {
    path: protectedRoutes.tournamentsCompleted,
    component: TournamentsCompleted,
    requiredRoles: [userAccess.admin],
    permissions: [TournamentPermissions.Read],
  },
  {
    path: protectedRoutes.tournamentsPatterns,
    component: TournamentsPatterns,
    requiredRoles: [userAccess.admin],
    permissions: [TournamentPermissions.Read],
  },
  {
    path: protectedRoutes.tournamentsEvent,
    component: TournamentsSpecialEvents,
    requiredRoles: [userAccess.admin],
    permissions: [TournamentPermissions.Read],
  },
  {
    path: protectedRoutes.goals,
    component: Goals,
    requiredRoles: [userAccess.admin],
    permissions: [GoalPermissions.Read],
    partnerPermissions: [PartnerPermissions.GameTaco],
  },
  {
    path: protectedRoutes.goalsManagement,
    component: GoalsManagement,
    requiredRoles: [userAccess.admin],
    permissions: [GoalPermissions.Read],
    partnerPermissions: [PartnerPermissions.GameTaco],
  },
  {
    path: protectedRoutes.playerList,
    component: PlayerList,
    requiredRoles: [userAccess.admin],
    permissions: [PlayerSupportPermissions.Read],
  },
  {
    path: protectedRoutes.playerPatterns,
    component: PlayerPatterns,
    requiredRoles: [userAccess.admin],
    permissions: [PlayerSupportPermissions.Read],
  },
  {
    path: protectedRoutes.playerSupport,
    component: PlayerSupport,
    requiredRoles: [userAccess.admin],
    permissions: [PlayerSupportPermissions.Read],
  },
  {
    path: protectedRoutes.promoCodeList,
    component: PromoList,
    requiredRoles: [userAccess.admin],
    permissions: [PartnerPermissions.WorldWinner],
    disableDateFilter: true,
    partnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    path: protectedRoutes.promoCodeChainRuleList,
    component: PromoCodeChainRule,
    requiredRoles: [userAccess.admin],
    permissions: [PartnerPermissions.WorldWinner],
    disableDateFilter: true,
    partnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    path: protectedRoutes.skuList,
    component: SkuList,
    requiredRoles: [userAccess.admin],
    permissions: [MarketingPermissions.Read],
    disableDateFilter: true,
    partnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    path: protectedRoutes.promoCodeType,
    component: PromoCodeType,
    requiredRoles: [userAccess.admin],
    permissions: [PartnerPermissions.WorldWinner, PartnerPermissions.FanDuel],
    disableDateFilter: true,
    partnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    path: protectedRoutes.currencyType,
    component: CurrencyType,
    requiredRoles: [userAccess.admin],
    permissions: [PartnerPermissions.WorldWinner],
    disableDateFilter: true,
    partnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    path: protectedRoutes.allocateSku,
    component: AllocateSku,
    requiredRoles: [userAccess.admin],
    permissions: [PartnerPermissions.WorldWinner],
    disableDateFilter: true,
    partnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    path: protectedRoutes.allocateSkuById,
    component: AllocateSku,
    requiredRoles: [userAccess.admin],
    permissions: [PartnerPermissions.WorldWinner],
    disableDateFilter: true,
    partnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    path: protectedRoutes.dailyReturnList,
    component: DailyReturnManagement,
    requiredRoles: [userAccess.admin],
    permissions: [PartnerPermissions.WorldWinner],
    disableDateFilter: true,
    partnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    path: protectedRoutes.segmentList,
    component: SegmentManagement,
    requiredRoles: [userAccess.admin],
    permissions: [PartnerPermissions.WorldWinner],
    disableDateFilter: true,
    partnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    path: protectedRoutes.fulfillmentList,
    component: FulfillmentCenter,
    requiredRoles: [userAccess.admin],
    permissions: [PartnerPermissions.WorldWinner],
    disableDateFilter: true,
    partnerPermissions: [PartnerPermissions.WorldWinner],
  },
];
