import React, { ReactElement } from 'react';
import { Grid, LinearProgress, TextField, Typography, Box, IconButton, MenuItem, ButtonProps } from '@mui/material';
import {
  gridFilteredSortedRowIdsSelector,
  GridOverlay,
  gridVisibleColumnFieldsSelector,
  useGridApiContext,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridPrintExportOptions,
  GridPrintExportMenuItem,
  GridToolbarContainer,
  GridToolbarContainerProps,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridRowModel,
  GridApiRef,
} from '@mui/x-data-grid-pro';
import { Clear, Search } from '@mui/icons-material';

export const NoRowsOverlay = (): ReactElement => {
  return (
    <GridOverlay>
      <Typography>No Rows Found</Typography>
    </GridOverlay>
  );
};

export const CustomLoadingOverlay = (): ReactElement => {
  return (
    <GridOverlay>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
        }}
      >
        <LinearProgress />
      </Box>
    </GridOverlay>
  );
};

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
  enableSearch: boolean;
  disableExport: boolean;
}

interface JsonExportMenuItemProps {
  hideMenu?: () => void;
}

interface CustomGridToolbarContainerProps extends GridToolbarContainerProps {
  disableExport: boolean;
}

const getJson = (apiRef: GridApiRef) => {
  // Select rows and columns
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

  // Format the data. Here we only keep the value
  const data = filteredSortedRowIds.map((id) => {
    const row: GridRowModel = {};
    visibleColumnsField.forEach((field) => {
      row[field] = apiRef.current.getCellParams(id, field).value; // eslint-disable-line
    });
    return row;
  });

  // Stringify with some indentation
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#parameters
  return JSON.stringify(data, null, 2);
};

const exportBlob = (blob: Blob | MediaSource, filename: string) => {
  // Save the blob in a json file
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};

const JsonExportMenuItem = (props: JsonExportMenuItemProps) => {
  const apiRef = useGridApiContext();

  const { hideMenu } = props;

  return (
    <MenuItem
      onClick={() => {
        const jsonString = getJson(apiRef);
        const blob = new Blob([jsonString], {
          type: 'text/json',
        });
        exportBlob(blob, 'DataGrid_demo.json');

        // Hide the export menu after the export
        hideMenu?.();
      }}
    >
      Export JSON
    </MenuItem>
  );
};

const today = new Date();
const csvOptions: GridCsvExportOptions = {
  fileName: `ToolBelt_CSV_${today.toISOString()}`,
  delimiter: ';',
  utf8WithBom: true,
};
const printOptions: GridPrintExportOptions = {
  hideFooter: true,
  hideToolbar: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer
    // touchRippleRef={props.TouchRippleProps}
    touchRippleRef={undefined}
    {...props}
  >
    <GridCsvExportMenuItem options={csvOptions} />
    <JsonExportMenuItem />
    <GridPrintExportMenuItem options={printOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = (props: CustomGridToolbarContainerProps) => (
  <GridToolbarContainer {...props}>
    <GridToolbarColumnsButton touchRippleRef={undefined} />
    <GridToolbarFilterButton />
    <GridToolbarDensitySelector touchRippleRef={undefined} />
    {!props.disableExport && <CustomExportButton />}
  </GridToolbarContainer>
);

export function QuickSearchToolbar(props: QuickSearchToolbarProps) {
  return (
    <>
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <Grid container spacing={1}>
          <Grid item lg={9}>
            <CustomToolbar disableExport={props.disableExport} />
          </Grid>
          <Grid item lg={3}>
            {props.enableSearch && (
              <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search Table…"
                InputProps={{
                  startAdornment: <Search fontSize="small" />,
                  endAdornment: (
                    <IconButton
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      style={{ visibility: props.value ? 'visible' : 'hidden' }}
                      onClick={props.clearSearch}
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  ),
                }}
                sx={{
                  width: '90%',
                  m: (theme) => theme.spacing(1, 0.5, 1.5),
                  '& .MuiSvgIcon-root': {
                    mr: 0.5,
                  },
                  '& .MuiInput-underline:before': {
                    borderBottom: 1,
                    borderColor: 'divider',
                  },
                }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
