import { toast } from 'react-toastify';
import { API_URLS } from '../constants/url';
import axiosInstance from '../utils/apis';
import Moment from 'moment';
import { AxiosError } from 'axios';

interface playerSupportAPIPayload {
  offset: number;
  limit: number;
  url: string | undefined;
  search?: string;
  searchBy?: string;
  startDate?: Date;
  endDate?: Date;
}

interface depositTransactionsAPIPayload {
  txnList?: string;
  search?: string;
  searchBy?: string;
}

export const getPlayerSupportData = async (payload: playerSupportAPIPayload) => {
  try {
    const { offset, limit, search, searchBy, startDate, endDate } = payload;
    let filterQuery = '';
    let url = `${API_URLS.GET_USER}?offset=${offset}&limit=${limit}`;
    if (searchBy?.length && search?.length) {
      filterQuery = `${searchBy}||=||${search}`;
      url += `&filter=${filterQuery}`;
    }
    if (startDate && endDate) {
      const startDateFilter = `User.created_at||>=||${Moment(startDate).format('YYYY-MM-DD')}`;
      const endDateFilter = `User.created_at||<=||${Moment(endDate).format('YYYY-MM-DD')}`;
      url += `&filter=${startDateFilter}`;
      url += `&filter=${endDateFilter}`;
    }
    const response = await axiosInstance.get(url);
    if (response.success) {
      return response;
    }
  } catch (e: unknown | AxiosError) {
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const getDepositTransactions = async (payload: depositTransactionsAPIPayload) => {
  try {
    const { txnList, search } = payload;
    const url = `${API_URLS.GET_DEPOSIT_TRANSACTIONS}?userId=${search}&txnList=${txnList}`;

    const response = await axiosInstance.get(url);

    if (response.success) {
      return response;
    }
  } catch (e: unknown | AxiosError) {
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const upsertMarkDepositTransactionsAsSkippable = async (payload: {
  data: {
    txnList: object[];
  };
}) => {
  try {
    const { data } = payload;

    const url = API_URLS.SKIP_DEPOSIT_TRANSACTIONS;

    const response = await axiosInstance.put(url, data);

    if (response.success) {
      return response;
    }
  } catch (e: unknown | AxiosError) {
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};
