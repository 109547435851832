/* eslint-disable indent */
import { useState, useMemo } from 'react';
import {
  Typography,
  Toolbar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  ListItemIcon,
  Button,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Tooltip,
  useMediaQuery,
  Drawer as MuiDrawer,
} from '@mui/material';
import { Grid } from '@mui/material';
import React, { ReactElement, useEffect } from 'react';
import SideBar from '../component/Sidebar/Sidebar';
import {
  Logout,
  // PersonAdd,
  AccessTimeFilled,
  Money as MoneyTwoTone,
  TrendingUp,
  ChevronRight,
  AutoFixHigh,
  Menu as MenuIcon,
} from '@mui/icons-material';
// import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
// import Breadcrumbs from '@mui/material/Breadcrumbs';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import Link from '@mui/material/Link';
import { RootState } from '../redux/reducers/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAction } from '../redux/actions/Authentication/authAction';
import { startDateAction, endDateAction } from '../redux/actions/CommonActions/commonActions';
import { useHistory, matchPath } from 'react-router';
import { TextField } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useAuth0 } from '@auth0/auth0-react';
import DropDown from '../component/DropDown/DropDown';
import './Layout.css';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import moment from 'moment';
import { RenderByPermission } from '../component/shared/RenderByPermission';
import { useTheme } from '@mui/styles';
import { showDateFilter } from '../utils/utils';
import { AllMenuConfig } from '../constants/sidebar.constant';
import { AppBar, Root, classes, Drawer, DrawerHeader, Main } from './LayoutStyle';
import { appMenuItems } from '../constants/sidebar.constant';
import { showActionStepperModalAction } from '../redux/actions/modalActions/modalActions';
import { useCurrentPartner } from '../hooks/useCurrentPartner';
import { AppMenuItemProps } from '../component/Sidebar/SideBarItem';
import { checkCurrentPartnerPermission } from '../utils/permission';

interface Props {
  children: React.ReactNode;
}

export const Layout = (props: Props): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(true);
  const [openStartCal, setOpenStartCal] = useState<boolean>(false);
  const [openEndCal, setOpenEndCal] = useState<boolean>(false);
  const [selectDateTitle, setSelectDateTitle] = useState<string>('Select Dates');
  const [currentTool, setCurrentTool] = useState<string>('');
  const isAuth = useSelector((state: RootState) => state.loginReducer);
  const [currentTab, setCurrentTab] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setCurrentTab(newTab);
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const { loginSuccess, data } = isAuth;
  const avatar = loginSuccess ? data.avatar : '';
  const isMenuOpen = Boolean(anchorEl);
  const isNotificationOpen = Boolean(notificationAnchorEl);
  const { logout } = useAuth0();
  const color = '#fff';
  const theme = useTheme();
  const { partnerShortName } = useCurrentPartner();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [fitTools, unfitTools] = useMemo(() => {
    const path = history.location.pathname;
    const itemData = Object.keys(AllMenuConfig).filter((item) => {
      return matchPath(path, {
        path: '/' + partnerShortName + AllMenuConfig[item].link,
        exact: true,
        strict: false,
      });
    });
    let itemArray: AppMenuItemProps[] = [];
    const getParentItems = (item: AppMenuItemProps) => {
      if (item.items?.includes(AllMenuConfig[itemData[0]])) {
        itemArray = item.items;
      } else {
        if (item.items && item.items.map((item) => Object.keys(item).includes('items'))) {
          item.items.forEach((item) => {
            if (item.items) {
              getParentItems(item);
            }
          });
        }
      }
    };
    appMenuItems.forEach((item) => {
      getParentItems(item);
    });
    let finalItem = itemArray?.filter((item) => !item?.items) || [];
    const copyPayload = [...finalItem];
    const unfitTools = finalItem;
    const num = matches ? 1 : 6;
    if (finalItem.length > num) {
      finalItem = copyPayload.splice(0, matches ? 1 : 6);
      // unfitTools = copyPayload;
    }
    return [finalItem, unfitTools];
  }, [history.location.pathname, matches]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleCurrentToolChange = (event: SelectChangeEvent) => {
    setCurrentTool(event.target.value);
  };
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  // const handelNotificationOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setNotificationAnchorEl(event.currentTarget);
  // };
  const handelNotificationClose = () => {
    setNotificationAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const logoutApp = () => {
    dispatch(logoutAction());
    logout({
      returnTo: window.location.origin,
    });
  };

  const startDateValue = useSelector((state: RootState) => state.commonReducers.startDateSelected);

  const endDateValue = useSelector((state: RootState) => state.commonReducers.endDateSelected);

  const [displayStartDate, setDisplayStartDate] = useState(moment(startDateValue).format('YYYY-MM-DD').toString());

  const [displayEndDate, setDisplayEndDate] = useState(moment(endDateValue).format('YYYY-MM-DD').toString());

  const handleFilterChange = (e: any) => {
    const newDate = new Date(new Date().setDate(new Date().getDate() - e.target.value));
    setDisplayStartDate(moment(newDate).format('YYYY-MM-DD').toString());
    dispatch(startDateAction(newDate));
  };

  const handleTopNavClick = (tool: AppMenuItemProps, newValue: number) => {
    setCurrentTab(newValue);
    if (tool.link) {
      history.push('/' + partnerShortName + tool.link);
    } else if (tool?.hasClick) {
      const getFunction = tool.accessKey && AllMenuConfig?.[tool.accessKey]?.handleItemClick;
      dispatch(getFunction(true));
    }
  };

  useEffect(() => {
    if (openEndCal == true || openStartCal == true) setSelectDateTitle('Update Dates');
  });

  useEffect(() => {
    if (matches) {
      setOpen(false);
    }
  }, [matches]);
  useEffect(() => {
    const currentPath = history.location.pathname;
    const cIndex = fitTools.findIndex((tool) => {
      return matchPath(currentPath, {
        path: '/' + partnerShortName + tool.link,
        exact: false,
        strict: false,
      });
    });
    setCurrentTab(cIndex);
  }, [history.location.pathname]);

  const handleLogoClick = () => {
    window.location.href = '/partnerLandingPage';
  };

  const [flag, setFlag] = useState(false);

  useEffect(() => {
    console.log(flag);
  }, [flag]);
  const menuId = 'account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
        <InputLabel sx={{ fontSize: '20px' }}>Current Tool Set </InputLabel>
        <Select label="Current Tool Set" value={currentTool} onChange={handleCurrentToolChange}>
          {unfitTools.map((tools, index) => {
            return (
              <MenuItem value={tools.name} key={index} onClick={() => handleTopNavClick(tools, index)}>
                {tools.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <MenuItem onClick={logoutApp}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Log Out
      </MenuItem>
    </Menu>
  );

  const renderCurrentPartnerLogo = (size: string) => {
    const currentPartner = partnerShortName;

    switch (currentPartner) {
      case 'gt':
        if (size == 'sm' || size == 'small') {
          return 'https://taco-webapp.s3.us-west-2.amazonaws.com/game_Taco_short_logo.png';
        }
        return 'https://taco-webapp.s3-us-west-2.amazonaws.com/email/logo.png';

      case 'sk':
        if (size == 'sm' || size == 'small') {
          return '/images/SkillifyBullLogo.png';
        }
        return '/images/SkillifyLogo.png';

      case 'ww':
        if (size == 'sm' || size == 'small') {
          return '/images/ww-small.png';
        }
        return '/images/Combined-Shape.png';

      case 'fd':
        if (size == 'sm' || size == 'small') {
          return '/images/fd-small.png';
        }
        return '/images/fd-large-logo.png';

      default:
        if (size == 'sm' || size == 'small') {
          return 'https://taco-webapp.s3.us-west-2.amazonaws.com/game_Taco_short_logo.png';
        }
        return 'https://taco-webapp.s3-us-west-2.amazonaws.com/email/logo.png';
    }
  };

  const notificationId = 'notification-menu';
  const renderNotification = (
    <Menu
      anchorEl={notificationAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={notificationId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isNotificationOpen}
      onClose={handelNotificationClose}
    >
      <MenuItem sx={{ justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ListItemIcon>
            <MoneyTwoTone fontSize="small" />
          </ListItemIcon>
          You have 20 Withdraw Request to review!
        </div>
        <Typography component="div" variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
          <AccessTimeFilled fontSize="small" sx={{ mx: 1 }} />
          13 Min
        </Typography>
      </MenuItem>
      <MenuItem sx={{ justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ListItemIcon>
            <MoneyTwoTone fontSize="small" />
          </ListItemIcon>
          You have 20 Withdraw Request to review!
        </div>
        <Typography component="div" variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
          <AccessTimeFilled fontSize="small" sx={{ mx: 1 }} />
          33 Min
        </Typography>
      </MenuItem>
      <MenuItem sx={{ justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ListItemIcon>
            <TrendingUp fontSize="small" />
          </ListItemIcon>
          Checkout New Feature !
        </div>
        <Typography component="div" variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
          <AccessTimeFilled fontSize="small" sx={{ mx: 1 }} />
          56 Min
        </Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <Root>
      <div className={classes.root}>
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <Grid container>
              <Grid item lg={9} md={8} sm={7} xs={6}>
                <Grid container>
                  {matches ? (
                    <Grid
                      item
                      lg={1}
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <IconButton onClick={handleDrawerOpen}>
                        <MenuIcon style={{ color: 'white' }} />
                      </IconButton>
                    </Grid>
                  ) : null}
                  <Grid item lg={8} md={8} sm={8} xs={4}>
                    <Tabs
                      value={currentTab}
                      onChange={handleTabChange}
                      sx={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        fontWeight: 'bold',
                        color: theme.palette.secondary.contrastText,
                        opacity: 1.0,
                      }}
                      indicatorColor="secondary"
                      aria-label="Current Tool Set Tabs"
                    >
                      {fitTools
                        .filter((tools) => !tools.PartnerPermissions || checkCurrentPartnerPermission(tools.PartnerPermissions))
                        .map((tools, index) => {
                          return tools.Permissions ? (
                            <RenderByPermission
                              key={index}
                              permissions={tools.Permissions}
                              renderIf={() => (
                                <Tab
                                  sx={{
                                    opacity: 1.0,
                                  }}
                                  value={index}
                                  label={tools.name}
                                  onClick={() => handleTopNavClick(tools, index)}
                                />
                              )}
                            />
                          ) : (
                            <Button
                              onClick={() => handleTopNavClick(tools, index)}
                              sx={{
                                paddingLeft: '20px',
                                color: 'white',
                                textTransform: 'none',
                              }}
                              key={index}
                              size="large"
                            >
                              <span style={{ fontSize: '16px' }}>{tools.name}</span>
                            </Button>
                          );
                        })}
                    </Tabs>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={3} md={4} sm={5} xs={6}>
                <Grid
                  container
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <Grid item lg={8} md={8} sm={8} xs={6} style={{ display: 'flex' }}>
                    {showDateFilter(history) && (
                      <>
                        <DropDown
                          title={selectDateTitle}
                          style={{
                            backgroundColor: theme.palette.secondary.light,
                            fontWeight: 'bold',
                            color: '#fcfcfc',
                          }}
                          handleFilterChange={handleFilterChange}
                          items={[
                            {
                              name: displayStartDate ? 'Start (EST)  : ' + displayStartDate : 'Start (EST)',
                              onClick: () => {
                                setOpenStartCal(true);
                              },
                            },
                            {
                              name: displayEndDate ? 'End (EST)  : ' + displayEndDate : 'End (EST)',
                              onClick: () => {
                                setOpenEndCal(true);
                              },
                            },
                            {
                              name: '',
                              enableDropdown: true,
                              onClick: () => {
                                setFlag(!flag);
                              },
                            },
                            {
                              name: 'Clear Dates',
                              onClick: () => {
                                setDisplayStartDate('Select Date');
                                setDisplayEndDate('Select Date');
                                setSelectDateTitle('Select Dates');
                              },

                              styleItem: {
                                color: theme.palette.secondary.contrastText,
                                backgroundColor: theme.palette.secondary.light,
                                fontWeight: 'bold',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                textAlign: 'center',
                              },
                            },
                          ]}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          {openStartCal && (
                            <DatePicker
                              open={openStartCal}
                              onClose={() => setOpenStartCal(false)}
                              label="Start Date"
                              openTo="year"
                              views={['year', 'month', 'day']}
                              allowSameDateSelection={true}
                              value={startDateValue}
                              onChange={() => {
                                return;
                              }}
                              onAccept={(newValue) => {
                                setDisplayStartDate(moment(newValue).format('YYYY-MM-DD').toString());
                                dispatch(startDateAction(newValue));
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className={classes.datePicker}
                                  sx={{
                                    svg: { color, display: 'none' },
                                    input: {
                                      color,
                                      height: '0px',
                                    },
                                    label: { color },
                                  }}
                                />
                              )}
                            />
                          )}

                          {openEndCal && (
                            <DatePicker
                              open={openEndCal}
                              onClose={() => setOpenEndCal(false)}
                              disableFuture
                              label="End Date"
                              openTo="year"
                              views={['year', 'month', 'day']}
                              allowSameDateSelection={true}
                              value={endDateValue}
                              onChange={() => {
                                return;
                              }}
                              onAccept={(newValue) => {
                                setDisplayEndDate(moment(newValue).format('YYYY-MM-DD').toString());
                                dispatch(endDateAction(newValue));
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className={classes.datePicker}
                                  sx={{
                                    svg: { color, display: 'none' },
                                    input: {
                                      color,
                                      height: '0px',
                                    },
                                    label: { color },
                                  }}
                                />
                              )}
                            />
                          )}
                        </LocalizationProvider>
                      </>
                    )}
                  </Grid>

                  <Grid item lg={2} md={2} sm={2} xs={3}>
                    <Tooltip title="Action Wizard" placement="bottom" arrow>
                      <IconButton
                        size="large"
                        edge="end"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={() => {
                          dispatch(showActionStepperModalAction(true));
                        }}
                        color="inherit"
                      >
                        <AutoFixHigh />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={3}>
                    <IconButton
                      size="large"
                      edge="end"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      color="inherit"
                      // sx={{ marginLeft: '20px' }}
                    >
                      <Avatar alt="User Profile" src={avatar} sx={{ width: '44px', height: '44px' }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
          {renderMenu}
          {renderNotification}
        </AppBar>
        {matches ? (
          <MuiDrawer
            open={open}
            variant={'temporary'}
            onClose={handleDrawerClose}
            PaperProps={{
              sx: {
                backgroundColor: 'primary.main',
                width: '260px',
                color: '#fcfcfc',
              },
            }}
          >
            <DrawerHeader>
              <Toolbar
                sx={{
                  marginLeft: '-21px',
                  justifyContent: 'space-between',
                }}
                onClick={() => handleLogoClick()}
              >
                {open ? (
                  <img src={renderCurrentPartnerLogo('large')} width="150" className="companyLogo" />
                ) : (
                  <img src={renderCurrentPartnerLogo('sm')} width="40" className="companyLogo" />
                )}
              </Toolbar>
            </DrawerHeader>
            <Divider sx={{ mt: '2px' }} />
            <SideBar open={open} />
          </MuiDrawer>
        ) : (
          <Drawer variant={'permanent'} className={matches ? '' : classes.drawer} open={open}>
            <DrawerHeader>
              <Toolbar
                sx={
                  open
                    ? {
                        marginLeft: '-21px',
                        justifyContent: 'space-between',
                      }
                    : {
                        justifyContent: 'space-between',
                        paddingRight: '0px !important',
                        paddingLeft: '0px !important',
                      }
                }
                onClick={() => handleLogoClick()}
              >
                {open ? (
                  <img src={renderCurrentPartnerLogo('large')} width="150" className="companyLogo" />
                ) : (
                  <img src={renderCurrentPartnerLogo('sm')} width="40" className="companyLogo" />
                )}
              </Toolbar>
              <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen} className={open ? classes.closeIconWhenOen : classes.closeIcon}>
                {open ? <ChevronLeftIcon style={{ color: 'white' }} /> : <ChevronRight style={{ color: 'white' }} />}
              </IconButton>
            </DrawerHeader>
            <Divider sx={{ mt: '2px' }} />
            <SideBar open={open} />
          </Drawer>
        )}
        <Main open={open}>
          <Toolbar />
          {props.children}
        </Main>
      </div>
    </Root>
  );
};
