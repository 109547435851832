import React, { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import { Backdrop, CircularProgress } from '@mui/material';

const PREFIX = 'Loader';

const classes = {
  backdrop: `${PREFIX}-backdrop`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.backdrop}`]: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff',
  },
}));

export const Loader = (props: { open: boolean }): ReactElement => {
  return (
    <Root>
      <Backdrop className={classes.backdrop} open={props.open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Root>
  );
};
