import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
// import { API_URLS } from '../constants/url';
import axiosInstance from '../utils/apis';
import { startLoader, stopLoader } from '../utils/utils';
import { API_URLS } from '../constants/url';
interface DailyRewardsAPIPayload {
  url: string;
  partnerId?: string;
}

export const getSetList = async (payload: DailyRewardsAPIPayload) => {
  try {
    startLoader();
    const { url, partnerId } = payload;
    const response = await axiosInstance.get(url + (partnerId ? `?partnerId=${partnerId}` : ''));
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const getBehaviourList = async (payload: DailyRewardsAPIPayload) => {
  try {
    startLoader();
    const { url, partnerId } = payload;
    const response = await axiosInstance.get(url + (partnerId ? `?partnerId=${partnerId}` : ''));
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export interface upsertSetInterface {
  setId: number | undefined;
  name: string | undefined;
  description: string | undefined;
  type: string | undefined;
  sequenceNum: number | undefined;
  params: Record<string, unknown> | undefined;
  metadata: Record<string, unknown> | undefined;
}

export interface upsertSetGroupInterface {
  setGroupId: number | undefined;
  name: string | undefined;
  description: string | undefined;
  type: string | undefined;
  span: string | undefined;
  resetStrategy: number | undefined;
  platforms: string[] | undefined;
  whitelist: string[] | undefined;
  blacklist: string[] | undefined;
  params: Record<string, unknown> | undefined;
  metadata: Record<string, unknown> | undefined;
}

export const upsertDailyRewardSetData = async (payload: { isEdit: boolean; data: upsertSetInterface }) => {
  try {
    const { isEdit, data } = payload;
    if (isEdit) {
      const response = await axiosInstance.put(API_URLS.UPDATE_DAILY_REWARD_SET, data);
      if (response.success) {
        return response;
      }
    } else {
      const response = await axiosInstance.post(API_URLS.CREATE_DAILY_REWARD_SET, data);
      console.error({ response });
      if (response.success) {
        return response;
      }
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const upsertDailyRewardSetGroupData = async (payload: { isEdit: boolean; data: upsertSetGroupInterface }) => {
  try {
    const { isEdit, data } = payload;
    if (isEdit) {
      const response = await axiosInstance.put(API_URLS.UPDATE_DAILY_REWARD_SET_GROUP, data);
      if (response.success) {
        return response;
      }
    } else {
      const response = await axiosInstance.post(API_URLS.UPDATE_DAILY_REWARD_SET_GROUP, data);
      console.error({ response });
      if (response.success) {
        return response;
      }
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

interface rewardInterface {
  currencyTypeId: string;
  amount: string;
}

export interface upsertSlotInterface {
  slotId: number | undefined;
  setId: number | undefined;
  name: string;
  weight: number;
  rewards: rewardInterface[] | undefined;
  sequenceNum: number | undefined;
  params: Record<string, unknown> | undefined;
  metadata: Record<string, unknown> | undefined;
}

export const upsertDailyRewardSlotData = async (payload: { isEdit: boolean; data: upsertSlotInterface }) => {
  try {
    const { isEdit, data } = payload;
    if (isEdit) {
      const response = await axiosInstance.put(API_URLS.UPDATE_DAILY_REWARD_SLOT, data);
      if (response.success) {
        return response;
      }
    } else {
      const response = await axiosInstance.post(API_URLS.CREATE_DAILY_REWARD_SLOT, data);
      if (response.success) {
        return response;
      }
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const deleteSlotData = async (payload: { slotId: number | undefined }) => {
  try {
    startLoader();
    const { slotId } = payload;
    const response = await axiosInstance.delete(API_URLS.DELETE_DAILY_REWARD_SLOT + `?slotId=${slotId}`);
    console.error({ response });
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const deleteSetData = async (payload: { setId: number | undefined }) => {
  try {
    startLoader();
    const { setId } = payload;
    const response = await axiosInstance.delete(API_URLS.DELETE_DAILY_REWARD_SET + `?setId=${setId}`);
    console.error({ response });
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const deleteSetGroupData = async (payload: { setGroupId: number | undefined }) => {
  try {
    startLoader();
    const { setGroupId } = payload;
    const response = await axiosInstance.delete(API_URLS.DELETE_DAILY_REWARD_SET_GROUP + `?setGroupId=${setGroupId}`);
    console.error({ response });
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

interface setGroupMember {
  setId: number | undefined;
  setGroupId: number | undefined;
  sequenceNum: number | undefined;
}
export const upsertDailyReturnSetGroupMember = async (payload: { isEdit: boolean; data: setGroupMember }) => {
  try {
    const { data } = payload;
    const response = await axiosInstance.post(API_URLS.CREATE_DAILY_REWARD_SET_GROUP_MEMBER, data);
    if (response.success) {
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const removeSetGroupMember = async (payload: { setGroupId: number | undefined; setId: number }) => {
  try {
    startLoader();
    const { setGroupId, setId } = payload;
    const response = await axiosInstance.delete(API_URLS.DELETE_DAILY_REWARD_SET_GROUP_MEMBER + `?setGroupId=${setGroupId}&setId=${setId}`);
    console.error({ response });
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};
