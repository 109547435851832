import { PartnerPermissions } from '../../../constants/permission';

export const PromoteActionData = [
  {
    label: 'Goal',
    moduleName: 'showDeployGoal',
    type: 'Promote',
    actionKey: 'Goals',
    description: 'Deployment Wizard',
    alterNativeKey: 'Goal',
    PartnerPermissions: [PartnerPermissions.GameTaco],
  },
  {
    label: 'Variable',
    moduleName: 'showDeployGoal',
    type: 'Promote',
    actionKey: 'Goals',
    description: 'Deployment Wizard',
    alterNativeKey: 'Variable',
    PartnerPermissions: [PartnerPermissions.GameTaco],
  },
  {
    label: 'SKU',
    moduleName: 'showDeployGoal',
    type: 'Promote',
    actionKey: 'Marketing',
    description: 'Deployment Wizard',
    alterNativeKey: 'SKU',
    PartnerPermissions: [PartnerPermissions.GameTaco],
  },
  {
    label: 'Promo',
    moduleName: 'showDeployGoal',
    type: 'Promote',
    actionKey: 'Marketing',
    description: 'Deployment Wizard',
    alterNativeKey: 'Promo',
    PartnerPermissions: [PartnerPermissions.GameTaco],
  },
];

export const MainActionData = [
  {
    label: 'Create Promo Code',
    moduleName: 'showCreatePromoCode',
    type: 'Add',
    actionKey: 'Promo Code',
    description: 'Create a new promo code',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Edit Promo Code',
    moduleName: 'showCreatePromoCode',
    type: 'Edit',
    actionKey: 'Promo Code',
    description: 'Edit a promo code',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Delete Promo Code',
    moduleName: 'showGlobalDelete',
    type: 'Delete',
    actionKey: 'Promo Code',
    description: 'Delete a promo code',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Create Promo Code Type',
    moduleName: 'showCreatePromoType',
    type: 'Add',
    actionKey: 'PromoCode Type',
    description: 'Create a new promo code type',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Edit Promo Code Type',
    moduleName: 'showCreatePromoType',
    type: 'Edit',
    actionKey: 'PromoCode Type',
    description: 'Edit a promo code type',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Delete Promo Code Type',
    moduleName: 'showGlobalDelete',
    type: 'Delete',
    actionKey: 'PromoCode Type',
    description: 'Delete a promo code type',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Create currency type',
    moduleName: 'showUpsertCurrencyType',
    type: 'Add',
    actionKey: 'Currency Type',
    description: 'Create a new currency type',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Edit currency type',
    moduleName: 'showUpsertCurrencyType',
    type: 'Edit',
    actionKey: 'Currency Type',
    description: 'Edit a currency type',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Delete currency type',
    moduleName: 'showGlobalDelete',
    type: 'Delete',
    actionKey: 'Currency Type',
    description: 'Delete a currency type',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Create SKU',
    moduleName: 'showCreateSku',
    type: 'Add',
    actionKey: 'SKU',
    description: 'Create a new SKU',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Edit SKU',
    moduleName: 'showCreateSku',
    type: 'Edit',
    actionKey: 'SKU',
    description: 'Edit a SKU',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Delete SKU',
    moduleName: 'showGlobalDelete',
    type: 'Delete',
    actionKey: 'SKU',
    description: 'Delete a SKU',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Allocate SKU',
    moduleName: 'showAllocateSku',
    type: 'Edit',
    actionKey: 'SKU',
    alterNativeKey: 'Allocate SKU',
    description: 'Allocate a SKU',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Create Promo Code Chain Rule',
    moduleName: 'showChainRule',
    type: 'Add',
    actionKey: 'Promo Code Chain Rule',
    description: 'Create a new promo code chain rule',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Edit Promo Code Chain Rule',
    moduleName: 'showChainRule',
    type: 'Edit',
    actionKey: 'Promo Code Chain Rule',
    description: 'Edit a new promo code chain rule',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Delete Promo Code Chain Rule',
    moduleName: 'showGlobalDelete',
    type: 'Delete',
    actionKey: 'Promo Code Chain Rule',
    description: 'Delete a new promo code chain rule',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Edit  Transaction',
    moduleName: 'showCreateTransaction',
    type: 'Edit',
    actionKey: 'Transaction',
    description: 'Edit  a Transaction',
    PartnerPermissions: [PartnerPermissions.FanDuel],
  },
  {
    label: 'Delete Transaction',
    moduleName: 'showGlobalDelete',
    type: 'Delete',
    actionKey: 'Transaction',
    description: 'Delete a Transaction',
    PartnerPermissions: [PartnerPermissions.FanDuel],
  },
  {
    label: 'Edit  Transaction(Failed)',
    moduleName: 'showCreateTransaction',
    type: 'Edit',
    actionKey: 'Transaction (Failed)',
    description: 'Edit  a Transaction',
    PartnerPermissions: [PartnerPermissions.FanDuel],
  },
  {
    label: 'Delete Transaction (Failed)',
    moduleName: 'showGlobalDelete',
    type: 'Delete',
    actionKey: 'Transaction (Failed)',
    description: 'Delete a Transaction',
    PartnerPermissions: [PartnerPermissions.FanDuel],
  },
  {
    label: 'Remove Transaction (Deleted)',
    moduleName: 'showRemoveTransaction',
    type: 'Delete',
    actionKey: 'Transaction (Deleted)',
    description: 'Remove a Transaction Which Was Deleted',
    alterNativeKey: 'Remove Transaction (Deleted)',
    PartnerPermissions: [PartnerPermissions.FanDuel],
  },
  {
    label: 'Restore Transaction (Deleted)',
    moduleName: 'showUndoDeleteTransaction',
    type: 'Delete',
    actionKey: 'Transaction (Deleted)',
    description: 'Restore Transaction Which Was Deleted',
    alterNativeKey: 'Restore Transaction (Deleted)',
    PartnerPermissions: [PartnerPermissions.FanDuel],
  },
  {
    label: 'Create Daily Door Set',
    moduleName: 'showDailyReturnSet',
    type: 'Add',
    actionKey: 'Daily Door',
    description: 'Create a Daily Return set',
    alterNativeKey: 'Create Set',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Create Daily Door Set Group',
    moduleName: 'showDailyReturnSetGroup',
    type: 'Add',
    actionKey: 'Daily Door',
    description: 'Create a Daily Door Set Group',
    alterNativeKey: 'Create Set Group',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Update Daily Door Set',
    moduleName: 'updateDailyReturnSet',
    type: 'Edit',
    actionKey: 'Daily Door',
    description: 'Update a Daily Door set',
    alterNativeKey: 'Update Set',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Update Daily Door Set Group',
    moduleName: 'updateDailyReturnSetGroup',
    type: 'Edit',
    actionKey: 'Daily Door',
    description: 'Update a Daily Door set Group',
    alterNativeKey: 'Update Set Group',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Delete Daily Door Set',
    moduleName: 'deleteDailyReturnSet',
    type: 'Delete',
    actionKey: 'Daily Door',
    description: 'Delete a Daily Door set',
    alterNativeKey: 'Delete Set',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Delete Daily Door Set Group',
    moduleName: 'deleteDailyReturnSetGroup',
    type: 'Delete',
    actionKey: 'Daily Door',
    description: 'Delete a Daily Door Set Group',
    alterNativeKey: 'Delete Set group',
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    label: 'Deployment Wizard',
    type: 'Tool',
    subOptions: PromoteActionData,
    actionKey: 'Data Promotion',
    description: 'Deployment Wizard',
    alterNativeKey: 'Promote Data',
    PartnerPermissions: [PartnerPermissions.GameTaco],
  },
  // {
  //   label: 'Deploy Goal',
  //   moduleName: 'showDeployGoal',
  //   type: 'Add',
  //   actionKey: 'Deploy Goal',
  //   description: 'Deploy a new goal',
  //   PartnerPermissions: [PartnerPermissions.GameTaco],
  // },
];
