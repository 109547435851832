import { prizeRedeemCancelState, prizeRedeemConfirmState, prizeReedemTypeState } from '../actions/prizeReedem/prizeReedemType';
import { DEFAULT_VAL } from '../actions/type';
import { Reducer } from 'redux';
import { ReducerState } from './authReducer';

interface State extends ReducerState {
  data: unknown;
}

export const prizeReedemReducer: Reducer<State> = (state = DEFAULT_VAL, action) => {
  const { type, data } = action;
  switch (type) {
    case prizeReedemTypeState.request:
      return { ...state, loading: true };
    case prizeReedemTypeState.success:
      return { ...state, loading: false, data: data, success: true };
    case prizeReedemTypeState.error:
      return { ...state, loading: false, data: data, success: false };

    default:
      return state;
  }
};

export const prizeReedemConfirmReducer: Reducer<State> = (state = DEFAULT_VAL, action) => {
  const { type, data } = action;
  switch (type) {
    case prizeRedeemConfirmState.request:
      return { ...state, loading: true };
    case prizeRedeemConfirmState.success:
      return { ...state, loading: false, data: data, success: true };
    case prizeRedeemConfirmState.error:
      return { ...state, loading: false, data: data, success: false };

    default:
      return state;
  }
};

export const prizeReedemCancelReducer: Reducer<State> = (state = DEFAULT_VAL, action) => {
  const { type, data } = action;
  switch (type) {
    case prizeRedeemCancelState.request:
      return { ...state, loading: true };
    case prizeRedeemCancelState.success:
      return { ...state, loading: false, data: data, success: true };
    case prizeRedeemCancelState.error:
      return { ...state, loading: false, data: data, success: false };

    default:
      return state;
  }
};
