import React, { ReactElement } from 'react';
import { checkCurrentPartnerPermission, checkPermission } from '../../utils/permission';

interface RenderProps {
  permissions: Array<string>;
  partnerPermissions?: Array<string>;
  renderIf: () => ReactElement | Element | null;
  renderElse?: () => React.ReactElement | Element | null;
}
export const RenderByPermission = ({ permissions, partnerPermissions, renderIf, renderElse = () => null }: RenderProps) => {
  let condition;
  if (partnerPermissions) {
    condition = checkPermission(permissions) && checkCurrentPartnerPermission(partnerPermissions);
  } else {
    condition = checkPermission(permissions);
  }
  return <>{condition ? renderIf() : renderElse()}</>;
};
