import { Button, Dialog, DialogContent, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import React, { Fragment, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showCreatePromoTypeModalAction, showCreateSuccessModalAction } from '../../../redux/actions/modalActions/modalActions';
import { RootState } from '../../../redux/reducers/rootReducer';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { addPromoType, updatePromoType } from '../../../services/promoCodeType.service';
import { Help } from '@mui/icons-material';

const CreatePromoType = (): ReactElement => {
  const dispatch = useDispatch();
  const createPromoType = useSelector((state: RootState) => state.modalReducer.showCreatePromoType);
  const IsEdit = createPromoType?.data?.id || createPromoType?.data?.promoCodeTypeId;
  const isReadable = createPromoType?.isReadable;

  const [promoCodeTypeName, setPromoCodeTypeName] = React.useState<string>('');
  const handleClose = () => {
    dispatch(showCreatePromoTypeModalAction(false, null));
  };
  const handleSubmitPromoType = async (value: string) => {
    try {
      if (value) {
        const bodyData = {
          promocodeType: {
            promoCodeTypeName: value,
          },
        };
        const res = await addPromoType(bodyData);
        if (res) {
          toast.success('Successfully created Promo Code Type!');
        }

        setPromoCodeTypeName('');
        dispatch(showCreateSuccessModalAction(false, { create: true }));
      }
    } catch (e) {
      toast.error('Error : ' + e);
    }
  };
  const handleUpdateSubmitPromoType = async (value: string) => {
    try {
      if (value && IsEdit && IsEdit > 0) {
        const bodyData = {
          promocodeType: {
            promoCodeTypeId: IsEdit,
            promoCodeTypeName: value,
          },
        };
        const res = await updatePromoType(bodyData);
        if (res) {
          dispatch(showCreateSuccessModalAction(false, { update: true }));
          toast.success('Promo Code Type Update Successfully');
          setPromoCodeTypeName('');
        } else {
          toast.error('Something Is Wrong');
        }
      }
    } catch (e) {
      toast.error('Error : ' + e);
    }
  };

  const handleFormSubmit = () => {
    IsEdit ? handleUpdateSubmitPromoType(promoCodeTypeName) : handleSubmitPromoType(promoCodeTypeName);
    dispatch(showCreatePromoTypeModalAction(false, null));
  };

  return (
    <>
      <Dialog open={createPromoType.show} onClose={handleClose} disableRestoreFocus>
        <DialogContent className="modal-body">
          <Grid container spacing={3} sx={{ align: 'center', justifyContent: 'center' }}>
            <Grid item xs={11} sm={7} md={9} lg={10}>
              <Typography variant="h4" style={{ marginBottom: '10px' }}>
                {IsEdit ? 'Edit' : 'Create'} Promo Code Type
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right', marginRight: '-30px' }}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                fullWidth
                id="standard-basic"
                variant="outlined"
                size="medium"
                focused
                defaultValue={createPromoType.data?.promoCodeTypeName}
                inputProps={{ readOnly: isReadable }}
                name="PromoCodeTypeName"
                onChange={(e) => setPromoCodeTypeName(e.target.value)}
                label={
                  <Fragment>
                    Promo Code Type
                    <Tooltip
                      sx={{
                        position: 'relative',
                        bottom: '10px',
                        right: '5px',
                      }}
                      title="Promo Code Type Description"
                      placement="right-start"
                    >
                      <IconButton size="medium">
                        <Help sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </Tooltip>
                  </Fragment>
                }
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={isReadable}
                sx={{
                  marginTop: '30px',
                }}
                onClick={() => {
                  handleFormSubmit();
                }}
              >
                {IsEdit ? 'Edit' : 'Create'} Promo Code Type
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreatePromoType;
