import { ThemeProvider, ThemeOptions } from '@mui/material';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import React, { createContext, ReactElement, useEffect, useState } from 'react';
// import { Loader } from './component/Loader/Loader';
import { useAuth0 } from '@auth0/auth0-react';
import ThemeContext from './context';
import Routes from './routes/Routes';
import { SnakePageLoader } from './component/Loader/SnakePageLoader/SnakePageLoader';
import { createTheme } from '@mui/material/styles';
import { gametaco, fanduel, skillify, worldwinner, common_theme } from './utils/theme';
import { Loader } from './component/Loader/Loader';
import { useCurrentPartner } from './hooks/useCurrentPartner';
const globalAny: any = global;
LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_PRO_KEY}`);

interface LoadingContextType {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}
interface ThemeType {
  [key: string]: ThemeOptions;
}
const App: React.FC = () => {
  const { isLoading, error } = useAuth0();
  const [themeInfo, setThemeInfo] = useState<string>('common_theme');
  const { currentPartner } = useCurrentPartner();

  useEffect(() => {
    setThemeInfo(currentPartner);
  }, []);
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  if (isLoading) {
    return <SnakePageLoader loadingText="Verifying Permissions..." />;
  }

  const LoadingContext = createContext<LoadingContextType>({
    loading: false,
    setLoading: undefined,
  });

  function LoadingProvider(props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal }): ReactElement {
    const [loading, setLoading] = useState(false);
    const value = { loading, setLoading };
    return (
      <LoadingContext.Provider value={value}>
        {props.children}
        {loading && <Loader open={loading} />}
      </LoadingContext.Provider>
    );
  }

  const themes: ThemeType = {
    fanduel,
    gametaco,
    skillify,
    worldwinner,
    common_theme,
  };

  const currentTheme = themes[themeInfo];

  return (
    <ThemeContext.Provider value={[themeInfo, setThemeInfo]}>
      <ThemeProvider theme={createTheme(currentTheme)}>
        <LoadingProvider>
          <LoadingContext.Consumer>
            {(funcs: any) => {
              globalAny.props = { ...funcs };
              return <Routes />;
            }}
          </LoadingContext.Consumer>
        </LoadingProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default App;
