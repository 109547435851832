import React, { ReactElement } from 'react';
const PrizeRedeemDiscovery = (): ReactElement => {
  return (
    <div>
      <h2>Prize Redeem Discovery </h2>
    </div>
  );
};

export default PrizeRedeemDiscovery;
