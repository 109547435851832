import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { API_URLS } from '../constants/url';
import axiosInstance from '../utils/apis';
import { startLoader, stopLoader } from '../utils/utils';
interface AllocateSKUPayload {
  data: {
    blacklist: number[];
    whitelist: number[];
    publiclist: number[];
    promoCodelist: number[];
    skuId: number;
  };
}
export const allocateSkuData = async (payload: AllocateSKUPayload) => {
  try {
    startLoader();
    const { data } = payload;
    const newUrl = API_URLS.EDIT_SKU_TAG_BY_ID;
    const response = await axiosInstance.post(newUrl, data);
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const getSkuTagBySkuId = async (payload: { id?: number }) => {
  try {
    startLoader();
    const { id } = payload;
    const newUrl = API_URLS.SKU_TAG_BY_SKU_ID + '?skuId=' + id;
    const response = await axiosInstance.get(newUrl);
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const getAllTag = async () => {
  try {
    const newUrl = API_URLS.GET_ALL_TAG;
    const response = await axiosInstance.get(newUrl);
    if (response.success) {
      return response;
    }
  } catch (e: unknown | AxiosError) {
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};
