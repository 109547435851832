import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import FormLogin from './FormLogin';
import { RootState } from '../../redux/reducers/rootReducer';
import { openRoute, protectedRoutes } from '../../constants/routeEnum';
import { RouteComponentProps } from 'react-router-dom';
import { loginAction, loginData } from '../../redux/actions/Authentication/authAction';
// import { addFavoritesData } from '../../redux/actions/CommonActions/commonActions';
import { toast } from 'react-toastify';
import { startLoader, stopLoader } from '../../utils/utils';
import { LoginState } from '../../redux/reducers/authReducer';
import { API_URLS } from '../../constants/url';
import { useAuth0 } from '@auth0/auth0-react';
import axiosInstance from '../../utils/apis';
type ChildComponentProps = RouteComponentProps;
export interface LoginSubmit {
  email: string;
  password: string;
}
interface User {
  email: string;
  name: string;
  picture: string;
  sub: string;
}

const Login: React.FC<ChildComponentProps> = () => {
  const dispatch = useDispatch();
  const loginReducer = useSelector((state: RootState) => state.loginReducer);
  const history = useHistory();
  const { isAuthenticated, user, isLoading, getAccessTokenSilently } = useAuth0<User>();
  useEffect(() => {
    const { loginSuccess, success, loading, errMsg }: LoginState = loginReducer;
    if (loginSuccess) {
      history.push(protectedRoutes.partnerLandingPage);
    } else if (!success && errMsg !== '') {
      toast.error(errMsg);
    }
    if (loading) {
      startLoader();
    } else {
      stopLoader();
    }
  }, [loginReducer]);
  useEffect(() => {
    getAccessTokenSilently({
      audience: `https://${process.env.REACT_APP_AUTH0_AUDIENCE}`,
      scope: 'profile',
    }).then((token) => {
      // eslint-disable-next-line no-console

      console.log({ user });

      if (isAuthenticated) {
        if (!localStorage.getItem('currentUser') && isAuthenticated) {
          setUserData(user, token);
        }
      } else {
        history.push(openRoute.login);
      }
    });
  }, [isLoading, isAuthenticated, user]);

  const loginSubmit = (value: LoginSubmit) => {
    dispatch(loginAction({ url: API_URLS.LOGIN_URL, value }));
  };
  const setUserData = async (user: User | undefined, token: string) => {
    try {
      startLoader();
      const permissionData: {
        adminUserId: number;
        permissions: string[];
      } = await axiosInstance.get(API_URLS.GET_PERMISSIONS, {
        params: { email: user?.email, sub: user?.sub },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      localStorage.setItem('adminUserID', permissionData.adminUserId.toString());

      const adminAuditTrackingID = user?.sub || 'NO ID';

      localStorage.setItem('adminSubID', adminAuditTrackingID);

      const userPayload = {
        adminId: permissionData?.adminUserId,
        userName: user?.name,
        sub: user?.sub,
        mail: user?.email,
        avatar: user?.picture,
        token: token,
        permissions: permissionData?.permissions,
        role: 'admin',
      };
      dispatch(loginData(userPayload));
      history.push(protectedRoutes.partnerLandingPage);
    } catch (err) {
      console.error(err);
      toast.error('Permission Fetching Failed');
    } finally {
      stopLoader();
    }
  };
  return (
    <div>
      <FormLogin loginSubmit={loginSubmit} />
    </div>
  );
};

export default Login;
