export enum breadCrumbsKey {
  'favorites' = 'favorites',
  'transactionDiscovery' = 'transactionDiscovery',
  'promo' = 'promo',
  'sku' = 'sku',
  'dailyReturnSets' = 'dailyReturnSets',
  'CurrencyType' = 'CurrencyType',
  'goal' = 'goal',
  'tags' = 'tags',
  'fulfillmentCenter' = 'fulfillment center',
}
export interface IBreadCrumbConfig {
  key: breadCrumbsKey;
  breadCrumbs: Array<{ name: string; link?: string }>;
}
export const BreadCrumbConfig: Array<IBreadCrumbConfig> = [
  {
    key: breadCrumbsKey.favorites,
    breadCrumbs: [{ name: 'Home', link: '/home' }],
  },
  {
    key: breadCrumbsKey.transactionDiscovery,
    breadCrumbs: [{ name: 'Home', link: '/home' }, { name: 'Discovery' }],
  },
  {
    key: breadCrumbsKey.promo,
    breadCrumbs: [{ name: 'Home', link: '/home' }, { name: 'Marketing' }],
  },
  {
    key: breadCrumbsKey.sku,
    breadCrumbs: [{ name: 'Home', link: '/home' }, { name: 'Marketing' }],
  },
  {
    key: breadCrumbsKey.tags,
    breadCrumbs: [{ name: 'Home', link: '/home' }, { name: 'Marketing' }],
  },
  {
    key: breadCrumbsKey.fulfillmentCenter,
    breadCrumbs: [{ name: 'Home', link: '/home' }, { name: 'Marketing' }],
  },
  {
    key: breadCrumbsKey.dailyReturnSets,
    breadCrumbs: [{ name: 'Home', link: '/home' }, { name: 'Marketing' }],
  },
  {
    key: breadCrumbsKey.CurrencyType,
    breadCrumbs: [{ name: 'Home', link: '/home' }, { name: 'Configurations' }],
  },
  {
    key: breadCrumbsKey.goal,
    breadCrumbs: [{ name: 'Home', link: '/home' }, { name: 'Goals' }],
  },
];
