import React, { ReactElement } from 'react';
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Meta } from '../../interfaces/common.interfaces';
const PREFIX = 'InputWrapper';
const classes = {
  margin: `${PREFIX}-margin`,
  textField: `${PREFIX}-textField`,
};
const Root = styled('div')(() => ({
  [`& .${classes.textField}`]: {
    maxWidth: 'inherit',
    boxSizing: 'border-box',
    display: 'flex',
  },
}));

interface inputProps {
  input: any;
  meta: Meta;
  label: string;
  [key: string]: any;
}
const InputWrapper = ({ input: { name, value, ...restInput }, meta, ...rest }: inputProps): ReactElement => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error !== '') && meta.touched;
  return (
    <Root>
      <FormControl className={classes.textField} variant="outlined">
        {rest.label && (
          <InputLabel shrink htmlFor="">
            {rest.label}
          </InputLabel>
        )}
        <OutlinedInput
          {...rest}
          name={name}
          error={meta.touched && meta.error ? true : false}
          inputProps={restInput}
          notched={rest.label ? true : false}
          value={value}
        />
        {showError && rest.customvalidate !== 'true' && <FormHelperText error={showError}>{meta.error || meta.submitError}</FormHelperText>}
      </FormControl>
    </Root>
  );
};
export default InputWrapper;
