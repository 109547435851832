import { GridColDef } from '@mui/x-data-grid-pro';

export const PLAYER_SUPPORT_COLUMNS: GridColDef[] = [
  {
    headerName: 'ID',
    field: 'id',
    width: 100,
    description: 'Player ID',
  },
  {
    headerName: 'Player Name',
    field: 'playerName',
    width: 200,
    description: 'Player Name',
  },
  {
    headerName: 'Email',
    field: 'email',
    width: 150,
    description: 'Player Email',
  },
  {
    headerName: 'Cash',
    field: 'cash',
    width: 150,
    description: 'Player Cash',
  },
  {
    headerName: 'BC',
    field: 'bonusCash',
    width: 100,
    description: 'Player Bonus Cash',
  },
  {
    headerName: 'RP',
    field: 'rewardPoints',
    width: 100,
    description: 'Player Reward Points',
  },
  {
    headerName: 'Date Joined',
    field: 'date',
    width: 200,
    description: 'Player Date Joined',
  },
  {
    headerName: 'Status',
    field: 'status',
    width: 150,
    description: 'Player Status',
  },
];
