import { Close, Help } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { API_URLS } from '../../../constants/url';
import { showActionStepperModalAction, showModalByModuleName } from '../../../redux/actions/modalActions/modalActions';
import { RootState } from '../../../redux/reducers/rootReducer';
import { getCurrencyTypeList } from '../../../services/currencyType.service';
import { getPromoCodeData } from '../../../services/promoCode.service';
import { getChainRuleList } from '../../../services/promoCodeChainRule.service';
import { getPromoTypeList } from '../../../services/promoCodeType.service';
import { getSkuList } from '../../../services/sku.services';
import { getTransactionsData } from '../../../services/transactions.service';
import { checkCurrentPartnerPermission } from '../../../utils/permission';
import { MainActionData } from './actionWizard.constant';

interface ActionOptionType {
  label: string;
  moduleName?: string;
  type: string;
  actionKey: string;
  alterNativeKey?: string;
  description?: string;
  subOptions?: ActionOptionType[];
}
interface ActionIdOptionType {
  label: string;
  value: string;
  item: {
    description: string;
    actionKey: string;
    moduleName: string;
  };
}

interface MainActionDataInterface {
  PartnerPermissions: string[];
}

interface actionInterface {
  actionKey: string;
  type?: string;
  subOptions?: any;
}

interface itemInterface {
  value: string;
}

export const ActionStepper = () => {
  const dispatch = useDispatch();
  const modalData = useSelector((state: RootState) => state.modalReducer.showActionStepper);
  const [activeStep, setActiveStep] = React.useState(0);
  const [actionData, setActionData] = React.useState<{
    Action: ActionOptionType | null;
    ActionOption: ActionIdOptionType | null;
    ActionId: string | undefined;
  }>({
    Action: null,
    ActionOption: null,
    ActionId: undefined,
  });
  const [actionOptions, setActionOptions] = React.useState<ActionIdOptionType[]>([]);
  const startDate = useSelector((state: RootState) => state.commonReducers.startDateSelected);
  const endDate = useSelector((state: RootState) => state.commonReducers.endDateSelected);
  const permissionWiseData = MainActionData.filter((item: MainActionDataInterface) => {
    return checkCurrentPartnerPermission(item?.PartnerPermissions);
  });
  const finalActionOptions = permissionWiseData.sort((a, b) => -b.actionKey.localeCompare(a.actionKey));
  const handleActionChange = (event: React.SyntheticEvent<Element, Event>, newValue: ActionOptionType | null) => {
    setActionData({ ...actionData, Action: newValue, ActionId: undefined });
    if (newValue?.type && newValue?.type !== 'Add') {
      setOptionAccordingAction(newValue);
    }
  };

  const setOptionAccordingAction = async (action: actionInterface) => {
    let api,
      idKey = '',
      nameKey = '';
    switch (action?.actionKey) {
      case 'Promo Code':
        api = getPromoCodeData({});
        idKey = 'promoCodeId';
        nameKey = 'code';
        break;
      case 'Promo Code Type':
        api = getPromoTypeList({ url: API_URLS.GET_PROMO_TYPE });
        idKey = 'promoCodeTypeId';
        nameKey = 'promoCodeTypeName';
        break;
      case 'Currency Type':
        api = getCurrencyTypeList({ url: API_URLS.GET_CURRENCY_TYPE });
        idKey = 'currencyTypeId';
        nameKey = 'name';
        break;
      case 'SKU':
        api = getSkuList({ url: API_URLS.GET_SKU });
        idKey = 'skuId';
        nameKey = 'name';
        break;
      case 'Promo Code Chain Rule':
        api = getChainRuleList({ url: API_URLS.GET_PROMO_CODE_CHAIN_RULE });
        idKey = 'ruleId';
        nameKey = 'ruleId';
        break;
      case 'Transaction':
        api = getTransactionsData({
          url: API_URLS.TRANSACTIONS_LIST,
          offset: 0,
          limit: 50,
          allFailed: false,
          showDeleted: false,
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate).toISOString(),
        });
        idKey = 'id';
        nameKey = 'playedGameId';
        break;
      case 'Transaction (Failed)':
        api = getTransactionsData({
          url: API_URLS.TRANSACTIONS_LIST,
          offset: 0,
          limit: 50,
          allFailed: true,
          showDeleted: false,
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate).toISOString(),
        });
        idKey = 'id';
        nameKey = 'playedGameId';
        break;
      case 'Transaction (Deleted)':
        api = getTransactionsData({
          url: API_URLS.TRANSACTIONS_LIST,
          offset: 0,
          limit: 50,
          allFailed: false,
          showDeleted: true,
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate).toISOString(),
        });
        idKey = 'id';
        nameKey = 'playedGameId';
        break;
      case '':
      default:
        setActionOptions([]);
        break;
    }
    const res = await api;
    let data;
    if (action?.type === 'Tool') {
      data = action?.subOptions?.map((option: ActionOptionType) => {
        return {
          value: option.label,
          label: option.label,
          item: option,
        };
      });
    } else {
      if (idKey === 'ruleId') {
        data = res?.data?.map((item: any) => {
          return {
            value: item[idKey] + item['strategy'].parent,
            label: String(item[nameKey] + '      (' + item['strategy'].parent + ')'),
            item,
          };
        });
      } else if (nameKey === 'playedGameId') {
        data = res?.data?.map((item: any) => {
          return {
            value: item[idKey],
            label: String(item[idKey] + ' - ' + item[nameKey]),
            item,
          };
        });
      } else {
        data = res?.data?.map((item: any) => {
          return {
            value: item[idKey],
            label: String(item[nameKey]),
            item,
          };
        });
      }
    }
    const finalData =
      action?.type === 'Tool'
        ? data
        : data.sort((a: any, b: any) => {
            const lowerCaseA = a.label;
            const lowerCaseB = b.label;
            return lowerCaseA.localeCompare(lowerCaseB, undefined, {
              numeric: true,
              sensitivity: 'base',
            });
          });
    setActionOptions([...finalData]);
  };

  const handleActionIdChange = (event: React.SyntheticEvent<Element, Event>, newValue: ActionIdOptionType | null) => {
    setActionData({
      ...actionData,
      ActionOption: newValue,
      ActionId: newValue?.value,
    });
  };

  const steps = [
    {
      label: 'Select Action',
      tooltipDescription: 'Select Action for perform respective Task',
      stepComponent: (
        <>
          <Autocomplete
            renderInput={(params) => <TextField {...params} label="Select Action" fullWidth />}
            value={actionData.Action}
            options={finalActionOptions}
            getOptionLabel={(option: ActionOptionType) => option.label}
            onChange={handleActionChange}
            groupBy={(option) => option.actionKey}
            renderOption={(props, option) => {
              return (
                <Tooltip title={option?.description || ''} arrow>
                  <Box component="li" {...props}>
                    <span>{option.label}</span>
                  </Box>
                </Tooltip>
              );
            }}
          ></Autocomplete>
        </>
      ),
    },
    ...(actionData?.Action?.type === 'Edit' || actionData?.Action?.type === 'Delete'
      ? [
          {
            label: `Select ${actionData?.Action?.actionKey} To ${actionData?.Action?.alterNativeKey ?? actionData?.Action?.type}`,
            tooltipDescription: `Select ${actionData?.Action?.actionKey} To ${actionData?.Action?.alterNativeKey ?? actionData?.Action?.type}`,
            stepComponent: (
              <>
                <Autocomplete
                  renderInput={(params) => <TextField {...params} label={`Select ${actionData?.Action?.actionKey}`} />}
                  value={actionData.ActionOption}
                  options={actionOptions}
                  getOptionLabel={(option: ActionIdOptionType) => option.label}
                  onChange={handleActionIdChange}
                  fullWidth
                  renderOption={(props, option) => {
                    return (
                      <Tooltip title={option?.item?.description} hidden={!option?.item.description} arrow>
                        <Box component="li" {...props}>
                          <span>{option.label}</span>
                        </Box>
                      </Tooltip>
                    );
                  }}
                ></Autocomplete>
              </>
            ),
          },
        ]
      : []),
    ...(actionData?.Action?.type === 'Tool'
      ? [
          {
            label: 'Select Data Type',
            tooltipDescription: 'Select Data Type',
            stepComponent: (
              <>
                <Autocomplete
                  renderInput={(params) => <TextField {...params} label={'Select Data Type'} />}
                  value={actionData.ActionOption}
                  options={actionOptions}
                  getOptionLabel={(option: ActionIdOptionType) => option.label}
                  onChange={handleActionIdChange}
                  groupBy={(option) => option.item.actionKey}
                  fullWidth
                  renderOption={(props, option) => {
                    return (
                      <Tooltip title={option?.label} hidden={!option?.label} arrow>
                        <Box component="li" {...props}>
                          <span>{option.label}</span>
                        </Box>
                      </Tooltip>
                    );
                  }}
                ></Autocomplete>
              </>
            ),
          },
        ]
      : []),
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClose = () => {
    dispatch(showActionStepperModalAction(false));
    setActionData({
      Action: null,
      ActionOption: null,
      ActionId: '',
    });
    setActiveStep(0);
  };

  const handleFinishClick = () => {
    if (actionData.ActionId && actionData.ActionId !== '') {
      const itemData = actionOptions.find((item: itemInterface) => item.value === actionData.ActionId);
      if (actionData.Action?.type === 'Tool') {
        dispatch(showModalByModuleName(itemData?.item?.moduleName as string, true));
      } else {
        dispatch(showModalByModuleName(actionData.Action?.moduleName as string, true, itemData?.item));
        if (actionData.Action?.type === 'Delete') {
          dispatch(
            showModalByModuleName(actionData.Action?.moduleName as string, true, {
              deleteModule: actionData.Action?.actionKey,
              deleteId: actionData.ActionId,
              item: itemData,
            }),
          );
        }
      }
      handleClose();
    } else if (actionData.Action?.type === 'Add') {
      dispatch(showModalByModuleName(actionData.Action?.moduleName as string, true));
      handleClose();
    } else {
      if (actionData.Action?.type === 'Delete' || actionData.Action?.type === 'Edit') {
        toast.error(`Select ${actionData.Action?.actionKey} to perform ${actionData?.Action?.alterNativeKey ?? actionData?.Action?.type} on it`);
      } else if (actionData.Action?.type === 'Tool') {
        toast.error('Select data to perform promote');
      }
    }
  };

  return (
    <>
      <Dialog open={modalData.show} onClose={handleClose} disableRestoreFocus>
        <DialogContent>
          <Box sx={{ minWidth: 400 }}>
            <Grid container spacing={3}>
              <Grid item lg={12}>
                <Typography variant="h5" style={{ marginBottom: '5px' }} align="center">
                  Action Wizard
                </Typography>
                <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right', marginTop: '-40px' }}>
                  <Close />
                </IconButton>
                <Divider
                  sx={{
                    backgroundColor: 'black',
                    borderBottomWidth: 2,
                    my: '15px',
                  }}
                />
              </Grid>
            </Grid>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel optional={index === 2 ? <Typography variant="caption">Last step</Typography> : null}>
                    {step.label}
                    <Tooltip
                      sx={{
                        position: 'relative',
                        bottom: '10px',
                        right: '5px',
                      }}
                      title={step?.tooltipDescription}
                    >
                      <IconButton size="small">
                        <Help sx={{ fontSize: '15px' }} />
                      </IconButton>
                    </Tooltip>
                  </StepLabel>
                  <StepContent>
                    {step.stepComponent}
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button variant="contained" onClick={index === steps.length - 1 ? handleFinishClick : handleNext} sx={{ mt: 1, mr: 1 }}>
                          {index === steps.length - 1
                            ? `${
                                actionData?.Action?.alterNativeKey
                                  ? actionData?.Action?.alterNativeKey
                                  : actionData?.Action
                                  ? actionData?.Action.type + ' ' + actionData?.Action.actionKey
                                  : 'continue'
                              }`
                            : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                          Back
                        </Button>
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>All steps completed - you&apos;re finished</Typography>
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  Reset
                </Button>
              </Paper>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
