/* eslint-disable indent */
import React, { forwardRef, ReactElement, Ref } from 'react';
import ListItem from '@mui/material/ListItem';
import { NavLink, NavLinkProps, useParams } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useDispatch, useSelector } from 'react-redux';
import { addFavoritesData, favApiAction, removeFavoritesData, topNavTools } from '../../redux/actions/CommonActions/commonActions';
import { classes } from './SideBarItem';
import { RootState } from '../../redux/reducers/rootReducer';
import { API_URLS } from '../../constants/url';
import { useHistory } from 'react-router-dom';
import { AppMenuItemProps } from './SideBarItem';

interface FavoriteItem {
  name?: string;
  title?: string | undefined | null;
  link?: string | undefined | null;
}

export interface AppMenuItemComponentProps {
  className?: string;
  link?: string | null | any; // because the InferProps props allows  null value
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  children: React.ReactNode;
  itemKey: string;
  isExpandable?: boolean;
  handleItemClick?: (arg1: boolean) => void;
  sidebarOpen?: boolean;
  title?: string | undefined | null;
  topLevel?: boolean;
  itemData?: AppMenuItemProps;
}
const NavComponent = forwardRef<Ref<HTMLAnchorElement>, NavLinkProps>((props: NavLinkProps, ref: any) => {
  return <NavLink exact {...props} innerRef={ref} />;
});
NavComponent.displayName = 'NavComponent';
const SidebarItemComponent = (props: AppMenuItemComponentProps): ReactElement => {
  const { className, onClick, link, children, itemKey, isExpandable, handleItemClick, sidebarOpen, topLevel } = props;
  let favDataCheck: FavoriteItem[] = useSelector((state: RootState) => state.commonReducers.favoritesData);

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{
    [key: string]: string;
  }>();
  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFav: FavoriteItem = {
      name: itemKey,
      title: props.title,
      link: props.link,
    };
    if (e.target.checked === true) {
      dispatch(
        addFavoritesData({
          name: itemKey,
          title: props.title,
          link: props.link,
        }),
      );
      favDataCheck.push(newFav);
    } else {
      dispatch(removeFavoritesData(itemKey));
      favDataCheck = favDataCheck.filter((item: any) => item.name !== newFav.name);
    }
    dispatch(favApiAction({ url: API_URLS.FAVORITES_API, favArray: favDataCheck }));
  };

  const onListItemClick = (e: React.MouseEvent<HTMLElement>) => {
    if (isExpandable && onClick) {
      onClick(e);
    } else if (handleItemClick) {
      dispatch(handleItemClick(true));
    } else if (!isExpandable) {
      dispatch(topNavTools([]));
      history.push('/' + params?.partner + link);
    }
  };
  // If link is not set return the orinary ListItem
  if (!link || typeof link !== 'string' || (link && !isExpandable && itemKey === 'home')) {
    return (
      <ListItem
        button
        className={className}
        onClick={sidebarOpen || (!sidebarOpen && !isExpandable) ? onListItemClick : undefined}
        sx={!sidebarOpen && (isExpandable || itemKey === 'home') ? { overflow: 'hidden' } : {}}
      >
        {children}
      </ListItem>
    );
  }

  // const favDataRef = useRef();
  // useEffect(() => {
  //   favDataRef.current.favDataCheck;
  // }, [favDataCheck]);
  // Return a LitItem with a link component
  return (
    <span style={{ display: 'flex', flexDirection: 'row' }} className={`${!sidebarOpen && !isExpandable && topLevel ? classes.menuItemHover : ''}`}>
      {/* <Grid container>
        <Grid item xs={8}> */}
      <ListItem button className={className} component={NavComponent} to={'/' + params?.partner + link}>
        {children}
      </ListItem>
      {/* <Grid item xs={4}> */}
      {itemKey !== 'home' && (
        <FormControlLabel
          sx={
            !sidebarOpen && !isExpandable && topLevel
              ? {
                  visibility: 'hidden',
                  height: 0,
                  marginLeft: '0px',
                  marginRight: '0px',
                  zIndex: 1000,
                }
              : {}
          }
          control={
            <Checkbox
              icon={<FavoriteBorderIcon />}
              checkedIcon={<FavoriteIcon sx={{ color: 'red' }} />}
              name="checkedH"
              onChange={(e) => handleCheckbox(e)}
              checked={favDataCheck.some((item: FavoriteItem) => item.name === itemKey)}
              sx={{ left: '11px' }}
            />
          }
          label=""
        />
      )}
      {/* </Grid>
        </Grid>
      </Grid> */}
    </span>
  );
};

export default SidebarItemComponent;
