export const MODAL_ACTION = {
  SHOW_USER_ACTION_MODEL: 'SHOW_USER_ACTION_MODEL',
  SHOW_NOTIFICATION_MODEL: 'SHOW_NOTIFICATION_MODAl',
  SHOW_ADD_CREDITS_MODEL: 'SHOW_ADD_CREDITS_MODAl',
  SHOW_USER_UPDATE_MODEL: 'SHOW_USER_UPDATE_MODEL',
  SHOW_CONTACT_MODEL: 'SHOW_CONTACT_MODEL',
  SHOW_RESET_PASSWORD_MODEL: 'SHOW_RESET_PASSWORD_MODEL',
  SHOW_AVATARS_MODEL: 'SHOW_AVATARS_MODEL',
  SHOW_CLOSE_ACCOUNT_MODEL: 'SHOW_CLOSE_ACCOUNT_MODEL',
  SHOW_MERGE_ACCOUNT_MODEL: 'SHOW_MERGE_ACCOUNT_MODEL',
  SHOW_UPDATE_TRANSACTION_MODEL: 'SHOW_UPDATE_TRANSACTION_MODEL',
  SHOW_UPSERT_CURRENCY_TYPE_MODEL: 'SHOW_UPSERT_CURRENCY_TYPE_MODEL',
  SHOW_UPSERT_SKIP_DEPOSIT_MODEL: 'SHOW_UPSERT_SKIP_DEPOSIT_MODEL',
  SHOW_UPDATE_SUCCESS_MODEL: 'SHOW_UPDATE_SUCCESS_MODEL',
  SHOW_CREATE_TRANSACTION_MODEL: 'SHOW_CREATE_TRANSACTION_MODEL',
  SHOW_CREATE_SUCCESS_MODEL: 'SHOW_CREATE_SUCCESS_MODEL',
  SHOW_UPDATE_FAILED_MODEL: 'SHOW_UPDATE_FAILED_MODEL',
  SHOW_DELETE_TRANSACTION_MODEL: 'SHOW_DELETE_TRANSACTION_MODEL',
  SHOW_REMOVE_TRANSACTION_MODEL: 'SHOW_REMOVE_TRANSACTION_MODEL',
  SHOW_CREATE_PROMO_CODE_MODEL: 'SHOW_CREATE_PROMO_CODE_MODEL',
  SHOW_CREATE_PROMOTYPE_MODEL: 'SHOW_CREATE_PROMOTYPE_MODEL',
  SHOW_CREATE_SKU_MODEL: 'SHOW_CREATE_SKU_MODEL',
  SHOW_ALLOCATE_SKU: 'SHOW_ALLOCATE_SKU',
  SHOW_ACTION_STEPPER_MODEL: 'SHOW_ACTION_STEPPER_MODEL',
  SHOW_MODEL_BY_MODULE: 'SHOW_MODEL_BY_MODULE',
  SHOW_GLOBAL_DELETE_MODEL: 'SHOW_GLOBAL_DELETE_MODEL',
  SHOW_CHAIN_RULE_MODEL: 'SHOW_CHAIN_RULE_MODEL',
  SHOW_UNDO_DELETE_TRANSACTION_MODEL: 'SHOW_UNDO_DELETE_TRANSACTION_MODEL',
  SHOW_DEPLOY_GOAL_MODEL: 'SHOW_DEPLOY_GOAL_MODEL',
  SHOW_SUCCESS_MODEL: 'SHOW_SUCCESS_MODEL',
  SHOW_GOAL_LIST_MODEL: 'SHOW_GOAL_LIST_MODEL',
  SHOW_CREATE_DAILY_RETURN_SET_MODAL: 'SHOW_CREATE_DAILY_RETURN_SET_LIST_MODEL',
  SHOW_CREATE_DAILY_RETURN_SET_GROUP_MODAL: 'SHOW_CREATE_DAILY_RETURN_SET_GROUP_MODEL',
  SHOW_CREATE_DAILY_RETURN_SLOT_MODAL: 'SHOW_CREATE_DAILY_RETURN_SLOT_MODAL',
  SHOW_CREATE_SEGMENT_MODAL: 'SHOW_CREATE_SEGMENT_MODAL',
  SHOW_CREATE_SEGMENT_FULLFILLMENT_JOB: 'SHOW_CREATE_SEGMENT_FULLFILLMENT_JOB',
  SHOW_SEGMENT_FULLFILLMENT_JOB: 'SHOW_SEGMENT_FULLFILLMENT_JOB',
};
