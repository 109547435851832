import React, { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useDispatch, useSelector } from 'react-redux';
import { favApiAction, removeFavoritesData } from '../../redux/actions/CommonActions/commonActions';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import CommonBreadcrumbs from '../../component/BreadCrumb/BreadCrumb';
import { RootState } from '../../redux/reducers/rootReducer';
import { useHistory } from 'react-router-dom';
import { API_URLS } from '../../constants/url';
import { AllMenuConfig } from '../../constants/sidebar.constant';
import { checkCurrentPartnerPermission, checkPermission } from '../../utils/permission';
import { useCurrentPartner } from '../../hooks/useCurrentPartner';
import { breadCrumbsKey } from '../../constants/breadCrumbConfig';

/**
 * Favorite list
 * @returns {JSX.Element}
 * @constructor
 */

interface CardDataType {
  name: string;
  title: string;
  link: string;
}

const Home = (): ReactElement => {
  let favorites = useSelector((state: RootState) => state.commonReducers.favoritesData);

  const [cardContent, setCardContent] = React.useState<{
    title: string;
    name: string;
  }>({ title: '', name: '' });
  const history = useHistory();
  const [openFavDialog, setOpenFavDialog] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    height: 200,
    [theme.breakpoints.down('xl')]: {
      height: 100,
    },
    width: '100% !important', // Overrides inline-style
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      '& .MuiImageBackdrop-root': {
        opacity: 0.15,
      },
      '& .MuiImageMarked-root': {
        opacity: 0,
      },
      '& .MuiTypography-root': {
        border: '4px solid currentColor',
      },
    },
  }));

  const ImageSrc = styled('img')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  });

  const Image = styled('span')(() => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
  }));

  const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.primary.dark,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  }));

  const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.primary.light,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  }));
  const { partnerShortName } = useCurrentPartner();

  const handleClickOpen = (data: CardDataType) => {
    const currentKey = Object.keys(AllMenuConfig).find((key) => AllMenuConfig[key].accessKey === data.name) as string;
    const link = data.link || AllMenuConfig?.[currentKey]?.link;
    history.push('/' + partnerShortName + link);
    setCardContent(data);
  };

  const handleClose = () => {
    setOpenFavDialog(false);
  };

  const handleFavCancel = () => {
    dispatch(removeFavoritesData(cardContent.name));
    favorites = favorites.filter((item: CardDataType) => item.name !== cardContent.name);
    dispatch(favApiAction({ url: API_URLS.FAVORITES_API, favArray: favorites }));
    handleClose();
  };

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, data: CardDataType) => {
    if (e.target.checked === false) {
      setOpenFavDialog(true);
    }
    setCardContent(data);
  };
  const hasPartnerPermission = (data: CardDataType) => {
    const currentKey = Object.keys(AllMenuConfig).find((key) => AllMenuConfig[key].accessKey === data.name) as string;
    const permission = AllMenuConfig?.[currentKey]?.Permissions;
    const partnerPermission = AllMenuConfig?.[currentKey]?.PartnerPermissions;
    if (partnerPermission.length > 0 && partnerPermission.length > 0) {
      return checkCurrentPartnerPermission(partnerPermission) && checkPermission(permission);
    } else if (permission.length > 0) {
      return checkPermission(permission);
    } else {
      return true;
    }
  };
  return (
    <>
      <CommonBreadcrumbs breadCrumbKey={breadCrumbsKey.favorites} />
      <Grid container spacing={2}>
        {favorites.map((data: CardDataType) => {
          return (
            hasPartnerPermission(data) && (
              <Grid item xs={12} key={data.name}>
                <ImageButton focusRipple key={data.name} onClick={() => handleClickOpen(data)}>
                  <ImageSrc />
                  <ImageBackdrop className="MuiImageBackdrop-root" />
                  <Image>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="inherit"
                      sx={{
                        position: 'relative',
                        fontSize: '2rem',
                        p: 4,
                        pt: 2,
                        pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                      }}
                    >
                      {data.title}
                      <ImageMarked className="MuiImageMarked-root" />
                    </Typography>
                  </Image>
                </ImageButton>

                <FormControlLabel
                  sx={{ right: '50px', position: 'absolute' }}
                  control={
                    <Checkbox
                      icon={<FavoriteBorderIcon fontSize="large" />}
                      checkedIcon={<FavoriteIcon fontSize="large" sx={{ color: 'red' }} />}
                      name="checkedH"
                      onChange={(e) => handleCheckbox(e, data)}
                      defaultChecked={true}
                    />
                  }
                  label=""
                />
              </Grid>
            )
          );
        })}
        {favorites.length === 0 && (
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ mt: 2 }}>
              Tip: Add your most-used Tools to the Home screen by clicking the
              {<FavoriteBorderIcon sx={{ height: '0.8em' }} />} icon next to a Tool within the Side Nav!
            </Typography>
          </Grid>
        )}
      </Grid>

      <Dialog open={openFavDialog} onClose={handleClose}>
        <DialogTitle>Are you sure you want to unfavorite?</DialogTitle>
        <DialogContent>{cardContent.title}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleFavCancel}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Home;
