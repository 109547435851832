import { GridColDef } from '@mui/x-data-grid-pro';

export const FULFILLMENT_JOB_LOGS_COLUMN = (): GridColDef[] => {
  return [
    {
      field: 'id',
      headerName: 'User ID',
      width: 120,
      description: 'Record ID on server for the Tag.',
    },
    {
      field: 'message',
      headerName: 'Error',
      width: 300,
      description: 'The user-friendly name of the Tag. The Name should be Short and Concise, elaborate in the Description section.',
    },
  ];
};
