import React, { Fragment, ReactElement, useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/rootReducer';
import { showDeployGoalModalAction, showSuccessModalAction } from '../../../redux/actions/modalActions/modalActions';
import { toast } from 'react-toastify';
import { Field, Form } from 'react-final-form';
import { Button, DialogContent, Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { Help } from '@mui/icons-material';
import { required } from '../../../utils/validations';
import SelectWrapper from '../../UI/SelectWrapper';
import InputFieldsTooltip from '../../InputFieldsTooltip';
import DateWrapper from '../../UI/DateWrapper';
import moment from 'moment';
import Checkbox from '@mui/material/Checkbox';

interface optionTypeT {
  label: string;
  value: string | number;
}

const envDummyData = [
  {
    label: 'Dev',
    value: 'Dev',
  },
  {
    label: 'Qa',
    value: 'Qa',
  },
  {
    label: 'Stg',
    value: 'Stg',
  },
  {
    label: 'Production',
    value: 'Production',
  },
];
const goalDummyData = [
  {
    label: 'First Game',
    value: 'First Game',
  },
  {
    label: 'First Cash Game',
    value: 'First Cash Game',
  },
  {
    label: 'First Deposit',
    value: 'First Deposit',
  },
];
const DeployGoal = (): ReactElement => {
  const deployGoalData = useSelector((state: RootState) => state.modalReducer.showDeployGoal);
  const goalData = deployGoalData.data || {};
  const [environments, setEnvironments] = useState<optionTypeT[]>([]);
  const [goals, setGoals] = useState<optionTypeT[]>([]);
  const [schedule, setSchedule] = useState(false);

  const currentDateTime = moment().format('MM/DD/yyyy HH:mm:ss');
  const nextYearDateTime = moment().add(1, 'years').format('MM/DD/yyyy HH:mm:ss');

  const dispatch = useDispatch();

  const useStyles = makeStyles({
    divider: {
      backgroundColor: 'black',
      borderBottomWidth: 2,
      marginTop: '25px',
      marginBottom: '25px',
    },

    createButton: {
      backgroundColor: 'rgb(50, 205, 50)',
      color: 'black',
      width: '250px',
      height: '50px',
      textTransform: 'none',
    },

    gridStyle: {
      display: 'flex',
      marginBottom: '20px',
    },

    typographySpacing: {
      marginRight: '20px',
    },
  });
  const classes = useStyles();

  const handleClose = () => {
    dispatch(showDeployGoalModalAction(false, null));
  };

  const handleDeploySubmit = async (value: any) => {
    try {
      const deployGoalData = {
        goal: {
          environment: value.environment.value,
          goal: value.goal.value,
          schedule: schedule,
          startTsz: value.startTsz,
          endTsz: value.endTsz,
        },
      };
      console.log('Deploy goal', deployGoalData);
      dispatch(showDeployGoalModalAction(false, null));
      switch (schedule) {
        case true:
          dispatch(
            showSuccessModalAction(true, {
              title: 'Deployment Scheduled',
              content: (
                <>
                  <Typography>The Goal has been scheduled Successfully!</Typography>
                  <br />
                  <Typography>{`Goal: ${value.goal.label}`}</Typography>
                  <Typography>{`Environment: ${value.environment.label}`}</Typography>
                  <Typography>{`Start Date: ${value.startTsz}`}</Typography>
                  <Typography>{`End Date: ${value.endTsz}`}</Typography>
                </>
              ),
            }),
          );
          break;
        case false:
          dispatch(
            showSuccessModalAction(true, {
              title: 'Deploy Successful',
              content: <Typography>{`The Goal, ${value.goal.label}, has been deployed to ${value.environment.label} Successfully!`}</Typography>,
            }),
          );
          break;
      }
    } catch (e) {
      toast.error('Something Went Wrong');
    }
  };

  useEffect(() => {
    const getEnvironments = () => {
      setEnvironments(envDummyData);
    };
    const getGoals = () => {
      setGoals(goalDummyData);
    };
    if (deployGoalData.show) {
      getEnvironments();
      getGoals();
    }
  }, [deployGoalData.show]);

  return (
    <div>
      <Dialog open={deployGoalData.show} onClose={handleClose} fullWidth disableRestoreFocus>
        <DialogContent className="modal-body">
          <div className="modal-ct">
            <Typography align="center" variant="h5" style={{ marginBottom: '10px' }}>
              Deploy Goal
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right', marginTop: '-40px' }}>
              <CloseIcon />
            </IconButton>
            <Divider className={classes.divider} />
            <Grid item xs={12}>
              <Form onSubmit={handleDeploySubmit} initialValues={goalData}>
                {({
                  handleSubmit,
                  // values
                }) => {
                  return (
                    <form method="post" onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Field
                            name="environment"
                            option={environments}
                            component={SelectWrapper}
                            validate={required}
                            label={
                              <Fragment>
                                Environment
                                <Tooltip
                                  sx={{
                                    position: 'relative',
                                    bottom: '10px',
                                    right: '5px',
                                  }}
                                  title="Environment description"
                                  placement="right-start"
                                >
                                  <IconButton size="medium">
                                    <Help sx={{ fontSize: '20px' }} />
                                  </IconButton>
                                </Tooltip>
                              </Fragment>
                            }
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                },
                              },
                            }}
                          ></Field>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="goal"
                            option={goals}
                            component={SelectWrapper}
                            validate={required}
                            label={
                              <Fragment>
                                Goal
                                <Tooltip
                                  sx={{
                                    position: 'relative',
                                    bottom: '10px',
                                    right: '5px',
                                  }}
                                  title="Environment description"
                                  placement="right-start"
                                >
                                  <IconButton size="medium">
                                    <Help sx={{ fontSize: '20px' }} />
                                  </IconButton>
                                </Tooltip>
                              </Fragment>
                            }
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                },
                              },
                            }}
                          ></Field>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex' }}>
                          <Checkbox
                            name="schedule"
                            checked={schedule}
                            style={{ marginTop: '4px' }}
                            onChange={() => {
                              setSchedule(!schedule);
                            }}
                          />
                          <Typography
                            variant="h6"
                            style={{
                              paddingTop: '8px',
                            }}
                          >
                            <InputFieldsTooltip title="Schedule Deployment" description="Schedule Deployment" />
                          </Typography>
                        </Grid>
                        {schedule && (
                          <>
                            <Grid item xs={12}>
                              <Field
                                name="startTsz"
                                component={DateWrapper}
                                inputFormat="MM/dd/yyyy HH:mm:ss"
                                type="datetime-local"
                                defaultValue={currentDateTime}
                                label={<InputFieldsTooltip title="Start Date" description="Start Date Description" />}
                              ></Field>
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                name="endTsz"
                                component={DateWrapper}
                                inputFormat="MM/dd/yyyy HH:mm:ss"
                                defaultValue={nextYearDateTime}
                                type="datetime-local"
                                label={<InputFieldsTooltip title="End Date" description="End Date Description" />}
                              ></Field>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12}>
                          <Button variant="contained" type="submit" fullWidth>
                            {schedule ? 'Deploy First Game' : 'Deploy Goal'}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Form>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DeployGoal;
