import React, { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  // InputLabel,
  // MenuItem,
  // Select,
  TextField,
} from '@mui/material';
const PREFIX = 'SelectWrapper';

const classes = {
  formControl: `${PREFIX}-formControl`,
  selectField: `${PREFIX}-selectField`,
};

const Root = styled('div')(() => ({
  [`& .${classes.selectField}`]: {
    maxWidth: 'inherit',
    boxSizing: 'border-box',
    display: 'flex',
  },
}));

interface OptionType {
  value: string | number;
  label: string;
}
interface Meta {
  submitError?: boolean;
  dirtySinceLastSubmit?: boolean;
  error?: string;
  touched?: boolean;
}
interface SelectType {
  input: any;
  meta: Meta;
  option: OptionType[];
  placeholder: string;
  className: string;
  readOnly?: boolean;
  multiple?: boolean;
  getDisabledOptions?: (option: any | boolean) => any;
  [key: string]: any;
}

const SelectWrapper = ({
  input,
  meta,
  option,
  readOnly,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // multiple,
  // getDisabledOptions,
  defaultValue,
  ...rest
}: SelectType): ReactElement => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;
  const finaleSorted = option.sort((a, b) => {
    const lowerCaseA = a.label;
    const lowerCaseB = b.label;
    return lowerCaseA.localeCompare(lowerCaseB, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  });
  return (
    <Root>
      <FormControl variant="outlined" className={`${classes.selectField}`}>
        <Autocomplete
          {...input}
          {...rest}
          defaultValue={defaultValue}
          className={rest.className}
          error={showError}
          options={finaleSorted}
          // getOptionDisabled={getDisabledOptions}
          disableClearable={readOnly}
          getOptionLabel={(option: OptionType) => option.label || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                readOnly: readOnly,
              }}
              {...rest}
            />
          )}
          onChange={(event, option: OptionType) => {
            input.onChange(option);
          }}
        />
        {showError && <FormHelperText error={showError}>{meta.error || meta.submitError}</FormHelperText>}
      </FormControl>
    </Root>
  );
};

export default SelectWrapper;
