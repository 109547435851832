/* eslint-disable indent */
import React, { useState, ReactElement, useMemo, useRef } from 'react';
import DataTable, { FetchDataParams, FilterByMenu, IDataGridRef } from '../../component/DataTable/DataTable';
import DropDown from '../../component/DropDown/DropDown';
import { AddCredits } from '../../component/Modals/PlayerSupport/AddCredits';
import NotificationModal from '../../component/Modals/PlayerSupport/NotificationModal';
import { UserActionModal } from '../../component/Modals/PlayerSupport/UserActionModal';
import { Grid } from '@mui/material';
import { getPlayerSupportData } from '../../services/playerSupport.service';
import { API_URLS } from '../../constants/url';
import { toast } from 'react-toastify';
import { startLoader, stopLoader } from '../../utils/utils';
import axiosInstance from '../../utils/apis';
import { PLAYER_SUPPORT_COLUMNS } from '../../constants/columns/playerSupport.constant';
import SelectDropdown from '../../component/SelectDropdown/SelectDropdown';
import { showAddCreditModalAction, showNotificationModalAction, showUserActionModalAction } from '../../redux/actions/modalActions/modalActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/rootReducer';
import BackendSearchBar from '../../component/BackendSearchBar/BackendSearchBar';
import { PlayerSupportPermissions } from '../../constants/permission';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

/**
 * Withdraw list
 * @returns {JSX.Element}
 * @constructor
 */
export interface ItemType {
  id: number;
  userName: string;
  profile: { name: string };
  email: string;
  funds: string;
  TacoToken: { tokens: string };
  bonus_cash: string;
  createdAt: Date;
  status: string;
}

export interface itemtype {
  user: UserType;
  status?: string;
  reason?: string;
  length?: number;
  typeCurrency?: number;
  amount?: number;
}
export interface UserType {
  id: number;
  userName: string;
  email: string;
  funds: number;
  bonus_cash: number;
  ticket: string;
  profile: { name: string };
  banned: string;
  status: string;
  createdAt: string;
  paypal_email: string;
}

const User_Default = {
  id: 0,
  userName: '',
  email: '',
  funds: 0,
  bonus_cash: 0,
  ticket: '',
  profile: { name: '' },
  banned: '',
  status: '',
  createdAt: '',
  paypal_email: '',
};

const PlayerSupport = (): ReactElement => {
  const [search, setSearch] = useState('');
  const [notificationAllUser, setNotificationAllUser] = useState<boolean>(false);

  const currentRowInfo = useRef<UserType>();
  const [message, setMessage] = useState<string>('');

  const DataGridRef = useRef<IDataGridRef>(null);
  const dispatch = useDispatch();

  const [searchBy, setSearchBy] = React.useState<FilterByMenu>({
    item: '',
  });

  const handleChange = (option: FilterByMenu) => {
    setSearchBy(option);
  };
  const startDate = useSelector((state: RootState) => state.commonReducers.startDateSelected);
  const endDate = useSelector((state: RootState) => state.commonReducers.endDateSelected);

  const menuItems: FilterByMenu[] = [
    { item: 'ID', columnName: 'User.id' },
    { item: 'Player Name', columnName: 'User.user_name' },
    { item: 'Email', columnName: 'User.email' },
    { item: 'Cash', columnName: 'User.funds' },
    { item: 'Status', columnName: 'User.status' },
  ];

  const columns = useMemo(() => {
    PLAYER_SUPPORT_COLUMNS.push({
      headerName: 'Action',
      field: 'action',
      width: 220,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <DropDown
              title="Select Action"
              items={[
                {
                  name: 'Player Profile',
                  onClick: () => {
                    processPlayerSupport(params.value);
                  },
                  Permissions: [PlayerSupportPermissions.Read],
                },
                {
                  name: 'Add Credits',
                  onClick: () => {
                    addFunds(params.value);
                  },
                  Permissions: [PlayerSupportPermissions.Update],
                },
                {
                  name: 'Player Discovery',
                  onClick: () => {
                    processPlayerSupport(params.value);
                  },
                  Permissions: [PlayerSupportPermissions.Update],
                },
                {
                  name: 'Update Player Status',
                  onClick: () => {
                    playerStatus(params.value);
                  },
                  Permissions: [PlayerSupportPermissions.Update],
                },
                {
                  name: 'Contact Player',
                  onClick: () => {
                    processPlayerSupport(params.value);
                  },
                  Permissions: [PlayerSupportPermissions.Update],
                },
                {
                  name: 'Send Notification',
                  onClick: () => {
                    sendNotification(params.value, false);
                  },
                  Permissions: [PlayerSupportPermissions.Update],
                },
              ]}
            />
          </>
        );
      },
    });

    return PLAYER_SUPPORT_COLUMNS;
  }, []);

  /* Player Status Function Calls */
  const playerStatus = async (value: UserType) => {
    setMessage(`Would you like to perform action on ${value.profile && value.profile.name ? value.profile.name : value.userName} account?`);
    currentRowInfo.current = value;
    dispatch(showUserActionModalAction(true, value));
  };

  const processPlayerSupport = (item: ItemType) => {
    // eslint-disable-next-line no-console
    console.log('process', item);
  };

  const updateStatusSubmit = (value: itemtype) => {
    startLoader();
    axiosInstance
      .post(API_URLS.UPDATE_USER_STATUS, value)
      .then(() => {
        toast.success('User status updated successfully!');
        DataGridRef.current?.fetchData();
        stopLoader();
        dispatch(showUserActionModalAction(false));
        currentRowInfo.current = User_Default;
      })
      .catch(() => {
        stopLoader();
        toast.error('Something went wrong while updating the status');
        currentRowInfo.current = User_Default;
        // handleUserActionClose();
      });
  };

  /* Add funds Function Calls */
  const addFunds = (value: UserType) => {
    currentRowInfo.current = value;
    dispatch(showUserActionModalAction(true, value));
  };

  const addFundsSubmit = (value: itemtype) => {
    startLoader();
    axiosInstance
      .post(API_URLS.ADD_FUND_BY_TYPE, value)
      .then(() => {
        toast.success('Funds Updated successfully !');
        stopLoader();
        dispatch(showAddCreditModalAction(false));
        DataGridRef.current?.fetchData();
      })
      .catch((error) => {
        toast.error('Something went wrong while updating the funds');
        stopLoader();
        console.error(error);
      });
  };

  const sendNotification = (value: ItemType | object, allUsers: boolean) => {
    dispatch(showNotificationModalAction(true, value));
    setNotificationAllUser(allUsers);
  };

  const performUserAction = (value: { action: string; selectedOption: string; suspendTill: number }) => {
    const user: itemtype = {
      user: {
        ...(currentRowInfo.current as UserType),
      },
    };
    user['status'] = value.action;
    user['reason'] = value.selectedOption;
    user['length'] = value.suspendTill;
    updateStatusSubmit(user);
  };

  const performUserAddCredits = (value: { action: string; selectedOption: string; suspendTill: number }) => {
    const user: itemtype = {
      user: {
        ...(currentRowInfo.current as UserType),
      },
    };
    user['reason'] = value.selectedOption;
    user['amount'] = Number(value.suspendTill);
    user['typeCurrency'] = 0;
    addFundsSubmit(user);
    dispatch(showAddCreditModalAction(false));
  };

  // useEffect(() => {
  //   DataGridRef.current?.fetchData();
  // }, [startDate, endDate]);

  async function fetchEvent({ page, pageSize }: FetchDataParams) {
    const helper = API_URLS.GET_USER;
    startLoader();
    const response = await getPlayerSupportData({
      offset: page * pageSize,
      limit: pageSize,
      url: helper,
      search,
      searchBy: searchBy.columnName,
      startDate: startDate ? new Date(startDate) : undefined,
      endDate: endDate ? new Date(endDate) : undefined,
    });
    stopLoader();
    const rowsData = response?.results?.map((value: ItemType) => {
      return {
        id: value.id ? value.id : 0,
        playerName: value.userName || (value.profile ? value.profile.name : ''),
        email: value.email,
        cash: value.funds,
        rewardPoints: value.TacoToken ? value.TacoToken.tokens : 0,
        bonusCash: value.bonus_cash ? value.bonus_cash : 0,
        amount: value.funds ? value.funds : 'No funds given',
        date: value.createdAt,
        status: value.status,
        action: value,
      };
    });

    return {
      data: rowsData || [],
      totalRows: response?.totalRows,
    };
  }

  const batchMenus = (
    <Grid item lg={4}>
      <div className="batch-actions">
        <DropDown
          title="Select Bulk Actions"
          items={[
            {
              name: 'Send Notifications to all Users',
              onClick: async function () {
                sendNotification({}, true);
              },
              Permissions: [PlayerSupportPermissions.Update],
            },

            //Future Scope
            // {
            //   name: 'Update Status',
            //   onClick: async function () {
            //     processPlayerSupport('updateStatus');
            //   },
            // },
            // {
            //   name: 'Contact Players',
            //   onClick: async function () {
            //     showModal('contactPlayer');
            //   },
            // },
            // {
            //   name: 'Deactivate Accounts',
            //   onClick: async function () {
            //     showModal('deactivateAcc');
            //   },
            // },
          ]}
        />
      </div>
    </Grid>
  );

  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: '10px',
        }}
      >
        <Grid item xs={10}>
          <BackendSearchBar search={search} setSearch={setSearch} DataGridRef={DataGridRef} />
        </Grid>

        <Grid>
          <SelectDropdown handleChange={handleChange} searchBy={searchBy} menuItems={menuItems} label="Search By" style={{ width: 150 }} />
        </Grid>
      </Grid>

      <DataTable columns={columns} checkbox={true} fetchData={fetchEvent} ref={DataGridRef} batchMenus={batchMenus} enableSearch />

      <UserActionModal submit={performUserAction} content={message} />

      <AddCredits submit={performUserAddCredits} content={message} />

      <NotificationModal allUsersFlag={notificationAllUser} />
    </>
  );
};

export default PlayerSupport;
