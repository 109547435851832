/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties } from 'react';
import { FilterByMenu } from '../DataTable/DataTable';
import { Autocomplete, TextField } from '@mui/material';

interface SelectDropdownProps {
  handleChange?: (option: { item: string; columnName?: string }) => void;
  searchBy?: {
    item: string;
    columnName?: string;
  };
  menuItems: FilterByMenu[];
  style?: CSSProperties;
  label: string;
  inputName?: string;
}

export default function SelectDropdown({ searchBy, menuItems, handleChange, style, label }: SelectDropdownProps) {
  return (
    <Autocomplete
      value={searchBy}
      onChange={(event, option) => {
        if (option) {
          handleChange && handleChange(option);
        }
      }}
      style={style}
      options={menuItems}
      fullWidth
      // renderOption={(props, option) => <h4>{option.item}</h4>}
      getOptionLabel={(option: FilterByMenu) => option.item}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
