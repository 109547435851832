/* eslint-disable indent */
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, useHistory, useRouteMatch } from 'react-router';
import { RootState } from '../redux/reducers/rootReducer';
import { findCommonElement } from '../utils/utils';
import { openRoute, protectedRoutes, userAccess } from '../constants/routeEnum';
import PartnerLandingPage from '../container/PartnerLandingPage/PartnerLandingPage';

import Login from '../container/Login/Login';
import 'react-toastify/dist/ReactToastify.css';
import PageNotFound from '../component/PageNotFound';
import { toast, ToastContainer } from 'react-toastify';
import { Layout } from './Layout';
import { CreditAccounts } from '../container/CreditAccounts/CreditAccounts';
import { PartnerPermissions } from '../constants/permission';
import { checkCurrentPartnerPermission, checkPermission } from '../utils/permission';
import { RouteConfig } from '../constants/routeConfig.constant';
import { ActionStepper } from '../component/Modals/GlobalModals/ActionStepper';
import CreatePromoCode from '../component/Modals/PromoCode/CreatePromoCode';
import DeployGoal from '../component/Modals/Goal/DeployGoal';
import SuccessModal from '../component/Modals/SuccessModal';
import SkuCreate from '../component/Modals/Sku/SkuCreate';
import UpsertCurrencyType from '../component/Modals/CurrencyType/UpsertCurrencyType';
import CreatePromoType from '../component/Modals/PromoCode/CreatePromoType';
import AllocateSkuModal from '../component/Modals/Sku/AllocateSkuModal';
import UpsertSkipDepositModal from '../component/Modals/PlayerSupport/SkipDepositModal';
import { UpsertDailyReturnSet } from '../component/Modals/DailyReturn/UpsertDailyReturnSet';
import { UpsertDailyReturnSlot } from '../component/Modals/DailyReturn/UpsertDailyReturnSlot';
import UpsertSegment from '../component/Modals/Segments/UpsertSegment';
import CreateFulfillmentJob from '../component/Modals/FulfillmentCenter/CreateFulfillmentJob';
import ShowFulfillmentJob from '../component/Modals/FulfillmentCenter/ShowFulfillmentJob';

// import { UpsertDailyReturnSetGroup } from '../component/Modals/DailyReturn/UpsertDailyRewardSetGroup';
import { GlobalDeleteModal } from '../component/Modals/GlobalModals/GlobalDeleteModal';
import { CreateChainRule } from '../component/Modals/PromoCode/CreateChainRule';
import { useCurrentPartner } from '../hooks/useCurrentPartner';
import { UpsertSetGroup } from '../component/Modals/DailyReturn/UpsertSetGroup';

interface ProtectedRouteProps {
  Component: React.FC<any>;
  path: string;
  exact?: boolean;
  requiredRoles: Array<string>;
  permissions: string[];
  partnerPermissions?: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  Component,
  path,
  exact = false,
  requiredRoles,
  permissions,
  partnerPermissions,
}: ProtectedRouteProps) => {
  const isAuth = useSelector((state: RootState) => state.loginReducer);
  const { currentPartner: themeInfo } = useCurrentPartner();
  const history = useHistory();
  const { loginSuccess, data } = isAuth;
  let userHasRequiredRole = false;

  // debugger;
  if (loginSuccess) {
    userHasRequiredRole = findCommonElement(requiredRoles, [data.role]);
  }
  const message = userHasRequiredRole ? 'Please Login to view this page' : 'You can`t be here!';
  const getPathName = () => {
    if (!loginSuccess) {
      return openRoute.login;
    } else if (themeInfo === null || themeInfo === 'common_theme') {
      toast.error('Please select a Partner');
      return protectedRoutes.partnerLandingPage;
    } else if (userHasRequiredRole) {
      toast.error('You are not authorized to view that page');
      return openRoute.fourOfour;
    } else {
      return openRoute.fourOfour;
    }
  };
  let condition = loginSuccess && userHasRequiredRole && checkPermission(permissions) && themeInfo && themeInfo !== 'common_theme';
  if (partnerPermissions && permissions) {
    condition =
      loginSuccess &&
      userHasRequiredRole &&
      checkCurrentPartnerPermission(partnerPermissions) &&
      checkPermission(permissions) &&
      themeInfo &&
      themeInfo !== 'common_theme';
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) =>
        condition ? (
          <Component {...props} />
        ) : history.location.pathname === '/partnerLandingPage' && loginSuccess ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: getPathName(),
              state: {
                message,
                requestedPath: path,
              },
            }}
          />
        )
      }
    />
  );
};

const AllProtectedRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <Layout>
        <Switch>
          {RouteConfig.map((route) => (
            <ProtectedRoute
              key={route.path}
              exact={true}
              path={path + route.path}
              Component={route.component}
              requiredRoles={route.requiredRoles}
              permissions={route.permissions}
              partnerPermissions={route.partnerPermissions}
            />
          ))}
          <Redirect from="*" to="/not-found" />
        </Switch>
      </Layout>
    </>
  );
};

const Routes = (): ReactElement => {
  return (
    <>
      <Switch>
        <Route path={openRoute.login} exact component={Login} />
        <ProtectedRoute
          path={protectedRoutes.partnerLandingPage}
          exact
          Component={PartnerLandingPage}
          requiredRoles={[userAccess.admin]}
          permissions={[PartnerPermissions.GameTaco]}
        />
        <Route exact path={openRoute.fourOfour} component={PageNotFound} />
        <Route path={'/:partner'} component={AllProtectedRoutes} />
      </Switch>
      <UpsertSetGroup />
      {/*<UpsertDailyReturnSetGroup />*/}
      <CreditAccounts />
      <ActionStepper />
      <CreatePromoCode />
      <SkuCreate />
      <UpsertCurrencyType />
      <CreatePromoType />
      <AllocateSkuModal />
      <UpsertSkipDepositModal />
      <UpsertDailyReturnSet />
      <UpsertDailyReturnSlot />
      <GlobalDeleteModal />
      <CreateChainRule />
      <UpsertSegment />
      <CreateFulfillmentJob />
      <ShowFulfillmentJob />
      <ToastContainer />
      <DeployGoal />
      <SuccessModal />
    </>
  );
};

export default Routes;
