import React, { ReactElement } from 'react';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import { Meta } from '../../interfaces/common.interfaces';
const PREFIX = 'InputWrapper';
const classes = {
  margin: `${PREFIX}-margin`,
  textField: `${PREFIX}-textField`,
};
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.margin}`]: {
    margin: theme.spacing(1),
  },
  [`& .${classes.textField}`]: {
    maxWidth: 'inherit',
    boxSizing: 'border-box',
    display: 'flex',
  },
}));
interface inputProps {
  input: any;
  meta: Meta;
  label: string;
  [key: string]: any;
}

const DateWrapper = ({ input: { name, value, onChange }, meta, ...rest }: inputProps): ReactElement => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error !== '') && meta.touched;
  return (
    <Root>
      <FormControl variant="outlined" fullWidth>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            {...rest}
            onChange={onChange}
            value={value || null}
            renderInput={(params) => <TextField name={name} error={showError} {...params} />}
          />
        </LocalizationProvider>
        {showError && <FormHelperText error={showError}>{meta.error || meta.submitError}</FormHelperText>}
      </FormControl>
    </Root>
  );
};
export default DateWrapper;
