import React, { ReactElement } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/rootReducer';
import { showResetPasswordModalAction } from '../../redux/actions/modalActions/modalActions';
export const ResetPasswordModal = (): ReactElement => {
  const modalData = useSelector((state: RootState) => state.modalReducer.showResetPassword);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(showResetPasswordModalAction(false));
  };
  return (
    <>
      <Dialog open={modalData.show} onClose={handleClose}>
        <DialogContent className="modal-body">
          <Typography variant="h5" align="center">
            {'Reset Password Dummy Model'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} type="button" color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
