import { makeStyles } from '@mui/styles';
import React from 'react';
import { toast } from 'react-toastify';
import { checkPermission } from '../../utils/permission';
import HandymanIcon from '@mui/icons-material/Handyman';
import { SpeedDial, SpeedDialAction, Theme } from '@mui/material';
interface FloatingButtonInterface {
  bulkActions?: {
    action: string;
    onClick: () => void;
    icon: React.ReactNode;
    Permissions: string[];
  }[];
  floatingButtonClick: () => void;
  fabPermissions: string[];
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
  staticTooltipLabel: {
    whiteSpace: 'nowrap',
    backgroundColor: '#aaa',
    color: '#f1f1f1',
  },
  fab: {
    background: theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.secondary.dark,
    },
    color: '#fff',
  },
}));

function FloatingButton(props: FloatingButtonInterface) {
  const { bulkActions, fabPermissions } = props;
  const classes = useStyles();
  return (
    <div>
      <SpeedDial
        ariaLabel="Floating Action Button"
        sx={{ position: 'fixed', bottom: 40, right: 40 }}
        icon={<HandymanIcon />}
        onClick={
          checkPermission(fabPermissions)
            ? () => console.log('Permission Granted')
            : () => toast.error('You are not authorized to perform this action')
        }
      >
        {bulkActions?.map((item, index) => {
          return (
            checkPermission(item.Permissions) && (
              <SpeedDialAction
                key={index}
                icon={item.icon}
                tooltipTitle={item.action}
                tooltipOpen
                classes={classes}
                onClick={() => {
                  item.onClick();
                }}
              />
            )
          );
        })}
      </SpeedDial>
    </div>
  );
}

export default FloatingButton;
