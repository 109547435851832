import { GridCellParams, GridFilterInputValue, GridFilterItem } from '@mui/x-data-grid-pro';
function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
function removeRegExp(value: string): string {
  return value.replace(/[[\]{}()*+?.,\\^$|#\s]/g, '');
}

export const COMMON_FILTER_COMPONENT = [
  {
    label: 'Contains',
    value: 'Contains',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
        return null;
      }

      return (params: GridCellParams): boolean => {
        const searchRegex = new RegExp(escapeRegExp(filterItem.value), 'i');
        console.log(removeRegExp(JSON.stringify(params.value)));
        return searchRegex.test(removeRegExp(JSON.stringify(params.value)));
      };
    },
    InputComponent: GridFilterInputValue,
  },
];
