import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/rootReducer';
import { showGlobalDeleteModalAction } from '../../../redux/actions/modalActions/modalActions';
import { deletePromoCodeData } from '../../../services/promoCode.service';
import { deletePromoType } from '../../../services/promoCodeType.service';
import { toast } from 'react-toastify';
import { deleteCurrencyType } from '../../../services/currencyType.service';
import { deleteSkuData } from '../../../services/sku.services';
import { deletePromoCodeChainRule } from '../../../services/promoCodeChainRule.service';
import InputFieldsTooltip from '../../InputFieldsTooltip';
import { deleteOneOrManyTransactions } from '../../../services/transactions.service';
import { deleteSetData, deleteSetGroupData, deleteSlotData } from '../../../services/dailyReturn.service';
import { deleteSegmentData } from '../../../services/segment.services';

// interface Props {
//   isOpen: boolean;
//   onCloseModel: () => void;
//   title: string;
//   subTitle?: string;
//   actionButtons: { name: string; onClick: () => void }[];
// }

export const GlobalDeleteModal = (): React.ReactElement => {
  const ModalData = useSelector((state: RootState) => state.modalReducer.showGlobalDelete);
  const isOpen = ModalData.show;
  const DeleteData = ModalData.data;
  const dispatch = useDispatch();
  const [deleteLanguage, setDeleteLanguage] = useState<string>('Delete');

  const handleClose = (): void => {
    dispatch(showGlobalDeleteModalAction(false));
  };

  useEffect(() => {
    if (isOpen) {
      if (DeleteData?.deleteModule == 'Segment') {
        setDeleteLanguage('Archive');
      }
    }
  }, [isOpen]);

  const handleDelete = async () => {
    try {
      if (DeleteData?.deleteModule) {
        let api;
        //here case should be same as give in action stepper MainActionData key actionKey
        switch (DeleteData.deleteModule) {
          case 'Promo Code':
            api = deletePromoCodeData({ id: DeleteData.deleteId });
            break;
          case 'Promo Code Type':
            api = deletePromoType({ id: DeleteData.deleteId });
            break;
          case 'Currency Type':
            api = deleteCurrencyType({ id: DeleteData.deleteId });
            break;
          case 'SKU':
            api = deleteSkuData({ id: DeleteData.deleteId });
            break;
          case 'Promo Code Chain Rule':
            api = deletePromoCodeChainRule({ id: DeleteData.deleteId });
            break;
          case 'Transaction':
            api = deleteOneOrManyTransactions({
              transactionIDs: DeleteData.deleteId,
              allFailed: false,
            });
            break;
          case 'Transaction (Failed)':
            api = deleteOneOrManyTransactions({
              transactionIDs: DeleteData.deleteId,
              allFailed: false,
            });
            break;
          case 'Slot':
            api = deleteSlotData({ slotId: DeleteData.deleteId });
            break;
          case 'Set':
            api = deleteSetData({ setId: DeleteData.deleteId });
            break;
          case 'Set Group':
            api = deleteSetGroupData({ setGroupId: DeleteData.deleteId });
            break;
          case 'Segment':
            api = deleteSegmentData({ id: DeleteData.deleteId });
            break;
          default:
            toast.error('Something went wrong');
            break;
        }
        const res = await api;
        if (res.success) {
          toast.success('Item Deleted successfully');
          handleClose();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      style={{
        zIndex: '500',
      }}
    >
      <Dialog open={isOpen} keepMounted onClose={handleClose}>
        <DialogTitle>Would you like to {deleteLanguage.toUpperCase()} the following record?</DialogTitle>
        <DialogContent sx={{ minWidth: '350px' }}>
          <Typography variant="h6">
            {DeleteData?.deleteModule} To {deleteLanguage}
          </Typography>
          <Divider sx={{ my: 0.5 }} />
          <Grid container item xs={12} sx={{ marginTop: '5%' }}>
            <TextField
              fullWidth
              label={<InputFieldsTooltip title="ID" description="ID Description" />}
              id="standard-basic"
              variant="outlined"
              size="small"
              name="userId"
              inputProps={{ readOnly: true }}
              value={`${DeleteData?.item?.value}`}
              type="number"
            />
            <TextField
              fullWidth
              label={<InputFieldsTooltip title="Name" description="Name Description" />}
              inputProps={{ readOnly: true }}
              id="standard-basic"
              variant="outlined"
              size="small"
              sx={{ marginTop: '3%' }}
              name="userId"
              value={`${DeleteData?.item?.label}`}
            />
            {DeleteData?.item?.item?.description && (
              <TextField
                fullWidth
                label={<InputFieldsTooltip title="Description" description="Description" />}
                id="standard-basic"
                inputProps={{ readOnly: true }}
                variant="outlined"
                size="small"
                sx={{ marginTop: '3%' }}
                name="userId"
                value={DeleteData?.item?.item?.description}
              />
            )}
          </Grid>
          <br />
          <Typography variant="h6">Please confirm the item details, once the process has been completed the record cannot be recovered.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" onClick={handleDelete}>
            {deleteLanguage}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
