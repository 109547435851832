import { GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';

export const TRANSACTION_COLUMNS: GridColDef[] = [
  {
    headerName: 'ID',
    field: 'id',
    width: 100,
    description: 'Transaction ID',
  },
  {
    headerName: 'User ID',
    field: 'userId',
    width: 100,
    description: 'User ID',
  },
  {
    headerName: 'Game ID',
    field: 'playedGameId',
    description: 'Game ID',
    width: 100,
  },
  {
    headerName: 'Tournament ID',
    field: 'tournamentId',
    width: 150,
    description: 'Tournament ID',
  },
  {
    headerName: 'Winnings',
    field: 'winnings',
    width: 100,
    description: 'Winning amount',
  },
  {
    headerName: 'Status',
    field: 'status',
    width: 130,
    description: 'Transaction status',
  },
  {
    headerName: 'Created',
    field: 'created_date',
    width: 100,
    description: 'Transaction created date',
    valueFormatter: (params: GridValueFormatterParams) => {
      const valueFormatted = moment(params.value as number)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');
      return valueFormatted.toLocaleString();
    },
  },
  {
    headerName: 'Updated',
    field: 'lastUpdated',
    width: 100,
    description: 'Transaction updated date',
    valueFormatter: (params: GridValueFormatterParams) => {
      const valueFormatted = moment(params.value as number)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');
      return valueFormatted.toLocaleString();
    },
  },
];
