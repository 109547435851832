import { toast } from 'react-toastify';
import axiosInstance from '../utils/apis';
import Moment from 'moment';
import { API_URLS } from '../constants/url';
import { AxiosError } from 'axios';

interface transactionsAPIPayload {
  offset: number;
  limit: number;
  url: string | undefined;
  search?: string;
  searchBy?: string;
  startDate?: string;
  endDate?: string;
  allFailed?: boolean;
  showDeleted?: boolean;
}

interface updateTransStatusPayload {
  transactionIDs: number | string;
  toStatus: string;
}

interface deleteTransPayload {
  transactionIDs?: number | undefined | null;
  allFailed?: boolean;
}

export const getTransactionsData = async (payload: transactionsAPIPayload) => {
  try {
    // startLoader();
    let filterQuery = '';
    const { offset, limit, url, search, searchBy, startDate, endDate, allFailed, showDeleted } = payload;
    let api_url = `${url}?offset=${offset}&limit=${limit}&allFailed=${allFailed}&showDeleted=${showDeleted}`;
    if (searchBy?.length && search?.length) {
      filterQuery = `${searchBy}||=||${search}`;
      api_url += `&filter=${filterQuery}`;
    }
    if (startDate && endDate) {
      const startDateFilter = `transactions.created_at||>=||'${Moment(startDate)}'`;
      const endDateFilter = `transactions.created_at||<=||'${Moment(endDate)}'`;
      api_url += `&filter=${startDateFilter}`;
      api_url += `&filter=${endDateFilter}`;
    }
    const response = await axiosInstance.get(api_url);
    if (response.success) {
      return response;
    }
  } catch (e: unknown | AxiosError) {
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }
  return null;
};

export const deleteOneOrManyTransactions = async (payload: deleteTransPayload) => {
  try {
    const { transactionIDs, allFailed } = payload;
    const api_url = `${API_URLS.DELETE_TRANSACTION}?transactionIDs=${transactionIDs}&allFailed=${allFailed}`;
    const response = await axiosInstance.delete(api_url);
    if (response.success) {
      return response;
    }
  } catch (e: unknown | AxiosError) {
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.errType || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.errType);
    }
  }
  return null;
};

export const rmvDeletedTransaction = async (payload: { id: number }) => {
  try {
    const { id } = payload;
    const response = await axiosInstance.delete(API_URLS.RMV_DELETED_TRANSACTIONS + '?transactionIDs=' + id);
    if (response.success) {
      return response;
    }
  } catch (e: unknown | AxiosError) {
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }
  return null;
};

export const updateTransStatus = async (payload: updateTransStatusPayload) => {
  try {
    const response = await axiosInstance.put(API_URLS.UPDATE_TRANSACTION_STATUS, payload);
    if (response.success) {
      return response;
    }
  } catch (e: unknown | AxiosError) {
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }
  return null;
};

export const undoDeleteTransaction = async (payload: { id: number }) => {
  try {
    const { id } = payload;
    const response = await axiosInstance.put(API_URLS.UNDO_DELETED_TRANSACTIONS + '?transactionIDs=' + id);
    if (response.success) {
      return response;
    }
  } catch (e: unknown | AxiosError) {
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }
  return null;
};
