import { GridColDef } from '@mui/x-data-grid-pro';

export const WITHDRAWALS_LIST_COLUMNS: GridColDef[] = [
  {
    headerName: 'ID',
    field: 'id',
    width: 100,
    description: 'Withdrawal ID',
  },
  {
    headerName: 'Player Name',
    field: 'playerName',
    width: 250,
    description: 'Player Name',
  },
  {
    headerName: 'Amount',
    field: 'amount',
    width: 200,
    description: 'Amount',
  },
  {
    headerName: 'Date',
    field: 'date',
    width: 200,
    description: 'Date',
  },
  {
    headerName: 'Status',
    field: 'status',
    width: 150,
    description: 'Status',
  },
];
