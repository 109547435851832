import makeStyles from '@mui/styles/makeStyles';

const useStylesSkuList = makeStyles(() => ({
  entitlements: {
    width: '100%',
    overflow: 'auto',
    overflowY: 'hidden',
    height: '37px',
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '&::-webkit-scrollbar': {
      height: '2px',
    },
  },
  submitButton: {
    marginTop: '10px',
  },
  entitlementsChip: {
    margin: '11px 2px',
    borderRadius: '2px',
    '& .MuiChip-label': {
      padding: '0px 5px',
    },
  },
}));

export default useStylesSkuList;
