import React, { ReactElement, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import TextField from '@mui/material/TextField';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/rootReducer';
import {
  showCreateSuccessModalAction,
  showCreateTransactionModalAction,
  showUpdateSuccessModalAction,
} from '../../../redux/actions/modalActions/modalActions';
import SelectDropdown from '../../SelectDropdown/SelectDropdown';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import Moment from 'moment';

import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { FilterByMenu } from '../../DataTable/DataTable';
export interface transactionData {
  userId: string | undefined;
  walletReferenceId: string | undefined;
  gameId: string | undefined;
  tournamentId: string | undefined;
  winnings: string | undefined;
  status: FilterByMenu | undefined | any;
}
const CreateTransaction = (): ReactElement => {
  const createTransactionData = useSelector((state: RootState) => state.modalReducer.showCreateTransaction);

  const transData = createTransactionData?.data;

  //Just for testing purpose hardcoded show
  //  createTransactionData.show = true;
  const useStyles = makeStyles({
    divider: {
      backgroundColor: 'black',
      borderBottomWidth: 2,
      marginTop: '25px',
      marginBottom: '25px',
    },

    createButton: {
      backgroundColor: 'rgb(50, 205, 50)',
      color: 'black',
      width: '250px',
      height: '50px',
      textTransform: 'none',
    },

    gridStyle: {
      display: 'flex',
      marginBottom: '20px',
    },

    typographySpacing: {
      marginRight: '20px',
    },
  });

  const classes = useStyles();

  const dispatch = useDispatch();
  const [transactionData, setTransactionData] = React.useState<transactionData>({
    userId: transData?.userId,
    walletReferenceId: transData?.walletReferenceId,
    gameId: transData?.playedGameId,
    tournamentId: transData?.tournamentId,
    winnings: transData?.winnings,
    status: transData?.status,
  });

  const handleInputChange = (option: FilterByMenu | undefined | any) => {
    setTransactionData({
      ...transactionData,
      ['status']: option,
    });
  };

  const menuItems: FilterByMenu[] = [
    { item: 'Pending', columnName: 'pending' },
    { item: 'Success', columnName: 'success' },
    { item: 'Failed', columnName: 'failed' },
  ];

  const handleClose = () => {
    dispatch(showCreateTransactionModalAction(false, null));
    resetData();
  };

  const handleSubmit = () => {
    //Call API here
    if (transData?.id) {
      dispatch(
        showUpdateSuccessModalAction(true, {
          transactionIds: transData.id,
          toStatus: transactionData?.status?.columnName?.toUpperCase(),
        }),
      );
      dispatch(showCreateTransactionModalAction(false, null));
    } else {
      dispatch(showCreateSuccessModalAction(true, null));
      dispatch(showCreateTransactionModalAction(false, null));
    }
    resetData();
  };
  const resetData = () => {
    setTransactionData({
      userId: '',
      walletReferenceId: '',
      gameId: '',
      tournamentId: '',
      winnings: '',
      status: { item: '' },
    });
  };

  useEffect(() => {
    setTransactionData({
      userId: transData?.userId,
      walletReferenceId: transData?.walletReferenceId,
      gameId: transData?.playedGameId,
      tournamentId: transData?.tournamentId,
      winnings: transData?.winnings,
      status: { item: transData?.status },
    });
  }, [createTransactionData.data]);

  return (
    <div>
      <Dialog open={createTransactionData.show} onClose={handleClose} disableRestoreFocus>
        <DialogContent className="modal-body">
          <div className="modal-ct">
            <Typography variant="h5" align="center">
              {transData?.id ? 'Edit' : 'Create'} New Transaction
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right', marginTop: '-40px' }}>
              <CloseIcon />
            </IconButton>
            <Divider className={classes.divider} />
            <Grid container style={{ marginTop: '20px', flexDirection: 'row' }}>
              {transData?.id && (
                <Grid xs={5.7} className={classes.gridStyle} sx={{ marginRight: '2%' }}>
                  <TextField
                    fullWidth
                    label="Transaction ID"
                    id="standard-basic"
                    variant="outlined"
                    value={transData?.id ? transData.id : ''}
                    size="small"
                    name="transactionid"
                    onChange={handleInputChange}
                    type="number"
                  />
                </Grid>
              )}
              <Grid xs={5.7} className={classes.gridStyle} sx={{ marginLeft: '2%' }}>
                <TextField
                  fullWidth
                  label="User ID"
                  id="standard-basic"
                  variant="outlined"
                  value={transData?.id ? transData.userId : ''}
                  size="small"
                  name="userId"
                  onChange={handleInputChange}
                  type="number"
                />
              </Grid>

              <Grid xs={12} className={classes.gridStyle}>
                <TextField
                  fullWidth
                  label="Wallet Reference ID"
                  id="standard-basic"
                  variant="outlined"
                  size="small"
                  name="walletReferenceId"
                  defaultValue={transData?.id ? transData.walletReferenceId : ''}
                />
              </Grid>

              <Grid xs={5.7} className={classes.gridStyle} sx={{ marginRight: '2%' }}>
                <TextField
                  fullWidth
                  label="Game ID"
                  id="standard-basic"
                  variant="outlined"
                  size="small"
                  name="gameId"
                  defaultValue={transData?.id ? transData.playedGameId : ''}
                  onChange={handleInputChange}
                  type="number"
                />
              </Grid>

              <Grid xs={5.7} className={classes.gridStyle} sx={{ marginLeft: '2%' }}>
                <TextField
                  fullWidth
                  label="Tournament ID"
                  id="standard-basic"
                  variant="outlined"
                  size="small"
                  name="tournamentId"
                  defaultValue={transData?.id ? transData.tournamentId : ''}
                  onChange={handleInputChange}
                  type="number"
                />
              </Grid>

              <Grid xs={3.8} className={classes.gridStyle} sx={{ marginRight: '1%' }}>
                <TextField
                  fullWidth
                  label="Winnings"
                  id="standard-basic"
                  variant="outlined"
                  size="small"
                  name="winnings"
                  defaultValue={transData?.id ? transData.winnings : ''}
                  onChange={handleInputChange}
                  type="number"
                />
              </Grid>

              {transData?.id && (
                <Grid xs={3.8} className={classes.gridStyle} sx={{ marginLeft: '1%', marginRight: '1%' }}>
                  <TextField
                    fullWidth
                    label="Created at"
                    id="standard-basic"
                    variant="outlined"
                    size="small"
                    name="created_at"
                    defaultValue={transData?.id ? Moment(transData.created_date).format('YYYY-MM-DD') : ''}
                    onChange={handleInputChange}
                  />
                </Grid>
              )}
              {transData?.id && (
                <Grid xs={3.8} className={classes.gridStyle} sx={{ marginLeft: '1%' }}>
                  <TextField
                    fullWidth
                    label="Updated at"
                    id="standard-basic"
                    variant="outlined"
                    size="small"
                    name="update_at"
                    defaultValue={transData?.id ? Moment(transData.lastUpdated).format('YYYY-MM-DD') : ''}
                    onChange={handleInputChange}
                  />
                </Grid>
              )}
            </Grid>

            {transData?.id && (
              <Grid xs={12} style={{ marginTop: '20px' }}>
                <Box textAlign="center">
                  <TextField
                    disabled
                    id="outlined-disabled"
                    label="Current Status"
                    value={transData?.id ? transData.status : ''}
                    style={{ width: 550, maxWidth: 600 }}
                  />
                </Box>
              </Grid>
            )}
            <Grid xs={12} container style={{ justifyContent: 'center' }}>
              <Box
                sx={{
                  marginTop: '30px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <SelectDropdown
                  handleChange={handleInputChange}
                  searchBy={transactionData.status}
                  menuItems={menuItems}
                  label="Select Status"
                  inputName="status"
                  style={{ width: 550, maxWidth: 600, height: '50px' }}
                />
              </Box>
            </Grid>
            <Box textAlign="center" sx={{ marginTop: '35px' }}>
              <Button variant="contained" onClick={handleSubmit}>
                <b>{transData?.id ? 'Edit Status' : 'Create'}</b>
              </Button>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateTransaction;
