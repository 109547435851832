export const CommunicationPublicRelationPermissions = {
  Create: 'create:comm_pr',
  Read: 'read:comm_pr',
  Update: 'update:comm_pr',
  Delete: 'delete:comm_pr',
};
export const CommonPermissions = {
  Read_Permissions: 'read:permissions',
};
export const DiscoveryPermissions = {
  Create: 'create:discovery',
  Read: 'read:discovery',
  Update: 'update:discovery',
  Delete: 'delete:discovery',
};
export const ExamplePermissions = {
  Create: 'create:example',
  Read: 'read:example',
  Update: 'update:example',
  Delete: 'delete:example',
};
export const GamePermissions = {
  Create: 'create:games',
  Read: 'read:games',
  Update: 'update:games',
  Delete: 'delete:games',
};
export const MarketingPermissions = {
  Create: 'create:marketing',
  Read: 'read:marketing',
  Update: 'update:marketing',
  Delete: 'delete:marketing',
};
export const PartnerPermissions = {
  GameTaco: 'partner:gametaco',
  WorldWinner: 'partner:worldwinner',
  FanDuel: 'partner:fanduel',
  Skillify: 'partner:skillify',
  ExamplePartner: 'partner:example',
};
export const PlatformPermissions = {
  Create: 'create:platform',
  Read: 'read:platform',
  Update: 'update:platform',
  Delete: 'delete:platform',
};
export const PlayerSupportPermissions = {
  Create: 'create:player_support',
  Read: 'read:player_support',
  Update: 'update:player_support',
  Delete: 'delete:player_support',
  Cash: 'cash:player_support',
};
export const SuperUserPermissions = {
  Theme: 'super:theme',
  Localization: 'super:localization',
};
export const TournamentPermissions = {
  Create: 'create:tournaments',
  Read: 'read:tournaments',
  Update: 'update:tournaments',
  Delete: 'delete:tournaments',
  Cash: 'cash:tournaments',
};
export const WithdrawalPermissions = {
  Create: 'create:withdrawals',
  Read: 'read:withdrawals',
  Update: 'update:withdrawals',
  Delete: 'delete:withdrawals',
};
export const GoalPermissions = {
  Create: 'goals:create',
  Read: 'goals:read',
  Update: 'goals:update',
  Delete: 'goals:delete',
};
