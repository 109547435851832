import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((Theme: Theme) => ({
  leftAlignText: {
    textAlign: 'right',
  },
  PaperStyle: {
    padding: '30px 60px',
    border: '2px solid rgba(0,0,0,0.50)',
  },
  HeadingText: {
    margin: '15px 0px',
  },
  Tag: {
    display: 'flex',
    overflow: 'hidden',
  },
  bottomButtonDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: '20px',
    height: '70px',
  },
  bottomButtonDivNone: {
    display: 'none',
  },
  bottomButton: {
    padding: '5px 50px',
  },
  marginTopDiv: {
    marginTop: '30px',
  },
  backdrop: {
    zIndex: Theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default useStyles;
