import React, { ReactElement, useState } from 'react';
import { Form, Field } from 'react-final-form';
import Input from '../../UI/Input';
import { required } from '../../../utils/validations';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, Grid, Paper, TextField, Typography } from '@mui/material';
import { API_URLS } from '../../../constants/url';
import axiosInstance from '../../../utils/apis';
import { useDispatch, useSelector } from 'react-redux';
import { showNotificationModalAction } from '../../../redux/actions/modalActions/modalActions';
import { RootState } from '../../../redux/reducers/rootReducer';
import { startLoader, stopLoader } from '../../../utils/utils';
import { toast } from 'react-toastify';

interface ModalPropTypes {
  handleNotificationSubmit?: (value: notificationInterface, allUsersFlag: boolean) => void;
  allUsersFlag: boolean;
  show?: boolean;
}

interface OptionItem {
  userName: string;
  id: number;
}

interface notificationInterface {
  allUsersFlag: boolean;
  title: string;
  message: string;
  userList: [];
}

interface userInterface {
  id?: number;
}

const NotificationModal = ({ allUsersFlag }: ModalPropTypes): ReactElement => {
  const [dropdownList, setDropdownList] = useState<OptionItem[]>([]);
  const [busySpinner, setBusySpinner] = useState(false);
  const notificationData = useSelector((state: RootState) => state.modalReducer.showNotification);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(showNotificationModalAction(false, null));
  };

  const handleNotificationSubmit = (value: notificationInterface) => {
    let params = {};
    let users: (number | undefined)[] = [];

    if (!value.allUsersFlag) {
      users = value.userList.map((user: userInterface) => {
        return user.id;
      });
    }

    params = {
      title: value.title,
      message: value.message,
      type: 'IN_GAME',
      users: users,
      usersBetweenDates: false,
      startDate: null,
      allUsers: value.allUsersFlag.toString(),
      endDate: null,
    };

    startLoader();
    axiosInstance
      .post(API_URLS.NOTIFICATION, params)
      .then(() => {
        toast.success('Notification sent!');
        stopLoader();
      })
      .catch(() => {
        toast.error('Something went wrong while sending the notification');
        stopLoader();
      });
    handleClose();
  };

  const searchHandler = (search: string) => {
    setBusySpinner(true);
    const url = `${API_URLS.SEARCH_USER}${search}`;

    axiosInstance
      .get(url)
      .then((data) => {
        setDropdownList(data?.results);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setBusySpinner(false);
      });
  };

  return (
    <>
      <Dialog open={notificationData.show} onClose={handleClose}>
        <Form
          onSubmit={handleNotificationSubmit}
          initialValues={{
            userList: notificationData.data,
            allUsersFlag: allUsersFlag,
          }}
        >
          {({ handleSubmit }) => (
            <>
              <DialogContent className="modal-body">
                <div className="modal-ct">
                  <Paper elevation={0}>
                    <Typography variant="h5" align="center">
                      Send Notification
                    </Typography>
                    {!allUsersFlag && (
                      <Typography variant="body1" align="center">
                        Please select the user(s) to whom you want to send the notification
                      </Typography>
                    )}
                  </Paper>

                  <form onSubmit={handleSubmit}>
                    <Grid container sx={{ mt: '10px' }}>
                      <Grid item lg={12}>
                        {!allUsersFlag && (
                          <Field name="userList">
                            {({ input }) => {
                              return (
                                <>
                                  <Typography variant="body1">Select users</Typography>
                                  <Autocomplete
                                    multiple
                                    id="MultiSelect"
                                    options={dropdownList}
                                    value={input.value}
                                    loading={busySpinner}
                                    isOptionEqualToValue={(option: OptionItem, value) => option.id === value.id}
                                    getOptionLabel={(option: OptionItem) => option.userName}
                                    onChange={(event, value) => {
                                      input.onChange(value);
                                    }}
                                    onInputChange={(e, value) => {
                                      searchHandler(value);
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} variant="outlined" label="Users" name={input.name} placeholder="Search Users" />
                                    )}
                                    sx={{
                                      margin: '8px',
                                    }}
                                  />
                                </>
                              );
                            }}
                          </Field>
                        )}
                      </Grid>

                      <Grid item lg={12}>
                        <Field name="title" type="text" placeholder="TITLE" component={Input} validate={required} />
                      </Grid>
                      <Grid item lg={12}>
                        <Field name="message" placeholder="MESSAGE" multiline rows={4} component={Input} validate={required} />
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </DialogContent>
              <DialogActions className="modal-footer btn-align">
                <Button type="button" color="primary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" color="primary" onClick={handleSubmit}>
                  Send
                </Button>
              </DialogActions>
            </>
          )}
        </Form>
      </Dialog>
    </>
  );
};

export default NotificationModal;
