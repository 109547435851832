import { toast } from 'react-toastify';
import axiosInstance from '../utils/apis';
import { AxiosError } from 'axios';
interface favoritesAPIPayload {
  offset?: number;
  limit?: number;
  url?: string | undefined;
  search?: string;
  searchBy?: string;
  startDate?: Date;
  goalStatus?: string;
  endDate?: Date;
}

export const getGoalsData = async (payload: favoritesAPIPayload) => {
  try {
    const { offset, limit, url, search, searchBy, startDate, endDate, goalStatus } = payload;

    let isActive = false;
    let isExpired = false;
    let isComplete = false;
    let isUsername = false;
    let id: string | undefined = '';

    if (searchBy == 'Username') {
      isUsername = true;
    } else if (searchBy == 'ID') {
      id = search;
    }

    switch (goalStatus) {
      case 'All':
        isActive = true;
        isExpired = true;
        isComplete = true;
        break;
      case 'Active':
        isActive = true;
        isExpired = false;
        isComplete = false;
        break;
      case 'Expired':
        isActive = false;
        isExpired = true;
        isComplete = false;
        break;
      case 'Completed':
        isActive = false;
        isExpired = false;
        isComplete = true;
        break;
    }
    const api_url = `${url}?offset=${offset}&limit=${limit}&search=${search}&searchBy=${searchBy}&startDate=${startDate}&endDate=${endDate}&isUsername=${isUsername}&id=${id}&isActive=${isActive}&isExpired=${isExpired}&isComplete=${isComplete}`;
    const response = await axiosInstance.get(api_url);
    if (response.success) {
      return response;
    } else {
      toast.error(response.message);
    }
  } catch (e: unknown | AxiosError) {
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error(e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};
