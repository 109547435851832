import { START_LOADER, STOP_LOADER } from '../actions/type';
import { Reducer } from 'redux';
import { COMMON_ACTIONS } from '../actions/type';

interface State {
  loading: boolean;
  favoritesData: Array<{ name: string; title: string; link: string }>;
  topNavTools: Array<string>;
  startDateSelected: Date;
  endDateSelected: Date;
  unfitTools: Array<string>;
  fitTools: Array<string>;
}
const todaysDate = new Date();
const prevMonthDate = new Date(new Date().setDate(new Date().getDate() - 30));
const initialState: State = {
  loading: false,
  favoritesData: [],
  topNavTools: [],
  startDateSelected: prevMonthDate,
  endDateSelected: todaysDate,
  unfitTools: [],
  fitTools: [],
};

export const commonReducers: Reducer<State> = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case START_LOADER:
      return { ...state, loading: true };
    case STOP_LOADER:
      return { ...state, loading: false };
    case COMMON_ACTIONS.START_DATE_SELECTED:
      return { ...state, startDateSelected: payload };
    case COMMON_ACTIONS.END_DATE_SELECTED:
      return { ...state, endDateSelected: payload };
    case COMMON_ACTIONS.ADD_FAVORITES_DATA:
      return { ...state, favoritesData: [...state.favoritesData, payload] };
    case COMMON_ACTIONS.CLEAR_FAVORITES_DATA:
      return { ...state, favoritesData: [] };
    case COMMON_ACTIONS.REMOVE_FAVORITES_DATA:
      return {
        ...state,
        favoritesData: state.favoritesData.filter((item) => item.name !== payload),
      };

    case COMMON_ACTIONS.TOPNAV_TOOLS: {
      const copyPayload = [...payload];
      state.fitTools = payload;
      if (payload.length > 4) {
        state.fitTools = copyPayload.splice(0, 4);
        state.unfitTools = copyPayload;
      }
      return {
        ...state,
        topNavTools: payload,
        fitTools: state.fitTools,
        unfitTools: copyPayload,
      };
    }
    default:
      return state;
  }
};
