import { Box, Card, CardContent, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { Fragment, ReactElement, useMemo, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { KeyboardArrowDown } from '@mui/icons-material';
// Activity item

import ActivityItem from './ActivityItem';
import { SnakePageLoader } from '../../component/Loader/SnakePageLoader/SnakePageLoader';
const PREFIX = 'Discovery';

const classes = {
  subHeader: `${PREFIX}-subHeader`,
  header: `${PREFIX}-header`,
  greenback: `${PREFIX}-greenback`,
  redBack: `${PREFIX}-redBack`,
  bottomContent: `${PREFIX}-bottomContent`,
  activityItem: `${PREFIX}-activityItem`,
  greenLightBack: `${PREFIX}-greenLightBack`,
  redLightBack: `${PREFIX}-redLightBack`,
  dataGridBoarder: `${PREFIX}-dataGridBoarder`,
  actionDetect: `${PREFIX}-actionDetect`,
  activityModel: `${PREFIX}-activityModel`,
  loadingContainer: `${PREFIX}-loadingContainer`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.subHeader}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiSvgIcon-root': {
      fontSize: '2rem',
    },
  },

  [`& .${classes.header}`]: {
    padding: '1rem 1.5rem',
    marginTop: '1rem',
    borderRadius: '0.25rem',
    fontSize: '1.5rem',
    cursor: 'pointer',
  },

  [`& .${classes.greenback}`]: {
    backgroundColor: '#00e676',
  },

  [`& .${classes.redBack}`]: {
    backgroundColor: '#ef5350',
  },

  [`& .${classes.bottomContent}`]: {
    padding: '1rem 1.5rem !important',
    marginTop: '1rem',
    borderRadius: '0.25rem',
  },

  [`& .${classes.activityItem}`]: {
    padding: '1rem',
    lineHeight: '1.7rem',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    color: '#626262',
  },

  [`& .${classes.greenLightBack}`]: {
    backgroundColor: '#c8e6c9 !important',
  },

  [`& .${classes.redLightBack}`]: {
    backgroundColor: '#ffcdd2 !important',
  },

  [`& .${classes.dataGridBoarder}`]: {
    '& .MuiDataGrid-root': {
      border: '1px solid #bcb7b769',
      '&  .MuiDataGrid-cell': {
        border: '1px solid #bcb7b769',
      },
    },
  },

  [`& .${classes.actionDetect}`]: {
    position: 'fixed',
    width: '100%',
    left: '0',
    bottom: '0',
    right: '0',
    top: '0',
    backgroundColor: '#172b4dd9',
    zIndex: 9999,
  },

  [`& .${classes.activityModel}`]: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    minHeight: 'calc(100% - 1rem)',
    width: 'auto',
    margin: '1.75rem auto',
    maxWidth: '500px',
  },

  [`& .${classes.loadingContainer}`]: {
    padding: '1.25rem',
    flex: '1 1 auto',
    textAlign: 'center',
    backgroundColor: '#2979ff',
    borderRadius: '0.25rem',
  },
}));

interface loaderType {
  [key: string]: boolean;
}
interface ItemType {
  id: string;
  title: string;
  success: boolean;
}
const Discovery = (): ReactElement => {
  const [collapseID, setCollapseID] = useState('collapse1');
  const activities: ItemType[] = [
    {
      id: 'activity1',
      title: 'Transaction Logs',
      success: true,
    },
    {
      id: 'activity2',
      title: 'Players Deposits',
      success: false,
    },
    {
      id: 'activity3',
      title: 'User Activity',
      success: true,
    },
  ];
  const [loadingIndex, setLoadingIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState<loaderType>({});
  const [loadError, setLoadError] = useState(false);
  const [loadSuccess, setLoadSuccess] = useState(false);
  const toggleCollapse = async (nextID: string) => {
    if (collapseID !== nextID) {
      setTimeout(() => setLoader({ ...loader, [nextID]: false }));
    }
    setCollapseID(collapseID !== nextID ? nextID : '');
    return;
  };

  // It will be executed before rendering

  useMemo(() => {
    setTimeout(() => setLoadingIndex(1), 1000);
    setTimeout(() => setLoadingIndex(2), 2000);
    setTimeout(() => setLoading(false), 3000);
    const success = activities.find((item) => item.success === false);
    if (success) {
      setLoadError(true);
    } else {
      setLoadSuccess(true);
    }
  }, []);

  // Render activities
  const renderActivities = () => {
    const views = activities.map((item, index) => {
      return (
        <Fragment key={index}>
          {index <= loadingIndex && (
            <Paper key={index} elevation={0}>
              <Box onClick={() => toggleCollapse(item.id)} className={`${classes.header} ${item.success ? classes.greenback : classes.redBack}`}>
                <div className={classes.subHeader}>
                  <Typography variant="h6">{item.title}</Typography>
                  {collapseID === item.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDown />}
                </div>
              </Box>

              {loader?.[item.id] === false ? <ActivityItem isOpen={collapseID === item.id ? true : false} item={item} /> : null}
            </Paper>
          )}
        </Fragment>
      );
    });

    return views;
  };
  return (
    <>
      <Root>
        <Paper elevation={0}>{renderActivities()}</Paper>
        {loading && (
          <div
            style={{
              position: 'fixed',
              width: '100%',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: 9999,
            }}
          >
            <SnakePageLoader loadingText="Verifying Permissions..." />
          </div>
        )}

        {loadError && loadingIndex === 2 && (
          <Card elevation={0}>
            <CardContent className={classes.bottomContent}>
              <Typography variant="body1" align="center">
                Suspicious patterns discovered!
              </Typography>
            </CardContent>
          </Card>
        )}
        {loadSuccess && loadingIndex === 2 && (
          <Card elevation={0}>
            <CardContent className={classes.bottomContent}>No Suspicious patterns discovered!</CardContent>
          </Card>
        )}
      </Root>
    </>
  );
};

export default Discovery;
