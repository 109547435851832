import moment, { Moment } from 'moment';
export function timeConvert(n: number) {
  const numOfMinutes = n;
  const time = moment.duration(numOfMinutes, 'minutes');
  const noOfDays = time.days();
  const noOfHours = time.hours();
  const noOfMinutes = time.minutes();
  let duration = '';
  if (noOfDays) {
    duration += time.days() + ' day(s) ';
  }
  if (noOfHours) {
    duration += time.hours() + ' hour(s) ';
  }
  if (noOfMinutes) {
    duration += time.minutes() + ' minute(s) ';
  }
  return duration;
}

export function estToUtc(date: number) {
  const estDate = moment.tz(moment(date).format('YYYY-MM-DD HH:mm:ss'), 'America/New_York').format();
  const utcDate = moment.utc(estDate).toDate();
  return utcDate;
}

export function utcToEst(date: Moment) {
  const test = moment.utc(date.format());
  const serverDate = test.tz('America/New_York').toDate();
  return serverDate;
}
