import { Button, Tooltip } from '@mui/material';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { GridColDef, GridCellParams } from '@mui/x-data-grid-pro';
import React, { Fragment, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { API_URLS } from '../../constants/url';
import DataTable, { fetchData, IDataGridRef } from '../../component/DataTable/DataTable';
import { getCurrencyTypeList } from '../../services/currencyType.service';
import { useDispatch, useSelector } from 'react-redux';
import { showGlobalDeleteModalAction, showUpsertCurrencyTypeModalAction } from '../../redux/actions/modalActions/modalActions';
import { RootState } from '../../redux/reducers/rootReducer';
import AddIcon from '@mui/icons-material/Add';
import { MarketingPermissions } from '../../constants/permission';
import FloatingButton from '../../component/FloatingButton/FloatingButton';
import CommonBreadcrumbs from '../../component/BreadCrumb/BreadCrumb';
import { breadCrumbsKey } from '../../constants/breadCrumbConfig';

interface ICurrencyItem {
  currencyTypeId: number;
  name: string;
  description: string;
}

interface ICurrencyRow {
  id: number;
  name: string;
  description: string;
}

export interface IDeleteData {
  deleteId?: number;
  deleteModule?: string;
  item?: {
    value?: number;
    label?: string;
    item?: {
      description?: string;
    };
  };
}

const CurrencyType = () => {
  const dispatch = useDispatch();
  const DataGridRef = useRef<IDataGridRef>(null);

  const globalDeleteModalData = useSelector((state: RootState) => state.modalReducer.showGlobalDelete);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', description: 'Record ID on server' },
    {
      field: 'name',
      headerName: 'Currency Type Name',
      description: 'Computer-friendly name for currency; please use all lowercase letters. ',
      flex: 2,
    },
    {
      field: 'description',
      headerName: 'Description',
      description: 'Human-friendly description of the currency type.',
      flex: 3,
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      description: 'Action',
      flex: 2,
      renderCell: (params: GridCellParams) => (
        <Fragment>
          <Tooltip title="Edit">
            <Button
              size="small"
              style={{ marginLeft: 16 }}
              onClick={() => {
                handleEdit(params.row);
              }}
            >
              <Edit color="secondary" />
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              size="small"
              style={{ marginLeft: 16 }}
              onClick={() => {
                handleDelete(params.row);
              }}
            >
              <DeleteOutline color="error" />
            </Button>
          </Tooltip>
        </Fragment>
      ),
    },
  ];

  const handleDoubleClick = (e: any) => {
    dispatch(showUpsertCurrencyTypeModalAction(true, e.row, false));
  };

  const floatingButtonClick = () => {
    // dispatch(
    //   showUpsertCurrencyTypeModalAction(true, { name: '', description: '' }),
    // );
  };

  const fetchData: fetchData<
    | {
        data: {
          id: number;
          name: string;
          description: string;
          action: number;
        }[];
        totalRows: number;
      }
    | undefined
  > = async () => {
    try {
      const res = await getCurrencyTypeList({
        url: API_URLS.GET_CURRENCY_TYPE,
      });
      const data = res?.data?.map((item: ICurrencyItem) => {
        return {
          id: item.currencyTypeId,
          name: item.name,
          description: item.description,
          action: item.currencyTypeId,
        };
      });
      return {
        data: data || [],
        totalRows: res?.totalRows,
      };
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        toast.error(e.message);
      }
    }
  };

  const handleEdit = async (row: ICurrencyRow) => {
    try {
      dispatch(showUpsertCurrencyTypeModalAction(true, row));
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  const handleDelete = async (row: ICurrencyRow) => {
    try {
      const deleteData: IDeleteData = {};
      deleteData.deleteModule = 'Currency Type';
      deleteData.deleteId = row.id;
      deleteData.item = {};
      deleteData.item.value = row.id;
      deleteData.item.label = row.description;
      deleteData.item.item = {};
      deleteData.item.item.description = row.description;
      dispatch(showGlobalDeleteModalAction(true, deleteData));
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  const createSuccessData = useSelector((state: RootState) => state.modalReducer.showCreateSuccess);

  useEffect(() => {
    DataGridRef.current?.fetchData();
  }, [createSuccessData.data]);

  useEffect(() => {
    if (!globalDeleteModalData.show) {
      DataGridRef.current?.fetchData();
    }
  }, [globalDeleteModalData.show]);

  const bulkActions = [
    {
      action: 'Create Currency Type',
      onClick: () => {
        dispatch(
          showUpsertCurrencyTypeModalAction(true, {
            name: '',
            description: '',
          }),
        );
      },
      icon: <AddIcon />,
      Permissions: [MarketingPermissions.Create],
    },
  ];

  return (
    <>
      <CommonBreadcrumbs title="Currency Type" breadCrumbKey={breadCrumbsKey.CurrencyType} />
      <DataTable columns={columns} fetchData={fetchData} ref={DataGridRef} isInfiniteScroll enableSearch handleDoubleClick={handleDoubleClick} />

      <FloatingButton bulkActions={bulkActions} floatingButtonClick={floatingButtonClick} fabPermissions={[MarketingPermissions.Create]} />
      {/* <UpsertCurrencyType /> */}
    </>
  );
};

export default CurrencyType;
