const drawerWidth = 260;
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
const PREFIX = 'Routes';

export const classes = {
  root: `${PREFIX}-root`,
  drawer: `${PREFIX}-drawer`,
  datePicker: `${PREFIX}-datePicker`,
  closeIcon: `${PREFIX}-closeIcon`,
  closeIconWhenOen: `${PREFIX}-closeIconWhenOen`,
};

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
  },
  [`& .${classes.drawer}`]: {
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      background: theme.palette.primary.main,
      color: '#fff ',
    },
  },

  [`& .${classes.datePicker}`]: {
    width: '0px',
    marginRight: '10px',
  },
  [`& .${classes.closeIcon}`]: {
    position: 'fixed',
    top: '20px',
    left: '45px',
    zIndex: '1200',
    fontSize: '1.2rem',
    visibility: 'hidden',
    padding: '2px',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  [`& .${classes.closeIconWhenOen}`]: {
    position: 'fixed',
    top: '20px',
    left: '245px',
    zIndex: '1200',
    fontSize: '1.2rem',
    visibility: 'hidden',
    padding: '2px',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  display: 'block',
  overflowX: 'hidden',
  overflowY: 'auto',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar': {
    width: '4px',
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
  overflowX: 'visible',
  overflowY: 'visible',
  '& .MuiCollapse-root': {
    whiteSpace: 'nowrap',
    position: 'absolute',
    left: '100%',
    top: 0,
    color: '#fff',
    marginLeft: '2px',
    background: theme.palette.primary.main,
    borderRadius: '5px',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar': {
    width: '4px',
  },
});

export const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up('sm')]: {
    marginLeft: '57px',
  },
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      marginLeft: '260px',
    },
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  background: theme.palette.primary.main,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'calc(100% - 57px)',
    marginLeft: '57px',
  },
  paddingRight: '0px !important',
  ...(open && {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: `${drawerWidth}px`,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  '&:hover': {
    [`& .${open ? classes.closeIconWhenOen : classes.closeIcon} `]: {
      visibility: 'visible',
      transition: '0.5s ease-in-out',
    },
  },

  position: 'fixed',
  zIndex: 9999,
  top: 0,
  left: 0,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  position: 'relative',
  overflow: 'visible',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...(theme.mixins.toolbar as any),
}));
