import React, { ReactElement } from 'react';

const PlayerPatterns = (): ReactElement => {
  return (
    <div>
      <h1> Player Pattern </h1>
    </div>
  );
};

export default PlayerPatterns;
