import FloatingButton from '../../component/FloatingButton/FloatingButton';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable, { IDataGridRef } from '../../component/DataTable/DataTable';
import { PlatformPermissions } from '../../constants/permission';
import { showCreateSegmentFulfillmentModalAction, showSegmentFulfillmentJobModalAction } from '../../redux/actions/modalActions/modalActions';
import AddIcon from '@mui/icons-material/Add';
import { MarketingPermissions } from '../../constants/permission';
import { RootState } from '../../redux/reducers/rootReducer';
import CommonBreadcrumbs from '../../component/BreadCrumb/BreadCrumb';
import { FULFILLMENT_CENTER_LIST_COLUMN } from '../../constants/columns/fulfillmentCenter.constants';
import { useCurrentPartner } from '../../hooks/useCurrentPartner';
import { breadCrumbsKey } from '../../constants/breadCrumbConfig';
import { GridColDef } from '@mui/x-data-grid-pro';
import { DataResponseType } from '../PromoCodeChainRule/PromoCodeChainRule';

import { getFullfillmentJobList } from '../../services/fulfillment.service';

interface FulfillmentJobResponse {
  id: number;
  start_at: string;
  end_at: string;
  user_count: number;
  tags: number[];
  tagLabels: string[];
  action: string;
  sf: string;
  status: string;
  created_at: string;
  updated_at: string;
  created_by: string;
}

const TagList = () => {
  const dispatch = useDispatch();
  const { partnerShortName } = useCurrentPartner();
  // const showFulfillmentData = useSelector((state: RootState) => state.modalReducer.showFulfillmentJob);
  // const allocateSegmentData = useSelector((state: RootState) => state.modalReducer.showAllocateSegment);
  const globalDeleteModalData = useSelector((state: RootState) => state.modalReducer.showGlobalDelete);
  const dataGridRef = useRef<IDataGridRef>(null);
  const TAG_SEGMENT_COLUMN_DATA = FULFILLMENT_CENTER_LIST_COLUMN({ tags: 'Test Tag' });
  const columns = useMemo<GridColDef[]>(() => {
    return [...TAG_SEGMENT_COLUMN_DATA];
  }, [TAG_SEGMENT_COLUMN_DATA]);

  const handleCreateModel = () => {
    console.log('FAB Clicked');
  };

  const handleDoubleClick = (e: any) => {
    console.log('Double Clicked a Record');
    dispatch(showSegmentFulfillmentJobModalAction(true, e.row));
  };

  const bulkActions = [
    {
      action: 'Allocate Tags',
      onClick: () => {
        console.log('Create Tag Clicked');
        dispatch(showCreateSegmentFulfillmentModalAction(true, null));
      },
      icon: <AddIcon />,
      Permissions: [MarketingPermissions.Create],
    },
  ];

  useEffect(() => {
    if (!globalDeleteModalData.show) {
      dataGridRef.current?.fetchData();
    }
  }, [globalDeleteModalData.show]);

  const fetchData = async () => {
    try {
      const res: DataResponseType<FulfillmentJobResponse> = await getFullfillmentJobList({
        partnerId: partnerShortName,
      });

      console.log({ res });

      const data = res?.data?.map((item: FulfillmentJobResponse) => {
        return {
          id: item.id,
          start: item.start_at,
          end: item.end_at,
          user_count: item.user_count,
          tags: item.tagLabels,
          action: item.action,
          success_fails: item.sf,
          status: item.status,
          created: item.updated_at,
          updated: item.updated_at,
          createdBy: item.created_by,
        };
      });

      return {
        data: data || [],
        totalRows: res?.totalRows,
      };
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  // useEffect(() => {
  //   if (!upsertSegmentData.show && upsertSegmentData?.data?.success) {
  //     dataGridRef.current?.fetchData();
  //   }
  // }, [upsertSegmentData.show]);

  return (
    <>
      <CommonBreadcrumbs title="Fulfillment Center" breadCrumbKey={breadCrumbsKey.fulfillmentCenter} />
      <DataTable
        columns={columns}
        fetchData={fetchData}
        ref={dataGridRef}
        hideFooter
        isInfiniteScroll
        enableSearch
        handleDoubleClick={handleDoubleClick}
        isGetRowClassName={true}
      />
      <FloatingButton bulkActions={bulkActions} floatingButtonClick={handleCreateModel} fabPermissions={[PlatformPermissions.Create]} />
    </>
  );
};

export default TagList;
