import axios from 'axios';
import { toast } from 'react-toastify';

const axiosInstance = axios.create({
  timeout: 40000, // request timeout
});

// request interceptor
axiosInstance.interceptors.request.use(
  (config: any) => {
    // do something before request is sent
    const userData = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const token = userData?.token;
    // const adminSubId = localStorage.getItem('adminSubID');
    config.headers['Content-Type'] = 'application/json';
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}` || '';
      // config.headers['AdminSubId'] = adminSubId;
    }
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  },
);

// response interceptor
axiosInstance.interceptors.response.use(
  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    if (response.data && response.data.error) {
      return Promise.reject(response);
    }

    return response.data;
  },
  (error) => {
    // 401: redirect to login
    const userData = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const token = userData?.token;
    if (error?.response?.status === 401 && token) {
      localStorage.removeItem('currentUser');
      window.location.href = '/';
      toast.error('Session expired');
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;

interface IHeader {
  [key: string]: string;
}
export function getHeaders(headers: Array<IHeader>): IHeader {
  const header: IHeader = {};
  headers.forEach((value: IHeader) => {
    header[Object.keys(value)[0]] = value[Object.keys(value)[0]];
  });
  return header;
}
