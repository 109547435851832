import { GridColDef } from '@mui/x-data-grid-pro';

export const DEPOSITS_COLUMNS = (columnClass: any): GridColDef[] => {
  console.log(columnClass);
  return [
    {
      headerName: 'ID',
      field: 'id',
      width: 100,
      description: 'The Internal Transaction ID for the Deposit.',
    },

    {
      headerName: 'Brain Tree ID',
      field: 'braintreeId',
      width: 250,
      description: 'The Brain Tree Transaction ID for the Deposit.',
    },
    {
      headerName: 'SKU ID',
      field: 'skuId',
      width: 200,
      description: 'The ID for the SKU offer purchase by the Player for this Deposit Transaction.',
    },
    {
      headerName: 'Remaining Balance',
      field: 'remainingBalance',
      width: 150,
      description: 'The Balance available to be considered in the daily Asyn Withdrawal Cycle.',
    },
    {
      headerName: 'Skipped ?',
      field: 'isSkippedTransaction',
      width: 150,
      description: 'The Fraud team is able to skip Deposit Transactions from being used in Async Withdrawal Calculations',
    },
    {
      headerName: 'Reason',
      field: 'isSkippedReason',
      width: 150,
      description: 'The reason why the Fraud team has choosen to mark this transaction as skippable during the Async Withdrawal Calculations.',
    },
  ];
};
