export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';

export const DEFAULT_VAL = {
  loading: false,
  data: undefined,
  success: false,
};

export const DATA_DEFAULT = {
  success: false,
  signon_token: '',
  gameId: null,
  gameName: '',
  userRedeemCount: null,
  userId: null,
  userName: '',
  mail: '',
  sub: '',
  funds: '',
  bonus_cash: '',
  token: '',
  avatar: '',
  ticket: null,
  remainingClaim: null,
  login_count: {
    nextTokenAmount: null,
    nextTokenTime: null,
  },
  gToken: null,
  msg: '',
  guest: null,
  age_verification_provider: null,
  age_verification_timestamp: null,
  role: '',
  permissions: [],
};

export const LOGIN_DEFAULT = {
  loading: false,
  data: DATA_DEFAULT,
  success: false,
  loginSuccess: false,
  errMsg: '',
};

export const LOADING_STATE = {
  loading: false,
};

export const COMMON_ACTIONS = {
  START_DATE_SELECTED: 'START_DATE_SELECTED',
  END_DATE_SELECTED: 'END_DATE_SELECTED',
  ADD_FAVORITES_DATA: 'ADD_FAVORITES_DATA',
  REMOVE_FAVORITES_DATA: 'REMOVE_FAVORITES_DATA',
  TOPNAV_TOOLS: 'TOPNAV_TOOLS',
  EXTRA_TOPNAV_TOOLS: 'EXTRA_TOPNAV_TOOLS',
  CALL_FAVORITES_API: 'CALL_FAVORITES_API',
  CLEAR_FAVORITES_DATA: 'CLEAR_FAVORITES_DATA',
  CURRENT_TOOL_SELECTED: 'CURRENT_TOOL_SELECTED',
};
export const MODAL_STATE = {
  showUserAction: {
    show: false,
    data: null,
  },
  showNotification: {
    show: false,
    data: null,
  },
  showAddCredit: {
    show: false,
    data: null,
  },
  showUserUpdate: {
    show: false,
    data: null,
  },
  showContact: {
    show: false,
    data: null,
  },
  showResetPassword: {
    show: false,
    data: null,
  },
  showAvatars: {
    show: false,
    data: null,
  },
  showCloseAccount: {
    show: false,
    data: null,
  },
  showMergeAccount: {
    show: false,
    data: null,
  },
  showUpdateTransaction: {
    show: false,
    data: null,
  },
  showUpsertCurrencyType: {
    show: false,
    data: null,
  },
  showUpsertSkipDeposit: {
    show: false,
    data: null,
  },
  showUpdateSuccess: {
    show: false,
    data: null,
  },
  showDeleteTransaction: {
    show: false,
    data: null,
  },
  showRemoveTransaction: {
    show: false,
    data: null,
  },
  showUndoDeleteTransaction: {
    show: false,
    data: null,
  },
  showCreateTransaction: {
    show: false,
    data: null,
  },
  showCreateSuccess: {
    show: false,
    data: null,
  },
  showUpdateFailed: {
    show: false,
    data: {
      trnIDs: [
        23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 23, 24, 25, 26, 27,
        28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
        33, 34, 35, 36, 37, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
        23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
      ],
    },
  },
  showCreatePromoCode: {
    show: false,
    data: null,
  },
  showCreatePromoType: {
    show: false,
    data: null,
  },
  showCreateSku: {
    show: false,
    data: null,
  },
  showAllocateSku: {
    show: false,
    data: null,
  },
  showActionStepper: {
    show: false,
    data: null,
  },
  showGlobalDelete: {
    show: false,
    data: null,
  },
  showChainRule: {
    show: false,
    data: null,
  },
  showDeployGoal: {
    show: false,
    data: null,
  },
  showSuccess: {
    show: false,
    data: null,
  },
  showGoalList: {
    show: false,
    data: null,
  },
  showDailyReturnSet: {
    show: false,
    data: null,
  },
  showDailyReturnSetGroup: {
    show: false,
    data: null,
  },
  showDailyReturnSlot: {
    show: false,
    data: null,
  },
  showCreateSegment: {
    show: false,
    data: null,
  },
  showCreateSegmentFulfillmentJob: {
    show: false,
    data: null,
  },
  showSegmentFulfillmentJob: {
    show: false,
    data: null,
  },
};
