import { combineReducers } from 'redux';
import { loginReducer } from './authReducer';
import { commonReducers } from './commonReducers';
import { prizeReedemReducer, prizeReedemCancelReducer, prizeReedemConfirmReducer } from './prizeReedemReducer';
import { playerReducer } from '../reducers/PlayerActionReducers';
import { modalReducer } from './ModalReducer';

const rootReducer = combineReducers({
  loginReducer: loginReducer,
  commonReducers: commonReducers,
  prizeReedemReducer: prizeReedemReducer,
  prizeReedemCancelReducer: prizeReedemCancelReducer,
  prizeReedemConfirmReducer: prizeReedemConfirmReducer,
  playerReducer: playerReducer,
  modalReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
