import React, { ReactElement, useMemo, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/rootReducer';
import { showSegmentFulfillmentJobModalAction } from '../../../redux/actions/modalActions/modalActions';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';

import { Form, Field } from 'react-final-form';
import InputWrapper from '../../../component/UI/Input';
import { toast } from 'react-toastify';
import { FetchFulfillmentData, getFullfillmentJobById } from '../../../services/fulfillment.service';
import { composeValidators, required, maxLength } from '../../../utils/validations';
import InputFieldsTooltip from '../../InputFieldsTooltip';
import DataTable, { IDataGridRef } from '../../DataTable/DataTable';
import { FULFILLMENT_JOB_LOGS_COLUMN } from '../../../constants/columns/fulfillmentJobLogs.constants';
import { GridColDef } from '@mui/x-data-grid-pro';
import CircularProgress from '@mui/material/CircularProgress';
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone';
import WarningAmberTwoToneIcon from '@mui/icons-material/WarningAmberTwoTone';
import PriorityHighTwoToneIcon from '@mui/icons-material/PriorityHighTwoTone';
import moment from 'moment';

const handleStatusIcon = (status: string) => {
  switch (status) {
    case 'Processing':
      return <CircularProgress size={20} />;
      break;
    case 'Complete':
      return <TaskAltTwoToneIcon />;
      break;
    case 'Errors':
      return <WarningAmberTwoToneIcon />;
      break;
    case 'Failed':
      return <PriorityHighTwoToneIcon />;
      break;
    default:
      return;
  }
  console.log('FAB Clicked');
};

interface FulfillmentJobLogResponse {
  user_id: number;
  message: string;
}

const ShowFulfillmentJob = (): ReactElement => {
  const showSegmentFulfillmentJobModalData = useSelector((state: RootState) => state.modalReducer.showSegmentFulfillmentJob);
  const segmentData = showSegmentFulfillmentJobModalData.data || {};
  const jobId = segmentData?.id ? segmentData.id : false;
  // const isReadable = showSegmentFulfillmentJobModalData?.isReadable;
  const dataGridRef = useRef<IDataGridRef>(null);
  const FULFILLMENT_LOG_COLUMN_DATA = FULFILLMENT_JOB_LOGS_COLUMN();
  const columns = useMemo<GridColDef[]>(() => {
    return [...FULFILLMENT_LOG_COLUMN_DATA];
  }, [FULFILLMENT_LOG_COLUMN_DATA]);

  const [formData, setFormData] = useState<FetchFulfillmentData>();

  const dispatch = useDispatch();

  const useStyles = makeStyles({
    divider: {
      backgroundColor: 'black',
      borderBottomWidth: 2,
      marginTop: '25px',
      marginBottom: '25px',
    },
    leftGrid: {
      marginRight: '90px',
      marginLeft: '20px',
      flexDirection: 'column',
    },
    rightGrid: {
      marginRight: '20px',
      marginLeft: '90px',
      flexDirection: 'column',
    },
    updateStatusButton: {
      backgroundColor: 'rgb(50, 205, 50)',
      color: 'black',
      width: '250px',
      height: '50px',
      textTransform: 'none',
    },
    gridStyle: {
      marginBottom: '25px',
    },
  });

  const classes = useStyles();
  const handleClose = () => {
    dispatch(showSegmentFulfillmentJobModalAction(false, null));
  };

  const handleSubmit = () => {
    console.log('Handle Sumbit');
  };

  const fetchData = async () => {
    try {
      const fulfillmentJob = await getFullfillmentJobById({ id: jobId });
      setFormData({
        action: fulfillmentJob?.data.action,
        user_count: fulfillmentJob?.data.user_count,
        tags: fulfillmentJob?.data.tags,
        logs: fulfillmentJob?.data.logs,
        success_count: fulfillmentJob?.data.success_count,
        failed_count: fulfillmentJob?.data.failed_count,
        start: fulfillmentJob?.data.start,
        end: fulfillmentJob?.data.end,
        status: fulfillmentJob?.data.status,
        sf: `${fulfillmentJob?.data.success_count}/${fulfillmentJob?.data.failed_count}`,
        created_by: fulfillmentJob?.data.created_by,
      });

      const data = fulfillmentJob?.data?.logs?.map((item: FulfillmentJobLogResponse) => {
        return {
          id: item.user_id,
          message: item.message,
        };
      });

      return {
        data: data || [],
        totalRows: fulfillmentJob?.totalRows,
      };
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  // useEffect(() => {
  //   if (!showSegmentFulfillmentJobModalData.show) {
  //     if (jobId) getFulfillmentJobData(jobId);
  //   }
  // }, [showSegmentFulfillmentJobModalData.show]);

  return (
    <div>
      <Dialog open={showSegmentFulfillmentJobModalData.show} fullWidth onClose={handleClose} disableRestoreFocus>
        <DialogContent className="modal-body">
          <div className="modal-ct">
            <Grid container>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <Typography variant="h5" align="center">
                    {`Fulfillment Job ${jobId}`}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right' }}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />

            <Grid container style={{ marginTop: '20px' }}>
              <Grid item xs={12}>
                <Form onSubmit={handleSubmit}>
                  {({ handleSubmit }) => (
                    <form method="post" onSubmit={handleSubmit}>
                      <Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={6} className={classes.gridStyle}>
                            <Field
                              component={InputWrapper}
                              id="standard-basic"
                              variant="outlined"
                              defaultValue={jobId ? formData?.action : ''}
                              name="action"
                              type="text"
                              readOnly={!!jobId}
                              disabled={!!jobId}
                              validate={composeValidators(required, maxLength(128))}
                              label={<InputFieldsTooltip title="Job Action" description={'Cannot edit the Name of an existing Segment.'} />}
                            />
                          </Grid>
                          <Grid item xs={6} className={classes.gridStyle}>
                            <Field
                              component={InputWrapper}
                              id="standard-basic"
                              variant="outlined"
                              defaultValue={jobId ? `${formData?.user_count}` : '0'}
                              name="userCount"
                              // onChange={handleNameChange}
                              type="number"
                              readOnly={!!jobId}
                              disabled={!!jobId}
                              placeholder="Enter Segment Name"
                              label={<InputFieldsTooltip title="User Count" description={'Cannot edit the Name of an existing Segment.'} />}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.gridStyle}>
                          <Field
                            component={InputWrapper}
                            validate={composeValidators(required)}
                            id="standard-basic"
                            variant="outlined"
                            name="Tags"
                            multiline
                            rows={2}
                            readOnly={!!jobId}
                            disabled={!!jobId}
                            defaultValue={formData && formData.tags ? `${formData.tags}` : 'NO Tags'}
                            type="text"
                            placeholder="Enter Description"
                            label={<InputFieldsTooltip title="Tags" description="Human-friendly description of the Tag." />}
                          />
                        </Grid>

                        <Grid container spacing={1}>
                          <Grid xs={4} item justifyContent={'left'} display={'flex'}>
                            <InputFieldsTooltip
                              title="Logs"
                              description="Upload the CSV File that contains the User IDs of the player's you want to add or remove Tags from."
                            />
                          </Grid>
                          <Grid xs={4} item justifyContent={'center'} display={'flex'}>
                            {formData && formData.failed_count ? `Failed: ${formData.failed_count} ` : ''}
                          </Grid>
                          <Grid xs={4} item justifyContent={'right'} display={'flex'}>
                            {formData && formData.status ? `${formData.status} ` : 'Processing '}{' '}
                            {formData && formData.status ? handleStatusIcon(formData?.status) : handleStatusIcon('Processing')}
                          </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.gridStyle}>
                          <DataTable
                            columns={columns}
                            fetchData={fetchData}
                            ref={dataGridRef}
                            minimumHeight={'45vh'}
                            maximumHeight={'500px'}
                            hideFooter
                            isInfiniteScroll
                            enableSearch
                          />
                          <Grid container spacing={1}>
                            <Grid xs={6} item justifyContent={'left'} display={'flex'}>
                              {formData && formData.start
                                ? `Start: ${moment(formData.start as number)
                                    .utc()
                                    .toDate()}`
                                : 'Start: '}
                            </Grid>
                            <Grid xs={6} item justifyContent={'right'} display={'flex'}>
                              {formData && formData.end
                                ? `End: ${moment(formData.end as number)
                                    .utc()
                                    .toDate()}`
                                : 'End: Incomplete'}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item justifyContent={'left'} display={'flex'}>
                            {formData && formData.created_by ? `Created By: ${formData.created_by}` : 'No Creator Available'}
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Form>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ShowFulfillmentJob;
