import { IconButton, Tooltip } from '@mui/material';
import React, { Fragment } from 'react';
import { Help } from '@mui/icons-material';
interface Tooltip {
  enableNumber?: boolean;
  title: string;
  description: string;
}

function InputFieldsTooltip({ title, description }: Tooltip) {
  return (
    <>
      <Fragment>
        {title}
        <Tooltip
          sx={{
            position: 'relative',
            bottom: '10px',
            right: '5px',
          }}
          title={description}
          placement="right-start"
        >
          <IconButton size="medium">
            <Help sx={{ fontSize: '20px' }} />
          </IconButton>
        </Tooltip>
      </Fragment>
    </>
  );
}

export default InputFieldsTooltip;
