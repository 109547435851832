import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { API_URLS } from '../constants/url';
import axiosInstance from '../utils/apis';
import { startLoader, stopLoader } from '../utils/utils';
interface PromoCodeAPIPayload {
  url?: string;
  id?: number;
  partnerId?: string;
}
export interface UpsertPromoCode {
  promocode: {
    promoCodeId?: number;
    codeBeforeUpdate?: string;
    code?: string;
    promoCodeTypeId?: number;
    description?: string;
    shortDescription?: string;
    startTsz?: Date;
    endTsz?: Date;
    instanceExpiryMins?: number;
    partnerTagId?: string;
    advertiserId?: string;
    platformNames?: string[];
    usageCount?: number;
    public?: boolean;
    created?: Date;
    updated?: Date;
    metadata?: {
      ruleId?: number;
      isChained?: boolean;
      isChainStart?: boolean;
      hide_code?: boolean;
    };
  };
}
interface UpsertPromoCodeAPIPayload {
  isEdit: boolean;
  data: UpsertPromoCode;
}

export const getPromoCodeData = async (payload: PromoCodeAPIPayload) => {
  try {
    startLoader();
    const { id, partnerId } = payload;
    const newUrl = API_URLS.GET_PROMO_CODE + (id ? `?id=${id}` : '') + (partnerId ? `?partnerId=${partnerId}` : '');
    const response = await axiosInstance.get(newUrl);
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }
  return null;
};

export const getPromoCodeBySkuId = async (payload: { id: number }) => {
  try {
    startLoader();
    const { id } = payload;
    const response = await axiosInstance.get(API_URLS.GET_PROMO_CODE_BY_SKUID + '?id=' + id);
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }
  return null;
};

export const deletePromoCodeData = async (payload: { id: number | undefined }) => {
  try {
    startLoader();
    const { id } = payload;
    const response = await axiosInstance.delete(API_URLS.DELETE_PROMO_CODE + '?id=' + id);
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.errType || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.errType);
    }
  }

  return null;
};

export const upsertPromoCodeData = async (payload: UpsertPromoCodeAPIPayload) => {
  try {
    startLoader();
    if (payload.isEdit) {
      const response = await axiosInstance.put(API_URLS.UPDATE_PROMO_CODE, payload.data);
      if (response.success) {
        stopLoader();
        return response;
      }
    } else {
      const response = await axiosInstance.post(API_URLS.ADD_PROMO_CODE, payload.data);
      console.error({ response });
      if (response.success) {
        stopLoader();
        return response;
      }
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const getPlatformList = async (payload: PromoCodeAPIPayload) => {
  try {
    startLoader();
    const { url, id } = payload;
    const response = await axiosInstance.get(`${url}${id ? `?id=${id}` : ''}`);
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};
