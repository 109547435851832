import { GridColDef } from '@mui/x-data-grid-pro';

export const TOURNAMENT_ACTIVE_COLUMNS: GridColDef[] = [
  {
    headerName: 'ID',
    field: 'id',
    width: 100,
    description: 'Tournament ID',
  },
  {
    headerName: 'Type Currency',
    field: 'typeCurrency',
    width: 250,
    description: 'Type Currency',
  },
  {
    headerName: 'Type',
    field: 'type',
    width: 200,
    description: 'Type',
  },
  {
    headerName: 'Time Remaining',
    field: 'timeRemaining',
    width: 200,
    description: 'Time Remaining',
  },
  {
    headerName: 'Size',
    field: 'size',
    width: 150,
    description: 'Size',
  },
  {
    headerName: 'Prize',
    field: 'prize',
    width: 150,
    description: 'Prize',
  },
  {
    headerName: 'Game Name',
    field: 'gameName',
    width: 150,
    description: 'Game Name',
  },
  {
    headerName: 'Entry Fee',
    field: 'entryFee',
    width: 150,
    description: 'Entry Fee',
  },
  {
    headerName: 'Created At',
    field: 'createdAt',
    width: 150,
    description: 'Created At',
  },
];
