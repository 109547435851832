import { Checkbox, FormControl, Grid, MenuItem, Paper, Select, Typography, FormControlLabel, Button, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
// import Autocomplete from '@mui/material/Autocomplete';
// import TextField from '@mui/material/TextField';
import { API_URLS } from '../../constants/url';
import { allocateSkuData, getAllTag, getSkuTagBySkuId } from '../../services/allocateSku.service';
import { getPromoCodeData } from '../../services/promoCode.service';
import { getSkuList } from '../../services/sku.services';
import useStyles from './allocateSkuStyle';

interface ParamTypes {
  id: string;
}

interface tagType {
  tagId: number;
  tagName: string;
  tagType: number;
}

interface SKuDataType {
  skuId: number;
  whitelist: Array<number>;
  blacklist: Array<number>;
  publiclist: Array<number>;
  promoCodelist: Array<number>;
}
interface elementType {
  tagId: number;
  isWhiteListed: boolean;
  isBlackListed: boolean;
}

interface IPromoCode {
  promoCodeId: number;
}

interface ISkuType {
  description: string;
  skuId: number;
  price: number;
  priceCurrencyTypeId: number;
  isBaseSku: boolean;
  isEnabled: boolean;
  name: string;
}

interface PromoCode {
  advertiserId: string;
  code: string;
  created: null | number;
  description: string;
  endTsz: number;
  instanceExpiryMins: number;
  metadata: {
    ruleId: number;
    isChained: boolean;
    isChainStart: boolean;
  };
  partnerTagId: number;
  platformNames: string[];
  platforms: null | Array<string>;
  promoCodeId: number;
  promoCodeTypeId: number;
  public: boolean;
  shortDescription: string;
  startTsz: number;
  tagId: number;
  updated: null | number;
  usageCount: number;
}
const AllocateSku = () => {
  const classes = useStyles();
  const { id } = useParams<ParamTypes>();
  const [Tags, setTags] = useState<tagType[]>([]);
  const [AllocateSkuData, setAllocateSkuData] = useState<SKuDataType>({
    skuId: 0,
    whitelist: [],
    blacklist: [],
    publiclist: [],
    promoCodelist: [],
  });
  const [skuTagData, setSkuTagData] = useState<tagType[]>([]);
  const [isHide, setIsHide] = useState<boolean>(true);
  const [skuList, setSkuList] = useState<ISkuType[]>([]);
  const [promoCodeList, setPromoCodeList] = useState<PromoCode[]>([]);
  const [selectedPromoCode, setSelectedPromoCodes] = useState<number[]>([]);
  const handleSetSku = async (event: SelectChangeEvent<number>) => {
    const value = event.target.value as number;

    if (value !== 0) {
      setIsHide(false);
    } else {
      setIsHide(true);
    }
    setAllocateSkuData({ ...AllocateSkuData, skuId: value });
    try {
      const res = await getSkuTagBySkuId({ id: value });
      if (res.data) {
        const tempAllocateSkuData: SKuDataType = {
          skuId: value,
          whitelist: [],
          blacklist: [],
          publiclist: [],
          promoCodelist: [],
        };
        let newTag = [...Tags];
        //empty tag and data
        setAllocateSkuData({ ...tempAllocateSkuData });
        newTag = Tags.map((tag: tagType) => {
          return {
            ...tag,
            tagType: 0,
          };
        });
        setTags([...newTag]);
        res.data.forEach((element: elementType) => {
          const index = Tags.findIndex((item: tagType) => item.tagId === element.tagId);
          if (element.isWhiteListed) {
            tempAllocateSkuData.whitelist.push(element.tagId);
            if (index >= 0) {
              newTag[index].tagType = 1;
            }
          } else if (element.isBlackListed) {
            tempAllocateSkuData.blacklist.push(element.tagId);
            if (index >= 0) {
              newTag[index].tagType = 2;
            }
          } else {
            tempAllocateSkuData.publiclist.push(element.tagId);
            newTag[index].tagType = 3;
          }
        });
        setTags([...newTag]);
        setAllocateSkuData({ ...tempAllocateSkuData });
      }
    } catch (e: unknown) {
      if (typeof e === 'string') {
        e.toUpperCase();
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const tempAllocateSkuData: SKuDataType = {
        skuId: AllocateSkuData.skuId,
        whitelist: [],
        blacklist: [],
        publiclist: [],
        promoCodelist: [],
      };
      tempAllocateSkuData.promoCodelist = [...selectedPromoCode];
      Tags.forEach((element: tagType) => {
        if (element.tagType === 1) {
          tempAllocateSkuData.whitelist.push(element.tagId);
        } else if (element.tagType === 2) {
          tempAllocateSkuData.blacklist.push(element.tagId);
        } else if (element.tagType === 3) {
          tempAllocateSkuData.publiclist.push(element.tagId);
        }
      });
      setAllocateSkuData({ ...tempAllocateSkuData });
      const res = await allocateSkuData({ data: tempAllocateSkuData });

      if (res.success === true) {
        setAllocateSkuData({
          skuId: 0,
          whitelist: [],
          blacklist: [],
          publiclist: [],
          promoCodelist: [],
        });
        const newTag = Tags.map((tag: tagType) => {
          return {
            ...tag,
            tagType: 0,
          };
        });
        setTags([...newTag]);
        setIsHide(true);
        toast.success('Sku Tag Updated Successfully');
      } else {
        toast.error('Something Is Wrong');
      }
    } catch (err) {
      toast.error(`${err}`);
    }
  };

  const handlePromoListChange = (e: React.ChangeEvent<HTMLInputElement>, promoCode: IPromoCode) => {
    // const { name, value, checked } = event.currentTarget;
    if (e.target.checked === true) {
      setSelectedPromoCodes([...selectedPromoCode, promoCode.promoCodeId]);
    } else {
      setSelectedPromoCodes(selectedPromoCode.filter((code: number) => code !== promoCode.promoCodeId));
    }
  };
  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.currentTarget;
    const indexOfTag = Tags.findIndex((item: tagType) => item.tagName === name);
    const newTag = [...Tags];
    newTag[indexOfTag].tagType = checked === false ? 0 : value === 'white' ? 1 : value === 'black' ? 2 : 3;
    setTags(newTag);
  };

  useEffect(() => {
    if (id) {
      setAllocateSkuData({ ...AllocateSkuData, skuId: Number(id) });
      setIsHide(false);
    }
  }, [id]);

  useEffect(() => {
    const data = skuTagData?.map((tags: tagType) => {
      return {
        tagId: tags.tagId,
        tagName: tags.tagName,
        tagType: 0,
      };
    });
    setTags([...data]);
  }, [skuTagData]);

  useEffect(() => {
    const getTag = async () => {
      const res = await getAllTag();
      setSkuTagData([...res.data]);
    };
    const getSkuListData = async () => {
      const res = await getSkuList({ url: API_URLS.GET_SKU });
      setSkuList([...res.data]);
    };
    getSkuListData();
    getTag();
  }, []);

  useEffect(() => {
    const getPromoCodes = async () => {
      const res: { data: PromoCode[] } = await getPromoCodeData({});
      setPromoCodeList([...res.data]);
    };
    getPromoCodes();
  }, []);

  return (
    <>
      <form method="post" onSubmit={handleSubmit}>
        <Grid container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={7} md={9} lg={10}>
              <Typography variant="h4" style={{ marginBottom: '10px' }}>
                Allocate Sku
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={1} sm={1} md={2} lg={2}></Grid>
            <Grid item xs={4} sm={4} md={3} lg={3} className={classes.leftAlignText}>
              <Typography variant="h6" style={{ paddingTop: '10px' }}>
                SKU :
              </Typography>
            </Grid>
            <Grid item xs={5} sm={5} md={3} lg={2}>
              <FormControl fullWidth variant="outlined">
                <Select
                  labelId="skuId"
                  id="skuId"
                  // name="skuId"
                  displayEmpty
                  value={AllocateSkuData.skuId}
                  onChange={handleSetSku}
                  sx={{ width: 300 }}
                >
                  <MenuItem value={0} selected={true}>
                    Select SKU
                  </MenuItem>
                  {skuList?.map((val: ISkuType, index: number) => {
                    return (
                      <MenuItem value={val.skuId} key={index}>
                        {val.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={1} sm={2} md={2} lg={2}></Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} className={classes.marginTopDiv} hidden={isHide}>
              <Typography variant="h5" className={classes.HeadingText}>
                Whitelist Tag :
              </Typography>
              <Paper className={classes.PaperStyle}>
                <Grid container spacing={3}>
                  {Tags.map((tag: tagType, index: number) => {
                    return (
                      <Grid item xs={6} sm={4} md={3} lg={3} className={classes.Tag} key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={tag.tagType === 1 ? true : false}
                              name={tag.tagName}
                              value={'white'}
                              onChange={handleInput}
                            />
                          }
                          label={tag.tagName}
                          labelPlacement="end"
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={1} sm={2} md={2} lg={2}></Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} className={classes.marginTopDiv} hidden={isHide}>
              <Typography variant="h5" className={classes.HeadingText}>
                Blacklist Tag :
              </Typography>
              <Paper className={classes.PaperStyle}>
                <Grid container spacing={3}>
                  {Tags.map((tag: tagType, index: number) => {
                    return (
                      <Grid item xs={6} sm={4} md={3} lg={3} className={classes.Tag} key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={tag.tagType === 2 ? true : false}
                              name={tag.tagName}
                              value={'black'}
                              onChange={handleInput}
                            />
                          }
                          label={tag.tagName}
                          value={tag.tagName}
                          labelPlacement="end"
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          {/*<Grid container spacing={3}>
					  <Grid item xs={1} sm={2} md={2} lg={2}></Grid>
					  <Grid
						  item
						  xs={12}
						  sm={12}
						  md={8}
						  lg={8}
						  className={classes.marginTopDiv}
						  hidden={isHide}
					  >
						  <Typography variant="h5" className={classes.HeadingText}>
							  Public Tag :
						  </Typography>
						  <Paper className={classes.PaperStyle}>
							  <Grid container spacing={3}>
								  {Tags.map((tag: tagType, index: number) => {
									  return (
										  <Grid
											  item
											  xs={6}
											  sm={4}
											  md={3}
											  lg={3}
											  className={classes.Tag}
											  key={index}
										  >
											  <FormControlLabel
												  control={
													  <Checkbox
														  color="primary"
														  checked={tag.tagType === 3 ? true : false}
														  name={tag.tagName}
														  value={'public'}
														  onChange={handleInput}
													  />
												  }
												  label={tag.tagName}
												  value={tag.tagName}
												  labelPlacement="end"
											  />
										  </Grid>
									  );
								  })}
							  </Grid>
						  </Paper>
					  </Grid>
		  </Grid>*/}
          <Grid container spacing={3}>
            <Grid item xs={1} sm={2} md={2} lg={2}></Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} className={classes.marginTopDiv} hidden={isHide}>
              <Typography variant="h5" className={classes.HeadingText}>
                Promo Code List :
              </Typography>
              <Paper className={classes.PaperStyle}>
                <Grid container spacing={3}>
                  {promoCodeList.map((promoCode: PromoCode, index: number) => {
                    return (
                      <Grid item xs={6} sm={4} md={3} lg={3} className={classes.Tag} key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox color="primary" name={promoCode.code} value={'public'} onChange={(e) => handlePromoListChange(e, promoCode)} />
                          }
                          label={promoCode.code}
                          value={promoCode.code}
                          labelPlacement="end"
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} className={isHide ? classes.bottomButtonDivNone : classes.bottomButtonDiv}>
              <Button variant="contained" type="submit" className={classes.bottomButton}>
                <Typography variant="h6"> Update </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AllocateSku;
