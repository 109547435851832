import React from 'react';
import { InputAdornment, TextField, IconButton } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { IDataGridRef } from '../DataTable/DataTable';
import { Grid } from '@mui/material';

interface IBackendSearchBar {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  DataGridRef: React.MutableRefObject<IDataGridRef | null>;
  label?: string;
}

function BackendSearchBar({ search, setSearch, DataGridRef, label }: IBackendSearchBar) {
  return (
    <Grid xs={12}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          DataGridRef.current?.fetchData();
        }}
      >
        <TextField
          fullWidth
          label={label ? label : 'Backend Search'}
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    DataGridRef.current?.fetchData();
                  }}
                >
                  <SearchOutlined />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
    </Grid>
  );
}

export default BackendSearchBar;
