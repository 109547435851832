import React, { ReactElement, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/rootReducer';
import {
  showUpsertSkipDepositModalAction,
  showCreateSuccessModalAction,
  // showUpsertSkipDepositInterface,
} from '../../../redux/actions/modalActions/modalActions';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { Form, Field } from 'react-final-form';
import InputWrapper from '../../UI/Input';
import CheckBoxWrapper from '../../UI/CheckBoxWrapper';
import { toast } from 'react-toastify';

import { upsertMarkDepositTransactionsAsSkippable } from '../../../services/playerSupport.service';

import { composeValidators, required, maxLength } from '../../../utils/validations';

import InputFieldsTooltip from '../../InputFieldsTooltip';

const UpsertDepositTransaction = (): ReactElement => {
  const upsertSkipDepositData = useSelector((state: RootState) => state.modalReducer.showUpsertSkipDeposit);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSingleReasonForAll, setIsSingleReasonForAll] = useState<boolean>(false);

  const [singleReason, setSingleReason] = useState<string>('');

  const depositTransactionData: any = upsertSkipDepositData?.data || {};

  console.log({ depositTransactionData });

  const internalTxnId = depositTransactionData[0]?.id;

  const braintreeId = depositTransactionData[0]?.bt_txn_id;

  const remainingBalance = depositTransactionData[0]?.remaining_balance;

  const skuId = depositTransactionData[0]?.skuId;

  const isSkippedTransaction = depositTransactionData[0]?.isSkippedTransaction || false;

  const isSkippedReason = depositTransactionData[0]?.isSkippedReason;

  const isReadable = upsertSkipDepositData?.isReadable;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (isSkippedTransaction!) {
    depositTransactionData[0]['isSkippedTransaction'] = true;
  }

  const areMultipleTransactionSelected = depositTransactionData.length > 1;

  const dispatch = useDispatch();

  // useEffect(() => {
  //   setIsSingleReasonForAll(false);
  // });

  const useStyles = makeStyles({
    divider: {
      backgroundColor: 'black',
      borderBottomWidth: 2,
      marginTop: '25px',
      marginBottom: '25px',
    },
    leftGrid: {
      marginRight: '90px',
      marginLeft: '20px',
      flexDirection: 'column',
    },
    rightGrid: {
      marginRight: '20px',
      marginLeft: '90px',
      flexDirection: 'column',
    },
    updateStatusButton: {
      backgroundColor: 'rgb(50, 205, 50)',
      color: 'black',
      width: '250px',
      height: '50px',
      textTransform: 'none',
    },
    gridStyle: {
      marginBottom: '25px',
    },
  });

  const classes = useStyles();
  const handleClose = () => {
    dispatch(showUpsertSkipDepositModalAction(false, null));
  };

  const handleReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let elementId = parseInt(e.target.id);
    if (!areMultipleTransactionSelected) {
      elementId = 0;
    } else if (isSingleReasonForAll) {
      setSingleReason(e.target.value);
      return;
    }
    depositTransactionData[elementId]['isSkippedReason'] = e.target.value;
  };

  const handleIsSkippedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const elementId = parseInt(e.target.id);
    console.log('Is Skipped Changed');
    depositTransactionData[elementId]['isSkippedTransaction'] = e.target.checked;
  };

  const handleIsSingleReasonChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('This is the function to change isSingeReasonForAll');
    console.log(e.target.checked);
    setIsSingleReasonForAll(e.target.checked);
  };

  const handleUpdateSubmit = async () => {
    try {
      const data = {
        txnList: [],
      };

      const txnList: any = [];
      depositTransactionData.forEach((depositT: any) => {
        const upsertDepositTransactionData = {
          bt_txn_id: depositT.bt_txn_id,
          remaining_balance: depositT.remaining_balance,
          reason: isSingleReasonForAll ? singleReason : depositT.isSkippedReason,
        };

        txnList.push(upsertDepositTransactionData);
      });

      data.txnList = txnList;

      console.log(data);

      const res = await upsertMarkDepositTransactionsAsSkippable({ data });

      if (res) {
        dispatch(showCreateSuccessModalAction(false, { update: true }));
        dispatch(showUpsertSkipDepositModalAction(false, null));
        setIsSingleReasonForAll(false);
        toast.success('Deposit Transaction(s) Successfully Skipped!');
      }
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  return (
    <div>
      <Dialog open={upsertSkipDepositData.show} onClose={handleClose} disableRestoreFocus fullWidth={true} maxWidth="md">
        {areMultipleTransactionSelected ? (
          <DialogContent className="modal-body">
            <div className="modal-ct">
              <Grid container>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Typography variant="h5" align="center">
                      Skip Multiple Deposits
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right' }}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />

              <Grid xs={12}>
                <Form onSubmit={handleUpdateSubmit}>
                  {({ handleSubmit }) => (
                    <form method="post" onSubmit={handleSubmit}>
                      {depositTransactionData.map((deposit: any, index: any) => {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <Grid container>
                            <Grid item xs={6} className={classes.gridStyle}>
                              <Field
                                component={InputWrapper}
                                id="standard-basic"
                                variant="outlined"
                                defaultValue={deposit.bt_txn_id}
                                name={'braintreeTransactionId' + deposit.id}
                                type="text"
                                readOnly={isReadable}
                                placeholder="Braintree ID"
                                disabled
                                label={<InputFieldsTooltip title="Braintree Transaction ID" description="The Braintree transaction ID" />}
                              />
                            </Grid>

                            <Grid item xs={6} className={classes.gridStyle}>
                              <Field
                                component={InputWrapper}
                                id="standard-basic"
                                variant="outlined"
                                defaultValue={deposit.remaining_balance}
                                name={'remainingBalance' + deposit.id}
                                type="number"
                                readOnly={isReadable}
                                disabled
                                label={
                                  <InputFieldsTooltip
                                    title="Remaining Balance"
                                    description="The remaining balance available for withdraw for the Deposit Transaction."
                                  />
                                }
                              />
                            </Grid>
                            {isSingleReasonForAll ? (
                              <div></div>
                            ) : (
                              <Grid item xs={12} className={classes.gridStyle}>
                                <Field
                                  component={InputWrapper}
                                  id={`${index}`}
                                  variant="outlined"
                                  defaultValue={deposit.isSkippedReason}
                                  name={`isReason${deposit.id}`}
                                  type="text"
                                  readOnly={isReadable}
                                  onChange={handleReasonChange}
                                  validate={composeValidators(required, maxLength(80))}
                                  placeholder="Please enter the reason why the Transaction is being skipped."
                                  label={
                                    <InputFieldsTooltip
                                      title={isSkippedTransaction ? 'Update Reason' : 'Reason'}
                                      description="This is the reason why the Deposit Transaction is being marked as Skipped. Reason must be 80 characters or less"
                                    />
                                  }
                                />
                              </Grid>
                            )}

                            <Grid xs={12}>
                              <Divider className={classes.divider}></Divider>
                            </Grid>
                          </Grid>
                        );
                      })}
                      <Box
                        textAlign="center"
                        sx={{
                          marginBottom: '10 px',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Grid xs={4} className={classes.gridStyle} alignItems="left">
                          <InputFieldsTooltip
                            title="Use Same reason for each"
                            description="Would you like to use the same reason for each transaction you are updating ?"
                          />
                          <Field
                            name="isSingleReason"
                            id="isSingleReason"
                            component={CheckBoxWrapper}
                            onClick={(e: any) => {
                              console.log('update!');
                              handleIsSingleReasonChanged(e);
                            }}
                            defaultValue={isSingleReasonForAll}
                            type="checkbox"
                          />
                        </Grid>
                        {isSingleReasonForAll ? (
                          <Grid item xs={12} className={classes.gridStyle}>
                            <Field
                              component={InputWrapper}
                              id="singleReason"
                              variant="outlined"
                              name="singleReason"
                              type="text"
                              readOnly={isReadable}
                              onChange={handleReasonChange}
                              validate={composeValidators(required, maxLength(80))}
                              placeholder="Please enter the reason why the Transaction is being skipped."
                              label={
                                <InputFieldsTooltip
                                  title={isSkippedTransaction ? 'Update Reason' : 'Reason'}
                                  description="This is the reason why the Deposit Transaction is being marked as Skipped. Reason must be 80 characters or less"
                                />
                              }
                            />
                          </Grid>
                        ) : (
                          <div></div>
                        )}

                        <Grid item lg={12}>
                          <Button variant="contained" color="primary" type="submit" fullWidth disabled={isReadable}>
                            <b>{areMultipleTransactionSelected ? 'Update Selected Deposits' : 'Update Deposit'}</b>
                          </Button>
                        </Grid>
                      </Box>
                    </form>
                  )}
                </Form>
              </Grid>
            </div>
          </DialogContent>
        ) : (
          <DialogContent className="modal-body">
            <div className="modal-ct">
              <Grid container>
                <Grid container spacing={3}>
                  <Grid item xs={10}>
                    <Typography variant="h5" align="center">
                      {depositTransactionData[0]?.isSkippedTransaction ? 'Transaction Already Skipped' : 'Skip Deposit Transaction'}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right' }}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />

              <Grid container style={{ marginTop: '20px' }}>
                <Grid item xs={12}>
                  <Form onSubmit={handleUpdateSubmit}>
                    {({ handleSubmit }) => (
                      <form method="post" onSubmit={handleSubmit}>
                        <Grid>
                          <Grid item xs={12} className={classes.gridStyle}>
                            <Field
                              component={InputWrapper}
                              id="standard-basic"
                              variant="outlined"
                              defaultValue={internalTxnId}
                              name="internalDepositTransactionId"
                              type="text"
                              readOnly={isReadable}
                              disabled
                              label={<InputFieldsTooltip title="Internal Transaction ID" description="Record ID on the server." />}
                            />
                          </Grid>
                          <Grid item xs={12} className={classes.gridStyle}>
                            <Field
                              component={InputWrapper}
                              id="standard-basic"
                              variant="outlined"
                              defaultValue={braintreeId}
                              name="braintreeTransactionId"
                              type="text"
                              readOnly={isReadable}
                              placeholder="Braintree ID"
                              disabled
                              label={<InputFieldsTooltip title="Braintree Transaction ID" description="The Braintree transaction ID" />}
                            />
                          </Grid>

                          <Grid item xs={12} className={classes.gridStyle}>
                            <Field
                              component={InputWrapper}
                              id="standard-basic"
                              variant="outlined"
                              defaultValue={skuId}
                              name="skuId"
                              type="number"
                              readOnly={isReadable}
                              disabled
                              label={
                                <InputFieldsTooltip
                                  title="SKU ID"
                                  description="This is the SKU Identifier of the Deposit Offer the Player Purchased"
                                />
                              }
                            />
                          </Grid>

                          <Grid item xs={12} className={classes.gridStyle}>
                            <Field
                              component={InputWrapper}
                              id="standard-basic"
                              variant="outlined"
                              defaultValue={remainingBalance}
                              name="remainingBalance"
                              type="number"
                              readOnly={isReadable}
                              disabled
                              label={
                                <InputFieldsTooltip
                                  title="Remaining Balance"
                                  description="The remaining balance available for withdraw for the Deposit Transaction."
                                />
                              }
                            />
                          </Grid>

                          <Grid item xs={12} className={classes.gridStyle}>
                            <InputFieldsTooltip
                              title="Skip Deposit"
                              description="Once a Transaction has been skipped the Async Withdrawal process will not include it in the calculation for available withdrawal balance."
                            />
                            <Field
                              name="skipTransaction"
                              component={CheckBoxWrapper}
                              onChange={handleIsSkippedChange}
                              defaultValue={isSkippedTransaction}
                              type="checkbox"
                              disabled={!!isReadable || isSkippedTransaction}
                            />
                          </Grid>

                          <Grid item xs={12} className={classes.gridStyle}>
                            <Field
                              component={InputWrapper}
                              id="standard-basic"
                              variant="outlined"
                              defaultValue={isSkippedReason}
                              name="isSkippedReason"
                              type="text"
                              readOnly={isReadable}
                              onChange={handleReasonChange}
                              validate={composeValidators(required, maxLength(80))}
                              placeholder="Please enter the reason why the Transaction is being skipped."
                              label={
                                <InputFieldsTooltip
                                  title={isSkippedTransaction ? 'Update Reason' : 'Reason'}
                                  description="This is the reason why the Deposit Transaction is being marked as Skipped. Reason must be 80 characters or less"
                                />
                              }
                            />
                          </Grid>
                        </Grid>
                        <Box
                          textAlign="center"
                          sx={{
                            marginBottom: '10 px',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Grid item lg={12}>
                            <Button variant="contained" color="primary" type="submit" fullWidth disabled={isReadable}>
                              <b>{areMultipleTransactionSelected ? 'Update Selected Deposits' : 'Update Deposit'}</b>
                            </Button>
                          </Grid>
                        </Box>
                      </form>
                    )}
                  </Form>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
};

export default UpsertDepositTransaction;
