import React, { ReactElement } from 'react';
import { Checkbox, FormControl, FormHelperText } from '@mui/material';
import { Meta } from '../../interfaces/common.interfaces';
interface inputProps {
  input: any;
  meta: Meta;
  label: string;
  [key: string]: any;
}
const CheckBoxWrapper = ({ input: { name, value, checked, onChange }, meta, ...rest }: inputProps): ReactElement => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;
  return (
    <>
      <FormControl variant="outlined" sx={{ mt: '5px' }}>
        <Checkbox {...rest} name={name} value={value} checked={checked} onChange={onChange} />
        {showError && <FormHelperText error={showError}>{meta.error || meta.submitError}</FormHelperText>}
      </FormControl>
    </>
  );
};
export default CheckBoxWrapper;
