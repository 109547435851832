import React from 'react';
import { Typography } from '@mui/material';

const PageNotFound: React.FC = () => {
  return (
    <div>
      <div>
        <Typography variant="h1" textAlign={'center'} className="pgNotFountH1">
          404
        </Typography>
        <Typography variant="h3" textAlign={'center'} className="pgNotFountP">
          Oops! Something is wrong.
        </Typography>
      </div>
    </div>
  );
};

export default PageNotFound;
