import { toast } from 'react-toastify';
import axiosInstance from '../utils/apis';
import Moment from 'moment';
import { AxiosError } from 'axios';
interface withdrawalsAPIPayload {
  offset: number;
  limit: number;
  url: string | undefined;
  search?: string;
  searchBy?: string;
  startDate?: Date;
  endDate?: Date;
}

export const getWithdrawalsData = async (payload: withdrawalsAPIPayload) => {
  try {
    const { offset, limit, url, search, searchBy, startDate, endDate } = payload;
    let filterQuery = '';
    let api_url = `${url}?offset=${offset}&limit=${limit}`;
    if (searchBy?.length && search?.length) {
      filterQuery = `${searchBy}||=||${search}`;
      api_url += `&filter=${filterQuery}`;
    }
    if (startDate && endDate) {
      const startDateFilter = `TransactionLog.created_at||>=||${Moment(startDate).format('YYYY-MM-DD')}`;
      const endDateFilter = `TransactionLog.created_at||<=||${Moment(endDate).format('YYYY-MM-DD')}`;
      api_url += `&filter=${startDateFilter}`;
      api_url += `&filter=${endDateFilter}`;
    }
    const response = await axiosInstance.get(api_url);
    if (response.success) {
      return response;
    } else {
      toast.error(response.message);
    }
  } catch (e: unknown | AxiosError) {
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};
