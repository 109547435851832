export interface PromoType {
  label: string;
  value: number;
  startTime: number;
  endTime: number;
  usageCount: number;
  platformNames: string[];
  metadata?: {
    ruleId: number;
    isChained: boolean;
    isChainStart: boolean;
    hide_code: boolean;
  };
}
interface PromoTypeFilters {
  promoStartTime?: number;
  promoEndTime?: number;
  promoPlatformNames?: string[];
  onlyParent?: boolean;
  notChained?: boolean;
}

function promoCodeFilter(promoCodeList: PromoType[], { promoStartTime, promoEndTime, promoPlatformNames, onlyParent, notChained }: PromoTypeFilters) {
  let updatedList: any[] = promoCodeList;
  if (promoStartTime && promoEndTime) {
    updatedList = promoCodeList.map((promoCode: PromoType) => {
      if (promoCode.startTime < promoStartTime || promoCode.endTime > promoEndTime) {
      } else {
        return promoCode;
      }
    });
  }
  if (promoPlatformNames) {
    updatedList = updatedList.map((promoCode: PromoType) => {
      if (promoPlatformNames.every((val: string) => promoCode?.platformNames.includes(val))) {
        return promoCode;
      }
    });
  }
  if (onlyParent) {
    updatedList = updatedList.map((promoCode: PromoType) => {
      if (promoCode?.metadata?.isChainStart && promoCode?.metadata?.isChained) {
        return promoCode;
      }
    });
  }
  if (notChained) {
    updatedList = updatedList.map((promoCode: PromoType) => {
      if (!(promoCode && promoCode.metadata) || (promoCode && promoCode.metadata && promoCode.metadata.isChained === false)) {
        return promoCode;
      }
    });
  }
  updatedList = updatedList.filter(function (promoCode: PromoType) {
    return promoCode !== undefined;
  });
  return updatedList;
}

export default promoCodeFilter;
