import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_ACTION } from './authType';
import { ActionCreator, Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { startLoader, stopLoader } from '../../../utils/utils';
import { getHeaders } from '../../../utils/apis';
import { HEADERS } from '../../../constants/headers';

interface Payload {
  url: string;
  value: {
    email: string;
    password: string;
  };
}

interface loginDataInterface {
  adminId?: number;
  userName?: string;
  mail?: string;
  avatar?: string;
  token?: string;
  permissions?: string[];
  role?: string;
}

export type LoginAction = ActionCreator<ThunkAction<void, Payload, null, Action<'LOGIN_REQUEST'>>>;

export const loginAction: LoginAction = (payload: Payload) => (dispatch) => {
  const { value, url } = payload;
  startLoader();
  const headers = getHeaders([HEADERS.X_GAME_NAME, HEADERS.X_GAME_PLATFORM, HEADERS.X_VERSION]);
  dispatch({
    type: LOGIN_REQUEST,
    payload: {
      request: {
        method: 'POST',
        url: url,
        data: value,
        headers,
      },
    },
  });
  stopLoader();
  // postWithDelay(url, value, undefined, [
  //   ContentType,
  //   XVersion,
  //   XGameName,
  //   XGamePlatform,
  // ])
  //   .then((data) => {
  //     if (data.success) {
  //       localStorage.setItem('currentUser', JSON.stringify(data));
  //       // dispatch({
  //       //   type: LOGIN_SUCCESS,
  //       //   data,
  //       // });
  //       stopLoader();
  //     } else if (!data.success) {
  //       // dispatch({
  //       //   type: LOGIN_FAIL,
  //       //   data: data,
  //       // });
  //       stopLoader();
  //     }
  //   })
  //   .catch((error) => {
  //     stopLoader();
  //     dispatch({
  //       type: LOGIN_FAIL,
  //       data: error,
  //     });
  //   });
};

export const logoutAction = (): { type: string } => {
  localStorage.removeItem('currentUser');
  localStorage.removeItem('theme');
  return { type: LOGOUT_ACTION };
};

export const loginData = (payload: loginDataInterface): { type: string; payload: loginDataInterface } => {
  return { type: LOGIN_SUCCESS, payload };
};
