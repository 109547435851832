import React, { ReactElement } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';

import { Box, TextareaAutosize, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/rootReducer';
import { showCreateSuccessModalAction, showUpdateSuccessModalAction } from '../../../redux/actions/modalActions/modalActions';
import { makeStyles } from '@mui/styles';
import { updateTransStatus } from '../../../services/transactions.service';
import { toast } from 'react-toastify';

const UpdateTransactionSuccess = (): ReactElement => {
  const updateSuccessData = useSelector((state: RootState) => state.modalReducer.showUpdateSuccess);

  const updateTrnData = updateSuccessData.data;

  //Just for testing purpose hardcoded show

  const useStyles = makeStyles({
    divider: {
      backgroundColor: 'grey',
      borderBottomWidth: 2,
      marginTop: '1%',
      marginBottom: '1%',
    },

    button: {
      backgroundColor: 'rgb(50, 205, 50)',
      color: 'black',
      width: '70%',
      height: '20%',
      marginTop: '7%',
      textTransform: 'none',
    },
  });

  const classes = useStyles();

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(showUpdateSuccessModalAction(false, null));
  };

  const handleSubmit = async () => {
    const payload = {
      transactionIDs: updateTrnData && updateTrnData.transactionIds ? updateTrnData.transactionIds : '',
      toStatus: updateTrnData && updateTrnData.toStatus ? updateTrnData.toStatus : '',
    };
    const res = await updateTransStatus(payload);
    if (res.success) {
      toast.success('Updated Transaction status successfully!');
      dispatch(showCreateSuccessModalAction(false, { update: true }));
    } else {
      toast.error('Something Is Wrong');
    }
    dispatch(showUpdateSuccessModalAction(false, null));
  };

  const trnIDs = updateTrnData && updateTrnData.transactionIds;

  return (
    <div>
      <Dialog open={updateSuccessData.show} onClose={handleClose}>
        <DialogContent className="modal-body">
          <div className="modal-ct">
            <Typography variant="h6" align="center">
              Edit Transaction Status Success
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right', marginTop: '-40px' }}>
              <CloseIcon />
            </IconButton>
            <Divider className={classes.divider} />
            <Typography align="center">The following transactions were successfully updated</Typography>
            <Box textAlign="center">
              {trnIDs && (
                <TextareaAutosize
                  maxRows={3}
                  aria-label="maximum height"
                  placeholder="Maximum 4 rows"
                  value={trnIDs}
                  style={{ width: '90%', marginBottom: '5px', marginTop: '1%' }}
                  readOnly
                />
              )}
              <Button className={classes.button} onClick={handleSubmit}>
                Okay
              </Button>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UpdateTransactionSuccess;
