import { Button, Tooltip } from '@mui/material';
import FloatingButton from '../../component/FloatingButton/FloatingButton';
import { DeleteOutline, Edit } from '@mui/icons-material';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { API_URLS } from '../../constants/url';
import DataTable, { IDataGridRef } from '../../component/DataTable/DataTable';
import { PlatformPermissions } from '../../constants/permission';
import { showAllocateSkuAction, showCreateSkuModalAction, showGlobalDeleteModalAction } from '../../redux/actions/modalActions/modalActions';
import AddIcon from '@mui/icons-material/Add';
import { MarketingPermissions } from '../../constants/permission';
import { RootState } from '../../redux/reducers/rootReducer';
import CommonBreadcrumbs from '../../component/BreadCrumb/BreadCrumb';
import { SKU_LIST_COLUMN } from '../../constants/columns/skuList.constant';
import useStylesSkuList from './skuListStyle';
import { useCurrentPartner } from '../../hooks/useCurrentPartner';
import { getSkuList } from '../../services/sku.services';
import { breadCrumbsKey } from '../../constants/breadCrumbConfig';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { IDeleteData } from '../CurrencyType/CurrencyType';
import { DataResponseType } from '../PromoCodeChainRule/PromoCodeChainRule';

interface SkuResponse {
  skuId: number;
  name: string;
  price: number;
  entitlements: {
    [key: string]: string;
  };
  isEnabled: boolean;
  isBaseSku: boolean;
  platformNames: string[];
  description: string;
  platforms: string[];
  promoList: [];
}
const SkuList = () => {
  const dispatch = useDispatch();
  const { partnerShortName } = useCurrentPartner();
  const upsertSkuData = useSelector((state: RootState) => state.modalReducer.showCreateSku);
  const allocateSkuData = useSelector((state: RootState) => state.modalReducer.showAllocateSku);
  const globalDeleteModalData = useSelector((state: RootState) => state.modalReducer.showGlobalDelete);
  const dataGridRef = useRef<IDataGridRef>(null);
  const skuClasses = useStylesSkuList();
  const SKU_COLUMN_DATA = SKU_LIST_COLUMN(skuClasses, dispatch);
  const columns = useMemo<GridColDef[]>(() => {
    const All_Column: GridColDef[] = [
      {
        field: 'action',
        headerName: 'Action',
        headerAlign: 'center',
        description: 'Action',
        width: 220,
        renderCell: (params: GridRenderCellParams) => (
          <>
            <Tooltip title="Edit">
              <Button
                size="small"
                sx={{ ml: '16px' }}
                onClick={() => {
                  dispatch(showCreateSkuModalAction(true, { id: params.value }));
                }}
              >
                <Edit color="secondary" />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                size="small"
                style={{ marginLeft: 16 }}
                onClick={() => {
                  const deleteData: IDeleteData = {};
                  deleteData.deleteModule = 'SKU';
                  deleteData.deleteId = params.row.id;
                  deleteData.item = {};
                  deleteData.item.value = params.row.id;
                  deleteData.item.label = params.row.name;
                  deleteData.item.item = {};
                  deleteData.item.item.description = params.row.description;
                  dispatch(showGlobalDeleteModalAction(true, deleteData));
                }}
              >
                <DeleteOutline color="error" />
              </Button>
            </Tooltip>
          </>
        ),
      },
    ];
    return [...SKU_COLUMN_DATA, ...All_Column];
  }, [SKU_COLUMN_DATA]);

  const handleCreateModel = () => {
    console.log('FAB Clicked');
  };

  const handleDoubleClick = (e: any) => {
    dispatch(showCreateSkuModalAction(true, e.row, false));
  };

  const bulkActions = [
    {
      action: 'Create Sku',
      onClick: () => {
        dispatch(showCreateSkuModalAction(true, null));
      },
      icon: <AddIcon />,
      Permissions: [MarketingPermissions.Create],
    },
    {
      action: 'Allocate Sku',
      onClick: () => {
        dispatch(showAllocateSkuAction(true, null));
      },
      icon: <AddIcon />,
      Permissions: [MarketingPermissions.Create],
    },
  ];
  useEffect(() => {
    if (upsertSkuData.show && upsertSkuData?.data?.success) {
      dataGridRef.current?.fetchData();
    }
  }, [upsertSkuData, allocateSkuData]);

  useEffect(() => {
    if (!globalDeleteModalData.show) {
      dataGridRef.current?.fetchData();
    }
  }, [globalDeleteModalData.show]);
  const fetchData = async () => {
    try {
      const res: DataResponseType<SkuResponse> = await getSkuList({
        url: API_URLS.GET_SKU,
        partnerId: partnerShortName,
      });
      const data = res?.data?.map((item: SkuResponse) => {
        return {
          id: item.skuId,
          name: item.name,
          price: item.price,
          entitlements: item.entitlements,
          isEnabled: item.isEnabled,
          isBaseSku: item.isBaseSku,
          platformNames: item.platformNames,
          description: item.description,
          platforms: item.platforms,
          promoList: item.promoList,
          action: item.skuId,
        };
      });
      return {
        data: data || [],
        totalRows: res?.totalRows,
      };
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };
  useEffect(() => {
    if (!upsertSkuData.show && upsertSkuData?.data?.success) {
      dataGridRef.current?.fetchData();
    }
  }, [upsertSkuData.show]);

  return (
    <>
      <CommonBreadcrumbs title="SKU" breadCrumbKey={breadCrumbsKey.sku} />
      <DataTable
        columns={columns}
        fetchData={fetchData}
        ref={dataGridRef}
        hideFooter
        isInfiniteScroll
        enableSearch
        handleDoubleClick={handleDoubleClick}
      />
      <FloatingButton bulkActions={bulkActions} floatingButtonClick={handleCreateModel} fabPermissions={[PlatformPermissions.Create]} />
    </>
  );
};

export default SkuList;
