import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

interface Props {
  isOpen: boolean;
  onCloseModel: () => void;
  title: string;
  subTitle?: string;
  actionButtons: { name: string; onClick: () => void }[];
}

export const DialogModel = (props: Props): React.ReactElement => {
  return (
    <div>
      <Dialog open={props.isOpen} keepMounted onClose={props.onCloseModel}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent sx={{ minWidth: '350px' }}>{props.subTitle ? <DialogContentText>{props.subTitle}</DialogContentText> : ''}</DialogContent>
        <DialogActions>
          <Button onClick={props.onCloseModel} color="primary">
            Cancel
          </Button>
          {props.actionButtons.map((button, index) => (
            <Button onClick={button.onClick} color="primary" key={index}>
              {button.name}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </div>
  );
};
