// All un-protected routes will be defined in here
export enum openRoute {
  login = '/',
  fourOfour = '/not-found',
}

// All protected routes will be defiend
export enum protectedRoutes {
  dashboard = '/dashboards/v1',

  home = '/home',

  //Discovery
  tournamentDiscovery = '/discovery/tournament',
  transactionDiscovery = '/discovery/transaction',
  locationDiscovery = '/discovery/location',

  partnerLandingPage = '/partnerLandingPage',
  // Withdrawals
  withdrawals = '/withdrawals',
  pendingWithdrawals = '/withdrawals/pending',
  historyWithdrawals = '/withdrawals/history',
  // Match Maker
  matchMakingConfiguration = '/mm/config',
  matchMakingPatterns = '/mm/patterns',
  matchMakingDiscovery = '/mm/discovery',
  matchMakingBotCash = '/mm/bots/cash',
  matchMakingBotToken = '/mm/bots/token',
  // Player Management
  playerListHome = '/pm',
  playerList = '/pm/player/list',
  playerPatterns = '/pm/player/patterns',
  playerDiscovery = '/pm/player/discovery',
  playerSupport = '/pm/player/support',
  // prize
  prize = '/prize',
  prizePending = '/prize/pending',
  prizeDiscovery = '/prize/discovery',
  prizeHistory = '/prize/history/',
  // goals
  goals = '/goals',
  goalsManagement = '/goals/management',
  // Tournaments
  tournaments = '/tournaments',
  tournamentsConfig = '/tournaments/config',
  tournamentCreate = '/tournaments/create',
  tournamentsActive = '/tournaments/active',
  tournamentsPatterns = '/tournaments/patterns',
  tournamentsCancelled = '/tournaments/cancelled',
  tournamentsCompleted = '/tournaments/completed',
  tournamentsEvent = '/tournaments/events',
  promoCodeList = '/promoCode/list',
  skuList = '/sku/list',
  dailyReturnList = '/dailyReturn/set/list',
  promoCodeType = '/promoCodeType/list/get',
  currencyType = '/currencyType/list',
  allocateSku = '/allocateSku',
  allocateSkuById = '/allocateSku/:id',
  promoCodeChainRuleList = '/promoCodeChainRule/list',
  deposits = '/playerServices/deposits',
  goalsList = '/playerServices/goalsList',
  segmentList = '/tags/management',
  fulfillmentList = '/fulfillment',
}

// Any new user access need tobe added to the system need to pass a string element in here to give permission
export enum userAccess {
  admin = 'admin',
  test = 'test',
}
