import React, { ReactElement } from 'react';
import { Form, Field } from 'react-final-form';
import { required } from '../../../utils/validations';
import { dropdowns } from '../../../constants/constants';
import SelectWrapper from '../../UI/SelectWrapper';
import InputWrapper from '../../UI/Input';
import { Button, Dialog, DialogActions, DialogContent, Paper, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/rootReducer';
import { showAddCreditModalAction } from '../../../redux/actions/modalActions/modalActions';
interface ModalPropTypes {
  handleClose?: () => void;
  submit: (value: { action: string; selectedOption: string; suspendTill: number }) => void;
  show?: boolean;
  content: string;
}

export const AddCredits = ({ submit, content }: ModalPropTypes): ReactElement => {
  const { fundReason, fundType } = dropdowns;
  const addCredits = useSelector((stata: RootState) => stata.modalReducer.showAddCredit);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(showAddCreditModalAction(false));
  };
  return (
    <>
      <Dialog open={addCredits.show} onClose={handleClose}>
        <Form onSubmit={submit}>
          {({ handleSubmit }) => (
            <>
              <form onSubmit={handleSubmit}>
                <DialogContent className="modal-body">
                  <div className="modal-ct">
                    <Paper elevation={0}>
                      <Typography variant="h5" align="center">
                        {'Add Funds'}
                      </Typography>
                      <Typography variant="body1" align="center">
                        {content}
                      </Typography>
                    </Paper>
                    <Grid container>
                      <Grid item lg={12} md={12} xl={12}>
                        <Field name="action" option={fundType} component={SelectWrapper} validate={required} />
                      </Grid>
                      <Grid item lg={12} md={12} xl={12}>
                        <Field name="selectedOption" option={fundReason} component={SelectWrapper} validate={required} />
                      </Grid>
                      {
                        <Grid item lg={12} md={12} xl={12}>
                          <Field
                            name="suspendTill"
                            type="number"
                            label="Fund amount"
                            placeholder="Enter amount of days"
                            component={InputWrapper}
                            validate={required}
                          />
                        </Grid>
                      }
                    </Grid>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} type="button" color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    Update
                  </Button>
                </DialogActions>
              </form>
            </>
          )}
        </Form>
      </Dialog>
    </>
  );
};
