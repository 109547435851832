import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import VerifiedIcon from '@mui/icons-material/Verified';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import { Tooltip } from '@mui/material';

export const GOALS_LIST_COLUMNS = (
  handleColumnVisibility: (columnName: string, defVisibility: boolean, localStorageVar: string) => boolean,
): GridColDef[] => {
  return [
    {
      headerName: 'Goal ID',
      field: 'parentGoalID',
      width: 100,
      description: 'Goal ID',
      hide: handleColumnVisibility('parentGoalID', true, 'columnVisibilityGoals'),
    },
    {
      headerName: 'Instance ID',
      field: 'id',
      width: 100,
      description: 'Goal ID',
      hide: handleColumnVisibility('id', false, 'columnVisibilityGoals'),
    },
    {
      headerName: 'Start Date (EST),',
      field: 'startDate',
      width: 250,
      description: 'Start Date (EST),',
      hide: handleColumnVisibility('startDate', true, 'columnVisibilityGoals'),
    },
    {
      headerName: 'End Date (EST)',
      field: 'endDate',
      width: 200,
      description: 'End Date (EST)',
      hide: handleColumnVisibility('endDate', true, 'columnVisibilityGoals'),
      renderCell: (params: GridRenderCellParams) => <>{params?.row?.endDate ? params?.row?.endDate : 'Never Ends'}</>,
    },
    {
      headerName: 'Goal Name',
      field: 'goalName',
      width: 250,
      description: 'Goal Name',
      hide: handleColumnVisibility('goalName', true, 'columnVisibilityGoals'),
    },
    {
      headerName: 'Display Name',
      field: 'displayName',
      width: 150,
      description: 'Display Name',
      hide: handleColumnVisibility('displayName', true, 'columnVisibilityGoals'),
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 150,
      description: 'Status',
      hide: handleColumnVisibility('status', true, 'columnVisibilityGoals'),
      renderCell: (params: GridRenderCellParams) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {params?.row?.status == 'Active' && (
            <Tooltip title="Active">
              <PublishedWithChangesIcon sx={{ color: 'blue' }} />
            </Tooltip>
          )}
          {params?.row?.status == 'Expired' && (
            <Tooltip title="Active">
              <HourglassDisabledIcon sx={{ color: '#DEC20B' }} />
            </Tooltip>
          )}
          {params?.row?.status == 'Completed' && (
            <Tooltip title="Active">
              <VerifiedIcon sx={{ color: 'green' }} />
            </Tooltip>
          )}
          {params?.row?.status}
        </div>
      ),
    },
    {
      headerName: 'Description',
      field: 'description',
      width: 150,
      description: 'Description',
      hide: handleColumnVisibility('description', false, 'columnVisibilityGoals'),
    },
    {
      headerName: 'Subtitle',
      field: 'subtitle',
      width: 150,
      description: 'Subtitle',
      hide: handleColumnVisibility('subtitle', false, 'columnVisibilityGoals'),
    },
    {
      headerName: 'Goal Type',
      field: 'goalType',
      width: 150,
      description: 'Goal Type',
      hide: handleColumnVisibility('goalType', false, 'columnVisibilityGoals'),
    },
    {
      headerName: 'Goal Category',
      field: 'goalCategory',
      width: 150,
      description: 'Goal Category',
      hide: handleColumnVisibility('goalCategory', false, 'columnVisibilityGoals'),
    },
    {
      headerName: 'Segment/Universe',
      field: 'segmentUniverse',
      width: 150,
      description: 'Segment/Universe',
      hide: handleColumnVisibility('segment', false, 'columnVisibilityGoals'),
    },
    {
      headerName: 'Progress required',
      field: 'progressRequired',
      width: 200,
      description: 'Progress Required',
      hide: handleColumnVisibility('progressRequired', false, 'columnVisibilityGoals'),
    },
    {
      headerName: 'Goal Progress',
      field: 'goalProgress',
      width: 150,
      description: 'Goal Progress',
      hide: handleColumnVisibility('goalProgress', false, 'columnVisibilityGoals'),
    },
    {
      headerName: 'Max Claimable Rounds',
      field: 'maxClaimableRounds',
      width: 200,
      description: 'Max Claimable Rounds',
      hide: handleColumnVisibility('maxClaimableRounds', false, 'columnVisibilityGoals'),
    },
    {
      headerName: 'Rounds Completed',
      field: 'roundsCompleted',
      width: 150,
      description: 'Rounds Completed',
      hide: handleColumnVisibility('roundsCompleted', false, 'columnVisibilityGoals'),
    },
    {
      headerName: 'Reward Type',
      field: 'rewardType',
      width: 150,
      description: 'Reward Type',
      hide: handleColumnVisibility('rewardType', false, 'columnVisibilityGoals'),
    },
    {
      headerName: 'Rewards/round',
      field: 'rewards/round',
      width: 200,
      description: 'Rewards/round',
      hide: handleColumnVisibility('rewards/round', false, 'columnVisibilityGoals'),
    },

    {
      headerName: 'Total Payout',
      field: 'totalPayout',
      width: 200,
      description: 'Total Payout',
      hide: handleColumnVisibility('totalPayout', false, 'columnVisibilityGoals'),
    },

    {
      headerName: 'Rewards/claims pending',
      field: 'rewardsClaimsPending',
      width: 200,
      description: 'Rewards/claims pending',
      hide: handleColumnVisibility('rewardsClaimsPending', false, 'columnVisibilityGoals'),
    },
    {
      headerName: 'Last Reward Claimed Date',
      field: 'lastRewardClaimedDate',
      width: 200,
      description: 'Last Reward Claimed Date',
      hide: handleColumnVisibility('lastRewardClaimedDate', false, 'columnVisibilityGoals'),
      renderCell: (params: GridRenderCellParams) => (
        <>{params?.row?.lastRewardClaimedDate ? params?.row?.lastRewardClaimedDate : 'No Rewards Claimed'}</>
      ),
    },
  ];
};
