import { Box, Collapse, Grid, FormControl, InputLabel, Select, MenuItem, TextField, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React, { ReactElement, useEffect, useMemo } from 'react';

const PREFIX = 'ActivityItem';

const classes = {
  subHeader: `${PREFIX}-subHeader`,
  header: `${PREFIX}-header`,
  greenback: `${PREFIX}-greenback`,
  redBack: `${PREFIX}-redBack`,
  bottomContent: `${PREFIX}-bottomContent`,
  activityItem: `${PREFIX}-activityItem`,
  greenLightBack: `${PREFIX}-greenLightBack`,
  redLightBack: `${PREFIX}-redLightBack`,
  dataGridBoarder: `${PREFIX}-dataGridBoarder`,
  actionDetect: `${PREFIX}-actionDetect`,
  activityModel: `${PREFIX}-activityModel`,
  loadingContainer: `${PREFIX}-loadingContainer`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.subHeader}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiSvgIcon-root': {
      fontSize: '2rem',
    },
  },

  [`& .${classes.header}`]: {
    padding: '1rem 1.5rem',
    marginTop: '1rem',
    borderRadius: '0.25rem',
    fontSize: '1.5rem',
    cursor: 'pointer',
  },

  [`& .${classes.greenback}`]: {
    backgroundColor: '#00e676',
  },

  [`& .${classes.redBack}`]: {
    backgroundColor: '#ef5350',
  },

  [`& .${classes.bottomContent}`]: {
    padding: '1rem 1.5rem',
    marginTop: '1rem',
    borderRadius: '0.25rem',
    backgroundColor: '#ef5350',
  },

  [`& .${classes.activityItem}`]: {
    padding: '1rem',
    lineHeight: '1.7rem',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    color: '#626262',
  },

  [`& .${classes.greenLightBack}`]: {
    backgroundColor: '#c8e6c9 ',
  },

  [`& .${classes.redLightBack}`]: {
    backgroundColor: '#ffcdd2 ',
  },

  [`& .${classes.dataGridBoarder}`]: {
    '& .MuiDataGrid-root': {
      border: '1px solid #bcb7b769',
      '&  .MuiDataGrid-cell': {
        border: '1px solid #bcb7b769',
      },
    },
  },

  [`& .${classes.actionDetect}`]: {
    position: 'fixed',
    width: '100%',
    left: '0',
    bottom: '0',
    right: '0',
    top: '0',
    backgroundColor: '#172b4dd9',
    zIndex: 9999,
  },

  [`& .${classes.activityModel}`]: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    minHeight: 'calc(100% - 1rem)',
    width: 'auto',
    margin: '1.75rem auto',
    maxWidth: '500px',
  },

  [`& .${classes.loadingContainer}`]: {
    padding: '1.25rem',
    flex: '1 1 auto',
    textAlign: 'center',
    backgroundColor: '#2979ff ',
    borderRadius: '0.25rem',
  },
}));

/**
 * Discovery Activity Item
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
interface ItemType {
  id: string;
  title: string;
  success: boolean;
}
interface ActivityType {
  isOpen: boolean;
  item: ItemType;
}

const ActivityItem = (props: ActivityType): ReactElement => {
  const tableData = useMemo(() => {
    const demoDataTable = {
      columns: [
        {
          field: 'id',
          flex: 1,
        },
        {
          field: 'column1',
          flex: 3,
        },
      ],
      rows: [
        {
          id: '#1',
          column1: 'Demo1',
        },
        {
          id: '#2',
          column1: 'Demo2',
        },
        {
          id: '#3',
          column1: 'Demo3',
        },
        {
          id: '#4',
          column1: 'Demo4',
        },
        {
          id: '#5',
          column1: 'Demo5',
        },
        {
          id: '#6',
          column1: 'Demo6',
        },
        {
          id: '#7',
          column1: 'Demo7',
        },
        {
          id: '#8',
          column1: 'Demo8',
        },
        {
          id: '#9',
          column1: 'Demo9',
        },
        {
          id: '#10',
          column1: 'Demo10',
        },
        {
          id: '#11',
          column1: 'Demo11',
        },
        {
          id: '#12',
          column1: 'Demo12',
        },
      ],
    };
    return demoDataTable;
  }, []);

  useEffect(() => {
    // if (props.isOpen !== props.item.id) {
    //   return;
    // }
    // setTimeout(() => {
    //   setLoading(true);
    // }, 500);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 1500);
  }, [props.isOpen]);

  const { item, isOpen } = props;

  return (
    <Root>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Paper className={`${classes.activityItem} ${item.success ? classes.greenLightBack : classes.redLightBack}`}>
          Findings
          <Box>
            <Box>
              <ul>
                <li>Item #1 Explianation</li>
                <li>Item #2 Explianation</li>
                <li>Item #3 Explianation</li>
                <li>Item #4 Explianation</li>
                <li>Item #5 Explianation</li>
              </ul>
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid item sm={6} md={6} lg={6}></Grid>
            <Grid item sm={6} md={6} lg={6} style={{ marginBottom: '20px' }}>
              <Grid container spacing={2}>
                <Grid item sm={4} md={4} lg={4}>
                  <FormControl fullWidth>
                    <InputLabel id="game-label">Game</InputLabel>
                    <Select labelId="game-label" label="game">
                      <MenuItem value={'test'}>test</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={4} md={4} lg={4}>
                  <TextField
                    id="date"
                    label="Birthday"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} md={4} lg={4}>
                  <FormControl fullWidth>
                    <InputLabel id="currency">Currency</InputLabel>
                    <Select labelId="currency" id="currency" label="currency">
                      <MenuItem value={'USD'}>USD</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.dataGridBoarder}>
            <DataGridPro columns={tableData.columns} rows={tableData.rows} autoHeight className={classes.dataGridBoarder} />
          </div>
        </Paper>
      </Collapse>
    </Root>
  );
};

export default ActivityItem;
