import { Button, Grid, Tooltip } from '@mui/material';
import { DeleteOutline, Edit } from '@mui/icons-material';
import React, { Fragment, useRef, useMemo, useEffect } from 'react';
import { toast } from 'react-toastify';
import DataTable, { IDataGridRef } from '../../component/DataTable/DataTable';
import { getPromoCodeData } from '../../services/promoCode.service';
import { useDispatch, useSelector } from 'react-redux';
import { showCreatePromoCodeModalAction, showGlobalDeleteModalAction } from '../../redux/actions/modalActions/modalActions';
import { PROMO_LIST_COLUMN } from '../../constants/columns/promoList.constant';
import { timeConvert } from '../../utils/timeConversion';
import useStylesSkuList from '../SKU/skuListStyle';
import { useCurrentPartner } from '../../hooks/useCurrentPartner';
import { RootState } from '../../redux/reducers/rootReducer';
import { IDeleteData } from '../CurrencyType/CurrencyType';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';

interface IPromoCodeResponse {
  data: IPromoCode[];
  totalRows: number;
}
interface IPromoCode {
  promoCodeId: number;
  code: string;
  promoCodeTypeId: string;
  shortDescription: string;
  description: string;
  startTsz: number;
  endTsz: number;
  instanceExpiryMins: number;
  public: boolean;
  platforms: string[];
  platformNames: string[];
  metadata: {
    ruleId: number;
  };
  usageCount: number;
  skuList: [];
}

const PromoCode = () => {
  const DataGridRef = useRef<IDataGridRef>(null);
  const dispatch = useDispatch();
  const columnClass = useStylesSkuList();
  const { partnerShortName } = useCurrentPartner();

  const promoCodeData = useSelector((state: RootState) => state.modalReducer.showCreatePromoCode);

  const globalDeleteModalData = useSelector((state: RootState) => state.modalReducer.showGlobalDelete);

  const PromoListColumns = useMemo<GridColDef[]>(() => {
    const Final_Column = PROMO_LIST_COLUMN(columnClass, dispatch);
    const All_Column: GridColDef[] = [
      {
        field: 'action',
        headerName: 'Action',
        description: 'Action',
        width: 250,
        renderCell: (params: GridRenderCellParams) => (
          <Fragment>
            {
              <Tooltip title="Edit">
                <Button size="small" style={{ marginLeft: 16 }} onClick={() => dispatch(showCreatePromoCodeModalAction(true, params.row))}>
                  <Edit color="secondary" />
                </Button>
              </Tooltip>
            }
            {params.row?.chainRuleData?.isChained ? (
              <Tooltip title="Cannot Delete Chained Promo Code">
                <Button size="small" style={{ marginLeft: 16 }}>
                  <DeleteOutline color="disabled" />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title="Delete">
                <Button
                  size="small"
                  style={{ marginLeft: 16 }}
                  onClick={() => {
                    const deleteData: IDeleteData = {};
                    deleteData.deleteModule = 'Promo Code';
                    deleteData.deleteId = params.row.id;
                    deleteData.item = {};
                    deleteData.item.value = params.row.id;
                    deleteData.item.label = params.row.code;
                    deleteData.item.item = {};
                    deleteData.item.item.description = params.row.description;
                    dispatch(showGlobalDeleteModalAction(true, deleteData));
                  }}
                >
                  <DeleteOutline color="error" />
                </Button>
              </Tooltip>
            )}
          </Fragment>
        ),
      },
    ];
    return [...Final_Column, ...All_Column];
  }, [PROMO_LIST_COLUMN]);

  const handleDoubleClick = (e: any) => {
    dispatch(showCreatePromoCodeModalAction(true, e.row, false));
  };

  const fetchData = async () => {
    try {
      const res: IPromoCodeResponse = await getPromoCodeData({
        partnerId: partnerShortName,
      });

      console.log({ res });
      const data = res?.data?.map((item: IPromoCode) => {
        return {
          id: item.promoCodeId,
          code: item.code,
          PromoCodeTypeId: item.promoCodeTypeId,
          shortDescription: item.shortDescription,
          description: item.description,
          startTsz: item.startTsz,
          endTsz: item.endTsz,
          instanceExpiryMins: timeConvert(item.instanceExpiryMins),
          isPublic: item.public,
          platforms: item.platforms,
          platformNames: item.platformNames,
          ruleId: item?.metadata?.ruleId,
          chainRuleData: item?.metadata,
          usageCount: item.usageCount,
          skuList: item.skuList,
        };
      });
      return {
        data: data || [],
        totalRows: res?.totalRows,
      };
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  useEffect(() => {
    DataGridRef.current?.fetchData();
  }, [promoCodeData.show]);

  useEffect(() => {
    if (!globalDeleteModalData.show) {
      DataGridRef.current?.fetchData();
    }
  }, [globalDeleteModalData.show]);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DataTable
            columns={PromoListColumns}
            fetchData={fetchData}
            ref={DataGridRef}
            handleDoubleClick={handleDoubleClick}
            isInfiniteScroll
            enableSearch
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PromoCode;
