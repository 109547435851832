import React, { useState, ReactElement, useRef } from 'react';
import Moment from 'moment';
import DataTable, { FetchDataParams, FilterByMenu, IDataGridRef } from '../../component/DataTable/DataTable';
import { API_URLS } from '../../constants/url';
import { Grid } from '@mui/material';
import { getTournamentsData } from '../../services/tournaments.service';
import { TOURNAMENT_ACTIVE_COLUMNS } from '../../constants/columns/tournamentsActive.constant';
import SelectDropdown from '../../component/SelectDropdown/SelectDropdown';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/rootReducer';
import BackendSearchBar from '../../component/BackendSearchBar/BackendSearchBar';
/**
 * Withdraw list
 * @returns {JSX.Element}
 * @constructor
 */
interface ItemType {
  user: { userName: string };
  token: string;
  createdAt: string;
  entryFee: string;
  gameName: string;
  id: number;
  prize: number;
  size: number;
  timeRemaining: string;
  type: string;
  typeCurrency: string;
}

const menuItems: FilterByMenu[] = [
  { item: 'ID', columnName: 'tournaments.id' },
  { item: 'Size', columnName: 'tournaments.size' },
  { item: 'Prize', columnName: 'tournaments.prize' },
  { item: 'Entry Fee', columnName: 'tournaments.entry_fee' },
];

const TournamentsActive = (): ReactElement => {
  const [search, setSearch] = useState('');
  const DataGridRef = useRef<IDataGridRef>(null);
  const [searchBy, setSearchBy] = React.useState<FilterByMenu>({
    item: '',
  });
  const handleChange = (option: FilterByMenu) => {
    setSearchBy(option);
  };

  const startDate = useSelector((state: RootState) => state.commonReducers.startDateSelected);
  const endDate = useSelector((state: RootState) => state.commonReducers.endDateSelected);

  // useEffect(() => {
  //   DataGridRef.current?.fetchData();
  // }, [startDate, endDate]);

  async function fetchEvent({ page, pageSize }: FetchDataParams) {
    const helper = API_URLS.TOURNAMENTS_LIST;
    const response = await getTournamentsData({
      offset: page * pageSize,
      limit: pageSize,
      url: helper,
      search,
      searchBy: searchBy.columnName,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    });

    const rows = response?.results?.map((item: ItemType) => {
      return {
        createdAt: Moment(item.createdAt, 'DD/MM/YYYY, HH:mm:ss a').format('YYYY/MM/DD/ HH:mm:ss'),
        entryFee: item && item.entryFee,
        gameName: item.gameName,
        id: item.id,
        prize: item.prize,
        size: item.size,
        timeRemaining: item.timeRemaining,
        type: item.type,
        typeCurrency: item.typeCurrency,
      };
    });

    return {
      data: rows || [],
      totalRows: response?.totalRows,
    };
  }

  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Grid item xs={10}>
          <BackendSearchBar search={search} setSearch={setSearch} DataGridRef={DataGridRef} />
        </Grid>

        <Grid>
          <SelectDropdown handleChange={handleChange} searchBy={searchBy} menuItems={menuItems} label="Search By" style={{ width: 150 }} />
        </Grid>
      </Grid>
      <DataTable columns={TOURNAMENT_ACTIVE_COLUMNS} checkbox fetchData={fetchEvent} ref={DataGridRef} isInfiniteScroll enableSearch />
    </>
  );
};

export default TournamentsActive;
