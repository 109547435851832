import React, { ReactElement } from 'react';

import Dialog from '@mui/material/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/rootReducer';
import { Box, Button, DialogContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { showSuccessModalAction } from '../../redux/actions/modalActions/modalActions';

const SuccessModal = (): ReactElement => {
  const successModalData = useSelector((state: RootState) => state.modalReducer.showSuccess);
  const successData = successModalData.data || {};

  const dispatch = useDispatch();

  const useStyles = makeStyles({
    divider: {
      backgroundColor: 'black',
      borderBottomWidth: 2,
      marginTop: '25px',
      marginBottom: '25px',
    },

    createButton: {
      backgroundColor: 'rgb(50, 205, 50)',
      color: 'black',
      width: '250px',
      height: '50px',
      textTransform: 'none',
    },

    gridStyle: {
      display: 'flex',
      marginBottom: '20px',
    },

    typographySpacing: {
      marginRight: '20px',
    },
  });
  const classes = useStyles();

  const handleClose = () => {
    dispatch(showSuccessModalAction(false, null));
  };

  return (
    <div>
      <Dialog open={successModalData.show} onClose={handleClose} disableRestoreFocus>
        <DialogContent>
          <Box sx={{ maxWidth: 400 }}>
            <Typography align="center" variant="h5" style={{ marginBottom: '10px' }}>
              {successData.title}
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right', marginTop: '-40px' }}>
              <CloseIcon />
            </IconButton>
            <Divider className={classes.divider} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {successData.content}
                <Button variant="contained" onClick={handleClose} style={{ marginTop: '30px' }} fullWidth>
                  CLOSE
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SuccessModal;
