import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { BreadCrumbConfig, breadCrumbsKey, IBreadCrumbConfig } from '../../constants/breadCrumbConfig';
import { makeStyles } from '@mui/styles';
import { NavLink } from 'react-router-dom';
import { Theme } from '@mui/material';
import { Home } from '@mui/icons-material';
interface Props {
  title?: string;
  breadCrumbKey: breadCrumbsKey;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiBreadcrumbs-ol': {
      marginBottom: '5px',
      justifyContent: 'flex-end',
      '& .MuiBreadcrumbs-li > a': {
        textDecoration: 'none',
        color: theme.palette.text.secondary,
      },
    },
  },
}));

export default function CommonBreadcrumbs(props: Props) {
  const classes = useStyles();
  const { title, breadCrumbKey } = props;
  const breadcrumbsData = BreadCrumbConfig.filter((item: IBreadCrumbConfig) => item.key === breadCrumbKey);
  const breadcrumbs = breadcrumbsData[0]?.breadCrumbs;
  return (
    <div role="presentation" className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumbs.map((item, index) => (
          <NavLink key={index} color="inherit" to={item?.link || '#'} style={!item.link ? { cursor: 'default' } : {}}>
            {item.name === 'Home' && <Home fontVariant={'small'} sx={{ m: '-4px', mr: '1px', height: '0.8em' }} />}
            {item.name}
          </NavLink>
        ))}
        {title && <Typography color="text.primary">{title}</Typography>}
      </Breadcrumbs>
    </div>
  );
}
