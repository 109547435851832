import React, { ReactElement } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';

import { Box, TextareaAutosize, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/rootReducer';
import { showCreateSuccessModalAction, showUndoDeleteTransactionModalAction } from '../../../redux/actions/modalActions/modalActions';
import { makeStyles } from '@mui/styles';
import { undoDeleteTransaction } from '../../../services/transactions.service';
import { toast } from 'react-toastify';
const UndoDeleteTransaction = (): ReactElement => {
  const undoDeleteTransactionData = useSelector((state: RootState) => state.modalReducer.showUndoDeleteTransaction);
  const trnIDs =
    typeof undoDeleteTransactionData.data !== 'number' ? undoDeleteTransactionData?.data?.deleteId : (undoDeleteTransactionData.data as number);
  //Just for testing purpose hardcoded show

  const useStyles = makeStyles({
    divider: {
      backgroundColor: 'grey',
      borderBottomWidth: 2,
      marginTop: '1%',
      marginBottom: '1%',
    },

    button: {
      backgroundColor: 'rgb(205, 50, 50)',
      color: 'black',
      width: '70%',
      height: '20%',
      marginTop: '3%',
      textTransform: 'none',
    },
  });

  const classes = useStyles();

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(showUndoDeleteTransactionModalAction(false, null));
  };

  const handleUndoDeleted = async () => {
    const res = await undoDeleteTransaction({ id: trnIDs || 0 });
    if (res.success) {
      toast.success('Undo-Delete operation of the selected transaction(s) is successful');
      dispatch(showCreateSuccessModalAction(false, { rmvDeleted: true }));
    } else {
      toast.error('Something Is Wrong');
    }
    dispatch(showUndoDeleteTransactionModalAction(false, null));
  };

  return (
    <div>
      <Dialog open={undoDeleteTransactionData.show} onClose={handleClose}>
        <DialogContent className="modal-body">
          <div className="modal-ct">
            <Typography variant="h4" align="center">
              Undo - Delete Transaction(s)
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right', marginTop: '-40px' }}>
              <CloseIcon />
            </IconButton>
            <Divider className={classes.divider} />
            <Typography variant="h6" align="center" style={{ marginTop: '20px' }}>
              Please confirm you would like to Undo - Delete the following record(s) from the database:
            </Typography>
            <Box textAlign="center">
              {trnIDs && (
                <TextareaAutosize
                  maxRows={3}
                  aria-label="maximum height"
                  placeholder="Maximum 4 rows"
                  value={trnIDs}
                  style={{ width: '90%', marginBottom: '5px', marginTop: '1%' }}
                  readOnly
                />
              )}
              <Button className={classes.button} onClick={handleUndoDeleted}>
                Undo-Delete
              </Button>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UndoDeleteTransaction;
