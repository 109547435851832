import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

// const theme = createTheme();
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    margin: '5px 0px',
    minWidth: '100%',
  },
  divider: {
    backgroundColor: 'black',
    borderBottomWidth: 2,
    marginTop: '25px',
    marginBottom: '25px',
  },
  chip: {
    margin: theme.spacing(0.2),
    borderRadius: '1px',
    '& .MuiChip-label': {
      padding: '0px 8px',
      justifyContent: 'left',
    },
  },
  skuChip: {
    justifyContent: 'left',
    display: 'flex',
    '& .MuiChip-root': {
      justifyContent: 'left',
      display: 'flex',
    },
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  entitlementError: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    minHeight: '60px',
    boxShadow: 'none',
    borderColor: 'red',
  },
  gridStyle: {
    marginBottom: '25px',
  },
}));

export default useStyles;
