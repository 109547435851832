import React, { ReactElement } from 'react';

import WithdrawList from './WithdrawalsList';

/**
 * Withdraws history
 * @returns {JSX.Element}
 * @constructor
 */
const WithdrawsHistory = (): ReactElement => {
  return <WithdrawList type="history" />;
};

export default WithdrawsHistory;
