import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { API_URLS } from '../constants/url';
import axiosInstance from '../utils/apis';
import { startLoader, stopLoader } from '../utils/utils';
interface PromoCodeAPIPayload {
  url?: string;
  id?: number;
}

export const getCurrencyTypeList = async (payload: PromoCodeAPIPayload) => {
  try {
    const { url } = payload;
    startLoader();
    const response = await axiosInstance.get(url || '');
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};
export const getCurrencyType = async (payload: PromoCodeAPIPayload) => {
  try {
    startLoader();
    const { url, id } = payload;
    const response = await axiosInstance.get(url + '?id=' + id);
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const deleteCurrencyType = async (payload: { id: number | undefined }) => {
  try {
    startLoader();
    const { id } = payload;
    const response = await axiosInstance.delete(API_URLS.DELETE_CURRENCY_TYPE + '?id=' + id);
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.errType || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.errType);
    }
  }

  return null;
};
interface addCurrencyPayload {
  currencyType: {
    name: string | undefined;
    description: string | undefined;
  };
}
export const addCurrencyType = async (payload: addCurrencyPayload) => {
  try {
    startLoader();
    const response = await axiosInstance.post(API_URLS.ADD_CURRENCY_TYPE, payload);
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};
interface updateCurrencyTypePayload {
  currencyType: {
    currencyTypeId: number | undefined;
    name: string | undefined;
    description: string | undefined;
  };
}
export const updateCurrencyType = async (payload: updateCurrencyTypePayload) => {
  try {
    startLoader();
    const response = await axiosInstance.put(API_URLS.UPDATE_CURRENCY_TYPE, payload);
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};
