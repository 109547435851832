import {
  Grading,
  HomeOutlined,
  LabelOutlined,
  LocalOffer,
  ReceiptLong,
  SupportAgent,
  // Castle,
  Newspaper,
  CurrencyExchange,
  CheckBoxOutlined,
  DisplaySettings,
  SvgIconComponent,
} from '@mui/icons-material';
import { AppMenuItemProps } from '../component/Sidebar/SideBarItem';

import {
  showAvatarsModalAction,
  showCloseAccountModalAction,
  showContactModalAction,
  showMergeAccountModalAction,
  showResetPasswordModalAction,
  showUserUpdateModalAction,
} from '../redux/actions/modalActions/modalActions';
import {
  CommonPermissions,
  DiscoveryPermissions,
  GamePermissions,
  PartnerPermissions,
  PlayerSupportPermissions,
  TournamentPermissions,
  WithdrawalPermissions,
  GoalPermissions,
  MarketingPermissions,
} from './permission';
import { protectedRoutes } from './routeEnum';

export type IAllMenuConfig = {
  [key: string]: any;
};

export const AllMenuConfig: IAllMenuConfig = {
  home: {
    name: 'Home',
    Icon: HomeOutlined,
    accessKey: 'home',
    link: protectedRoutes.home,
    topLevel: true,
    Permissions: [CommonPermissions.Read_Permissions],
  },
  // favorite: {
  //   name: 'Favorites',
  //   accessKey: 'favorites',
  //   link: protectedRoutes.home,
  //   title: 'Favorites',
  //   Permissions: [CommonPermissions.Read_Permissions],
  // },
  // discovery: {
  //   name: 'Discovery',
  //   accessKey: 'discovery',
  //   link: protectedRoutes.dashboard,
  // },
  withdrawalHome: {
    name: 'All',
    accessKey: 'withdrawalHome',
    title: 'Withdrawal All',
    link: protectedRoutes.withdrawals,
    Permissions: [WithdrawalPermissions.Read],
  },
  withdrawalPending: {
    name: 'Pending',
    accessKey: 'withdrawalPending',
    title: 'Withdrawal Pending',
    link: protectedRoutes.pendingWithdrawals,
    Permissions: [WithdrawalPermissions.Read],
  },
  withdrawalHistory: {
    name: 'History',
    accessKey: 'withdrawalHistory',
    title: 'Withdrawal History',
    link: protectedRoutes.historyWithdrawals,
    Permissions: [WithdrawalPermissions.Read],
  },
  // prizeRedeemHome: {
  //   name: 'Home',
  //   accessKey: 'prizeRedeemHome',
  //   link: protectedRoutes.prize,
  // },
  // prizeRedeemPending: {
  //   name: 'Pending',
  //   accessKey: 'prizeRedeemPending',
  //   link: protectedRoutes.prizePending,
  // },
  prizeRedeemDiscovery: {
    name: 'Discovery',
    accessKey: 'prizeRedeemDiscovery',
    title: 'Prize Redeem Discovery',
    link: protectedRoutes.prizeDiscovery,
    Permissions: [PlayerSupportPermissions.Read],
  },
  // prizeReedemHistory: {
  //   name: 'History',
  //   accessKey: 'prizeReedemHistory',
  //   link: protectedRoutes.prizeHistory,
  // },
  playerList: {
    name: 'Player List',
    accessKey: 'playerList',
    title: 'Player List',
    link: protectedRoutes.playerList,
    Permissions: [PlayerSupportPermissions.Read],
  },
  playerPatterns: {
    name: 'Player Pattern',
    accessKey: 'playerPatterns',
    title: 'Player Pattern',
    link: protectedRoutes.playerPatterns,
    Permissions: [PlayerSupportPermissions.Read],
  },
  playerDiscovery: {
    name: 'Discovery',
    accessKey: 'playerDiscovery',
    title: 'Player Discovery',
    Icon: SupportAgent,
    Permissions: [PlayerSupportPermissions.Read],
  },
  playerSupport: {
    name: 'Quick Actions',
    accessKey: 'playerSupport',
    Icon: SupportAgent,
    title: 'Player Support',
    Permissions: [PlayerSupportPermissions.Read],
  },
  redeemSupport: {
    name: 'Redeem',
    accessKey: 'redeemSupport',
    title: 'Redeem Support',
    Icon: SupportAgent,
    Permissions: [PlayerSupportPermissions.Read],
  },
  MMConfiguration: {
    name: 'Configurations',
    accessKey: 'MMConfiguration',
    title: 'Match Making Configuration',
    link: protectedRoutes.matchMakingConfiguration,
    Permissions: [GamePermissions.Read],
  },
  MMPatterns: {
    name: 'Patterns',
    accessKey: 'MMPatterns',
    title: 'Match Making Patterns',
    link: protectedRoutes.matchMakingPatterns,
    Permissions: [GamePermissions.Read],
  },
  MMCashBots: {
    name: 'Cash Bots',
    accessKey: 'MMCashBots',
    title: 'Match Making Cash Bots',
    link: protectedRoutes.matchMakingBotCash,
    Permissions: [GamePermissions.Read],
  },
  MMTokenBots: {
    name: 'Token Bots',
    accessKey: 'MMTokenBots',
    title: 'Match Making Token Bots',
    link: protectedRoutes.matchMakingBotToken,
    Permissions: [GamePermissions.Read],
  },
  MMDiscovery: {
    name: 'Discovery',
    accessKey: 'MMDiscovery',
    title: 'Match Making Discovery',
    link: protectedRoutes.matchMakingDiscovery,
    Permissions: [GamePermissions.Read],
  },
  goalsManagement: {
    name: 'Management',
    accessKey: 'goalsManagement',
    title: 'Goals Management',
    link: protectedRoutes.goalsManagement,
    Permissions: [GoalPermissions.Read],
    PartnerPermissions: [PartnerPermissions.GameTaco],
  },
  tournamentsConfig: {
    name: 'Configurations',
    accessKey: 'tournamentsConfig',
    title: 'Tournaments Configuration',
    link: protectedRoutes.tournamentsConfig,
    Permissions: [TournamentPermissions.Read],
  },
  tournamentsCreate: {
    name: 'Create',
    accessKey: 'tournamentsCreate',
    title: 'Tournaments Create',
    link: protectedRoutes.tournamentCreate,
    Permissions: [TournamentPermissions.Read],
  },
  tournamentsActive: {
    name: 'Active',
    accessKey: 'tournamentsActive',
    title: 'Tournaments Active',
    link: protectedRoutes.tournamentsActive,
    Permissions: [TournamentPermissions.Read],
  },
  tournamentsPatterns: {
    name: 'Patterns',
    accessKey: 'tournamentsPatterns',
    title: 'Tournaments Patterns',
    link: protectedRoutes.tournamentsPatterns,
    Permissions: [TournamentPermissions.Read],
  },
  tournamentsCancelled: {
    name: 'Cancelled',
    accessKey: 'tournamentsCancelled',
    title: 'Tournaments Cancelled',
    link: protectedRoutes.tournamentsCancelled,
    Permissions: [TournamentPermissions.Read],
  },
  tournamentsCompleted: {
    name: 'Completed',
    accessKey: 'tournamentsCompleted',
    title: 'Tournaments Completed',
    link: protectedRoutes.tournamentsCompleted,
    Permissions: [TournamentPermissions.Read],
  },
  tournamentsEvents: {
    name: 'Special Events',
    accessKey: 'tournamentsEvents',
    title: 'Tournaments Special Events',
    link: protectedRoutes.tournamentsEvent,
    Permissions: [TournamentPermissions.Read],
  },
  MKSku: {
    name: 'SKU',
    Icon: LabelOutlined,
    accessKey: 'sku',
    title: 'SKU',
    link: protectedRoutes.skuList,
    Permissions: [MarketingPermissions.Read],
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  MKDailyReturn: {
    name: 'Daily Return',
    Icon: LabelOutlined,
    accessKey: 'dailyReturn',
    title: 'Daily Return',
    link: protectedRoutes.dailyReturnList,
    Permissions: [MarketingPermissions.Read],
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  MKPromoCode: {
    name: 'Promo',
    Icon: LocalOffer,
    accessKey: 'promo',
    title: 'Promo',
    link: protectedRoutes.promoCodeList,
    Permissions: [MarketingPermissions.Read],
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },

  MKAllocateSku: {
    name: 'Allocate Sku',
    Icon: CheckBoxOutlined,
    accessKey: 'allocateSku',
    title: 'Allocate Sku',
    link: protectedRoutes.allocateSku,
    Permissions: [MarketingPermissions.Read],
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  MKSegmentManagement: {
    name: 'Tags',
    Icon: LabelOutlined,
    accessKey: 'tags',
    title: 'Tags',
    link: protectedRoutes.segmentList,
    Permissions: [MarketingPermissions.Read],
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  MKFulfillmentCenter: {
    name: 'Fulfillment Center',
    Icon: LabelOutlined,
    accessKey: 'fulfillmentCenter',
    title: 'Fulfillment Center',
    link: protectedRoutes.fulfillmentList,
    Permissions: [MarketingPermissions.Read],
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  PLCurrencyType: {
    name: 'Currency Type',
    Icon: CurrencyExchange,
    accessKey: 'CurrencyType',
    title: 'Currency Type',
    link: protectedRoutes.currencyType,
    Permissions: [MarketingPermissions.Read],
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  PSUpdateStatus: {
    name: 'Update Status',
    accessKey: 'PSUpdateStatus',
    title: 'Update Status',
    hasClick: true,
    handleItemClick: showUserUpdateModalAction,
    Permissions: [PlayerSupportPermissions.Read],
  },
  PSContact: {
    name: 'Contact',
    accessKey: 'PSContact',
    title: 'Contact',
    hasClick: true,
    handleItemClick: showContactModalAction,
    Permissions: [PlayerSupportPermissions.Read],
  },
  PSResetPassword: {
    name: 'Reset Password',
    accessKey: 'PSResetPassword',
    title: 'Reset Password',
    hasClick: true,
    handleItemClick: showResetPasswordModalAction,
    Permissions: [PlayerSupportPermissions.Read],
  },
  PSAvatars: {
    name: 'Avatars',
    accessKey: 'PSAvatars',
    title: 'Player Avatars',
    hasClick: true,
    handleItemClick: showAvatarsModalAction,
    Permissions: [PlayerSupportPermissions.Read],
  },
  PSDeposits: {
    name: 'Deposits',
    accessKey: 'PSDeposits',
    title: 'Deposits',
    Permissions: [PlayerSupportPermissions.Read],
    PartnerPermissions: [PartnerPermissions.WorldWinner],
    link: protectedRoutes.deposits,
  },
  PSGoals: {
    name: 'Goals',
    accessKey: 'PSGoals',
    title: 'Goals',
    Permissions: [GoalPermissions.Read],
    PartnerPermissions: [PartnerPermissions.FanDuel],
    link: protectedRoutes.goalsList,
  },
  PSCloseAccount: {
    name: 'Close Account',
    accessKey: 'PSCloseAccount',
    title: 'Close Account',
    hasClick: true,
    handleItemClick: showCloseAccountModalAction,
    Permissions: [PlayerSupportPermissions.Read],
  },
  PSMergeAccount: {
    name: 'Merge Accounts',
    accessKey: 'PSMergeAccount',
    title: 'Merge Accounts',
    hasClick: true,
    handleItemClick: showMergeAccountModalAction,
    Permissions: [PlayerSupportPermissions.Read],
  },
  PDTournamentDiscovery: {
    name: 'Tournament',
    accessKey: 'TournamentDiscovery',
    title: 'Tournament Discovery',
    link: protectedRoutes.tournamentDiscovery,
    Permissions: [DiscoveryPermissions.Read],
  },
  PDTransactionDiscovery: {
    name: 'Transaction',
    accessKey: 'TransactionDiscovery',
    title: 'Transaction Discovery',
    link: protectedRoutes.transactionDiscovery,
    Icon: ReceiptLong,
    Permissions: [DiscoveryPermissions.Read],
    PartnerPermissions: [PartnerPermissions.FanDuel],
  },
  PDLocationDiscovery: {
    name: 'Location',
    accessKey: 'LocationDiscovery',
    title: 'Location Discovery',
    link: protectedRoutes.locationDiscovery,
    Permissions: [DiscoveryPermissions.Read],
  },
  PDGameMoves: {
    name: 'Game Moves',
    accessKey: 'GameMoves',
    title: 'Game Moves',
    link: protectedRoutes.locationDiscovery,
    Permissions: [DiscoveryPermissions.Read],
  },
  PSPrizeRedeemHome: {
    name: 'Home',
    accessKey: 'prizeRedeemHome',
    title: 'Prize Redeem Home',
    link: protectedRoutes.prize,
    Permissions: [PlayerSupportPermissions.Read],
  },
  PSPrizeRedeemPending: {
    name: 'Pending',
    accessKey: 'prizeRedeemPending',
    title: 'Prize Redeem Pending',
    link: protectedRoutes.prizePending,
    Permissions: [PlayerSupportPermissions.Read],
  },
  PSPrizeReedemHistory: {
    name: 'History',
    accessKey: 'prizeRedeemHistory',
    title: 'Prize Redeem History',
    link: protectedRoutes.prizeHistory,
    Permissions: [PlayerSupportPermissions.Read],
  },
};

export type IAppMenuItems = {
  name?: string;
  Icon?: SvgIconComponent;
  topLevel?: boolean;
  items?: any[];
  Permissions?: string[];
  PartnerPermissions?: string[];
  accessKey?: string;
  link?: string;
  hasClick?: boolean;
};

export const appMenuItems: AppMenuItemProps[] = [
  {
    ...AllMenuConfig.home,
  },
  {
    name: 'Marketing',
    Icon: Newspaper,
    topLevel: true,
    items: [
      AllMenuConfig.MKPromoCode,
      AllMenuConfig.MKSku,
      AllMenuConfig.MKDailyReturn,
      AllMenuConfig.MKSegmentManagement,
      AllMenuConfig.MKFulfillmentCenter,
    ],
    Permissions: [CommonPermissions.Read_Permissions],
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    name: 'Configurations',
    Icon: DisplaySettings,
    topLevel: true,
    items: [AllMenuConfig.PLCurrencyType],
    Permissions: [CommonPermissions.Read_Permissions],
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  {
    name: 'Player Services',
    Icon: DisplaySettings,
    topLevel: true,
    items: [AllMenuConfig.PSGoals],
    Permissions: [GoalPermissions.Read],
    PartnerPermissions: [PartnerPermissions.FanDuel],
  },
  {
    name: 'Goals',
    Icon: DisplaySettings,
    topLevel: true,
    items: [AllMenuConfig.goalsManagement],
    Permissions: [GoalPermissions.Read],
    PartnerPermissions: [PartnerPermissions.GameTaco],
  },
  {
    name: 'Player Services',
    Icon: DisplaySettings,
    topLevel: true,
    items: [AllMenuConfig.PSDeposits],
    Permissions: [PlayerSupportPermissions.Read],
    PartnerPermissions: [PartnerPermissions.WorldWinner],
  },
  // {
  //   name: 'Dashboard',
  //   Icon: IconDashboard,
  //   topLevel: true,
  //   items: [AllMenuConfig.discovery],
  //   Permissions: [CommunicationPublicRelationPermissions.Read],
  // },
  {
    name: 'Discovery',
    Icon: Grading,
    topLevel: true,
    items: [
      // AllMenuConfig.discovery,
      // AllMenuConfig.playerDiscovery,
      // AllMenuConfig.PDTournamentDiscovery,
      AllMenuConfig.PDTransactionDiscovery,
      // AllMenuConfig.PDLocationDiscovery,
      // AllMenuConfig.PDGameMoves,
      // AllMenuConfig.prizeRedeemDiscovery,
      // AllMenuConfig.MMDiscovery,
    ],
    Permissions: [DiscoveryPermissions.Read],
    PartnerPermissions: [PartnerPermissions.FanDuel],
  },
  // {
  //   name: 'Withdrawals',
  //   // Icon: IconPeople,
  //   topLevel: true,
  //   items: [
  //     AllMenuConfig.withdrawalHome,
  //     AllMenuConfig.withdrawalPending,
  //     AllMenuConfig.withdrawalHistory,
  //   ],
  //   Permissions: [WithdrawalPermissions.Read],
  // },
  // {
  //   name: 'Prize Redeem',
  //   Icon: IconBarChart,
  //   topLevel: true,
  //   items: [
  //     AllMenuConfig.prizeRedeemHome,
  //     AllMenuConfig.prizeRedeemPending,
  //     // AllMenuConfig.prizeRedeemDiscovery,
  //     AllMenuConfig.prizeReedemHistory,

  //   ],
  // },
  // {
  //   name: 'Player Support',
  //   // Icon: IconLibraryBooks,
  //   topLevel: true,
  //   items: [
  //     AllMenuConfig.playerList,
  //     AllMenuConfig.playerPatterns,
  //     {
  //       ...AllMenuConfig.playerDiscovery,
  //       items: [
  //         AllMenuConfig.PDTournamentDiscovery,
  //         AllMenuConfig.PDTransactionDiscovery,
  //         AllMenuConfig.PDLocationDiscovery,
  //         AllMenuConfig.PDGameMoves,
  //       ],
  //     },
  //     {
  //       ...AllMenuConfig.playerSupport,
  //       items: [
  //         AllMenuConfig.PSUpdateStatus,
  //         AllMenuConfig.PSContact,
  //         AllMenuConfig.PSAvatars,
  //         AllMenuConfig.PSResetPassword,
  //         AllMenuConfig.PSMergeAccount,
  //         AllMenuConfig.PSCloseAccount,
  //       ],
  //     },
  //     {
  //       ...AllMenuConfig.redeemSupport,
  //       items: [
  //         AllMenuConfig.PSPrizeRedeemHome,
  //         AllMenuConfig.PSPrizeRedeemPending,
  //         AllMenuConfig.PSPrizeReedemHistory,
  //       ],
  //     },
  //   ],
  //   Permissions: [PlayerSupportPermissions.Read],
  // },
  // {
  //   name: 'Match Maker',
  //   Icon: IconPeople,
  //   topLevel: true,
  //   items: [
  //     AllMenuConfig.MMConfiguration,
  //     AllMenuConfig.MMPatterns,
  //     AllMenuConfig.MMCashBots,
  //     AllMenuConfig.MMTokenBots,
  //   ],
  //   Permissions: [GamePermissions.Read],
  // },
  // {
  //   name: 'Tournaments',
  //   Icon: IconDashboard,
  //   topLevel: true,
  //   items: [
  //     AllMenuConfig.tournamentsConfig,
  //     AllMenuConfig.tournamentsCreate,
  //     AllMenuConfig.tournamentsActive,
  //     AllMenuConfig.tournamentsPatterns,
  //     AllMenuConfig.tournamentsCancelled,
  //     AllMenuConfig.tournamentsCompleted,
  //   ],
  //   Permissions: [TournamentPermissions.Read],
  // },
];
