import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { ArrowDropDown } from '@mui/icons-material';
import { checkPermission } from '../../utils/permission';
import { FormControl, InputLabel, Select } from '@mui/material';

interface IDropDownProps {
  title: string;
  items: {
    name: string;
    disabled?: boolean;
    onClick: () => void;
    Permissions?: string[];
    styleItem?: any;
    enableDropdown?: boolean;
  }[];
  style?: React.CSSProperties;
  orientation?: 'up' | 'down';
  handleFilterChange?: any;
}

export default function DropDown(props: IDropDownProps): React.ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const up = props.orientation === 'up';
  return (
    <>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
        style={props.style}
      >
        {props.title}
      </Button>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: up ? 'top' : 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: up ? 'bottom' : 'top',
          horizontal: 'left',
        }}
      >
        {props.items.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              item.onClick();
              handleClose();
            }}
            disabled={item.disabled || !checkPermission(item.Permissions)}
          >
            <ListItemText primaryTypographyProps={item.styleItem} primary={item.name} />
            {item.enableDropdown && (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Quick Filters</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Quick Filters" onChange={props.handleFilterChange}>
                  <MenuItem value={0}>None</MenuItem>
                  <MenuItem value={30}>Last 30 Days</MenuItem>
                  <MenuItem value={60}>Last 60 Days</MenuItem>
                  <MenuItem value={90}>Last 90 Days</MenuItem>
                </Select>
              </FormControl>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
