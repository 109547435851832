import { Dispatch } from 'react';
import { COMMON_ACTIONS } from '../type';

interface favApiInterface {
  url: string;
  favArray: Array<favArrayInterface>;
}

interface favArrayInterface {
  name?: string | null | undefined;
  title?: string | null | undefined;
  link?: string | null | undefined;
}

export const favApiAction =
  (payload: favApiInterface) =>
  (
    dispatch: Dispatch<{
      type: string;
      payload: {
        request: {
          method: string;
          url: string;
          data: {
            adminUserID: string | null;
            favArray: favArrayInterface[];
          };
        };
      };
    }>,
  ) => {
    const { favArray, url } = payload;

    const adminUserID = localStorage.getItem('adminUserID');

    const value = {
      adminUserID,
      favArray,
    };

    dispatch({
      type: COMMON_ACTIONS.CALL_FAVORITES_API,
      payload: {
        request: {
          method: 'PUT',
          url: url,
          data: value,
        },
      },
    });
  };

export const startDateAction = (payload: Date | null) => {
  return { type: COMMON_ACTIONS.START_DATE_SELECTED, payload: payload };
};

export const endDateAction = (payload: Date | null) => {
  return { type: COMMON_ACTIONS.END_DATE_SELECTED, payload: payload };
};

export const addFavoritesData = (payload: favArrayInterface) => {
  return { type: COMMON_ACTIONS.ADD_FAVORITES_DATA, payload: payload };
};

export const removeFavoritesData = (payload: string | null | undefined) => {
  return { type: COMMON_ACTIONS.REMOVE_FAVORITES_DATA, payload: payload };
};

export const clearFavoritesData = () => {
  return { type: COMMON_ACTIONS.CLEAR_FAVORITES_DATA };
};

export const topNavTools = (payload: Array<string>) => {
  return { type: COMMON_ACTIONS.TOPNAV_TOOLS, payload: payload };
};

export const extraTopNavTools = (payload: Array<string>) => {
  return { type: COMMON_ACTIONS.EXTRA_TOPNAV_TOOLS, payload: payload };
};
