import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { API_URLS } from '../constants/url';
import axiosInstance from '../utils/apis';
import { startLoader, stopLoader } from '../utils/utils';

interface PromoCodeChainRuleAPIPayload {
  url?: string;
  id?: number;
}

export const getChainRuleList = async (payload: PromoCodeChainRuleAPIPayload) => {
  try {
    const { url } = payload;
    startLoader();
    const response = await axiosInstance.get(url || '');
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

interface getChainRuleI {
  id: number;
}

export const getChainRule = async (payload: getChainRuleI) => {
  try {
    startLoader();
    const { id } = payload;
    const response = await axiosInstance.get(API_URLS.GET_PROMO_CODE_CHAIN_RULE + '?id=' + id);
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }
  return null;
};
interface getExpirationStrategyListPayload {
  type?: string;
}
export const getExpirationStrategyList = async (payload: getExpirationStrategyListPayload) => {
  try {
    startLoader();
    const { type } = payload;
    const response = await axiosInstance.get(`${API_URLS.GET_PROMO_CODE_EXPIRATION_STRATEGY}${type ? '?type=' + type : ''}`);

    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const upsertChainRuleData = async (payload: {
  isEdit: boolean;
  type?: string;
  data: {
    ruleId?: number | undefined;
    strategy: {
      parent: string | undefined;
      chain: string[];
      trigger: string;
      expirationStrategy: string;
    };
    parentPromoCodeID: number | undefined;
    unassignChildPromoCodeIds?: string[];
    assignChildPromoCodeIds?: string[];
    unassignParentPromoCodeId?: string[];
    assignParentPromoCodeId?: number | null | undefined;
  };
}) => {
  try {
    startLoader();
    const { isEdit, type, data } = payload;
    if (isEdit) {
      const response = await axiosInstance.put(API_URLS.UPDATE_PROMO_CODE_CHAIN_RULE, data);
      if (response.success) {
        stopLoader();
        return response;
      }
    } else {
      const response = await axiosInstance.post(`${API_URLS.ADD_PROMO_CODE_CHAIN_RULE}${type ? '?type=' + type : ''}`, data);
      console.error({ response });
      if (response.success) {
        stopLoader();
        return response;
      }
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const deletePromoCodeChainRule = async (payload: { id: number | undefined }) => {
  try {
    startLoader();
    const { id } = payload;
    const response = await axiosInstance.delete(API_URLS.DELETE_PROMO_CODE_CHAIN_RULE + '?id=' + id);
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.errType || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.errType);
    }
  }

  return null;
};
