import axios, { AxiosRequestConfig } from 'axios';

const request = (params: AxiosRequestConfig): Promise<any> => {
  // create an axios instance
  const service = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL, // url = base url + request url
    timeout: 40000, // request timeout
  });

  // request interceptor
  service.interceptors.request.use(
    (config) => {
      // do something before request is sent
      // TODO: Add token header
      return config;
    },
    (error) => {
      // do something with request error
      return Promise.reject(error);
    },
  );

  // response interceptor
  service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    (response) => {
      // console.log(response);
      const res = response.data;

      // if the custom code is not 200, it is judged as an error.
      // if (response.status !== 200 || response.status !== 202) {
      //   // 40x: redirect to login
      //   if (response.status >= 400 && response.status < 500) {
      //     window.location.href = '/login';
      //   }
      //   return Promise.reject(new Error(res.message || 'Error'));
      // }
      // else {
      // console.log(res);
      return res;
      // }
    },
    (error) => {
      // const status = error?.response?.status;
      // // 40x: redirect to login
      // if (status >= 400 && status < 500) {
      //   window.location.href = '/login';
      // }
      return Promise.reject(error);
    },
  );

  return service(params);
};

export default request;
