import { useCurrentPartner } from '../hooks/useCurrentPartner';
import { loginDetails } from '../redux/selector';
export const checkPermission = (permission: Array<string> = []) => {
  const userData = loginDetails();
  const permissionList = userData?.permissions || [];
  return permission.every((item) => permissionList.includes(item));
};

export const checkCurrentPartnerPermission = (permission: Array<string> = []) => {
  const { currentPartner } = useCurrentPartner();
  const userData = loginDetails();
  const permissionList = userData?.permissions || [];
  return permission.some((item) => {
    return item.split(':')[1] === currentPartner && permissionList.includes(item);
  });
};
