import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/rootReducer';
import { showGoalListModalAction } from '../../../redux/actions/modalActions/modalActions';
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid-pro';
import { Field, Form } from 'react-final-form';
import InputFieldsTooltip from '../../InputFieldsTooltip';
import DateWrapper from '../../UI/DateWrapper';
import InputWrapper from '../../UI/Input';
import { ExpandMore } from '@mui/icons-material';
import LinearProgress from '@mui/material/LinearProgress';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import VerifiedIcon from '@mui/icons-material/Verified';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';

export default function GoalsListModal() {
  const goalList = useSelector((state: RootState) => state.modalReducer.showGoalList);
  const goalListData = goalList.data;
  console.log(goalList, open);
  console.log(goalListData);
  const dispatch = useDispatch();

  const isReadable = goalList?.isReadable;
  const handleClose = () => {
    dispatch(showGoalListModalAction(false, null));
  };

  const handleSubmitForm = (e: any) => {
    console.log(e);
  };

  return (
    <>
      <Dialog open={goalList.show} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent className="modal-body">
          <Grid>
            <Tooltip title="This is the Display Name for the goal and what the Player sees within the game.">
              <Typography
                align="center"
                variant="h5"
                style={{
                  margin: 'auto',
                  width: 'fit-content',
                }}
              >
                {goalListData?.displayName}
              </Typography>
            </Tooltip>
            <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right', marginTop: '-40px' }}>
              <GridCloseIcon />
            </IconButton>
            <Tooltip title="This is the subtitle for the goal, this is the description the player will see within the Rewards section.">
              <Typography align="center" variant="subtitle1" style={{ margin: 'auto', width: 'fit-content' }}>
                {goalListData?.subtitle}
              </Typography>
            </Tooltip>
            <Divider
              style={{
                backgroundColor: 'black',
                borderBottomWidth: 2,
                marginTop: '25px',
              }}
            />
          </Grid>
          <Grid container item xs={12}>
            <Form onSubmit={handleSubmitForm}>
              {({ handleSubmit }) => {
                return (
                  <form method="post" onSubmit={handleSubmit}>
                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Grid sx={{ marginTop: '5%' }}>
                        <Grid sx={{ display: 'flex' }} columnGap={1}>
                          <Typography sx={{ fontSize: '15px' }}>
                            <span style={{ fontWeight: 'bold' }}>Instance ID</span>: {goalListData?.id}
                          </Typography>

                          <Typography sx={{ fontSize: '15px' }}>
                            <span style={{ fontWeight: 'bold' }}>
                              {' | '}
                              Goal ID
                            </span>
                            : {goalListData?.parentGoalID}
                          </Typography>
                        </Grid>
                        <Box sx={{ display: 'flex', marginTop: '2%' }}>
                          <Typography
                            sx={{
                              fontSize: '15px',
                              fontWeight: 'bold',
                            }}
                          >
                            Status:
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '15px',
                              margin: '0% 3% 0% 3%',
                            }}
                          >
                            {goalListData?.status}
                          </Typography>

                          {goalListData?.status == 'Active' && <PublishedWithChangesIcon sx={{ color: 'blue' }} />}
                          {goalListData?.status == 'Expired' && <HourglassDisabledIcon sx={{ color: '#DEC20B' }} />}
                          {goalListData?.status == 'Completed' && <VerifiedIcon sx={{ color: 'green' }} />}
                        </Box>
                      </Grid>
                      <Grid sx={{ marginTop: '5%' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            marginTop: '2%',
                            justifyContent: 'end',
                            marginRight: '10px',
                          }}
                        >
                          <Tooltip title="Pending Claims">
                            <Badge badgeContent={goalListData?.rewardsClaimsPending} color="error">
                              <RedeemOutlinedIcon color="primary" />
                            </Badge>
                          </Tooltip>
                          {/* <Typography
                            sx={{
                              fontSize: '15px',
                            }}
                          >
                            <span style={{ fontWeight: 'bold' }}>
                              Claims Pending
                            </span>{' '}
                            : {goalListData?.rewardsClaimsPending}
                          </Typography> */}
                        </Box>
                        <Typography sx={{ fontSize: '15px' }}>
                          <span style={{ fontWeight: 'bold' }}>Player ID</span> : {goalListData?.playerId}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid sx={{ display: 'flex', marginTop: '5%' }} columnGap={2}>
                      <Grid item xs={12}>
                        <Box>
                          <Typography variant="body2" color="text.secondary">
                            Max Claimable Rounds
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress
                              variant="determinate"
                              value={Math.round((goalListData?.roundsCompleted * 100) / goalListData?.maxClaimableRounds)}
                            />
                          </Box>
                          <Box sx={{ minWidth: 35 }}>
                            <Typography variant="body2" color="text.secondary">
                              {`${goalListData?.roundsCompleted}/${goalListData?.maxClaimableRounds}`}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid sx={{ display: 'flex', marginTop: '5%' }} columnGap={2}>
                      {goalListData?.status == 'Completed' ? (
                        <>
                          {goalListData?.progressRequired > 1 ? (
                            <>
                              All Rounds Have Been Completed!
                              <VerifiedIcon sx={{ color: 'green' }} />
                              {goalListData?.progressRequired} tasks per round
                            </>
                          ) : (
                            <>
                              All Rounds Have Been Completed!
                              <VerifiedIcon sx={{ color: 'green' }} /> 1 task per round
                            </>
                          )}
                        </>
                      ) : (
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="body2" color="text.secondary">
                              Current Rounds Progress
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: '100%', mr: 1 }}>
                              <LinearProgress
                                variant="determinate"
                                value={Math.round((goalListData?.goalProgress * 100) / goalListData?.progressRequired)}
                              />
                            </Box>
                            <Box sx={{ minWidth: 35 }}>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >{`${goalListData?.goalProgress}/${goalListData?.progressRequired}`}</Typography>
                            </Box>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                    <Grid sx={{ display: 'flex', marginTop: '5%' }} columnGap={2}>
                      {/* <Grid item xs={2}>
                        <Field
                          name="goalID"
                          component={InputWrapper}
                          type="text"
                          readOnly={isReadable}
                          defaultValue={goalListData?.id}
                          placeholder="Goal ID"
                          label={
                            <InputFieldsTooltip
                              title="Goal ID"
                              description="Goal ID Description"
                            />
                          }
                        />
                      </Grid> */}
                      <Grid item xs={6}>
                        <Field
                          name="goalName"
                          component={InputWrapper}
                          type="text"
                          readOnly={isReadable}
                          defaultValue={goalListData?.goalName}
                          placeholder="Goal Name"
                          label={<InputFieldsTooltip title="Goal Name" description="Goal Name Description" />}
                        />
                      </Grid>
                      {/* <Grid item xs={2}>
                        <Field
                          name="rewardsClaimPending"
                          component={InputWrapper}
                          type="text"
                          readOnly={isReadable}
                          defaultValue={goalListData?.rewardsClaimsPending}
                          placeholder="Claims pending"
                          label={
                            <InputFieldsTooltip
                              title="Claims pending"
                              description="Rewards/Claims pending description"
                            />
                          }
                        />
                      </Grid> */}
                      <Grid item xs={6}>
                        {goalListData?.lastRewardClaimedDate ? (
                          <Field
                            name="lastClaimedDate"
                            component={DateWrapper}
                            inputFormat="MM/dd/yyyy HH:mm:ss"
                            defaultValue={goalListData?.lastRewardClaimedDate}
                            readOnly={isReadable}
                            label={<InputFieldsTooltip title="Last Claimed Date" description="Last Claimed Date Description" />}
                          />
                        ) : (
                          <Field
                            name="lastClaimedDate"
                            component={InputWrapper}
                            type="text"
                            readOnly={isReadable}
                            placeholder={'No Rewards Claimed'}
                            label={<InputFieldsTooltip title="Last Claimed Date" description="Last Claimed Date Description" />}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', marginTop: '5%' }} columnGap={2}>
                      <Grid item xs={6}>
                        <Field
                          name="startTsz"
                          component={DateWrapper}
                          // inputFormat="MM/dd/yyyy HH:mm:ss"
                          defaultValue={goalListData?.startDate}
                          readOnly={isReadable}
                          label={<InputFieldsTooltip title="Start Date" description="Start Date (EST) Description" />}
                        ></Field>
                      </Grid>

                      <Grid item xs={6}>
                        {goalListData?.endDate ? (
                          <Field
                            name="endTsz"
                            component={DateWrapper}
                            inputFormat="MM/dd/yyyy HH:mm:ss"
                            placeHolder="Never Ends"
                            readOnly={isReadable}
                            defaultValue={goalListData?.endDate}
                            label={<InputFieldsTooltip title="End Date" description="End Date (EST) Description" />}
                          ></Field>
                        ) : (
                          <Field
                            name="endTsz"
                            component={InputWrapper}
                            type="text"
                            readOnly={isReadable}
                            placeholder={'Never Ends'}
                            label={<InputFieldsTooltip title="End Date" description="End Date (EST) Description" />}
                          />
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ display: 'flex', marginTop: '5%' }} columnGap={2}>
                      <Grid item xs={6}>
                        <Field
                          name="rewardType"
                          component={InputWrapper}
                          type="text"
                          readOnly={isReadable}
                          defaultValue={goalListData?.rewardType}
                          placeholder="Reward Type"
                          label={<InputFieldsTooltip title="Reward Type" description="Reward Type description" />}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Field
                          name="rewards/round"
                          component={InputWrapper}
                          type="text"
                          readOnly={isReadable}
                          defaultValue={goalListData?.rewardsRound}
                          placeholder="Rewards/Round"
                          label={<InputFieldsTooltip title="Rewards/Round" description="Rewards/Round description" />}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Field
                          name="totalPayout"
                          component={InputWrapper}
                          type="text"
                          readOnly={isReadable}
                          defaultValue={goalListData?.totalPayout}
                          placeholder="Total Payout"
                          label={<InputFieldsTooltip title="Total Payout" description="Total Payout description" />}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: '5%' }}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography sx={{ width: '33%', flexShrink: 0 }}>Advanced</Typography>
                          <Typography sx={{ color: 'text.secondary', fontSize: '0.9rem' }}>Description, Universe, Type, Category</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Grid item xs={12} sx={{ marginTop: '5%' }}>
                            <Field
                              name="description"
                              component={InputWrapper}
                              type="text"
                              readOnly={isReadable}
                              defaultValue={goalListData?.description}
                              placeholder="Description"
                              label={<InputFieldsTooltip title="Description" description=" Description" />}
                            />
                          </Grid>

                          <Grid item xs={12} sx={{ marginTop: '5%' }}>
                            <Field
                              name="segment/universe"
                              component={InputWrapper}
                              type="text"
                              readOnly={isReadable}
                              defaultValue={goalListData?.segmentUniverse}
                              placeholder="Segment/Universe"
                              label={<InputFieldsTooltip title="Segment/Universe" description="Segment/Universe description" />}
                            />
                          </Grid>
                          <Grid sx={{ display: 'flex' }} columnGap={2}>
                            <Grid item xs={6} sx={{ marginTop: '5%' }}>
                              <Field
                                name="goalType"
                                component={InputWrapper}
                                type="text"
                                readOnly={isReadable}
                                defaultValue={goalListData?.goalType}
                                placeholder="Goal Type"
                                label={<InputFieldsTooltip title="Goal Type" description="Goal Type description" />}
                              />
                            </Grid>

                            <Grid item xs={6} sx={{ marginTop: '5%' }}>
                              <Field
                                name="goalCategory"
                                component={InputWrapper}
                                type="text"
                                readOnly={isReadable}
                                defaultValue={goalListData?.goalCategory}
                                placeholder="Goal Category"
                                label={<InputFieldsTooltip title="Goal Category" description="Goal Category description" />}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </form>
                );
              }}
            </Form>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
