import { Grid } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { IDataGridRef } from '../../component/DataTable/DataTable';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FloatingButton from '../../component/FloatingButton/FloatingButton';
import { MarketingPermissions } from '../../constants/permission';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/rootReducer';

import CommonBreadcrumbs from '../../component/BreadCrumb/BreadCrumb';

import TagList from '../TagSegments/TagList';
import ArchivedTags from './ArchivedTags';

import { breadCrumbsKey } from '../../constants/breadCrumbConfig';
import { showCreateSegmentFulfillmentModalAction, showCreateSegmentModalAction } from '../../redux/actions/modalActions/modalActions';
import AddIcon from '@mui/icons-material/Add';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const TagDashboard = () => {
  const DataGridRef = useRef<IDataGridRef>(null);
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const bulkActions = [
    {
      action: 'Create Tag',
      onClick: () => {
        console.log('Create Tag Clicked');
        dispatch(showCreateSegmentModalAction(true, null));
      },
      icon: <AddIcon />,
      Permissions: [MarketingPermissions.Create],
    },
    {
      action: 'Allocate Tags',
      onClick: () => {
        console.log('Create Tag Clicked');
        dispatch(showCreateSegmentFulfillmentModalAction(true, null));
      },
      icon: <AddIcon />,
      Permissions: [MarketingPermissions.Create],
    },
  ];

  const handleCreateModel = () => {
    console.log('FAB Clicked');
  };

  const updateReloadTable = useSelector((state: RootState) => state.modalReducer.showCreatePromoCode);

  useEffect(() => {
    if (!updateReloadTable.show) {
      DataGridRef.current?.fetchData();
    }
  }, [updateReloadTable.show]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Tabs variant="scrollable" value={tabValue} onChange={handleTabChange} aria-label="Tag Tabs">
            <Tab label="Tag List" {...a11yProps(0)} />
            <Tab label="Archived" {...a11yProps(1)} />
          </Tabs>
        </Grid>
        <Grid item xs={4} justifyContent="flex-end" sx={{ display: 'flex' }}>
          <CommonBreadcrumbs title="Tags" breadCrumbKey={breadCrumbsKey.tags} />
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={tabValue} index={0}>
            <TagList />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ArchivedTags />
          </TabPanel>
        </Grid>
      </Grid>

      <FloatingButton bulkActions={bulkActions} floatingButtonClick={handleCreateModel} fabPermissions={[MarketingPermissions.Create]} />
    </>
  );
};

export default TagDashboard;
