import React, { useState, useMemo, useRef, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DataTable, { FilterByMenu, IDataGridRef } from '../../component/DataTable/DataTable';
import { DEPOSITS_COLUMNS } from '../../constants/columns/deposits.constant';
import BackendSearchBar from '../../component/BackendSearchBar/BackendSearchBar';
import { GridColDef, GridRenderCellParams, GridRowId, GridRowModel, useGridApiRef } from '@mui/x-data-grid-pro';
import useStylesSkuList from '../SKU/skuListStyle';
import { Button, Grid, TextField, Tooltip } from '@mui/material';
import SelectDropdown from '../../component/SelectDropdown/SelectDropdown';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { getDepositTransactions } from '../../services/playerSupport.service';
import { showUpsertSkipDepositInterface, showUpsertSkipDepositModalAction } from '../../redux/actions/modalActions/modalActions';

interface toolTipOption {
  title?: string;
  hidden?: boolean;
}

const Deposits = (props: any): JSX.Element => {
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();

  const DataGridRef = useRef<IDataGridRef>(null);
  const [search, setSearch] = useState<string>('');
  const [txnList, setTxnList] = useState<string>('');
  const [toolTipOptions] = useState<toolTipOption>({
    title: 'Skip',
    hidden: false,
  });
  const columnClass = useStylesSkuList();

  const [searchBy, setSearchBy] = React.useState<FilterByMenu>({
    item: 'User ID',
  });

  // Hide actions on history type
  const columns = useMemo<GridColDef[]>(() => {
    const Final_Column = DEPOSITS_COLUMNS(columnClass);
    const All_Column: GridColDef[] = [
      {
        field: 'action',
        headerName: 'Action',
        description: 'Action',
        width: 250,
        renderCell: (params: GridRenderCellParams) => (
          <Fragment>
            <Tooltip title={params.row?.isSkippedTransaction ? 'Update Skipped Reason' : 'Skip Transaction'} hidden={toolTipOptions?.hidden}>
              <Button
                size="small"
                style={{ marginLeft: 16 }}
                // disabled={params?.row?.isSkippedTransaction}
                onClick={() => {
                  const getSelectRows: Map<GridRowId, GridRowModel> = apiRef.current.getSelectedRows();

                  const txnList: showUpsertSkipDepositInterface[] = [];
                  let depositTransactions: showUpsertSkipDepositInterface;
                  if (getSelectRows.size < 1) {
                    depositTransactions = {
                      id: params?.row?.id,
                      bt_txn_id: params?.row?.braintreeId,
                      remaining_balance: params?.row?.remainingBalance,
                      isSkippedReason: params?.row?.isSkippedReason || 'Please set a Reason',
                      skuId: params?.row?.skuId,
                      isSkippedTransaction: params?.row?.isSkippedTransaction,
                    };
                    txnList.push(depositTransactions);
                  } else {
                    getSelectRows.forEach((deposit) => {
                      depositTransactions = {
                        id: deposit?.id,
                        bt_txn_id: deposit?.braintreeId,
                        remaining_balance: deposit?.remainingBalance,
                        isSkippedReason: deposit?.isSkippedReason || 'Please set a Reason',
                        skuId: deposit?.skuId,
                        isSkippedTransaction: deposit?.isSkippedTransaction,
                      };
                      txnList.push(depositTransactions);
                    });
                  }

                  console.log({ txnList });

                  const rowData = params.row;
                  console.log({ rowData });

                  dispatch(showUpsertSkipDepositModalAction(true, txnList));
                }}
              >
                <SkipNextIcon color="secondary" />
              </Button>
            </Tooltip>
          </Fragment>
        ),
      },
    ];
    return [...Final_Column, ...All_Column];
  }, [DEPOSITS_COLUMNS]);

  const menuItems: FilterByMenu[] = [
    { item: 'User ID', columnName: 'user.id' },
    // { item: 'Username', columnName: 'user.user_name' },
  ];

  const handleChange = (option: FilterByMenu) => {
    setSearchBy(option);
  };

  const handleDoubleClick = (e: any) => {
    console.log({ e });
    const txnList: showUpsertSkipDepositInterface[] = [
      {
        id: e?.row?.id,
        bt_txn_id: e?.row?.braintreeId,
        remaining_balance: e?.row?.remainingBalance,
        isSkippedReason: e?.row?.isSkippedReason || 'Please set a Reason',
        skuId: e?.row?.skuId,
        isSkippedTransaction: e?.row?.isSkippedTransaction,
      },
    ];
    dispatch(showUpsertSkipDepositModalAction(true, txnList, true));
  };

  const [pageLoad, setPageLoad] = useState<boolean>(false);

  useEffect(() => {
    setPageLoad(true);
    // stopLoader();
  }, []);

  async function fetchEvent() {
    console.log({ search, searchBy, txnList });
    console.log({ txnList });
    const propType = props.type !== 'history';

    console.log({ propType });
    // startLoader();
    if (pageLoad) {
      const response = await getDepositTransactions({
        search,
        searchBy: searchBy.item,
        txnList,
      });
      // stopLoader();
      const rowsData = response?.data?.map((value: any) => {
        return {
          id: value.ext_transaction_aux_id,
          braintreeId: value.bt_txn_id,
          skuId: value.sku_id,
          remainingBalance: value.remaining_balance,
          isSkippedTransaction: value?.metadata?.transaction_skipped || false,
          isSkippedReason: value?.metadata?.reason || '',
        };
      });

      return {
        data: rowsData || [],
        // totalRows: response?.totalRows,
        totalRows: 1,
      };
    } else {
      return {
        data: [],
        totalRows: 0,
      };
    }
  }

  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: '10px',
        }}
      >
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Transactions"
            value={txnList}
            onChange={(event) => {
              setTxnList(event.target.value);
            }}
            variant="outlined"
            // style={{ display: 'flex', marginTop: 10 }}
          />
        </Grid>

        <Grid item xs={4}>
          <BackendSearchBar search={search} setSearch={setSearch} DataGridRef={DataGridRef} />
        </Grid>

        <Grid item>
          <SelectDropdown handleChange={handleChange} searchBy={searchBy} menuItems={menuItems} label="Search By" style={{ width: 150 }} />
        </Grid>
      </Grid>

      <DataTable
        columns={columns}
        checkbox={props.type !== 'history'}
        fetchData={fetchEvent}
        apiReference={apiRef}
        ref={DataGridRef}
        enableSearch
        handleDoubleClick={handleDoubleClick}
      />
    </>
  );
};

export default Deposits;
