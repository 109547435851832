import React, { useState, ReactElement, useMemo, useRef } from 'react';
import Moment from 'moment';
import DataTable, { FetchDataParams, FilterByMenu, IDataGridRef } from '../../component/DataTable/DataTable';
import WithdrawHelper from '../../utils/withdraw';
import { API_URLS } from '../../constants/url';
import { toast } from 'react-toastify';
import { getWithdrawalsData } from '../../services/withdraw.service';
import { Grid } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import DropDown from '../../component/DropDown/DropDown';
import { DialogModel } from '../../component/Model/DialogModel';
import { AxiosError } from 'axios';
import { WITHDRAWALS_LIST_COLUMNS } from '../../constants/columns/withdrawalsList.constant';
import SelectDropdown from '../../component/SelectDropdown/SelectDropdown';
import { startLoader, stopLoader } from '../../utils/utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/rootReducer';
import BackendSearchBar from '../../component/BackendSearchBar/BackendSearchBar';
import { WithdrawalPermissions } from '../../constants/permission';

/**
 * Withdraw list
 * @returns {JSX.Element}
 * @constructor
 */
interface WithdrawListType {
  type: string;
}
interface logType {
  id: number;
  createdAt: string;
  updatedAt: string;
  type: string;
  status: string;
  game_version: string;
  submittedData: {
    email: string;
    uname: string;
    amount: number;
    prizeid: number;
    prizename: string;
  };
  amount: string;
}
export interface ItemType {
  TransactionLog: logType;
  user: { userName: string };
  token: string;
}

const menuItems: FilterByMenu[] = [
  { item: 'Email', columnName: 'user.email' },
  { item: 'Username', columnName: 'user.user_name' },
  { item: 'Amount', columnName: 'TransactionLog.amount' },
];

const WithdrawList = (props: WithdrawListType): ReactElement => {
  const [selectedItems, setSelectedItems] = useState<ItemType[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  let new_column: GridColDef[] = [];
  const [search, setSearch] = useState<string>('');
  const [searchBy, setSearchBy] = React.useState<FilterByMenu>({
    item: '',
  });

  const handleChange = (option: FilterByMenu) => {
    setSearchBy(option);
  };
  const DataGridRef = useRef<IDataGridRef>(null);
  const startDate = useSelector((state: RootState) => state.commonReducers.startDateSelected);
  const endDate = useSelector((state: RootState) => state.commonReducers.endDateSelected);
  // Hide actions on history type
  const columns = useMemo<GridColDef[]>(() => {
    if (props.type !== 'history') {
      new_column = [
        {
          headerName: 'Action',
          field: 'action',
          width: 250,
          renderCell: (params: GridRenderCellParams) => {
            const item = params.value as ItemType;
            const status = item.TransactionLog.status || '';
            const disableProcess = status.match(/(Processed|Refunded|Fraud|Cancelled)/);
            return (
              <>
                <DropDown
                  title="Action"
                  items={[
                    {
                      name: 'Process Withdraw',
                      disabled: disableProcess ? true : false,
                      onClick: () => {
                        processWithdraw(params.value as ItemType);
                      },
                      Permissions: [WithdrawalPermissions.Update],
                    },
                    {
                      name: 'Analyze account',
                      onClick: () => {
                        analyzeAccount(params.value as ItemType);
                      },
                      Permissions: [WithdrawalPermissions.Update],
                    },
                  ]}
                />
              </>
            );
          },
        },
      ];
    }
    return [...WITHDRAWALS_LIST_COLUMNS, ...new_column] as GridColDef[];
  }, [props.type]);

  // Process withdraw
  const processWithdraw = (item: ItemType) => {
    // setLoading(true);
    toast.info('Feature under development!');
    // eslint-disable-next-line no-console
    console.log('process', item);
    // WithdrawHelper.processWithdrawInBatches({
    //   withdraws: [item]
    // }).then(response => {
    //   toast.info(
    //     response && response.message
    //   );
    // }).catch(e => {
    //   console.log(e);
    // }).finally(() => {
    //   setLoading(false);
    // });
  };

  // Analyze account
  const analyzeAccount = (item: ItemType) => {
    toast.info('Feature under development!');
    // setLoading(true);
    // eslint-disable-next-line no-console
    console.log('analyze', item);
    // TODO
    // setTimeout(() => {
    //   setLoading(false);
    // }, 2000);
  };

  // Process batch in batches
  const processWithdrawInBatches = () => {
    setShowModal(true);
  };

  // Confirm process withdraws
  const doProcessWithdrawsInBatches = () => {
    toast.info('Feature under development!');
    // setShowModal(false);
    // setLoading(true);
    WithdrawHelper.processWithdrawInBatches({
      withdraws: selectedItems,
    })
      .then((response: { message: string }) => {
        toast.info(response && response.message);
      })
      .catch((e: AxiosError) => {
        console.error(e);
      });
  };

  // Items selected
  const onItemsSelected = (items: ItemType[]) => {
    setSelectedItems(items);
  };

  // Toggle modal
  const toggleModal = () => {
    setShowModal(false);
  };

  const batchMenus = (
    <Grid item lg={4}>
      <div className="batch-actions">
        <DropDown
          title="Select Actions"
          items={[
            {
              name: 'Process Selected Withdrawals',
              onClick: processWithdrawInBatches,
            },
          ]}
        />
      </div>
    </Grid>
  );

  async function fetchEvent({ page, pageSize }: FetchDataParams) {
    let helper;
    switch (props.type) {
      case 'pending':
        helper = API_URLS.WITHDRAWALS_PENDING;
        break;
      case 'history':
        helper = API_URLS.WITHDRAWALS_HISTORY;
        break;
      case 'all':
        helper = API_URLS.WITHDRAWALS_REQUEST;
        break;
    }
    startLoader();
    const response = await getWithdrawalsData({
      offset: page * pageSize,
      limit: pageSize,
      url: helper,
      search,
      searchBy: searchBy.columnName,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    });
    const rows = response?.results?.map((item: ItemType) => {
      const status = item.TransactionLog.status || '';
      return {
        id: item.TransactionLog.id,
        playerName: item.user && item.user.userName,
        amount: '$' + item.TransactionLog.amount,
        date: Moment(item.TransactionLog.createdAt).format('YYYY/MM/DD/ HH:mm:ss'),
        status,
        action: item,
      };
    });
    stopLoader();
    return {
      data: rows || [],
      totalRows: response?.totalRows,
    };
  }
  // useEffect(() => {
  //   DataGridRef.current?.fetchData();
  // }, [startDate, endDate]);
  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: '10px',
        }}
      >
        <Grid item xs={10}>
          <BackendSearchBar search={search} setSearch={setSearch} DataGridRef={DataGridRef} />
        </Grid>

        <Grid>
          <SelectDropdown handleChange={handleChange} searchBy={searchBy} menuItems={menuItems} label="Search By" style={{ width: 150 }} />
        </Grid>
      </Grid>
      <DataTable
        columns={columns}
        checkbox={props.type !== 'history'}
        fetchData={fetchEvent}
        onItemsSelected={onItemsSelected}
        ref={DataGridRef}
        batchMenus={props.type === 'history' ? null : batchMenus}
        enableSearch
      />

      <DialogModel
        isOpen={showModal}
        onCloseModel={toggleModal}
        title="Are you sure you want to process withdrawals ?"
        subTitle="This Process can't be undo"
        actionButtons={[
          {
            name: 'Done',
            onClick: doProcessWithdrawsInBatches,
          },
        ]}
      />
    </>
  );
};

export default WithdrawList;
