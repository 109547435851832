import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Tooltip,
  Chip,
  Autocomplete,
  InputLabel,
  ListItemText,
  OutlinedInput,
  SelectChangeEvent,
  MenuItem,
  Select,
} from '@mui/material';
import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showAllocateSkuAction } from '../../../redux/actions/modalActions/modalActions';
import { RootState } from '../../../redux/reducers/rootReducer';
import CloseIcon from '@mui/icons-material/Close';
import { Help, SearchOutlined } from '@mui/icons-material';
import { API_URLS } from '../../../constants/url';
import './skuStyle.css';

import { allocateSkuData, getAllTag, getSkuTagBySkuId } from '../../../services/allocateSku.service';

import { getPromoCodeData, getPromoCodeBySkuId, getPlatformList } from '../../../services/promoCode.service';
import { getSkuList, upsertSKUData } from '../../../services/sku.services';

//Accordian
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toast } from 'react-toastify';
import InputFieldsTooltip from '../../InputFieldsTooltip';

const steps = [
  { label: 'Select SKU', description: 'Select a SKU to Allocate' },
  { label: 'Whitelist Tags', description: 'Select Tags to Whitelisted' },
  { label: 'Blacklist Tags', description: 'Select Tags to Backlisted' },
  /*{ label: 'Public Tags', description: 'Select which Tags will be Public' },*/
  {
    label: 'Promo Codes',
    description: 'Select which Promo Codes use this SKU',
  },
  { label: 'Review', description: 'Review allocations before submitting' },
];

interface tagType {
  tagId: number;
  tagName: string;
  tagType: number;
}

interface ISKUTagData {
  tagId: number;
  name: string;
}

interface SKuDataType {
  skuId: number;
  whitelist: Array<number>;
  blacklist: Array<number>;
  publiclist: Array<number>;
  promoCodelist: Array<number>;
}

interface SKUListItem {
  description?: string;
  entitlements?: {
    bonusbuck: number;
    realmoney: number;
    gamecredit: number;
    rewardpoint: number;
  };
  isBaseSku?: boolean;
  isEnabled?: boolean;
  name: string;
  platformNames?: string[];
  platforms?: string[];
  price?: number;
  priceCurrencyTypeId?: number;
  skuId?: number;
  metadata?: {
    bestValue?: boolean;
  };
}

interface PromoListItem {
  advertiserId: string;
  code: string;
  created: number;
  description: string;
  endTsz: number;
  instanceExpiryMins: number;
  metadata: {
    ruleId: number;
    isChained: boolean;
    isChainStart: boolean;
    hide_code: boolean;
  };
  partnerTagId: number;
  platformNames: string[];
  platforms: null | Array<string>;
  promoCodeId: number;
  promoCodeTypeId: number;
  public: boolean;
  shortDescription: string;
  startTsz: number;
  tagId: number;
  updated: number;
}

interface PromoRes {
  success: boolean;
  data: [PromoListItem];
}

let isTagSet = false;
const AllocateSkuModal = (): ReactElement => {
  const [expanded, setExpanded] = React.useState<string>('');

  const handleAccordianChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };
  const dispatch = useDispatch();
  const showAllocateSkuModal = useSelector((state: RootState) => state.modalReducer.showAllocateSku);

  const handleClose = () => {
    setActiveStep(0);
    setSelectedPromoCodes([]);
    setAllocateSkuData({
      skuId: 0,
      whitelist: [],
      blacklist: [],
      publiclist: [],
      promoCodelist: [],
    });
    dispatch(showAllocateSkuAction(false, null));
    setSkuData(null);
    setSkuList([]);
    setPlatform([]);
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    setFilterText('');

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const id: number | undefined = showAllocateSkuModal?.data?.id || showAllocateSkuModal?.data?.skuId;
  const [Tags, setTags] = useState<tagType[]>([]);
  const [AllocateSkuData, setAllocateSkuData] = useState<SKuDataType>({
    skuId: 0,
    whitelist: [],
    blacklist: [],
    publiclist: [],
    promoCodelist: [],
  });
  const [skuTagData, setSkuTagData] = useState<ISKUTagData[]>([]);
  const [skuList, setSkuList] = useState<SKUListItem[]>([]);
  const [promoCodeList, setPromoCodeList] = useState<PromoListItem[]>([]);
  const [selectedPromoCode, setSelectedPromoCodes] = useState<number[]>([]);
  const [filterText, setFilterText] = useState<string>('');
  const [skuData, setSkuData] = useState<SKUListItem | undefined | null>(null);
  const handleSetSku = async (event: SyntheticEvent<Element, Event>, option: SKUListItem | null) => {
    if (option?.skuId) {
      setSkuTagBySkyID(option.skuId);
      setSkuData(option);
      setPlatform(option?.platformNames || []);
    }
  };
  const setSkuTagBySkyID = async (skuId: number) => {
    setAllocateSkuData({ ...AllocateSkuData, skuId });
    try {
      const res = await getSkuTagBySkuId({ id: skuId });
      const promoCodeResult = await getPromoCodeBySkuId({ id: skuId });

      promoCodeResult?.data?.map((data: PromoListItem) => {
        selectedPromoCode.push(data.promoCodeId);
      });

      if (res.data) {
        const tempAllocateSkuData: SKuDataType = {
          skuId,
          whitelist: [],
          blacklist: [],
          publiclist: [],
          promoCodelist: [],
        };
        let newTag = [...Tags];
        if (newTag.length > 0) {
          //empty tag and data
          setAllocateSkuData({ ...tempAllocateSkuData });

          newTag = Tags.map((tag: tagType) => {
            return {
              ...tag,
              tagType: 0,
            };
          });
          setTags([...newTag]);
          res?.data?.forEach((element: { tagId: number; isWhiteListed: number[]; isBlackListed: number[] }) => {
            const index = Tags.findIndex((item: tagType) => item.tagId === element.tagId);
            if (element.isWhiteListed) {
              tempAllocateSkuData.whitelist.push(element.tagId);
              if (index >= 0) {
                newTag[index].tagType = 1;
              }
            } else if (element.isBlackListed) {
              tempAllocateSkuData.blacklist.push(element.tagId);
              if (index >= 0) {
                newTag[index].tagType = 2;
              }
            } else {
              tempAllocateSkuData.publiclist.push(element.tagId);
              newTag[index].tagType = 3;
            }
          });
          setTags([...newTag]);
          setAllocateSkuData({ ...tempAllocateSkuData });
        }
      }
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    try {
      e.preventDefault();
      const tempAllocateSkuData: SKuDataType = {
        skuId: AllocateSkuData.skuId,
        whitelist: [],
        blacklist: [],
        publiclist: [],
        promoCodelist: [],
      };
      tempAllocateSkuData.promoCodelist = [...selectedPromoCode];
      Tags.forEach((element: tagType) => {
        if (element.tagType === 1) {
          tempAllocateSkuData.whitelist.push(element.tagId);
        } else if (element.tagType === 2) {
          tempAllocateSkuData.blacklist.push(element.tagId);
        } else if (element.tagType === 3) {
          tempAllocateSkuData.publiclist.push(element.tagId);
        }
      });
      setAllocateSkuData({ ...tempAllocateSkuData });
      await updateSkuData();
      const res = await allocateSkuData({ data: tempAllocateSkuData });
      if (res.success === true) {
        handleClose();
        const newTag = Tags.map((tag: tagType) => {
          return {
            ...tag,
            tagType: 0,
          };
        });
        setTags([...newTag]);
        toast.success('Sku Tag Updated Successfully');
      } else {
        toast.error('Something Is Wrong');
      }
    } catch (err) {
      toast.error(`${err}`);
    }
  };

  const updateSkuData = async () => {
    try {
      const bodyData: any = {
        sku: {
          ...skuData,
        },
      };
      const res = await upsertSKUData({ isEdit: true, data: bodyData });
      if (res?.success) {
        toast.success('SKU successfully updated!');
      } else {
        toast.error('Something Is Wrong');
      }
    } catch (err: any) {
      toast.error(`${err.message}`);
    }
  };

  const handlePromoListChange = (e: React.ChangeEvent<HTMLInputElement>, promoCode: PromoListItem) => {
    // const { name, value, checked } = event.currentTarget;
    if (e.target.checked === true) {
      setSelectedPromoCodes([...selectedPromoCode, promoCode.promoCodeId]);
    } else if (e.target.checked === false) {
      setSelectedPromoCodes(selectedPromoCode.filter((code: number) => code !== promoCode.promoCodeId));
    }
  };

  const handleAllSelect = (activeStep: number) => {
    if (activeStep === 4) {
      const promoAllSelect: number[] = [];
      promoCodeList.map((promoCodeData) => {
        promoAllSelect.push(promoCodeData.promoCodeId);
      });
      setSelectedPromoCodes(promoAllSelect);
    } else {
      const newTags: tagType[] = Tags.map((tag: tagType) => {
        tag.tagType = activeStep;
        return tag;
      });
      setTags(newTags);
    }
  };

  const handleNoneSelect = () => {
    if (activeStep === 4) {
      const promoAllSelect: number[] = [];
      setSelectedPromoCodes(promoAllSelect);
    } else {
      const newTags: tagType[] = Tags.map((tag: tagType) => {
        tag.tagType = 0;
        return tag;
      });
      setTags(newTags);
    }
  };
  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.currentTarget;
    const indexOfTag = Tags.findIndex((item: tagType) => item.tagName === name);
    const newTag = [...Tags];
    newTag[indexOfTag].tagType = checked === false ? 0 : value === 'white' ? 1 : value === 'black' ? 2 : 3;
    setTags(newTag);
  };

  const accordionData = [
    {
      panelName: 'panel1',
      tagName: 'Whitelist Tags',
      ariaControls: 'panel1bh-content',
      id: 'panel1bh-header',
      tagTypeValue: 1,
      checkboxValue: 'white',
    },
    {
      panelName: 'panel2',
      tagName: 'Blacklist Tags',
      ariaControls: 'panel2bh-content',
      id: 'panel2bh-header',
      tagTypeValue: 2,
      checkboxValue: 'black',
    },

    /*{
      panelName: 'panel3',
      tagName: ' Public Tags',
      ariaControls: 'panel3bh-content',
      id: 'panel3bh-header',
      tagTypeValue: 3,
      checkboxValue: 'public',
    },*/
  ];

  // const [showDescription, setShowDescription] = useState('');
  // const handleMenuItem = (description: string) => {
  //   setShowDescription(description);
  // };

  const [platform, setPlatform] = React.useState<string[]>([]);
  const [platformListDisplay, setPlatformList] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof platform>) => {
    const {
      target: { value },
    } = event;
    setPlatform(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  useEffect(() => {
    const data = skuTagData?.map((tags: ISKUTagData) => {
      return {
        tagId: tags.tagId,
        tagName: tags.name,
        tagType: 0,
      };
    });
    setTags([...data]);
    isTagSet = true;
  }, [skuTagData]);

  useEffect(() => {
    const getTag = async () => {
      const res = await getAllTag();
      setSkuTagData([...res.data]);
    };
    const getSkuListData = async () => {
      const res = await getSkuList({ url: API_URLS.GET_SKU });
      const sorted = res?.data?.sort((a: { name: string }, b: { name: string }) => {
        const lowerCaseA = a.name;
        const lowerCaseB = b.name;
        return lowerCaseA.localeCompare(lowerCaseB, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      });
      setSkuList([...sorted]);
    };

    const getPlatforms = async () => {
      const res = await getPlatformList({ url: API_URLS.GET_PLATFORM_LIST });
      if (res) {
        const platformList = res.data.map((item: { name?: string }) => {
          return item.name;
        });
        setPlatformList(platformList);
      } else {
        toast.error('Something Went Wrong');
      }
    };

    if (showAllocateSkuModal?.show) {
      getSkuListData();
      getTag();
      getPlatforms();
    }
  }, [showAllocateSkuModal?.show]);

  useEffect(() => {
    const getPromoCodes = async () => {
      const res: PromoRes = await getPromoCodeData({});
      setPromoCodeList([...res.data]);
    };
    if (showAllocateSkuModal?.show) {
      getPromoCodes();
    }
  }, [showAllocateSkuModal?.show]);

  useEffect(() => {
    if (id && id !== 0 && Tags.length > 0 && isTagSet && skuList.length > 0) {
      const skuRes = skuList?.find((sku: SKUListItem) => sku.skuId === id);
      setAllocateSkuData({ ...AllocateSkuData, skuId: Number(id) });
      setSkuTagBySkyID(Number(id));
      setSkuData(skuRes);
      skuRes && setPlatform(skuRes?.platformNames || []);
      isTagSet = false;
    }
  }, [id, Tags, skuList]);

  return (
    <>
      <Dialog fullWidth={true} maxWidth="md" open={showAllocateSkuModal.show} onClose={handleClose} disableRestoreFocus>
        <DialogContent className="modal-body">
          <Grid container spacing={3} sx={{ align: 'center', justifyContent: 'center' }}>
            <Grid item xs={11}>
              <Typography
                variant="h4"
                style={{
                  marginBottom: '50px',
                  textAlign: 'center',
                }}
              >
                Allocate SKU
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right' }}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};

                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label.label} {...stepProps}>
                    <StepLabel {...labelProps}>
                      <Grid container>
                        <Grid item xs={11}>
                          {label.label}
                        </Grid>
                        <Grid item xs={1}>
                          <Tooltip
                            sx={{
                              position: 'relative',
                              bottom: '20px',
                              right: '5px',
                            }}
                            title={label.description}
                          >
                            <IconButton size="small">
                              <Help sx={{ fontSize: '15px' }} />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {activeStep !== 0 && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography style={{ marginLeft: '60px', marginRight: '30px' }}>Tags</Typography>
                    <Typography style={{ marginLeft: '40px' }}>
                      Select
                      <span
                        onClick={() => handleAllSelect(activeStep)}
                        style={{
                          marginLeft: '10px',
                          marginRight: '10px',
                          color: 'blue',
                          cursor: 'pointer',
                        }}
                      >
                        All
                      </span>{' '}
                      |{' '}
                      <span
                        onClick={handleNoneSelect}
                        style={{
                          marginLeft: '10px',
                          marginRight: '30px',
                          color: 'red',
                          cursor: 'pointer',
                        }}
                      >
                        None
                      </span>
                    </Typography>
                    <TextField
                      label="Filter"
                      variant="outlined"
                      value={filterText}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFilterText(event.target.value);
                      }}
                      sx={{ marginLeft: '40px' }}
                      InputProps={{
                        style: {
                          height: '50px',
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => console.log('search')}>
                              <SearchOutlined />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}
                {activeStep == 0 && (
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginTop: '20px',
                      height: '300px',
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginTop: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <FormControl fullWidth variant="outlined">
                        <Autocomplete
                          id="skuId"
                          // name="skuId"
                          value={skuData}
                          onChange={handleSetSku}
                          options={skuList}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField {...params} label="Select SKU" />}
                          renderOption={(props, option) => {
                            return (
                              <Box component="li" {...props} key={option.skuId}>
                                <span>{option.name}</span>
                              </Box>
                            );
                          }}
                        />
                      </FormControl>
                      {skuData?.description && (
                        <TextField
                          value={skuData?.description}
                          label={<InputFieldsTooltip title="Description" description="Test Description" />}
                          sx={{ marginTop: '30px' }}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      )}
                      <FormControl fullWidth variant="outlined" sx={{ marginTop: '3%' }}>
                        <InputLabel id="demo-multiple-checkbox-label">
                          <InputFieldsTooltip title="Select Platforms" description="Select Platforms Description" />
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          disabled={true}
                          value={platform}
                          onChange={handleChange}
                          input={<OutlinedInput label="Select Platforms" />}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                        >
                          {platformListDisplay.map((data) => (
                            <MenuItem key={data} value={data}>
                              <Checkbox checked={platform.indexOf(data) > -1} />
                              <ListItemText primary={data} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <InputFieldsTooltip title="Best Value" description="SKU has best value or not" />
                      <Checkbox
                        value={skuData?.metadata?.bestValue || false}
                        checked={skuData?.metadata?.bestValue || false}
                        disabled={!skuData?.skuId || skuData?.skuId === 0}
                        onChange={({ target }) => {
                          setSkuData({
                            ...(skuData as SKUListItem),
                            metadata: {
                              bestValue: target.checked as boolean,
                            },
                          });
                        }}
                      />
                      {skuData?.metadata?.bestValue}
                    </Grid>
                  </Grid>
                )}
                {activeStep == 1 && (
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginTop: '20px',
                      overflowY: 'scroll',
                      height: '300px',
                      width: 'auto',
                    }}
                  >
                    {Tags.map((tag: tagType, index: number) => {
                      if (tag.tagName && tag.tagName.toLowerCase().includes(filterText.toLowerCase())) {
                        return (
                          <Grid item xs={12} sm={4} md={3} lg={3} sx={{ display: 'flex', overflow: 'hidden' }} key={index}>
                            <Tooltip title={tag.tagName}>
                              <Chip
                                disabled={tag.tagType === 2 || tag.tagType === 3 ? true : false}
                                avatar={
                                  <Checkbox
                                    sx={{
                                      backgroundColor: 'primary',
                                      color: 'white',
                                    }}
                                    disabled={tag.tagType === 2 || tag.tagType === 3 ? true : false}
                                    checked={tag.tagType === 1 || tag.tagType === 2 || tag.tagType === 3 ? true : false}
                                    name={tag.tagName}
                                    value={'white'}
                                    onChange={handleInput}
                                  />
                                }
                                label={tag.tagName}
                                sx={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  width: '100%',
                                  backgroundColor: 'grey',
                                  color: 'black',
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Grid>
                )}

                {activeStep == 2 && (
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginTop: '20px',
                      overflowY: 'scroll',
                      height: '300px',
                      width: 'auto',
                    }}
                  >
                    {Tags.map((tag: tagType, index: number) => {
                      if (tag.tagName && tag.tagName.toLowerCase().includes(filterText.toLowerCase())) {
                        return (
                          <Grid item xs={12} sm={4} md={3} lg={3} sx={{ display: 'flex', overflow: 'hidden' }} key={index}>
                            <Tooltip title={tag.tagName}>
                              <Chip
                                disabled={tag.tagType === 1 || tag.tagType === 3 ? true : false}
                                avatar={
                                  <Checkbox
                                    sx={{
                                      backgroundColor: 'primary',
                                      color: 'white',
                                    }}
                                    disabled={tag.tagType === 1 || tag.tagType === 3 ? true : false}
                                    checked={tag.tagType === 1 || tag.tagType === 2 || tag.tagType === 3 ? true : false}
                                    name={tag.tagName}
                                    value={'black'}
                                    onChange={handleInput}
                                  />
                                }
                                label={tag.tagName}
                                sx={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  width: '100%',
                                  backgroundColor: 'grey',
                                  color: 'black',
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Grid>
                )}

                {/*activeStep == 3 && (
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginTop: '20px',
                      overflowY: 'scroll',
                      height: '300px',
                      width: 'auto',
                    }}
                  >
                    {Tags.map((tag: tagType, index: number) => {
                      if (
                        tag.tagName &&
                        tag.tagName
                          .toLowerCase()
                          .includes(filterText.toLowerCase())
                      ) {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            md={3}
                            lg={3}
                            sx={{ display: 'flex', overflow: 'hidden' }}
                            key={index}
                          >
                            <Tooltip title={tag.tagName}>
                              <Chip
                                disabled={
                                  tag.tagType === 1 || tag.tagType === 2
                                    ? true
                                    : false
                                }
                                avatar={
                                  <Checkbox
                                    sx={{
                                      backgroundColor: 'primary',
                                      color: 'white',
                                    }}
                                    disabled={
                                      tag.tagType === 1 || tag.tagType === 2
                                        ? true
                                        : false
                                    }
                                    checked={
                                      tag.tagType === 1 ||
                                      tag.tagType === 2 ||
                                      tag.tagType === 3
                                        ? true
                                        : false
                                    }
                                    name={tag.tagName}
                                    value={'public'}
                                    onChange={handleInput}
                                  />
                                }
                                label={tag.tagName}
                                sx={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  width: '100%',
                                  backgroundColor: 'grey',
                                  color: 'black',
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Grid>
                )*/}

                {activeStep == 3 && (
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginTop: '20px',
                      overflowY: 'scroll',
                      height: '300px',
                    }}
                  >
                    {promoCodeList.map((promoCode: PromoListItem, index: number) => {
                      if (
                        promoCode.code.toLowerCase().includes(filterText.toLowerCase()) &&
                        promoCode.platformNames.some((name: string) => platform.includes(name))
                      ) {
                        return (
                          <Grid item xs={12} sm={4} md={3} lg={3} sx={{ display: 'flex', overflow: 'hidden' }} key={index}>
                            <Tooltip title={promoCode.code}>
                              <Chip
                                avatar={
                                  <Checkbox
                                    sx={{
                                      backgroundColor: 'primary',
                                      color: 'white',
                                    }}
                                    name={promoCode.code}
                                    value={'public'}
                                    onChange={(e) => handlePromoListChange(e, promoCode)}
                                    checked={selectedPromoCode.find((code) => code === promoCode.promoCodeId) ? true : false}
                                  />
                                }
                                label={promoCode.code}
                                sx={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  width: '100%',
                                  backgroundColor: 'grey',
                                  color: 'black',
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Grid>
                )}

                {activeStep == 4 && (
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginTop: '20px',
                      overflowY: 'scroll',
                      height: '300px',
                    }}
                  >
                    <Grid sx={{ marginLeft: '10%' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: 2.5,
                        }}
                      >
                        {platform.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginTop: '20px',
                        display: 'flex',
                      }}
                    >
                      <div>
                        {accordionData.map((accoData, index) => {
                          return (
                            <AccordionComponent
                              expanded={expanded}
                              key={index}
                              panelName={accoData.panelName}
                              handleAccordianChange={handleAccordianChange}
                              Tags={Tags}
                              handleInput={handleInput}
                              tagName={accoData.tagName}
                              ariaControls={accoData.ariaControls}
                              id={accoData.id}
                              tagTypeValue={accoData.tagTypeValue}
                              checkboxValue={accoData.checkboxValue}
                            />
                          );
                        })}
                        <Accordion expanded={expanded === 'panel4'} onChange={handleAccordianChange('panel4')}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>Promo Codes</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid
                              container
                              spacing={3}
                              sx={{
                                overflowY: 'scroll',
                                height: 'auto',
                              }}
                            >
                              {promoCodeList.map((promoCode: PromoListItem, index: number) => {
                                if (selectedPromoCode.find((code: number) => code === promoCode.promoCodeId)) {
                                  return (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={4}
                                      md={3}
                                      lg={3}
                                      sx={{
                                        display: 'flex',
                                        overflow: 'hidden',
                                      }}
                                      key={index}
                                    >
                                      <Tooltip title={promoCode.code}>
                                        <Chip
                                          avatar={
                                            <Checkbox
                                              sx={{
                                                backgroundColor: 'primary',
                                                color: 'white',
                                              }}
                                              name={promoCode.code}
                                              onChange={handleInput}
                                              checked={selectedPromoCode.some(function (code) {
                                                return code === promoCode.promoCodeId;
                                              })}
                                              value={'public'}
                                            />
                                          }
                                          label={promoCode.code}
                                          sx={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            width: '100%',
                                            backgroundColor: 'grey',
                                            color: 'black',
                                          }}
                                        />
                                      </Tooltip>
                                    </Grid>
                                  );
                                }
                              })}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </Grid>
                  </Grid>
                )}

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      Skip
                    </Button>
                  }
                  {activeStep === steps.length - 1 && <Button onClick={handleSubmit}>Finish</Button>}
                  {activeStep !== steps.length - 1 && <Button onClick={handleNext}>Next</Button>}
                </Box>
              </React.Fragment>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
interface AccordianComponentProps {
  expanded: string;
  handleAccordianChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  Tags: tagType[];
  handleInput?: (event: React.FormEvent<HTMLInputElement>) => void;
  tagName: string;
  ariaControls: string;
  id: string;
  tagTypeValue: number;
  checkboxValue: string;
  panelName: string;
}

const AccordionComponent = ({
  expanded,
  handleAccordianChange,
  Tags,
  handleInput,
  tagName,
  ariaControls,
  id,
  tagTypeValue,
  // checkboxValue,
  panelName,
}: AccordianComponentProps): ReactElement => {
  return (
    <Accordion expanded={expanded === panelName} onChange={handleAccordianChange(panelName)} sx={{ width: '845px' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={ariaControls} id={id}>
        <Typography sx={{ width: '33%', flexShrink: 0 }}>{tagName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          spacing={3}
          sx={{
            overflowY: 'scroll',
            height: 'auto',
          }}
        >
          {Tags.map((tag: tagType, index: number) => {
            if (tag.tagType === tagTypeValue) {
              return (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={3}
                  sx={{
                    display: 'flex',
                    overflow: 'hidden',
                  }}
                  key={index}
                >
                  <Tooltip title={tag.tagName}>
                    <Chip
                      avatar={
                        <Checkbox
                          sx={{
                            backgroundColor: 'primary',
                            color: 'white',
                          }}
                          name={tag.tagName}
                          onChange={handleInput}
                          checked={tag.tagType === tagTypeValue ? true : false}
                        />
                      }
                      label={tag.tagName}
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        width: '100%',
                        backgroundColor: 'grey',
                        color: 'black',
                      }}
                    />
                  </Tooltip>
                </Grid>
              );
            } else {
              return;
            }
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AllocateSkuModal;
