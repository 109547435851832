import { Button, Grid, Tooltip } from '@mui/material';
import FloatingButton from '../../component/FloatingButton/FloatingButton';
import { DeleteOutline, Edit } from '@mui/icons-material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { API_URLS } from '../../constants/url';
import DataTable, { IDataGridRef } from '../../component/DataTable/DataTable';
import {
  showCreateDailyReturnSetGroupModalAction,
  showCreateDailyReturnSetModalAction,
  showGlobalDeleteModalAction,
} from '../../redux/actions/modalActions/modalActions';
import BehaviourList from './SetGroupList';
import AddIcon from '@mui/icons-material/Add';
import { MarketingPermissions } from '../../constants/permission';
import { RootState } from '../../redux/reducers/rootReducer';
import CommonBreadcrumbs from '../../component/BreadCrumb/BreadCrumb';
import { SET_LIST_COLUMN } from '../../constants/columns/dailyReturn.constants';
import { useCurrentPartner } from '../../hooks/useCurrentPartner';
import { getSetList } from '../../services/dailyReturn.service';
import { breadCrumbsKey } from '../../constants/breadCrumbConfig';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { IDeleteData } from '../CurrencyType/CurrencyType';
import { DataResponseType } from '../PromoCodeChainRule/PromoCodeChainRule';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface SetResponse {
  setId: number;
  setGroupId: number;
  groupName: string;
  groupDescription: string;
  groupType: string;
  span: string;
  name: string;
  type: number;
  description: string;
  platforms: number[];
  platformNames: string[];
  resetStrategy: number;
  isEnabled: boolean;
}

const SetList = () => {
  const dispatch = useDispatch();
  const { partnerShortName } = useCurrentPartner();
  const upsertSetData = useSelector((state: RootState) => state.modalReducer.showDailyReturnSet);

  const globalDeleteModalData = useSelector((state: RootState) => state.modalReducer.showGlobalDelete);
  const dataGridRef = useRef<IDataGridRef>(null);
  const SET_COLUMN_DATA = SET_LIST_COLUMN();
  const columns = useMemo<GridColDef[]>(() => {
    const All_Column: GridColDef[] = [
      {
        field: 'action',
        headerName: 'Action',
        headerAlign: 'center',
        description: 'Action',
        width: 220,
        renderCell: (params: GridRenderCellParams) => (
          <>
            <Tooltip title="Edit">
              <Button
                size="small"
                sx={{ ml: '16px' }}
                onClick={() => {
                  dispatch(showCreateDailyReturnSetModalAction(true, { id: params.value }, false));
                }}
              >
                <Edit color="secondary" />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                size="small"
                style={{ marginLeft: 16 }}
                onClick={() => {
                  const deleteData: IDeleteData = {};
                  deleteData.deleteModule = 'Set';
                  deleteData.deleteId = params.row.id;
                  deleteData.item = {};
                  deleteData.item.value = params.row.id;
                  deleteData.item.label = params.row.name;
                  deleteData.item.item = {};
                  deleteData.item.item.description = params.row.description;
                  dispatch(showGlobalDeleteModalAction(true, deleteData));
                }}
              >
                <DeleteOutline color="error" />
              </Button>
            </Tooltip>
          </>
        ),
      },
    ];
    return [...SET_COLUMN_DATA, ...All_Column];
  }, [SET_COLUMN_DATA]);

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  const handleActionTrayClick = () => {
    console.log('Action Button Tray Opened');
  };

  const handleDoubleClick = (e: any) => {
    dispatch(showCreateDailyReturnSetModalAction(true, e.row, false));
  };

  const bulkActions = [
    {
      action: 'Create Set',
      onClick: () => {
        dispatch(showCreateDailyReturnSetModalAction(true, null));
      },
      icon: <AddIcon />,
      Permissions: [MarketingPermissions.Create],
    },
    {
      action: 'Create Set Group',
      onClick: () => {
        dispatch(showCreateDailyReturnSetGroupModalAction(true, null));
      },
      icon: <AddIcon />,
      Permissions: [MarketingPermissions.Create],
    },
  ];

  useEffect(() => {
    if (!globalDeleteModalData.show) {
      dataGridRef.current?.fetchData();
    }
  }, [globalDeleteModalData.show]);

  const fetchData = async () => {
    try {
      const res: DataResponseType<SetResponse> = await getSetList({
        url: API_URLS.GET_DAILY_REWARD_SETS,
        partnerId: partnerShortName,
      });

      const data = res?.data?.map((item: SetResponse) => {
        return {
          id: item.setId,
          name: item.name,
          groupName: item.groupName,
          groupDescription: item.groupDescription,
          groupType: item.groupType,
          resetStrategy: item.resetStrategy,
          span: item.span,
          description: item.description,
          type: item.type,
          isEnabled: item.isEnabled,
          platformNames: item.platformNames,
          action: item.setId,
        };
      });
      return {
        data: data || [],
        totalRows: res?.totalRows,
      };
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  useEffect(() => {
    if (!upsertSetData.show && upsertSetData?.data?.success) {
      dataGridRef.current?.fetchData();
    }
  }, [upsertSetData.show]);

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <Tabs variant="scrollable" value={tabValue} onChange={handleTabChange} aria-label="Daily Door">
            <Tab label="Sets" {...a11yProps(0)} />
            <Tab label="Set Groups" {...a11yProps(1)} />
          </Tabs>
        </Grid>
        <Grid item xs={4}>
          <CommonBreadcrumbs title="Daily Return" breadCrumbKey={breadCrumbsKey.dailyReturnSets} />
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={tabValue} index={0}>
            <DataTable
              columns={columns}
              fetchData={fetchData}
              ref={dataGridRef}
              hideFooter
              isInfiniteScroll
              enableSearch
              handleDoubleClick={handleDoubleClick}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <BehaviourList />
          </TabPanel>
        </Grid>
      </Grid>

      <FloatingButton bulkActions={bulkActions} floatingButtonClick={handleActionTrayClick} fabPermissions={[MarketingPermissions.Create]} />
    </>
  );
};

export default SetList;
