/* eslint-disable indent */
import {
  Button,
  Grid,
  Typography,
  Chip,
  FormControl,
  FormHelperText,
  Dialog,
  DialogContent,
  TextField,
  IconButton,
  Tooltip,
  Select,
  SelectChangeEvent,
  Divider,
  InputLabel,
  OutlinedInput,
  Box,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { Add, Close, Help } from '@mui/icons-material';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';
import useStyles from './skuStyles';
import { getCurrencyTypeList } from '../../../services/currencyType.service';
import { API_URLS } from '../../../constants/url';
import { getSkuById, upsertSKUData } from '../../../services/sku.services';
import { Field, Form } from 'react-final-form';
import InputWrapper from '../../UI/Input';
import { objectKeyLength, required } from '../../../utils/validations';
import SelectWrapper from '../../UI/SelectWrapper';
import CheckBoxWrapper from '../../UI/CheckBoxWrapper';
import { FormApi } from 'final-form';
import { showAllocateSkuAction, showCreateSkuModalAction } from '../../../redux/actions/modalActions/modalActions';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getAllTag, getSkuTagBySkuId } from '../../../services/allocateSku.service';
import { getPlatformList, getPromoCodeBySkuId } from '../../../services/promoCode.service';
import './skuStyle.css';
import { RootState } from '../../../redux/reducers/rootReducer';
import InputFieldsTooltip from '../../InputFieldsTooltip';

interface TypesType {
  value: number;
  label: string;
  is_selected?: number;
  name?: string;
  currencyTypeId: number;
}
interface CurrencyTypeData {
  value: number;
  label: string;
  is_selected?: number;
}
interface SkuDataType {
  skuId?: number;
  name?: string;
  price?: string;
  priceCurrencyTypeId?: {
    value: number;
    label: string;
  };
  isEnabled?: boolean;
  platformNames?: string[];
  isBaseSku?: boolean;
  entitlements?: {
    [key: string]: number;
  };
  description?: string;
  add_key?: string;
  add_value?: string;
}
interface tagType {
  tagId: number;
  tagName: string;
  tagType: number;
}
interface tagResponse {
  tagId: number;
  name: string;
}
interface SKuDataType {
  skuId: number;
  whitelist: Array<string>;
  blacklist: Array<string>;
  publiclist: Array<string>;
  promoCodelist: Array<{ name: string }>;
}

let isTagSet = false;
const SkuCreate = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currencyTypes, setCurrencyTypes] = useState<CurrencyTypeData[]>([]);
  const [entitlementCurrency, setEntitlementCurrency] = useState<CurrencyTypeData[]>([]);
  const [skuData, setSkuData] = useState<SkuDataType>({
    isEnabled: false,
    isBaseSku: false,
  });
  const [skuTagData, setSkuTagData] = useState<tagResponse[]>([]);
  const skuModal = useSelector((state: RootState) => state.modalReducer.showCreateSku);
  const skuId = skuModal?.data?.id || skuModal?.data?.skuId;
  const isReadable = skuModal?.isReadable;
  const [Tags, setTags] = useState<tagType[]>([]);
  const [AllocateSkuData, setAllocateSkuData] = useState<SKuDataType>({
    skuId: 0,
    whitelist: [],
    blacklist: [],
    publiclist: [],
    promoCodelist: [],
  });

  const [platform, setPlatform] = React.useState<string[]>([]);
  const [platformListDisplay, setPlatformList] = React.useState<string[]>([]);

  const handlePlatformChange = (event: SelectChangeEvent<typeof platform>) => {
    const {
      target: { value },
    } = event;
    setPlatform(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const selectedPromoCode: Array<{ name: string }> = [];
  const setSkuTagBySkyID = async (skuId: number) => {
    setAllocateSkuData({ ...AllocateSkuData, skuId });
    try {
      const res = await getSkuTagBySkuId({ id: skuId });

      const promoCodeResult = await getPromoCodeBySkuId({ id: skuId });
      promoCodeResult?.data?.map((data: { code: string }) => {
        selectedPromoCode.push({ name: data.code });
      });

      if (res.data) {
        const tempAllocateSkuData: SKuDataType = {
          skuId,
          whitelist: [],
          blacklist: [],
          publiclist: [],
          promoCodelist: selectedPromoCode,
        };
        let newTag = [...Tags];
        if (newTag.length > 0) {
          //empty tag and data
          setAllocateSkuData({ ...tempAllocateSkuData });

          newTag = Tags.map((tag: tagType) => {
            return {
              ...tag,
              tagType: 0,
            };
          });
          setTags([...newTag]);
          res?.data?.forEach((element: { tagId: number; tagName: string; isWhiteListed: boolean; isBlackListed: boolean }) => {
            const index = Tags.findIndex((item: tagType) => item.tagId === element.tagId);
            if (Tags[index]) {
              if (element.isWhiteListed) {
                tempAllocateSkuData.whitelist.push(Tags[index].tagName);
              } else if (element.isBlackListed) {
                tempAllocateSkuData.blacklist.push(Tags[index].tagName);
              } else {
                tempAllocateSkuData.publiclist.push(Tags[index].tagName);
              }
            }
          });
          setTags([...newTag]);
          setAllocateSkuData({ ...tempAllocateSkuData });
        }
      }
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  const [currencyTypeList, setCurrencyTypeList] = useState<TypesType[]>([]);
  const formRef: React.MutableRefObject<FormApi> = useRef({} as FormApi);

  //handle add entitlements data
  const handleAddData = () => {
    let newData = formRef.current.getFieldState('entitlements')?.value;
    const add_key_id = formRef.current.getFieldState('add_key')?.value?.value;
    const add_value = formRef.current.getFieldState('add_value')?.value;
    const add_key = currencyTypes.find((item) => item.value === add_key_id)?.label as string;
    SetTypeSelect([add_key], 1);
    newData = { ...newData, [add_key]: add_value };
    formRef.current.change('entitlements', newData);
    formRef.current.change('add_key', '');
    formRef.current.change('add_value', '');
  };

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [expandedOuter, setExpandedOuter] = React.useState<string | false>(false);

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleAccordionOuterChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedOuter(isExpanded ? panel : false);
  };

  //set Type is_select Or Not
  const SetTypeSelect = (key: string[], value: number) => {
    let newCurrencyType = [...currencyTypes];
    if (value === 1) {
      newCurrencyType = entitlementCurrency.filter((item: CurrencyTypeData) => !key.includes(item.label));
    } else {
      const newRec = currencyTypes.find((item: CurrencyTypeData) => item.label === key[0]);
      newCurrencyType = [...entitlementCurrency, newRec as CurrencyTypeData];
    }
    setEntitlementCurrency([...newCurrencyType]);
  };

  //handle entitlements Delete
  const handleDelete = (data: string) => {
    const entitlements = formRef.current.getFieldState('entitlements')?.value;
    if (currencyTypes.filter((type) => type.label === data).length !== 0) {
      SetTypeSelect([data], 0);
    }
    delete entitlements[data];
    formRef.current.change('entitlements', { ...entitlements });
    formRef.current.resetFieldState('entitlements');
  };

  const handleSubmit = async (value: SkuDataType) => {
    try {
      const bodyData = {
        sku: {
          name: value.name,
          price: value.price,
          priceCurrencyTypeId: value?.priceCurrencyTypeId?.value,
          entitlements: value.entitlements,
          platformNames: platform,
          isEnabled: value.isEnabled,
          isBaseSku: value.isBaseSku,
          description: value.description,
        },
      };
      const res = await upsertSKUData({ isEdit: false, data: bodyData });
      if (res?.success) {
        toast.success('Successfully created SKU!');
        handleClose(true);
        dispatch(showAllocateSkuAction(true, { skuId: res.data }));
      } else {
        toast.error('Something Is Wrong');
      }
    } catch (err) {
      toast.error(`${err}`);
    }
  };

  const handleUpdateSubmit = async (value: SkuDataType) => {
    try {
      const bodyData = {
        sku: {
          skuId: Number(skuId),
          name: value.name,
          price: value.price,
          priceCurrencyTypeId: value?.priceCurrencyTypeId?.value,
          entitlements: value.entitlements,
          isEnabled: value.isEnabled,
          platformNames: platform,
          isBaseSku: value.isBaseSku,
          description: value.description,
        },
      };
      const res = await upsertSKUData({ isEdit: true, data: bodyData });
      if (res?.success) {
        toast.success('SKU successfully updated!');
        handleClose(true);
      } else {
        toast.error('Something Is Wrong');
      }
    } catch (err) {
      toast.error(`${err}`);
    }
  };

  const handleClose = (success = false) => {
    formRef.current.reset();
    setSkuData({ isEnabled: false, isBaseSku: false });
    setPlatform([]);
    dispatch(showCreateSkuModalAction(false, success ? { success: true } : null));
  };
  async function getData() {
    try {
      const res = await getSkuById({ id: skuId });
      if (res) {
        const editData = res.data;
        const currencyLabel = currencyTypes.find((item: CurrencyTypeData) => item.value === editData.priceCurrencyTypeId)?.label as string;

        setSkuData({
          skuId: editData.skuId,
          name: editData.name,
          price: editData.price,
          priceCurrencyTypeId: {
            label: currencyLabel,
            value: editData.priceCurrencyTypeId,
          },
          isEnabled: editData.isEnabled,
          isBaseSku: editData.isBaseSku,
          entitlements: editData.entitlements,
          platformNames: editData.platformNames.filter((p: string | null) => p !== null),
          description: editData.description,
        });
        setPlatform(editData.platformNames.filter((p: string | null) => p !== null));
        const keys = Object.keys(editData.entitlements);
        SetTypeSelect(keys, 1);
      } else {
        toast.error('Something Went Wrong');
      }
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  }
  useEffect(() => {
    if (skuId && currencyTypes.length !== 0 && !skuData.entitlements) {
      getData();
    }
  }, [skuId, currencyTypes]);

  useEffect(() => {
    const data = currencyTypeList.map((item: TypesType) => {
      return {
        value: item.currencyTypeId,
        label: item.name || '',
      };
    });
    setCurrencyTypes([...data]);
    setEntitlementCurrency([...data]);
  }, [currencyTypeList]);

  useEffect(() => {
    const getCurrencyData = async () => {
      const res = await getCurrencyTypeList({
        url: API_URLS.GET_CURRENCY_TYPE,
      });
      setCurrencyTypeList([...res.data]);
    };

    const getPlatforms = async () => {
      const res = await getPlatformList({ url: API_URLS.GET_PLATFORM_LIST });
      if (res) {
        const platformList = res.data.map((item: { name: string }) => {
          return item.name;
        });
        setPlatformList(platformList);
      } else {
        toast.error('Something Went Wrong');
      }
    };

    if (skuModal.show) {
      getCurrencyData();
      getPlatforms();
    }
  }, [skuModal.show]);

  useEffect(() => {
    const data = skuTagData?.map((tags: { tagId: number; name: string }) => {
      return {
        tagId: tags.tagId,
        tagName: tags.name,
        tagType: 0,
      };
    });
    setTags([...data]);
    isTagSet = true;
  }, [skuTagData]);

  useEffect(() => {
    const getTag = async () => {
      const res = await getAllTag();
      setSkuTagData([...res.data]);
    };
    if (skuModal.show && skuId) {
      getTag();
    }
  }, [skuModal.show, skuId]);

  useEffect(() => {
    if (skuId && Tags.length > 0 && isTagSet) {
      setAllocateSkuData({
        ...AllocateSkuData,
        skuId: Number(skuId),
      });
      setSkuTagBySkyID(Number(skuId));
      isTagSet = false;
    }
  }, [skuId, Tags]);

  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const characterCode = e.key;
    if (characterCode === 'Backspace') return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  };

  return (
    <>
      <Dialog open={skuModal.show} onClose={() => handleClose()} disableRestoreFocus>
        <DialogContent>
          <Form onSubmit={!skuId ? handleSubmit : handleUpdateSubmit} initialValues={skuData}>
            {({ handleSubmit, form, values }) => {
              formRef.current = form;
              return (
                <form method="post" onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid container spacing={3}>
                      <Grid item lg={12}>
                        <Typography variant="h5" style={{ marginBottom: '10px' }} align="center">
                          {!skuId ? ' Create SKU' : 'Edit SKU'}
                        </Typography>
                        <IconButton aria-label="close" onClick={() => handleClose()} sx={{ float: 'right', marginTop: '-40px' }}>
                          <Close />
                        </IconButton>
                        <Divider className={classes.divider} />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Grid container spacing={2}>
                        <Grid item lg={6}>
                          <Field
                            name="name"
                            type="text"
                            component={InputWrapper}
                            validate={required}
                            placeholder="Enter SKU Name"
                            readOnly={isReadable}
                            label={<InputFieldsTooltip title="SKU Name" description="The user-friendly name of the SKU. Not shown to users." />}
                          />
                        </Grid>
                        <Grid item lg={6}>
                          <Field
                            name="price"
                            component={InputWrapper}
                            onKeyDown={handleKeypress}
                            placeholder="Enter SKU Price"
                            readOnly={isReadable}
                            label={<InputFieldsTooltip title="Price" description="The cost to the user for the SKU." />}
                            validate={required}
                          >
                            {/* {(props) => (
                              <InputFieldsTooltip
                                props={props}
                                enableNumber={true}
                                title="Price"
                                description="The cost to the user for the SKU."
                                placeholder="Enter SKU Price"
                              />
                            )} */}
                          </Field>
                        </Grid>
                        <Grid item lg={12}>
                          <Field
                            name="priceCurrencyTypeId"
                            option={currencyTypes}
                            component={SelectWrapper}
                            validate={required}
                            readOnly={isReadable}
                            label={
                              <Fragment>
                                Price Currency Type
                                <Tooltip
                                  sx={{
                                    position: 'relative',
                                    bottom: '10px',
                                    right: '5px',
                                  }}
                                  title="Price Currency Type description"
                                  placement="right-start"
                                >
                                  <IconButton size="medium">
                                    <Help sx={{ fontSize: '20px' }} />
                                  </IconButton>
                                </Tooltip>
                              </Fragment>
                            }
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                },
                              },
                            }}
                          >
                            {}
                          </Field>
                        </Grid>
                        <Grid item lg={12}>
                          <Field name="entitlements" autoFocus validate={objectKeyLength}>
                            {({ input, meta }) => (
                              <>
                                <FormControl fullWidth variant="outlined">
                                  <TextField
                                    label={
                                      <Fragment>
                                        Entitlements
                                        <Tooltip
                                          sx={{
                                            position: 'relative',
                                            bottom: '10px',
                                            right: '5px',
                                          }}
                                          title="The currencies and amounts given to users who purchase the SKU."
                                          placement="right-start"
                                        >
                                          <IconButton size="medium">
                                            <Help sx={{ fontSize: '20px' }} />
                                          </IconButton>
                                        </Tooltip>
                                      </Fragment>
                                    }
                                    onChange={input.onChange}
                                    error={meta.error && (meta.dirty || meta.submitFailed)}
                                    InputProps={{
                                      readOnly: true,
                                      startAdornment: (
                                        <div className={classes.root}>
                                          {Object.keys(input.value).map((data: any, index: number) => {
                                            return (
                                              <Chip
                                                label={data + ': ' + input.value[data]}
                                                className={classes.chip}
                                                onDelete={() => {
                                                  handleDelete(data);
                                                }}
                                                key={index}
                                              />
                                            );
                                          })}
                                        </div>
                                      ),
                                    }}
                                  ></TextField>
                                  {meta.error && (meta.dirty || meta.submitFailed) && (
                                    <FormHelperText error={meta.error !== ''}>{meta.error || meta.submitError}</FormHelperText>
                                  )}
                                </FormControl>
                              </>
                            )}
                          </Field>
                        </Grid>
                        <Grid item lg={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                              <Field
                                name="add_key"
                                option={entitlementCurrency}
                                readOnly={isReadable}
                                component={SelectWrapper}
                                displayEmpty
                                label={
                                  <Fragment>
                                    Entitlement Type
                                    <Tooltip
                                      sx={{
                                        position: 'relative',
                                        bottom: '10px',
                                        right: '5px',
                                      }}
                                      title="The currency type given to users who purchase the SKU."
                                      placement="right-start"
                                    >
                                      <IconButton size="medium">
                                        <Help sx={{ fontSize: '20px' }} />
                                      </IconButton>
                                    </Tooltip>
                                  </Fragment>
                                }
                              ></Field>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4}>
                              <Field
                                name="add_value"
                                component={InputWrapper}
                                onKeyDown={handleKeypress}
                                readOnly={isReadable}
                                placeholder="Enter Amount"
                                label={<InputFieldsTooltip title="Amount" description="The value of the entitled currency in the SKU." />}
                              />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <Button
                                variant="contained"
                                onClick={handleAddData}
                                disabled={!values.add_key || values.add_key === '' || !values.add_value || values.add_value === ''}
                                sx={{ py: '10px' }}
                              >
                                <Add style={{ fontSize: '34px' }}></Add>
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>

                        {skuId && (
                          <Grid item xs={12}>
                            <Accordion expanded={expandedOuter === 'panel9'} onChange={handleAccordionOuterChange('panel9')}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel9bh-content" id="panel9bh-header">
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>Review Tags</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
                                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>Whitelist Tags</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid
                                      container
                                      spacing={3}
                                      sx={{
                                        // overflowY: 'scroll',
                                        height: 'auto',
                                      }}
                                    >
                                      {AllocateSkuData.whitelist.map((tag: string, index: number) => {
                                        return (
                                          <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            lg={4}
                                            sx={{
                                              display: 'flex',
                                            }}
                                            key={index}
                                          >
                                            <Tooltip title={tag}>
                                              <Chip
                                                avatar={
                                                  <Checkbox
                                                    sx={{
                                                      backgroundColor: 'primary',
                                                      color: 'white',
                                                    }}
                                                    checked={tag ? true : false}
                                                    value={'whitelist'}
                                                  />
                                                }
                                                label={tag}
                                                sx={{
                                                  textOverflow: 'ellipsis',
                                                  overflow: 'hidden',
                                                  width: '100%',
                                                  backgroundColor: 'grey',
                                                  color: 'black',
                                                }}
                                              />
                                            </Tooltip>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
                                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>Blacklist Tags</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid
                                      container
                                      spacing={3}
                                      sx={{
                                        // overflowY: 'scroll',
                                        height: 'auto',
                                      }}
                                    >
                                      {AllocateSkuData.blacklist.map((tag: string, index: number) => {
                                        return (
                                          <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            lg={4}
                                            sx={{
                                              display: 'flex',
                                            }}
                                            key={index}
                                          >
                                            <Tooltip title={tag}>
                                              <Chip
                                                avatar={
                                                  <Checkbox
                                                    sx={{
                                                      backgroundColor: 'primary',
                                                      color: 'white',
                                                    }}
                                                    checked={tag ? true : false}
                                                    value={'black'}
                                                  />
                                                }
                                                label={tag}
                                                sx={{
                                                  textOverflow: 'ellipsis',
                                                  overflow: 'hidden',
                                                  width: '100%',
                                                  backgroundColor: 'grey',
                                                  color: 'black',
                                                }}
                                              />
                                            </Tooltip>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                                {/*<Accordion
                                  expanded={expanded === 'panel3'}
                                  onChange={handleAccordionChange('panel3')}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                  >
                                    <Typography
                                      sx={{ width: '33%', flexShrink: 0 }}
                                    >
                                      Public Tags
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid
                                      container
                                      spacing={3}
                                      sx={{
                                        // overflowY: 'scroll',
                                        height: 'auto',
                                      }}
                                    >
                                      {AllocateSkuData.publiclist.map(
                                        (tag: string, index: number) => {
                                          return (
                                            <Grid
                                              item
                                              xs={12}
                                              sm={6}
                                              md={4}
                                              lg={4}
                                              sx={{
                                                display: 'flex',
                                              }}
                                              key={index}
                                            >
                                              <Tooltip title={tag}>
                                                <Chip
                                                  avatar={
                                                    <Checkbox
                                                      sx={{
                                                        backgroundColor:
                                                          'primary',
                                                        color: 'white',
                                                      }}
                                                      checked={
                                                        tag ? true : false
                                                      }
                                                      value={'public'}
                                                    />
                                                  }
                                                  label={tag}
                                                  sx={{
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    width: '100%',
                                                    backgroundColor: 'grey',
                                                    color: 'black',
                                                  }}
                                                />
                                              </Tooltip>
                                            </Grid>
                                          );
                                        },
                                      )}
                                    </Grid>
                                  </AccordionDetails>
								</Accordion>*/}

                                <Accordion expanded={expanded === 'panel4'} onChange={handleAccordionChange('panel4')}>
                                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>Promo Code List</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid
                                      container
                                      spacing={3}
                                      sx={{
                                        // overflowY: 'scroll',
                                        height: 'auto',
                                      }}
                                    >
                                      {AllocateSkuData.promoCodelist.map((tag: { name: string }, index: number) => {
                                        return (
                                          <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            lg={4}
                                            sx={{
                                              display: 'flex',
                                            }}
                                            key={index}
                                          >
                                            <Tooltip title={tag.name}>
                                              <Chip
                                                avatar={
                                                  <Checkbox
                                                    sx={{
                                                      backgroundColor: 'primary',
                                                      color: 'white',
                                                    }}
                                                    checked={tag ? true : false}
                                                    value={'promocode'}
                                                  />
                                                }
                                                label={tag.name}
                                                sx={{
                                                  textOverflow: 'ellipsis',
                                                  overflow: 'hidden',
                                                  width: '100%',
                                                  backgroundColor: 'grey',
                                                  color: 'black',
                                                }}
                                              />
                                            </Tooltip>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>

                                <Button
                                  variant="contained"
                                  type="submit"
                                  fullWidth
                                  sx={{ mt: 2 }}
                                  onClick={() => {
                                    dispatch(showAllocateSkuAction(true, skuData));
                                    handleClose();
                                  }}
                                >
                                  Reallocate
                                </Button>
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        )}

                        <Grid item lg={12}>
                          <Field
                            name="description"
                            type="text"
                            component={InputWrapper}
                            validate={required}
                            readOnly={isReadable}
                            placeholder="Enter Description"
                            label={<InputFieldsTooltip title="Description" description="Human-friendly description of the SKU." />}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field name="platformIds">
                            {({ input, meta }) => (
                              <FormControl fullWidth variant="outlined">
                                <InputLabel id="demo-multiple-checkbox-label">
                                  <InputFieldsTooltip title="Select Platforms" description="Select Platforms Description" />
                                </InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  value={platform}
                                  onChange={handlePlatformChange}
                                  name={input.name}
                                  readOnly={isReadable}
                                  input={<OutlinedInput label="Select Platforms" />}
                                  error={meta.error && (meta.dirty || meta.submitFailed)}
                                  renderValue={(selected: string[]) => (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 0.5,
                                      }}
                                    >
                                      {selected.map((value: string) => (
                                        <Chip key={value} label={value} />
                                      ))}
                                    </Box>
                                  )}
                                >
                                  {platformListDisplay?.map((data: string) => (
                                    <MenuItem key={data} value={data}>
                                      <Checkbox checked={platform.indexOf(data) > -1} />
                                      <ListItemText primary={data} />
                                    </MenuItem>
                                  ))}
                                </Select>
                                {meta.error && (meta.dirty || meta.submitFailed) && (
                                  <FormHelperText error={meta.error !== ''}>{meta.error || meta.submitError}</FormHelperText>
                                )}
                              </FormControl>
                            )}
                          </Field>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          flexDirection="row"
                          sx={{
                            display: 'flex',
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <InputFieldsTooltip title="Enabled" description="SKU Is Enabled Or Not" />
                            <Field name="isEnabled" component={CheckBoxWrapper} type="checkbox" disabled={isReadable ? true : false} />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <InputFieldsTooltip title="Base SKU" description="SKU Is Base SKU Or Not" />
                            <Field name="isBaseSku" component={CheckBoxWrapper} type="checkbox" disabled={isReadable ? true : false} />
                          </Grid>
                        </Grid>
                        <Grid item lg={12}>
                          <Button variant="contained" color="primary" type="submit" fullWidth disabled={isReadable}>
                            {!skuId ? ' Create SKU' : 'Edit SKU'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SkuCreate;
