import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { API_URLS } from '../constants/url';
import axiosInstance from '../utils/apis';
import { startLoader, stopLoader } from '../utils/utils';
interface PromoCodeAPIPayload {
  url: string;
  partnerId?: string;
}

export const getSkuList = async (payload: PromoCodeAPIPayload) => {
  try {
    startLoader();
    const { url, partnerId } = payload;
    const response = await axiosInstance.get(url + (partnerId ? `?partnerId=${partnerId}` : ''));
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const getSkuById = async (payload: { id: number | undefined }) => {
  try {
    startLoader();
    const { id } = payload;
    const response = await axiosInstance.get(API_URLS.GET_SKU + '?id=' + id);
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};

export const deleteSkuData = async (payload: { id: number | undefined }) => {
  try {
    startLoader();
    const { id } = payload;
    const response = await axiosInstance.delete(API_URLS.DELETE_SKU + '?id=' + id);
    if (response.success) {
      stopLoader();
      return response;
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.errType || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.errType);
    }
  }

  return null;
};

export const upsertSKUData = async (payload: {
  isEdit: boolean;
  data: {
    sku: {
      name: string | undefined;
      price: string | undefined;
      priceCurrencyTypeId: number | undefined;
      entitlements:
        | {
            [key: string]: number;
          }
        | undefined;
      platformNames: string[];
      isEnabled: boolean | undefined;
      isBaseSku: boolean | undefined;
      description: string | undefined;
    };
  };
}) => {
  try {
    startLoader();
    if (!payload.isEdit) {
      const response = await axiosInstance.post(API_URLS.ADD_SKU, payload.data);
      if (response.success) {
        stopLoader();
        return response;
      }
    } else {
      const response = await axiosInstance.put(API_URLS.UPDATE_SKU, payload.data);
      if (response.success) {
        stopLoader();
        return response;
      }
    }
  } catch (e: unknown | AxiosError) {
    stopLoader();
    if (typeof e === 'string') {
      toast.error(e.toUpperCase());
    } else if (e instanceof Error) {
      toast.error((e as AxiosError)?.response?.data?.message || e.message);
    } else {
      toast.error((e as AxiosError).response?.data?.message);
    }
  }

  return null;
};
