import React, { Fragment, ReactElement, useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/rootReducer';
import {
  showChainRuleModalAction,
  showChainRuleModalInterface,
  showCreatePromoCodeModalAction,
} from '../../../redux/actions/modalActions/modalActions';
import { toast } from 'react-toastify';
import moment, { Moment } from 'moment-timezone';
import { Field, Form } from 'react-final-form';
import InputWrapper from '../../../component/UI/Input';
import { composeValidators, required, isUpperCase, maxLength, isValidAdvertiserID } from '../../../utils/validations';
import CheckBoxWrapper from '../../UI/CheckBoxWrapper';
import { getPlatformList, getPromoCodeData, UpsertPromoCode, upsertPromoCodeData } from '../../../services/promoCode.service';
import { API_URLS } from '../../../constants/url';
import { getPromoTypeList } from '../../../services/promoCodeType.service';
import DateWrapper from '../../../component/UI/DateWrapper';
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import SelectWrapper from '../../UI/SelectWrapper';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import InputFieldsTooltip from '../../InputFieldsTooltip';
import { ChevronRight, ExpandMore, Help } from '@mui/icons-material';
import { TreeItem, TreeView } from '@mui/lab';
import { getChainRule } from '../../../services/promoCodeChainRule.service';

interface PromoType {
  name?: string;
  promoCodeId?: number;
  code?: string;
  PromoCodeTypeId?: {
    label?: string;
    value: number;
  };
  description?: string;
  shortDescription?: string;
  instanceExpiryMins?: string;
  instanceExpiryHrs?: string;
  instanceExpiryDays?: string;
  partnerTagId?: string;
  advertiserId?: string;
  platformNames?: [];
  startTsz?: Date;
  endTsz?: Date;
  public?: boolean;
  usageCount?: number;
  tagId?: number;
  created?: Date;
  updated?: number;
  meta?: {
    ruleId?: number;
    isChainStart?: boolean;
    isChained?: boolean;
    hide_code?: boolean;
  };
  conceal?: boolean;
}
// interface promoCodeType {
//   promoCodeTypeId: number;
//   promoCodeTypeName: string;
// }

interface chainRuleInterfaceJson {
  ruleId?: number;
  trigger?: string;
  expirationStrategy?: string;
  type?: string;
  parent?: string;
  chain?: Array<string>;
  item?: showChainRuleModalInterface;
}

interface promoCodeTypeInterface {
  promoCodeTypeName: string;
  promoCodeTypeId: number;
}

interface promoCodeTypesT {
  label: string;
  value: string | number;
}

interface getRuleDataInterface {
  metadata: {
    ruleId: number;
    isChained: boolean;
    isChainStart: boolean;
  };
  code: string;
}
const CreatePromoCode = (): ReactElement => {
  const createPromoCodeData = useSelector((state: RootState) => state.modalReducer.showCreatePromoCode);

  const editPromoData = createPromoCodeData.data;
  const isReadable = createPromoCodeData.isReadable;

  const id = editPromoData?.id || editPromoData?.promoCodeId;

  const [promoCodeType, setPromoCodeType] = useState<promoCodeTypesT[]>([]);
  const dispatch = useDispatch();

  const chainRuleModalData = useSelector((state: RootState) => state.modalReducer.showChainRule);

  const [PromoData, setPromoData] = useState<PromoType>({
    // startTsz: new Date(),
    // endTsz: new Date(),
    public: false,
  });

  const [chainRuleData, setChainRuleData] = useState<chainRuleInterfaceJson | undefined>({});

  const useStyles = makeStyles({
    divider: {
      backgroundColor: 'black',
      borderBottomWidth: 2,
      marginTop: '25px',
      marginBottom: '25px',
    },

    createButton: {
      backgroundColor: 'rgb(50, 205, 50)',
      color: 'black',
      width: '250px',
      height: '50px',
      textTransform: 'none',
    },

    gridStyle: {
      display: 'flex',
      marginBottom: '20px',
    },

    typographySpacing: {
      marginRight: '20px',
    },
  });
  const classes = useStyles();

  const [platform, setPlatform] = React.useState<string[]>([]);
  const [platformListDisplay, setPlatformList] = React.useState<string[]>([]);
  const currentDateTime = moment().format('MM/DD/yyyy HH:mm:ss');
  const nextYearDateTime = moment().add(1, 'years').format('MM/DD/yyyy HH:mm:ss');

  const handleChange = (event: SelectChangeEvent<typeof platform>) => {
    const {
      target: { value },
    } = event;
    setPlatform(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleClose = () => {
    dispatch(showCreatePromoCodeModalAction(false, null));
    setPromoData({ public: false });
    setChainRuleData({});
    setPlatform([]);
  };

  const menuItems: Array<object> = [];
  promoCodeType.map((data) => {
    menuItems.push({ item: data.label, columnName: data.value });
  });

  function estToUtc(date: Date) {
    const estDate = moment(date).utc(false).tz('America/New_York').format();
    const utcDate = moment.utc(estDate).toDate();
    return utcDate;
  }

  function utcToEst(date: Moment) {
    const serverDate = moment(date).utc(false).tz('America/New_York').toDate();
    return serverDate;
  }

  const handleCreateSubmit = async (value: PromoType) => {
    try {
      const instanceExpiryMinsFinal =
        parseInt(value.instanceExpiryDays ? value.instanceExpiryDays : '') * 24 * 60 +
        parseInt(value.instanceExpiryHrs ? value.instanceExpiryHrs : '') * 60 +
        parseInt(value.instanceExpiryMins ? value.instanceExpiryMins : '');
      const data: UpsertPromoCode = {
        promocode: {
          code: value.code,
          promoCodeTypeId: Number(value.PromoCodeTypeId ? value.PromoCodeTypeId.value : 0),
          description: value.description,
          shortDescription: value.shortDescription,
          startTsz: value.startTsz && estToUtc(value.startTsz),
          endTsz: value.endTsz && estToUtc(value.endTsz),
          instanceExpiryMins: Number(instanceExpiryMinsFinal),
          partnerTagId: value.partnerTagId,
          platformNames: platform,
          metadata: { hide_code: value.conceal },
          advertiserId: value.advertiserId,
          usageCount: Number(value.usageCount),
          public: value.public || false,
          created: new Date(),
          updated: new Date(),
        },
      };
      const res = await upsertPromoCodeData({
        isEdit: false,
        data,
      });
      if (res) {
        toast.success('Successfully created Promo Code!');
        handleClose();
      }
    } catch (e) {
      toast.error('Something Went Wrong');
    }
  };

  const handleUpdateSubmit = async (value: PromoType) => {
    try {
      const instanceExpiryMinsFinal =
        parseInt(value.instanceExpiryDays ? value.instanceExpiryDays : '') * 24 * 60 +
        parseInt(value.instanceExpiryHrs ? value.instanceExpiryHrs : '') * 60 +
        parseInt(value.instanceExpiryMins ? value.instanceExpiryMins : '');
      if (PromoData && PromoData.meta) {
        PromoData.meta['hide_code'] = value.conceal;
      } else {
        PromoData.meta = {};
        PromoData.meta['isChainStart'] = false;
        PromoData.meta['isChained'] = false;
        PromoData.meta['ruleId'] = 0;
        PromoData.meta['hide_code'] = false;
      }
      const data: UpsertPromoCode = {
        promocode: {
          promoCodeId: Number(id),
          codeBeforeUpdate: PromoData.code,
          code: value.code,
          promoCodeTypeId: Number(value.PromoCodeTypeId ? value.PromoCodeTypeId.value : 0),
          description: value.description,
          shortDescription: value.shortDescription,
          startTsz: value.startTsz && estToUtc(value.startTsz),
          endTsz: value.endTsz && estToUtc(value.endTsz),
          instanceExpiryMins: Number(instanceExpiryMinsFinal),
          partnerTagId: value.partnerTagId,
          advertiserId: value.advertiserId,
          platformNames: platform,
          usageCount: Number(value.usageCount),
          public: value.public,
          created: PromoData.created,
          updated: new Date(),
          metadata: PromoData.meta,
        },
      };
      const res = await upsertPromoCodeData({ isEdit: true, data });
      if (res.data) {
        toast.success('Promo Code successfully updated!');
        handleClose();
      }
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  const getRuleData = async (editData: getRuleDataInterface) => {
    if (editData?.metadata?.ruleId && editData?.metadata?.ruleId !== 0) {
      const res = await getChainRule({ id: editData?.metadata?.ruleId });
      if (res?.data) {
        return {
          ruleId: res?.data?.ruleId,
          trigger: res?.data?.strategy?.trigger,
          expirationStrategy: res?.data?.strategy?.expirationStrategy,
          type: res?.data?.strategy?.parent === editData.code ? 'parent' : 'child',
          parent: res?.data?.strategy?.parent,
          chain: res?.data?.strategy?.chain?.join(' , '),
          item: res?.data,
        };
      }
    }
    return {};
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const characterCode = e.key;
    if (characterCode === 'Backspace') return;

    const characterNumber = Number(characterCode);

    if (e.currentTarget.name === 'instanceExpiryDays' || e.currentTarget.name === 'instanceExpiryHrs') {
      if (characterNumber >= 0 && characterNumber <= 9) {
        if (e.currentTarget.value && e.currentTarget.value.length) {
          return;
        }
      } else {
        e.preventDefault();
      }
    } else {
      if (characterNumber >= 0 && characterNumber <= 9) {
        if (e.currentTarget.value && e.currentTarget.value.length) {
          return;
        } else if (characterNumber === 0) {
          e.preventDefault();
        }
      } else {
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    const getPromoCodeTypeData = async () => {
      const res = await getPromoTypeList({ url: API_URLS.GET_PROMO_TYPE });
      if (res) {
        const promoCodeTypeData = res.data;
        const promoCodeTypeDataArray = promoCodeTypeData?.map((promoCodeType: promoCodeTypeInterface) => {
          return {
            label: promoCodeType.promoCodeTypeName,
            value: promoCodeType.promoCodeTypeId,
          };
        });
        setPromoCodeType(promoCodeTypeDataArray);
      } else {
        toast.error('Something Went Wrong');
      }
    };
    const getPlatforms = async () => {
      const res = await getPlatformList({ url: API_URLS.GET_PLATFORM_LIST });
      if (res) {
        const platformList = res.data.map((item: PromoType) => {
          return item.name;
        });
        setPlatformList(platformList);
      } else {
        toast.error('Something Went Wrong');
      }
    };
    if (createPromoCodeData.show && promoCodeType.length === 0) {
      getPromoCodeTypeData();
      getPlatforms();
    }
  }, [createPromoCodeData.show]);

  useEffect(() => {
    const getEditData = async () => {
      try {
        const res = await getPromoCodeData({
          id: id,
        });
        const findLabel = promoCodeType.find((data: promoCodeTypesT) => data.value === res.data.promoCodeTypeId)?.label;
        if (res) {
          const editData = res.data;

          const getPromoData = {
            promoCodeId: Number(editData.promoCodeId),
            code: editData.code,
            PromoCodeTypeId: {
              label: findLabel,
              value: editData.promoCodeTypeId,
            },
            description: editData.description,
            shortDescription: editData.shortDescription,
            instanceExpiryMins: editData.instanceExpiryMins,
            instanceExpiryHrs: '0',
            instanceExpiryDays: '0',
            platformNames: editData.platformNames,
            partnerTagId: editData.partnerTagId,
            advertiserId: editData.advertiserId,
            startTsz: utcToEst(moment.unix(editData.startTsz)),
            endTsz: utcToEst(moment.unix(editData.endTsz)),
            public: editData.public,
            usageCount: editData.usageCount,
            tagId: editData.tagId,
            created: editData.created,
            updated: editData.updated,
            meta: editData.metadata,
            conceal: editData.metadata?.hide_code,
          };
          const mins = getPromoData.instanceExpiryMins;
          const duration = moment.duration(mins, 'minutes');
          getPromoData.instanceExpiryDays = duration.days().toString();
          getPromoData.instanceExpiryHrs = duration.hours().toString();
          getPromoData.instanceExpiryMins = duration.minutes().toString();

          const chainData = await getRuleData(editData);
          setPlatform(getPromoData.platformNames);
          setPromoData(getPromoData);
          setChainRuleData(chainData);
        } else {
          toast.error('Something Went Wrong');
        }
      } catch (e) {
        toast.error('Something Went Wrong');
      }
    };
    if (id && promoCodeType.length !== 0) {
      getEditData();
    }
  }, [id, promoCodeType, chainRuleModalData.show]);

  return (
    <div>
      <Dialog open={createPromoCodeData.show} onClose={handleClose} fullWidth disableRestoreFocus>
        <DialogContent className="modal-body">
          <div className="modal-ct">
            <Typography align="center" variant="h5" style={{ marginBottom: '10px' }}>
              {id === undefined ? 'Create Promo Code' : 'Edit Promo Code'}
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right', marginTop: '-40px' }}>
              <CloseIcon />
            </IconButton>
            <Divider className={classes.divider} />
            <Grid item xs={12}>
              <Form onSubmit={id === undefined ? handleCreateSubmit : handleUpdateSubmit} initialValues={PromoData}>
                {({
                  handleSubmit,
                  // values
                }) => {
                  return (
                    <form method="post" onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Field
                            name="code"
                            component={InputWrapper}
                            type="text"
                            readOnly={isReadable}
                            validate={composeValidators(required, isUpperCase, maxLength(24))}
                            placeholder="Promo Code"
                            label={<InputFieldsTooltip title="Promo Code" description="Promo Code shown to or entered by a player." />}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="PromoCodeTypeId"
                            option={promoCodeType}
                            component={SelectWrapper}
                            validate={required}
                            readOnly={isReadable}
                            label={
                              <Fragment>
                                Promo Code Type
                                <Tooltip
                                  sx={{
                                    position: 'relative',
                                    bottom: '10px',
                                    right: '5px',
                                  }}
                                  title="Promo Code Type description"
                                  placement="right-start"
                                >
                                  <IconButton size="medium">
                                    <Help sx={{ fontSize: '20px' }} />
                                  </IconButton>
                                </Tooltip>
                              </Fragment>
                            }
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                },
                              },
                            }}
                          ></Field>
                        </Grid>

                        <Grid item xs={12}>
                          <Field
                            name="description"
                            component={InputWrapper}
                            type="text"
                            rows={3}
                            readOnly={isReadable}
                            multiline
                            validate={composeValidators(required, maxLength(128))}
                            placeholder="Description"
                            label={
                              <InputFieldsTooltip
                                title="Description"
                                description="The longer description of what the Promo Code offers, not shown in app."
                              />
                            }
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Field
                            name="shortDescription"
                            component={InputWrapper}
                            validate={composeValidators(required, maxLength(64))}
                            readOnly={isReadable}
                            type="text"
                            placeholder="Short Description"
                            label={
                              <InputFieldsTooltip
                                title="Short Description"
                                description="The “title” of the promo code shown to the user during deposit, not shown in app."
                              />
                            }
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <Field
                            name="startTsz"
                            component={DateWrapper}
                            inputFormat="MM/dd/yyyy HH:mm:ss"
                            defaultValue={currentDateTime}
                            readOnly={isReadable}
                            label={<InputFieldsTooltip title="Start Date" description="Start Date (EST) Description" />}
                          ></Field>
                        </Grid>

                        <Grid item xs={6}>
                          <Field
                            name="endTsz"
                            component={DateWrapper}
                            inputFormat="MM/dd/yyyy HH:mm:ss"
                            readOnly={isReadable}
                            defaultValue={nextYearDateTime}
                            label={<InputFieldsTooltip title="End Date" description="End Date (EST) Description" />}
                          ></Field>
                        </Grid>

                        <Grid container spacing={2} item xs={12} sx={{ display: 'flex' }}>
                          <Grid item xs={4}>
                            <Field
                              name="instanceExpiryDays"
                              component={InputWrapper}
                              validate={required}
                              readOnly={isReadable}
                              onKeyDown={handleKeypress}
                              placeholder="Days"
                              defaultValue={'1'}
                              label={
                                <InputFieldsTooltip
                                  enableNumber
                                  title="Instance Expiry Days"
                                  description="The amount of time after applying the promo code that the user can deposit to take advantage of the code."
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Field
                              name="instanceExpiryHrs"
                              component={InputWrapper}
                              validate={required}
                              onKeyDown={handleKeypress}
                              readOnly={isReadable}
                              placeholder="Hrs"
                              defaultValue={'0'}
                              label={
                                <InputFieldsTooltip
                                  enableNumber
                                  title="Instance Expiry Hrs"
                                  description="The amount of time after applying the promo code that the user can deposit to take advantage of the code."
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Field
                              name="instanceExpiryMins"
                              component={InputWrapper}
                              validate={required}
                              onKeyDown={handleKeypress}
                              readOnly={isReadable}
                              placeholder="Mins"
                              defaultValue={'0'}
                              label={
                                <InputFieldsTooltip
                                  enableNumber
                                  title="Instance Expiry Mins"
                                  description="The amount of time after applying the promo code that the user can deposit to take advantage of the code."
                                />
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Field name="platformIds">
                            {({ input, meta }) => (
                              <Tooltip
                                title={createPromoCodeData?.data?.chainRuleData?.isChained ? 'Can not edit platform for chained promocode' : ''}
                              >
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel id="demo-multiple-checkbox-label">
                                    <InputFieldsTooltip title="Select Platforms" description="Select Platforms Description" />
                                  </InputLabel>
                                  <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    readOnly={isReadable}
                                    value={platform}
                                    onChange={handleChange}
                                    name={input.name}
                                    disabled={createPromoCodeData?.data?.chainRuleData?.isChained}
                                    input={<OutlinedInput label="Select Platforms" />}
                                    error={meta.error && (meta.dirty || meta.submitFailed)}
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                      },
                                      transformOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      },
                                      marginThreshold: 2,
                                    }}
                                    renderValue={(selected: string[]) => (
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexWrap: 'wrap',
                                          gap: 0.5,
                                        }}
                                      >
                                        {selected.map((value: string) => (
                                          <Chip key={value} label={value} />
                                        ))}
                                      </Box>
                                    )}
                                  >
                                    {platformListDisplay?.map((data: string) => (
                                      <MenuItem key={data} value={data}>
                                        <Checkbox checked={platform.indexOf(data) > -1} />
                                        <ListItemText primary={data} />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {meta.error && (meta.dirty || meta.submitFailed) && (
                                    <FormHelperText error={meta.error !== ''}>{meta.error || meta.submitError}</FormHelperText>
                                  )}
                                </FormControl>
                              </Tooltip>
                            )}
                          </Field>
                        </Grid>

                        {PromoData.tagId ? (
                          <>
                            <Grid item xs={12}>
                              <Field
                                name="tagId"
                                component={InputWrapper}
                                type="text"
                                readOnly={isReadable}
                                validate={required}
                                placeholder="Tag ID"
                                label={<InputFieldsTooltip title="Tag ID" description="Tag ID Description" />}
                              />
                            </Grid>
                          </>
                        ) : (
                          ''
                        )}
                        {id && (
                          <Grid item xs={12}>
                            <Accordion>
                              <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography>Meta </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <TreeView
                                  aria-label="chain rule detail"
                                  defaultCollapseIcon={<ExpandMore />}
                                  defaultExpandIcon={<ChevronRight />}
                                  sx={{
                                    flexGrow: 1,
                                    overflowY: 'auto',
                                  }}
                                  disableSelection
                                >
                                  <TreeItem
                                    nodeId="1"
                                    label={
                                      <>
                                        Chain Rule &nbsp;
                                        {PromoData?.meta?.isChained ? (
                                          PromoData?.meta?.isChainStart ? (
                                            <Chip label="Parent" variant="filled" color="primary" />
                                          ) : (
                                            <Chip label="Child" variant="outlined" color="secondary" />
                                          )
                                        ) : null}
                                        {PromoData?.meta?.ruleId ? (
                                          PromoData?.meta?.ruleId !== 0 && <Chip label="Chained" variant="outlined" />
                                        ) : (
                                          <Chip label="Not Chained" />
                                        )}
                                      </>
                                    }
                                  >
                                    {PromoData?.meta?.ruleId
                                      ? PromoData?.meta?.ruleId !== 0 && (
                                          <>
                                            <TreeItem nodeId="2" label={`Rule Id : ${chainRuleData?.ruleId}`} />
                                            <TreeItem
                                              nodeId="3"
                                              label={`${
                                                chainRuleData?.type === 'parent'
                                                  ? 'Child Promo : ' + chainRuleData?.chain
                                                  : 'Parent Promo : ' + chainRuleData?.parent
                                              }`}
                                            />
                                            <TreeItem nodeId="4" label={`Trigger : ${chainRuleData?.trigger}`} />
                                            <TreeItem nodeId="5" label={`Executions Strategy : ${chainRuleData?.expirationStrategy}`} />
                                          </>
                                        )
                                      : null}
                                    <TreeItem
                                      nodeId="6"
                                      label={
                                        PromoData?.meta?.ruleId ? (
                                          PromoData?.meta?.ruleId !== 0 && (
                                            <Button
                                              variant="contained"
                                              onClick={() => {
                                                dispatch(showChainRuleModalAction(true, chainRuleData?.item));
                                              }}
                                            >
                                              Update Chain Rule
                                            </Button>
                                          )
                                        ) : (
                                          <Button
                                            variant="contained"
                                            disabled={isReadable}
                                            onClick={() => {
                                              dispatch(
                                                showChainRuleModalAction(true, {
                                                  parent: PromoData.code,
                                                }),
                                              );
                                            }}
                                          >
                                            Create Chain Rule
                                          </Button>
                                        )
                                      }
                                    />
                                  </TreeItem>
                                </TreeView>
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                              <Typography>Advanced </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid sx={{ display: 'flex', columnGap: 2 }}>
                                <Grid item xs={6}>
                                  <Field
                                    name="partnerTagId"
                                    component={InputWrapper}
                                    validate={composeValidators(maxLength(128))}
                                    type="text"
                                    readOnly={isReadable}
                                    placeholder="Partner Tag ID"
                                    label={
                                      <InputFieldsTooltip
                                        title="Partner Tag ID"
                                        description="The partnertag to be assigned to users who use this promo code. - Optional."
                                      />
                                    }
                                  />
                                </Grid>

                                <Grid item xs={6}>
                                  <Field
                                    name="advertiserId"
                                    component={InputWrapper}
                                    type="text"
                                    readOnly={isReadable}
                                    validate={composeValidators(maxLength(5), isValidAdvertiserID)}
                                    placeholder="Advertiser ID"
                                    label={
                                      <InputFieldsTooltip
                                        title="Advertiser ID"
                                        description="The advertiser ID to be assigned to users who use this promo code. Must be five alphanumeric characters with the first two characters “21.” Example: 21jea - Optional"
                                      />
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sx={{ marginTop: '5%' }}>
                                <Field
                                  name="usageCount"
                                  component={InputWrapper}
                                  readOnly={isReadable}
                                  validate={required}
                                  defaultValue={1}
                                  onKeyDown={handleKeypress}
                                  placeholder="Usage Count"
                                  label={<InputFieldsTooltip title="Usage Count" description="The Usage Count Description" />}
                                />
                                <Grid sx={{ display: 'flex', marginTop: '4%' }}>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      paddingTop: '7px',
                                      marginRight: '10px',
                                    }}
                                  >
                                    <InputFieldsTooltip title="ConCeal" description="PromoCode Is ConCeal Or Not" />
                                  </Typography>
                                  <Field name="conceal" component={CheckBoxWrapper} disabled={isReadable} type="checkbox" />
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex' }}>
                          <Typography
                            variant="h6"
                            style={{
                              paddingTop: '7px',
                              marginRight: '10px',
                            }}
                          >
                            <InputFieldsTooltip title="Enabled" description="PromoCode Is Enabled Or Not" />
                          </Typography>
                          <Field name="public" component={CheckBoxWrapper} type="checkbox" disabled={isReadable ? true : false} />
                        </Grid>
                        <Grid item xs={12}>
                          <Button variant="contained" type="submit" fullWidth disabled={isReadable}>
                            {id === undefined ? 'Create Promo Code' : 'Edit Promo Code'}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Form>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreatePromoCode;
