import React, { ReactElement } from 'react';

import WithdrawList from './WithdrawalsList';

/**
 * Pending withdraws
 * @returns {JSX.Element}
 * @constructor
 */
const PendingWithdrawals = (): ReactElement => {
  return <WithdrawList type="pending" />;
};

export default PendingWithdrawals;
