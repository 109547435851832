import { Reducer } from 'redux';
import * as constants from '../../actions/PlayerSupport/type';
import { DEFAULT_VAL } from '../../actions/type';
interface PlayerState {
  loading: boolean;
  data: any;
  success: boolean;
}

export const playerReducer: Reducer<PlayerState> = (state = DEFAULT_VAL, action) => {
  const { type, data } = action;
  switch (type) {
    case constants.PLAYER_API_REQ:
      return { ...state, loading: true };
    case constants.PLAYER_API_SUCCESS:
      return { ...state, loading: false, data: data, success: true };
    case constants.PLAYER_API_FAIL:
      return { ...state, loading: false, data: data, success: false };

    default:
      return state;
  }
};
